import React, { useState } from 'react';
import { FormControl } from '@mui/material';
import Select, { components } from 'react-select';
const { ValueContainer, Placeholder } = components;

/*Descripción:Este componente se encarga de desplegar la informacion del contenedor del booking seleccionado
Componente padre: EventosBookingTN
Componente hijo:
props: props.dataOption = informacion sobre las opciones del selectSearch
*/

export default function SelectedSearchContenedor(props) {
	// Se define el valor inicial de el SelectSearch
	const [defaultValue, setDefaultValue] = useState(
		props.dataOption[
			props.dataOption.findIndex(
				(obj) => obj.value === props.defaultValue.value
			)
		]
	);
	// Funcion para actualizar el contenedor seleccionado
	const handleChange = (event) => {
		props.actualizarContenedor(event.value);
		setDefaultValue(event);
	};

	// Se crean los estilos que tendrá el SelectSearch
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			height: '53px',
			border: state.isFocused ? '2px solid #002D58' : '1px solid #cccccc',
			boxShadow: state.isFocused ? 'none' : 'none',
			'&:hover': {
				border: '1px solid black',
			},
			background: props.disabled && 'white',
			fontSize: '13px',
			fontFamily: 'Source Sans Pro',
		}),
		placeholder: (provided, state) => ({
			...provided,
			position: 'absolute',
			top: state.hasValue || state.selectProps.inputValue ? -20 : '20%',
			transition: 'top 0.1s, font-size 0.1s',
			fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
			background: 'white',
			paddingLeft: '5px',
			paddingRight: '5px',
			fontFamily: 'Source Sans Pro',
		}),
		valueContainer: (provided, state) => ({
			...provided,
			overflow: 'visible',
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
		}),
		menu: (provided, state) => ({
			...provided,
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
			maxHeight: '200px',
		}),
		menuList: (provided, state) => ({
			...provided,
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
			maxHeight: '200px',
		}),
	};

	// Se crean los estilos para los elementos dentro del SelectSearch
	const CustomValueContainer = ({ children, ...props }) => {
		return (
			<ValueContainer {...props}>
				<Placeholder {...props} isFocused={props.isFocused}>
					{props.selectProps.placeholder}
				</Placeholder>
				{React.Children.map(children, (child) =>
					child && child.type !== Placeholder ? child : null
				)}
			</ValueContainer>
		);
	};

	return (
		<>
			<FormControl fullWidth>
				<Select
					labelId='demo-simple-select-required-label'
					label='Label'
					onChange={handleChange}
					autoWidth
					placeholder={props.customLabel + ' *'}
					noOptionsMessage={({ inputValue }) =>
						!inputValue ? 'value' : 'Valor no encontrado'
					}
					components={{
						ValueContainer: CustomValueContainer,
					}}
					isClearable={false}
					isSearchable={true}
					isDisabled={props.disabled}
					id={props.id}
					name={props.id}
					options={props.dataOption}
					value={defaultValue}
					styles={customStyles}
					required={true}
				/>
			</FormControl>
		</>
	);
}
