import MyImage from '../assets/img/login/img-login-one.jpg';
import MyImage2 from '../assets/img/login/img-login-two.png';
import MyImage3 from '../assets/img/login/img-login-three.png';
import MyImage4 from '../assets/img/login/img-login-four.png';
import MyImage5 from '../assets/img/login/img-login-five.png';
import MyImage6 from '../assets/img/login/img-login-six.png';
import MyImage7 from '../assets/img/login/img-login-seven.png';
import MyImage8 from '../assets/img/login/img-login-eight.png';
import MyImage9 from '../assets/img/login/img-login-nine.png';
import MyImage10 from '../assets/img/login/img-login-ten.png';
import MyImage11 from '../assets/img/login/img-login-eleven.png';
import { useState } from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';

/*Descripción:Este componente se encarga de renderizar una imagen aleatoria para el Login
Componente padre: Login.jsx
Componente hijo:
props: Sin uso de props
*/

export default function MyRandomLogin() {
	const [bgImage, setBgImage] = useState('');

	useEffect(() => {
		const images = [
			MyImage,
			MyImage2,
			MyImage3,
			MyImage4,
			MyImage5,
			MyImage6,
			MyImage7,
			MyImage8,
			MyImage9,
			MyImage10,
			MyImage11,
		]; // Lista de imágenes disponibles
		const randomIndex = Math.floor(Math.random() * images.length); // Genera un índice aleatorio
		setBgImage(images[randomIndex]); // Establece la imagen de fondo aleatoria
	}, []);

	return (
		<Grid
			item
			xs={false}
			sm={4}
			md={7}
			lg={7}
			xl={8}
			sx={{
				backgroundImage: `url(${bgImage})`, // Usa la imagen de fondo aleatoria
				backgroundRepeat: 'no-repeat',
				backgroundColor: (t) =>
					t.palette.mode === 'light'
						? t.palette.grey[50]
						: t.palette.grey[900],
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		/>
	);
}
