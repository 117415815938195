import React, { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import GetDataFetch from '../../get-data/GetDataFetch';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { Stack, Card, CardContent } from '@mui/material';
import LugarDeRecoleccion from './Cards/LugarDeRecoleccion';
import LugarDeEntrega from './Cards/LugarDeEntrega';
import CargasCartaPorte from './Cards/CargasCartaPorte';
import LinearProgress from '@mui/material/LinearProgress';
import { Snackbar } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Aereo Impo
Componente padre: CommonBooking
Componente hijo: MySnackBar //
	MyInfoStepper //
	InformacionCard
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición//
	props.getBookingsList = Funcion para obtener la lista de los bookings
*/

export default function EnviadoCartaPorte(props) {
	//Token
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');

	// Se crean las constantes para el uso del Card
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();

	//Alerta
	const [alertMessage, setAlertMessage] = useState({
		severity: '',
		message: '',
	});
	const [snackbar, setSnackbar] = useState({ open: false, message: ' ' });
	const [state] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});

	// Funcion para abrir y cerrar el Snackbar
	const handleOpenSnackBar = () => {
		setSnackbar({
			open: true,
			message: alertMessage,
		});
	};
	const handleCloseSnackbar = () => {
		setSnackbar({ open: false, message: '' });
	};

	//Headers
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);

	// fetch data
	const dataFetch = async () => {
		//Se comienza el loading del contenido
		setLoading(true);
		var url =
			'bookings/terrestre/nacional/cartaPorte/booking?booking=' +
			props.selectedBooking;

		// Se hace el Get Fetch con los datos
		setTimeout(async () => {
			await GetDataFetch(url, setLoading, setDataDetalles, navigate);
		}, 1000);
	};

	// useEffect Data Fetch
	useEffect(() => {
		dataFetch();
	}, [props.selectedBooking]);

	return (
		<>
			{loading ? (
				<Card
					sx={{
						width: '100%',
						maxHeight: '74vh',
						overflow: 'hidden',
					}}
				>
					<CardContent
						sx={{
							padding: '10px',
						}}
					>
						<LinearProgress />
					</CardContent>
				</Card>
			) : (
				<Stack
					direction='column'
					justifyContent='space-between'
					spacing={1}
					width={'100%'}
				>
					<Stack direction='row' spacing={2}>
						{/* Lugar de recoleccion */}
						<LugarDeRecoleccion dataDetalles={dataDetalles} />
						{/* Lugar de entrega */}
						<LugarDeEntrega dataDetalles={dataDetalles} />
					</Stack>

					{/*Cargas*/}
					<CargasCartaPorte
						dataDetalles={dataDetalles}
						selectedBooking={props.selectedBooking}
						alertMessage={alertMessage}
						setAlertMessage={setAlertMessage}
						handleOpenSnackBar={handleOpenSnackBar}
						handleCloseSnackbar={handleCloseSnackbar}
						dataFetch={dataFetch}
						hideEdit={false}
					/>
					{/* Snackbar */}
					<Snackbar
						open={snackbar.open}
						autoHideDuration={10000}
						onClose={handleCloseSnackbar}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
					>
						<Alert
							onClose={handleCloseSnackbar}
							severity={alertMessage.severity}
						>
							{alertMessage.message}
						</Alert>
					</Snackbar>
				</Stack>
			)}
		</>
	);
}
