import React, { useState, useEffect } from 'react';
import { Grid, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectSearchConsignatario from './SelectSearchs/SelectSearchConsignatario';
import ShowSelectSearchs from './ShowSelectSearchs';
import AgregarConsignatarioModal from '../../../catalogos/consignatarios/Modales/Consignatarios/AgregarConsignatarioModal';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import LoadingButton from '@mui/lab/LoadingButton';

/*Descripción:Este componente se encarga de desplegar la información del step y formulario Consignatario
Componente padre: InformationStepper
Componente hijo:
props: props.handleTextFieldChange = propiedad para actualizar el texto ingresado //
	props.handleNext = funcion para ir al siguiente step //
	props.data = información guardada a desplegar en el formulario //
	props.emptiesInputs = funcion para seleccionar el estilo //
	props.dataLada = información del selectSearch de la lada //
	props.ref = informacion de referencia de los validadores
*/

export default function ConsignatarioWithCatalog(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	// Funcion para obtener los datos de los contactos
	const [openModal, setOpenModal] = useState(false);
	const [indexContacto, setIndexContacto] = useState(0);
	const [pais, setPais] = useState('');
	const [indexPais, setIndexPais] = useState('');
	const [loadingButtonConsignatario, setLoadingButtonConsignatario] =
		useState(false);

	const dataOptionsConsignatarios =
		props.dataDetallesConsignatarios.consignatarios.map((option) => {
			return {
				value: option.cod_consignatario,
				label:
					'(ID:' +
					option.cod_consignatario +
					') ' +
					option.nombre_razon +
					', TAX ID:' +
					option.tax_id +
					' (' +
					option.pais +
					')',
			};
		});

	// Función para abrir y cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
		setLoadingButtonConsignatario(false);
	};
	const handleOpenModal = () => {
		setOpenModal(true);
		setLoadingButtonConsignatario(true);
	};

	// Funcion para obtener los datos de las direcciones
	const [indexDireccion, setIndexDireccion] = useState(0);
	const [infoConsignatarioNew, setInfoConsignatarioNew] = useState({
		nombre_razon: '',
		tax_id: '',
		pais: '',
	});

	// Funcion para actualizar el contenido del contenedor de equipo
	function actualizarPais(pais) {
		setPais(pais.value + '');
		setIndexPais(pais.label + '');
	}

	useEffect(() => {
		setInfoConsignatarioNew({
			...infoConsignatarioNew,
			pais: pais,
		});
	}, [pais]);

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			sx={{ minWidth: '360px' }}
			spacing={1}
			paddingTop={1}
		>
			<Stack
				direction={{
					xs: 'row',
					sm: 'row',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
				sx={{ justifyContent: 'space-between' }}
			>
				{/* Nombre del consignatario */}
				<Grid item width={'100%'}>
					<SelectSearchConsignatario
						setInfoConsignatario={props.setInfoConsignatario}
						infoConsignatario={props.infoConsignatario}
						required
						actualizarDato={props.actualizarConsignatario}
						customLabel={t('catalogos.consignatario.consignatario')}
						id='consignatario'
						dataOptionsConsignatarios={dataOptionsConsignatarios}
						emptiesInputs={props.emptiesInputs}
						// Variables de catalogos
						dataDetallesConsignatarios={
							props.dataDetallesConsignatarios
						}
						indexConsignatario={props.indexConsignatario}
						setIndexConsignatario={props.setIndexConsignatario}
						sameAsConsignee={props.sameAsConsignee}
					/>
				</Grid>
				<Grid item>
					<Tooltip title={t('botones.agregar')}>
						<LoadingButton
							variant='outlined'
							color='primary'
							id='BtnAgregarConsignatarioBL'
							onClick={handleOpenModal}
							loading={loadingButtonConsignatario}
							sx={{
								fontSize: '12px',
								marginTop: 3.5,
							}}
						>
							<DomainAddIcon />
						</LoadingButton>
					</Tooltip>
				</Grid>
				<AgregarConsignatarioModal
					infoConsignatario={infoConsignatarioNew}
					setInfoConsignatario={setInfoConsignatarioNew}
					actualizarPais={actualizarPais}
					indexPais={indexPais}
					setIndexPais={setIndexPais}
					dataDetallesPaises={props.dataDetallesPaises}
					handleCloseModal={handleCloseModal}
					openModal={openModal}
					handleClose={handleCloseModal}
					setOpenModal={setOpenModal}
					dataFetch={props.dataFetchConsignatarios}
					id={props.id}
					setAlertMessagePage={props.setAlertMessagePage}
					handleClickPage={props.handleOpenSnackbar}
					setLoadingButtonConsignatario={
						setLoadingButtonConsignatario
					}
				/>
			</Stack>
			{props.loadingContactos ||
			props.loadingDirecciones ||
			props.infoConsignatario === undefined ? (
				<></>
			) : (
				<ShowSelectSearchs
					dataOptionsConsignatarios={dataOptionsConsignatarios}
					indexConsignatario={props.indexConsignatario}
					dataDetallesContactos={props.dataContactos}
					actualizarContacto={props.actualizarContacto}
					dataOptionContactos={props.dataContactos.contactos}
					emptiesInputs={props.emptiesInputs}
					indexContacto={indexContacto}
					setIndexContacto={setIndexContacto}
					actualizarDireccion={props.actualizarDireccion}
					dataDetallesDirecciones={props.dataDirecciones}
					indexDireccion={indexDireccion}
					setIndexDireccion={setIndexDireccion}
					dataOptionDirecciones={props.dataDirecciones.direcciones}
					infoContacto={props.infoContacto}
					setInfoContacto={props.setInfoContacto}
					infoDireccion={props.infoDireccion}
					setInfoDireccion={props.setInfoDireccion}
					infoConsignatario={props.infoConsignatario}
					dataFetchContactos={props.dataFetchContactos}
					dataFetchDirecciones={props.dataFetchDirecciones}
					setAlertMessagePage={props.setAlertMessagePage}
					handleClickPage={props.handleOpenSnackbar}
				/>
			)}
		</Stack>
	);
}
