import * as React from 'react';
import { useState } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import SwitchTrackingComponent from '../track-and-trace/SwitchTrackingComponent';
import { useTranslation } from 'react-i18next';
import { Fade, IconButton, Tooltip, Box } from '@mui/material';
import { UploadFile, Visibility } from '@mui/icons-material';
import FileUploadModal from '../file-upload/FileUploadModal';
import { useNavigate } from 'react-router-dom';
import GetDocumentosFetch from '../get-data/GetDocumentosFetch';

/*Descripción:Este componente se encarga de renderizar la informacion de las filas de el DataGrid de bookings activos
Componente padre: ActiveBookingTable
Componente hijo: SwitchTrackingComponent
props: props.dataDetalles = detalles sobre el booking mostrados en el DataGrid //
	props.columns = columnas del DataGrid
*/

export default function ActiveBookingDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en ActiveBookingDataGrid
	const [letrasArray, setLetrasArray] = useState([]);
	const [palabra, setPalabra] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [openModalFiles, setOpenModalFiles] = useState(false);
	const [loadingDocumentos, setLoadingDocumentos] = useState(false);
	const [dataDetallesDocumentos, setDataDetallesDocumentos] = useState();
	const navigate = useNavigate();
	const [documento, setDocumento] = useState('');
	const [indexDocumento, setIndexDocumento] = useState('0');
	const [infoDocumento, setInfoDocumento] = useState({
		uiCodTipoDocumento: '',
		vDescripcionEspaniol: '',
		vDescripcionIngles: '',
	});
	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});

	// Funcion para actualizar el contenido del Documento
	function actualizarDocumento(documento) {
		setDocumento(documento.value + '');
		setIndexDocumento(documento.label + '');
	}

	//Datos para la tabla
	const tableData = [];
	var rowTitle;
	//Funcion para dar formato a la fecha
	function formato(fecha) {
		if (fecha !== '') {
			var info = fecha.split('/');
			return info[2] + '/' + info[1] + '/' + info[0];
		} else {
			return '';
		}
	}

	// Se declaran las columnas del DataGrid
	const columns = [
		{
			id: 'booking',
			field: 'booking',
			headerName: t('tablero.bookingsActivos.booking'),
			width: 135,
			editable: false,
		},
		{
			id: 'origen',
			field: 'origen',
			headerName: t('tablero.bookingsActivos.origen'),
			width: 130,
			editable: false,
			enabled: false,
		},
		{
			id: 'destinoFinal',
			field: 'destinoFinal',
			headerName: t('tablero.bookingsActivos.destino'),
			width: 130,
			editable: false,
			enabled: false,
		},
		{
			id: 'fechaAlta',
			field: 'fechaAlta',
			headerName: t('tablero.bookingsActivos.fechaAlta'),
			width: 100,
			renderCell: (params) => (
				<span title={params.value}>{formato(params.value)}</span>
			),
			editable: false,
			enabled: false,
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 100,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				return (
					<>
						<IconButton
							id='verMasIcon'
							className='primary'
							aria-label='add an alarm'
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								handleCellClick(params);
							}}
						>
							<Tooltip
								id='verMasIcon'
								title={t('catalogos.consignatario.verMas')}
							>
								<Visibility id='verMasIcon' color='primary' />
							</Tooltip>
						</IconButton>
						{props.role.includes('Cliente') && (
							<IconButton
								id='uploadFile'
								className='primary'
								aria-label='add an alarm'
								sx={{ cursor: 'pointer' }}
								onClick={() => {
									handleFileClick(params);
								}}
							>
								<Tooltip
									id='uploadFile'
									title={t('fileUpload.subirDocumento')}
								>
									<UploadFile
										id='uploadFile'
										color='primary'
									/>
								</Tooltip>
							</IconButton>
						)}
					</>
				);
			},
		},
	];

	// Funcion para obtener el booking al hacer click en una celda
	const handleCellClick = (params) => {
		rowTitle = params.row.booking;
		caracteresClave('tracking');
	};

	const handleFileClick = async (params) => {
		rowTitle = params.row.booking;
		caracteresClave('document');
	};

	// Funciones para abrir y cerrar el modal
	const handleOpenModalFiles = () => {
		setOpenModalFiles(true);
	};
	const handleCloseModalFiles = () => {
		setInfoDocumento({
			uiCodTipoDocumento: '',
			vDescripcionEspaniol: '',
			vDescripcionIngles: '',
		});
		setOpenModalFiles(false);
	};

	// Se recorre el array de bookings para obtener los datos de cada uno
	for (let i = 0; i < props.dataDetalles.booking.length; i++) {
		const factura = props.dataDetalles.booking[i];
		const fechaAlta = formato(factura['fecha-alta']);
		// Se valida si existe la factura
		if (factura) {
			// Si existe asigna los valores respectivos a la fila de datos
			const rowData = {
				id: `Booking ${i + 1}`,
				booking: factura.booking,
				origen: factura.origen,
				destinoFinal: factura['destino-final'],
				fechaAlta: fechaAlta,
			};
			// Se agrega la fila al array de datos
			tableData.push(rowData);
		}
	}

	//Funcion para obtener las letras clave de la palabra
	async function caracteresClave(type) {
		let letras = [];
		// Dependiendo del tamaño de la palabra se recolectan los caracteres relevantes del booking
		if (rowTitle.length === 18) {
			// Si es un RO tomamos los primeros 4 elementos del array y el ultimo
			letras = [rowTitle.slice(0, 4), rowTitle.slice(-1)];
		} else if (rowTitle.length === 17) {
			// Si es un booking "Normal" tomamos los primeros 3 elementos del array y el ultimo
			letras = [rowTitle.slice(0, 3), rowTitle.slice(-1)];
		} else if (rowTitle.length === 15) {
			// Si es un despacho tomamos los primeros 3 elementos del array y el ultimo
			letras = [rowTitle.slice(0, 3), rowTitle.slice(-1)];
		}
		// Se convierte el array de letras en un string y luego se convierte en un array de caracteres
		const letrasArray = letras.join('').split('');
		// Se asigna el array de letras a la variable global
		setLetrasArray(letrasArray);
		// Se asigna el booking a la variable global
		setPalabra(rowTitle);
		if (type === 'document') {
			setLoadingDocumentos(true);
			await GetDocumentosFetch(
				letrasArray,
				rowTitle,
				setLoadingDocumentos,
				setDataDetallesDocumentos,
				navigate
			);
			handleOpenModalFiles();
		} else if (type === 'tracking') {
			handleOpenModal();
		}
	}

	// Funciones para abrir y cerrar el modal
	const handleOpenModal = () => {
		// Condicion para caracteres minimos de busqueda
		if (palabra.length >= 15) {
			setOpenModal(true);
		}
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Funcion para traducir el DataGrid
	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<Fade in={true} timeout={700}>
			<Box style={{ height: '72vh', width: '100%' }}>
				<DataGrid
					disableRowSelectionOnClick
					rows={tableData}
					columns={columns}
					localeText={localeText()}
					initialState={{
						pagination: {
							paginationModel: {
								page: 0,
								pageSize: 10,
							},
						},
					}}
					pageSizeOptions={[10, 15, 20]}
					sx={{
						'& .MuiDataGrid-cell': {
							fontSize: '12px',
							fontFamily: 'Source Sans Pro',
						},
						'& .MuiDataGrid-columnHeaderTitle': {
							fontSize: '13px',
							fontFamily: 'Open Sans',
						},
					}}
				/>
				<SwitchTrackingComponent
					openModal={openModal}
					handleCloseModal={handleCloseModal}
					letrasArray={letrasArray}
					palabra={palabra}
					// props del FileUpload
					openModalFiles={openModalFiles}
					handleCloseModalFiles={handleCloseModalFiles}
					selectedBooking={palabra}
					dataDetallesDocumentos={dataDetallesDocumentos}
					infoDocumento={infoDocumento}
					actualizarDocumento={actualizarDocumento}
					documento={documento}
					indexDocumento={indexDocumento}
					setDocumento={setDocumento}
					setIndexDocumento={setIndexDocumento}
					setInfoDocumento={setInfoDocumento}
					state={state}
				/>
				{loadingDocumentos ? (
					// <LinearProgress />
					<></>
				) : (
					<>
						{dataDetallesDocumentos === undefined ? (
							<></>
						) : (
							<FileUploadModal
								modal={true}
								openModal={openModalFiles}
								handleCloseModal={handleCloseModalFiles}
								selectedBooking={palabra}
								dataDetallesDocumentos={dataDetallesDocumentos}
								infoDocumento={infoDocumento}
								actualizarDocumento={actualizarDocumento}
								documento={documento}
								indexDocumento={indexDocumento}
								setDocumento={setDocumento}
								setIndexDocumento={setIndexDocumento}
								setInfoDocumento={setInfoDocumento}
								state={state}
							/>
						)}
					</>
				)}
			</Box>
		</Fade>
	);
}
