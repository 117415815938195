import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar los eventos de Terrestre relacionados a Aéreo Impo
Componente padre: TimelineSelectorAereoImpo
Componente hijo: TimelineItem
props: props.dataTerrestre = informacion a desplegar sobre el Terrestre
*/

export default function TimelineEventosTerrestreImpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Timeline position='alternate'>
			{props.dataTerrestre['fecha-salida-puerto'] === 'N.D.' ||
			props.dataTerrestre['fecha-salida-puerto'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataTerrestre['fecha-salida-puerto']}
					title={t('eventos.salidaPuerto')}
				/>
			)}
			{props.dataTerrestre['fecha-llegada-planta-cliente'] === 'N.D.' ||
			props.dataTerrestre['fecha-llegada-planta-cliente'] ===
				undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataTerrestre['fecha-llegada-planta-cliente']}
					title={t('eventos.llegadaPlantaCliente')}
				/>
			)}
		</Timeline>
	);
}
