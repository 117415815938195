import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { darkTheme } from './darkTheme';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n'; // Import your i18n configuration

/*Descripción:Este componente se encarga de renderizar el index general
Componente padre: Global
Componente hijo: App.js
props: Sin uso de props
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeProvider theme={theme}>
		<CookiesProvider>
			<App />
		</CookiesProvider>
	</ThemeProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
