import { Box, Stack, Fade } from '@mui/material';
import React from 'react';
import ErrorBoundary from '../../components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import Img1 from '../../assets/img/cards/CardFotos/Uno.png';
import Img2 from '../../assets/img/cards/CardFotos/Dos.png';
// import Img13 from '../../assets/img/cards/CardFotos/Trece.png';
// import Img14 from '../../assets/img/cards/CardFotos/Catorce.png';
import CardCatalogo from '../../components/cards/CardCatalogo';
import BoxTitle from '../../components/titles/BoxTitle';

/*Descripción:Este componente muestra los catálogos que están disponibles para este cliente.
Componente padre: CommonLayout.jsx
Componente hijo:
props: Sin uso de props
*/

export default function IndexCatalogos() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		// Carga el contenido de la página======================
		<ErrorBoundary>
			<Box>
				<BoxTitle title={t('paginas.catalogos')} />
				<Box sx={{ width: '100%', paddingTop: '1%' }}>
					<Fade in={true} timeout={500}>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'row',
								lg: 'row',
								xl: 'row',
							}}
							spacing={3}
						>
							<Stack
								direction={{
									xs: 'row',
									sm: 'row',
									md: 'row',
									lg: 'row',
									xl: 'row',
								}}
								spacing={3}
							>
								<CardCatalogo
									title={t('catalogos.productos.titulo')}
									image={Img1}
									url='/configuracion/catalogos/productos'
								/>
								<CardCatalogo
									title={t('catalogos.consignatario.titulo')}
									image={Img2}
									url='/configuracion/catalogos/consignatario'
								/>
							</Stack>
							{/* <Stack
								direction={{
									xs: 'row',
									sm: 'row',
									md: 'row',
									lg: 'row',
									xl: 'row',
								}}
								spacing={3}
							>
								<CardCatalogo
									title={t('catalogos.misDirecciones.titulo')}
									image={Img14}
									url='/configuracion/catalogos/mis-direcciones'
								/>
								<CardCatalogo
									title={t('catalogos.misEmbalajes.titulo')}
									image={Img13}
									url='/configuracion/catalogos/embalajes'
								/>
							</Stack>
							<Stack
								direction={{
									xs: 'row',
									sm: 'row',
									md: 'row',
									lg: 'row',
									xl: 'row',
								}}
								spacing={3}
							>
								<CardCatalogo
									title={t(
										'catalogos.productosCartaPorte.titulo'
									)}
									image={Img1}
									url='/configuracion/catalogos/productos-cartaporte'
								/>
							</Stack> */}
						</Stack>
					</Fade>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
