import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import SelectSearchCiudades from '../SelectSearchCiudades';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

/*Descripción:Este componente se encarga de mostrar el formulario para agregar o editar una dirección.
Componente padre: EditarDireccionesLModal.jsx // AgregarDireccionesModal.jsx
Componente hijo: SelectSearchCiudades.jsx
props: props.actualizarDato = funcion para actualizar el estado de los campos del formulario //
props.dataCiudades = Información de las ciudades // props.actualizarCiudad = funcion para actualizar la ciudad //
props.handleTextFieldChange = funcion para actualizar el estado de los campos del formulario //
props.infoDireccion = Información de la dirección // props.dataDetallesDirecciones = Información de las direcciones //
*/

export default function DireccionForm(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [dataOptions, setDataOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		const arr = [];
		props.dataCiudades.catalogo.map((ciudad, index) => {
			// Se valida que el index de las secuencias exista
			return arr.push({
				value: ciudad.id,
				label: ciudad.ciudad,
			});
		});
		setDataOptions(arr);
		setLoading(false);
	}, [props.dataCiudades]);

	return (
		<Grid container rowSpacing={1.5} columnSpacing={{ md: 2, lg: 2 }}>
			{loading ? (
				<LinearProgress />
			) : (
				<>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<SelectSearchCiudades
							required
							actualizarDato={props.actualizarDato}
							customLabel={t('dataGrid.ciudad')}
							id='ciudad'
							dataOptions={dataOptions}
							name='factura'
							defaultValue={props.infoDireccion['cod_ciudad']}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography variant='subtitle2'>
							{t('catalogos.consignatario.direccionCompleta')}
						</Typography>
						<TextValidator
							validators={['required', 'minStringLength:3']}
							errorMessages={[
								t('errorMessages.campoRequerido'),
								'Calle invalida',
							]}
							onChange={props.handleTextFieldChange('calle')}
							required
							id='calle'
							name='calle'
							multiline
							rows={8}
							fullWidth
							value={props.infoDireccion.calle || ''}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<FmdGoodIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography
							variant='subtitle2'
							class='Consignatario-Card-Title'
							color='initial'
						>
							<InfoIcon fontSize='small' color='primary' />{' '}
							{t('catalogos.consignatario.mensajeDireccion')}
						</Typography>
					</Grid>
				</>
			)}
		</Grid>
	);
}
