import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';

export default function SearchBar({ onSearch }) {
	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState('');

	const handleSearchChange = (event) => {
		const searchTerm = event.target.value.replace(/\s/g, '').toUpperCase();
		setInputValue(searchTerm);
		onSearch(searchTerm);
	};

	return (
		<TextField
			id='inputSearchBooking'
			label={t('booking.buscarBooking')}
			variant='outlined'
			size='small'
			value={inputValue}
			onChange={handleSearchChange}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start'>
						<SearchIcon />
					</InputAdornment>
				),
			}}
		/>
	);
}
