import React from 'react';
import { Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información correspondiente a los contenedores
Componente padre: EventosBookingMaritimoExpo
Componente hijo:
props: props.data = informacion a desplegar //
	props.dataContenedor = informacion a desplegar sobre el contenedor //
	props.dataDespacho = informacion a desplegar sobre el despacho
*/

export default function InfoContenedorMaritimoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid item container spacing={1} lg={6} md={6} sm={6}>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.fechaZarpe')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.eta}
				</Typography>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.fechaEstimadaPuertoDescarga')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data['eta-destino']}
				</Typography>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.buque')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data['nombre-buque']}
				</Typography>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.lineaNaviera')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.linea}
				</Typography>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.masterBl')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.masterBL}
				</Typography>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.viaje')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.viaje}
				</Typography>
			</Grid>
			{props.role.includes('Cliente') && (
				<>
					{props.dataDespacho['resultado-modulacion'] !==
						undefined && (
						<Grid item lg={12} md={12} sm={12} xs={4}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.estatus.modulacion')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.dataDespacho['resultado-modulacion'] !==
								undefined ? (
									<>
										{props.dataDespacho[
											'resultado-modulacion'
										] === 'V' ? (
											<CircleIcon color='success' />
										) : (
											<>
												{props.dataDespacho[
													'resultado-modulacion'
												] === 'R' && (
													<CircleIcon
														sx={{ color: 'red' }}
													/>
												)}{' '}
											</>
										)}
									</>
								) : (
									<>
										<CircleIcon color='disabled' />
									</>
								)}
							</Typography>
						</Grid>
					)}
				</>
			)}

			{props.data.informacionCarga['carga-peligrosa'] !== undefined ? (
				<>
					{props.data.informacionCarga['carga-peligrosa'] === 'N' ? (
						<></>
					) : (
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.estatus.cargaPeligrosa')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.informacionCarga[
									'carga-peligrosa'
								] === 'S' && (
									<Warning sx={{ color: 'red' }} />
								)}{' '}
							</Typography>
						</Grid>
					)}
				</>
			) : (
				<></>
			)}
		</Grid>
	);
}
