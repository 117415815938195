import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@mui/material/Alert';
import InformationStepper from './InformationStepper';
import GetDataFetch from '../../get-data/GetDataFetch';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MySkeletonTitle from '../../Skeletons/SkeletonTitle';
import InformacionCard from './InformacionCard';
import { Snackbar } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Cookies from 'universal-cookie';
import ActionButton from './ActionButton';
import { Grid, Hidden } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Aereo Impo
Componente padre: CommonBooking
Componente hijo: MySnackBar //
	MyInfoStepper //
	InformacionCard
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición//
	props.getBookingsList = Funcion para obtener la lista de los bookings
*/

export default function PendienteCartaPorte(props) {
	const { t } = useTranslation();
	//Token
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Se crean las constantes para el uso del Card
	const [loading, setLoading] = useState(true);
	const [LoadingButton, setLoadingButton] = useState(false);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	//Paso actual de la carta porte
	const [activeStep, setActiveStep] = useState(0);
	//MisDirecciones
	const [loadingMisDirecciones, setloadingMisDirecciones] = useState(true);
	const [dataMisDirecciones, setDataMisDirecciones] = useState({});
	//Modal
	const [openModal, setOpenModal] = useState(false);
	//Alerta
	const [alertMessage, setAlertMessage] = useState({
		severity: '',
		message: '',
	});
	const [snackbar, setSnackbar] = useState({ open: false, message: ' ' });
	const [state] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	// Funcion para abrir y cerrar el Snackbar
	const handleOpenSnackBar = () => {
		setSnackbar({
			open: true,
			message: alertMessage,
		});
	};
	const handleCloseSnackbar = () => {
		setSnackbar({ open: false, message: '' });
	};
	//Actualizar datos de la carta porte
	function actualizarCartaPorte(name, value) {
		setDataDetalles({
			...dataDetalles,
			[name]: value,
		});
	}
	//Headers
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);

	// fetch data
	const dataFetch = async () => {
		//Se comienza el loading del contenido
		setLoading(true);
		var url =
			'bookings/terrestre/nacional/cartaPorte/booking?booking=' +
			props.selectedBooking;

		// Se hace el Get Fetch con los datos
		setTimeout(async () => {
			await GetDataFetch(url, setLoading, setDataDetalles, navigate);
		}, 1000);
	};

	//Data fetch mis direcciones
	const dataFetchMisDirecciones = async () => {
		//Se comienza el loading del contenido
		setloadingMisDirecciones(true);
		var url = 'catalogos/direcciones/all';
		// Se hace el Get Fetch con los datos
		await GetDataFetch(
			url,
			setloadingMisDirecciones,
			setDataMisDirecciones,
			navigate
		);
	};

	// Se hace el llamado a la funcion para obtener los datos
	useEffect(() => {
		dataFetch();
		setActiveStep(0);
	}, [props.selectedBooking]);

	// Se hace el llamado a la funcion para obtener los datos
	useEffect(() => {
		dataFetchMisDirecciones();
	}, []);

	// Funcion para validar las direcciones de recoleccion y entrega
	function validaDirecciones() {
		if (
			dataDetalles.lugarRecoleccion !== null &&
			dataDetalles.lugarEntrega !== null &&
			dataDetalles.lugarRecoleccion === dataDetalles.lugarEntrega
		) {
			return false;
		} else {
			return true;
		}
	}

	//Valida que la informacion este completa
	function validaInformacionCompleta() {
		var validacion = true;
		// Valida que todos los campos del formulario sean llenados
		Object.entries(dataDetalles).forEach(([key, value]) => {
			// Si no estan completamente llenados los marca como vacios
			if (value === '' || value === null || value === undefined) {
				validacion = false;
			}
		});
		// Si estan completamente llenados acciona el Alert
		if (dataDetalles.cargas.length === 0) {
			validacion = false;
		}

		return validacion;
	}

	//Guarda los datos nuevos de la carta porte
	async function updateCartaPorte() {
		var tempData = {};
		for (var i in dataDetalles) tempData[i] = dataDetalles[i];
		delete tempData['vNombreRecoleccion'];
		delete tempData['vRfcRecoleccion'];
		delete tempData['vCalleRecoleccion'];
		delete tempData['nCodigoPostalRecoleccion'];
		delete tempData['vColoniaRecoleccion'];
		delete tempData['vCiudadRecoleccion'];
		delete tempData['vEstadoRecoleccion'];
		delete tempData['vNombreEntrega'];
		delete tempData['vRfcEntrega'];
		delete tempData['vCalleEntrega'];
		delete tempData['nCodigoPostalEntrega'];
		delete tempData['vColoniaEntrega'];
		delete tempData['vCiudadEntrega'];
		delete tempData['vEstadoEntrega'];
		delete tempData['completedLugarEntrega'];
		delete tempData['completedLugarRecoleccion'];
		delete tempData['cargas'];

		const requestOptionsGuardar = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify(tempData),
			redirect: 'follow',
		};

		if (validaDirecciones() === true) {
			// Se hace el Data Fetch
			fetch(
				// Se hace la peticion al endpoint
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'bookings/terrestre/nacional/cartaPorte/update',
				requestOptionsGuardar
			)
				.then((response) => {
					// Se valida el status de la respuesta
					if (response.status === 200) {
						// Se muestra el mesnaje de exito
						setAlertMessage({
							severity: 'success',
							message: t('alertas.cartaPorteGuardada'),
						});
						handleOpenSnackBar();
						// Se actualiza la lista de bookings
						dataFetch();
					} else if (response.status === 401) {
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						// Se muestra el mensaje de error
						setAlertMessage({
							severity: 'error',
							message: t('alertas.cartaPorteGuardadaError'),
						});
						handleOpenSnackBar();
					}
				})
				.catch((error) => console.log('error', error));
		} else {
			setAlertMessage({
				severity: 'error',
				message: t('alertas.direccionesIguales'),
			});
			handleOpenSnackBar();
		}
	}

	//Confirma los datos de la carta porte
	async function confirmarCartaPorte() {
		setLoadingButton(true);
		const requestOptionsGuardar = {
			method: 'PATCH',
			headers: myHeaders,
			redirect: 'follow',
		};

		if (validaInformacionCompleta() === true) {
			//Se hace el Data Fetch
			fetch(
				// Se hace la peticion al endpoint
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'bookings/terrestre/nacional/cartaPorte/confirm?booking=' +
					props.selectedBooking,
				requestOptionsGuardar
			)
				.then((response) => {
					// Se valida el status de la respuesta
					if (response.status === 200) {
						// Se muestra el mesnaje de exito
						setAlertMessage({
							severity: 'success',
							message: t('alertas.cartaPorteEnviada'),
						});
						handleOpenSnackBar();
						// Se actualiza la lista de bookings
						setTimeout(() => {
							props.getBookingsList();
						}, 2000);
						setTimeout(() => {
							setLoadingButton(false);
						}, 2000);
					} else if (response.status === 401) {
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						// Se muestra el mensaje de error
						setAlertMessage({
							severity: 'error',
							message: t('alertas.cartaPorteEnviadaError'),
						});
						handleOpenSnackBar();
						setLoadingButton(false);
					}
				})
				.catch((error) => console.log('error', error));
		} else {
			setAlertMessage({
				severity: 'error',
				message: t('alertas.cartaPorteSinDatos'),
			});
			handleOpenSnackBar();
			setLoadingButton(false);
		}
	}

	return (
		<>
			<CssBaseline />
			<Container
				rowSpacing={1}
				sx={{
					height: '72vh',
					paddingLeft: '0px',
					paddingRight: '0px',
				}}
				maxWidth={false}
				disableGutters
			>
				<Card
					sx={{
						width: '100%',
						maxHeight: '74vh',
						overflow: 'hidden',
					}}
				>
					<CardContent
						sx={{
							padding: '10px',
						}}
					>
						<Grid
							item
							container
							paddingBottom={2}
							justifyContent={'space-between'}
						>
							<Grid item>
								{loadingMisDirecciones ? (
									<MySkeletonTitle />
								) : (
									<Typography variant='h6'>
										{props.selectedBooking}
									</Typography>
								)}
							</Grid>
							<Grid item>
								<ActionButton
									confirmarCartaPorte={confirmarCartaPorte}
									updateCartaPorte={updateCartaPorte}
									dataDetalles={dataDetalles}
									setOpenModal={setOpenModal}
									openModal={openModal}
									selectedBooking={props.selectedBooking}
									loadingButton={LoadingButton}
								/>
							</Grid>
						</Grid>

						<Grid item container rowSpacing={2} columnSpacing={2}>
							<Grid item lg={9} sx={12} sm={12} md={12}>
								{loading ? (
									<LinearProgress />
								) : (
									<InformationStepper
										activeStep={activeStep}
										setActiveStep={setActiveStep}
										dataFetch={dataFetch}
										updateCartaPorte={updateCartaPorte}
										actualizarCartaPorte={
											actualizarCartaPorte
										}
										alertMessage={alertMessage}
										setAlertMessage={setAlertMessage}
										handleOpenSnackBar={handleOpenSnackBar}
										handleCloseSnackbar={
											handleCloseSnackbar
										}
										setOpenModal={setOpenModal}
										openModal={openModal}
										dataMisDirecciones={dataMisDirecciones}
										dataDetalles={dataDetalles}
										selectedBooking={props.selectedBooking}
										endpoint={props.endpoint}
										getBookingsList={props.getBookingsList}
										dataFetchMisDirecciones={
											dataFetchMisDirecciones
										}
									/>
								)}
							</Grid>
							<Grid item lg={3} sx={12}>
								<Hidden lgDown>
									<InformacionCard
										selectedBooking={props.selectedBooking}
									/>
								</Hidden>
							</Grid>
						</Grid>
						<Snackbar
							open={snackbar.open}
							autoHideDuration={10000}
							onClose={handleCloseSnackbar}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
						>
							<Alert
								onClose={handleCloseSnackbar}
								severity={alertMessage.severity}
							>
								{alertMessage.message}
							</Alert>
						</Snackbar>
					</CardContent>
				</Card>
			</Container>
		</>
	);
}
