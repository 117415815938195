import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import BoxTitle from '../../../components/titles/BoxTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../../components/get-data/GetDataFetch';
import MySkeletonPage from '../../../components/Skeletons/SkeletonPage';
import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import ErrorBoundary from '../../../components/ErrorBoundary';
import DataGridMisEmbalajes from '../../../components/catalogos/mis-embalajes/DataGridMisEmbalajes';
import AgregarEmbalaje from '../../../components/catalogos/mis-embalajes/Modales/AgregarEmbalaje';
import AgregarEmbalajeExcelTemplate from '../../../components/catalogos/mis-embalajes/Modales/AgregarEmbalajeExcelTemplate';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente muestra el catálogo de Embalajes disponibles para el cliente.
Componente padre: CommonLayout.jsx
Componente hijo: DataGridProductos.jsx
props: props
*/

export default function MisEmbalajesList() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [dataDetalles, setDataDetalles] = useState({});
	// Se crea el modelo de los datos de la carga
	const [infoEmbalaje, setInfoEmbalaje] = useState({
		vAlias: '',
		vDescripcion: '',
		vClaveSat: '',
		bPeligroso: 0,
		vClaveSatPeligroso: '',
	});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [alertMessage, setAlertMessage] = useState({
		severity: '',
		message: '',
	});
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;
	const [openModal, setOpenModal] = useState(false);
	const [openModalCargaTemplate, setOpenModalCargaTemplate] = useState(false);

	// Se hace el Data Fetch
	const dataFetch = async () => {
		setLoading(true);
		let url = 'catalogos/embalajes/all';
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	// useEffect del Data Fetch
	useEffect(() => {
		dataFetch();
	}, []);

	// Funcion para abrir y cerrar el modal
	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Funcion para abrir y cerrar el modal de carga de template
	const handleOpenModalCargaTemplate = () => {
		setOpenModalCargaTemplate(true);
	};
	const handleCloseModalCargaTemplate = () => {
		setOpenModalCargaTemplate(false);
	};

	// Funcion para abrir y cerrar el Snackbar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	return (
		<ErrorBoundary>
			<Box>
				<BoxTitle title={t('catalogos.misEmbalajes.titulo')} />
				<Box
					sx={{
						paddingTop: '7px',
						bgcolor: 'background.secondary',
						borderRadius: 1,
						padding: 1,
					}}
				>
					<Grid item container>
						<Grid
							item
							xs={6}
							sm={6}
							md={6}
							lg={6}
							sx={{ textAlign: 'left' }}
						>
							<IconButton
								size='small'
								component={NavLink}
								to='/configuracion/catalogos'
								className='cerrarIconButton'
							>
								<ArrowBack />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						{loading ? (
							<MySkeletonPage />
						) : (
							<>
								<DataGridMisEmbalajes
									dataGrid={dataDetalles.embalajes}
									dataFetch={dataFetch}
									handleCloseModal={handleCloseModal}
									openModal={openModal}
									setOpenModal={setOpenModal}
									handleOpenModal={handleOpenModal}
									setInfoEmbalaje={setInfoEmbalaje}
									infoEmbalaje={infoEmbalaje}
									handleClickPage={handleClick1}
									setAlertMessagePage={setAlertMessage}
									setOpenModalCargaTemplate={
										setOpenModalCargaTemplate
									}
									handleOpenModalCargaTemplate={
										handleOpenModalCargaTemplate
									}
								/>
								<AgregarEmbalaje
									dataFetch={dataFetch}
									handleClickPage={handleClick1}
									setAlertMessagePage={setAlertMessage}
									handleCloseModal={handleCloseModal}
									openModal={openModal}
									setOpenModal={setOpenModal}
									setInfoEmbalaje={setInfoEmbalaje}
									infoEmbalaje={infoEmbalaje}
								/>
								<AgregarEmbalajeExcelTemplate
									dataFetch={dataFetch}
									handleClickPage={handleClick1}
									setAlertMessagePage={setAlertMessage}
									handleCloseModal={
										handleCloseModalCargaTemplate
									}
									openModal={openModalCargaTemplate}
									setOpenModal={setOpenModalCargaTemplate}
								/>
							</>
						)}
					</Grid>
					<Snackbar
						open={snackbar1.open}
						autoHideDuration={10000}
						onClose={handleClose1}
						anchorOrigin={{ vertical, horizontal }}
					>
						<Alert
							onClose={handleClose1}
							severity={alertMessage.severity}
						>
							{alertMessage.message}
						</Alert>
					</Snackbar>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
