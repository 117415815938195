import React from 'react';
import { Grid, InputAdornment } from '@mui/material';
import SelectSearch from '../../information-stepper/SelectSearch';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

ValidatorForm.addValidationRule('isDecimal', (value) => {
	// Regular expression pattern to match a decimal number
	const decimalRegex = /^-?\d*\.?\d+$/;
	if (decimalRegex.test(value)) {
		return true; // Validation passed
	}
	return false; // Validation failed
});

/*Descripción:Este componente se encarga de desplegar la información del step y formulario Carga
Componente padre: EditarCargaBLModal // AgregarCargaBLModal
Componente hijo:
props: props.handleTextFieldChange = propiedad para actualizar el texto ingresado //
	props.handleNext = funcion para ir al siguiente step //
	props.data = información guardada a desplegar en el formulario //
	props.emptiesInputs = funcion para seleccionar el estilo //
	props.ref = informacion de referencia de los validadores //
	props.actualizarDato = funcion para actualizar el dato seleccionado //
	props.dataBulto = informacion del selectSearch de los bultos
*/

export default function Carga(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Grid item id='Box-InfoStepper-Step-Carga'>
			<Grid container rowSpacing={2} columnSpacing={{ md: 2, lg: 2 }}>
				{/*Grid descripciones ingles y espaniol*/}
				<Grid
					item
					container
					columnSpacing={2}
					rowSpacing={2}
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<TextValidator
							ref={props.ref_nombre_embarcador}
							validators={['required']}
							errorMessages={['El campo es requerido']}
							onChange={props.handleTextFieldChange(
								'descripcion_esp'
							)}
							required
							id='descripcion_esp'
							name='descripcion_esp'
							label={t('booking.estatus.descMercEsp')}
							multiline
							rows={1}
							fullWidth
							value={props.infoCarga.descripcion_esp || ''}
							className={
								props.emptiesInputs.includes('descripcion_esp')
									? 'emptySelect'
									: ''
							}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<TextValidator
							ref={props.ref_nombre_embarcador}
							validators={['required']}
							errorMessages={['El campo es requerido']}
							onChange={props.handleTextFieldChange(
								'descripcion_ing'
							)}
							className={
								props.emptiesInputs.includes('descripcion_ing')
									? 'emptySelect'
									: 'Textfield-Descripcion-Ing'
							}
							required
							id='descripcion_ing'
							name='descripcion_ing'
							label={t('booking.estatus.descMercIng')}
							multiline
							rows={4}
							fullWidth
							value={props.infoCarga.descripcion_ing || ''}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Tooltip
											title={t(
												'booking.estatus.descMercIngHelper'
											)}
											arrow
											placement='right'
										>
											<InfoIcon
												className='primaryIcon'
												fontSize='medium'
											/>
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				{/*Grid marcas y tipo*/}
				<Grid
					item
					container
					columnSpacing={2}
					rowSpacing={2}
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<TextValidator
							onKeyDown={props.handleKeyPress}
							ref={props.ref_nombre_embarcador}
							validators={['required']}
							errorMessages={['El campo es requerido']}
							onChange={props.handleTextFieldChange(
								'marcas_numero'
							)}
							required
							id='marcas_numero'
							name='marcas_numero'
							label={t('booking.estatus.marcasNumeros')}
							variant='outlined'
							fullWidth
							value={props.infoCarga.marcas_numero || ''}
							className={
								props.emptiesInputs.includes('marcas_numero')
									? 'emptySelect'
									: ''
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Tooltip
											title={t(
												'booking.estatus.marcasNumerosHelper'
											)}
											arrow
											placement='right'
										>
											<InfoIcon
												className='primaryIcon'
												fontSize='medium'
											/>
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<SelectSearch
							defaultValue={props.infoCarga.tipo_bultos}
							required
							actualizarDato={props.actualizarDato}
							endpoint='catalogos/tipo-bultos'
							customLabel={t('selectSearch.tipoBulto')}
							id='tipo_bultos'
							dataOption={props.dataBulto}
							emptiesInputs={props.emptiesInputs}
						/>
					</Grid>
				</Grid>
				{/*Grid fraccion arancelaria y bulto*/}
				<Grid
					item
					container
					columnSpacing={2}
					rowSpacing={2}
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<TextValidator
							onKeyDown={props.handleKeyPress}
							ref={props.ref_nombre_embarcador}
							onChange={props.handleTextFieldChange(
								'fraccion_arancelaria'
							)}
							id='fraccion_arancelaria'
							name='fraccion_arancelaria'
							label={t('booking.estatus.fraccionArancelaria')}
							variant='outlined'
							required
							fullWidth
							value={props.infoCarga.fraccion_arancelaria || ''}
							className={
								props.emptiesInputs.includes(
									'fraccion_arancelaria'
								)
									? 'emptySelect'
									: ''
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Tooltip
											title={t(
												'booking.estatus.fraccionArancelariaHelper'
											)}
											arrow
											placement='right'
										>
											<InfoIcon
												className='primaryIcon'
												fontSize='medium'
											/>
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<TextValidator
							onKeyDown={props.handleKeyPress}
							ref={props.ref_nombre_embarcador}
							validators={['required', 'isNumber', 'minNumber:1']}
							errorMessages={[
								'El campo es requerido',
								'Cantidad de bultos invalida',
								'Cantidad de bultos tiene que ser positiva',
							]}
							onChange={props.handleTextFieldChange(
								'cantidad_bultos'
							)}
							required
							id='cantidad_bultos'
							name='cantidad_bultos'
							label={t('booking.estatus.cantidadBultos')}
							variant='outlined'
							fullWidth
							type='number'
							value={props.infoCarga.cantidad_bultos}
							className={
								props.emptiesInputs.includes('cantidad_bultos')
									? 'emptySelect'
									: ''
							}
						/>
					</Grid>
				</Grid>
				{/*Grid peso y volumen*/}
				<Grid
					item
					container
					columnSpacing={2}
					rowSpacing={2}
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<TextValidator
							onKeyDown={props.handleKeyPress}
							ref={props.ref_nombre_embarcador}
							validators={[
								'required',
								'isDecimal',
								'minNumber:0',
							]}
							errorMessages={[
								'El campo es requerido',
								'Peso invalido',
								'Peso tiene que ser positivo',
							]}
							onChange={props.handleTextFieldChange('peso')}
							required
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										KG
									</InputAdornment>
								),
							}}
							id='peso'
							name='peso'
							label={t('booking.estatus.peso')}
							variant='outlined'
							type='number'
							fullWidth
							value={props.infoCarga.peso || ''}
							className={
								props.emptiesInputs.includes('peso')
									? 'emptySelect'
									: ''
							}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<TextValidator
							onKeyDown={props.handleKeyPress}
							ref={props.ref_nombre_embarcador}
							validators={[
								'required',
								'isDecimal',
								'minNumber:0',
							]}
							errorMessages={[
								'El campo es requerido',
								'Volumen invalido',
								'Volumen tiene que ser positivo',
							]}
							onChange={props.handleTextFieldChange('volumen')}
							required
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										CBM
									</InputAdornment>
								),
							}}
							id='volumen'
							name='volumen'
							label={t('booking.estatus.volumen')}
							variant='outlined'
							type='number'
							fullWidth
							value={props.infoCarga.volumen || ''}
							className={
								props.emptiesInputs.includes('volumen')
									? 'emptySelect'
									: ''
							}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
