import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar los eventos de Aereo relacionados a Aéreo Impo
Componente padre: TimelineSelectorAereoImpo
Componente hijo: TimelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosAereopuertoImpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Timeline position='alternate'>
			{props.data['fecha-adicion'] === 'N.D.' ||
			props.data['fecha-adicion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-adicion']}
					title={t('eventos.altaRegistro')}
				/>
			)}
		</Timeline>
	);
}
