import { Edit } from '@mui/icons-material';
import { FormControlLabel, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditarProductoModal from './Modales/EditarProductoModal';
import GetDataFetch from '../../get-data/GetDataFetch';

/*Descripción:Este componente se encarga de renderizar el icono de editar un producto del catálogo
Componente padre: DataGridProductos
Componente hijo: EditarProductoModal
props: props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert //
	props.dataFetchBooking = props para actualizar la información mostrada //
	props.id = Información del ID de la fila
*/

export default function EditIconDataGrid(props) {
	// Se crean las constantes para el funcionamiendo del modal
	const [dataDetalles, setDataDetalles] = useState({});
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);

	// Funcion para cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Se hace el fetch para obtener los datos ya existentes en la DB
	const dataFetch = async () => {
		const url = 'catalogos/productos-por-cliente/details?id=' + props.id;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};
	useEffect(() => {
		dataFetch();
	}, [props.id]);

	// Funcion para editar y actualizar los valores en el modal
	const handleTextFieldChange = (name) => (event) => {
		actualizarDato(name, event.target.value.toUpperCase());
	};
	// Funcion para actualizar los datos del modal
	function actualizarDato(name, value) {
		setDataDetalles({
			...dataDetalles,
			[name]: value,
		});
	}

	// Función llamada al dar click al icono de editar
	const handleEditClick = async () => {
		await dataFetch();
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<IconButton
						id='editIconButtonDataGrid'
						className='primary'
						aria-label='add an alarm'
						onClick={handleEditClick}
					>
						<Edit color='primary' />
					</IconButton>
				}
			/>
			<EditarProductoModal
				loading={loading}
				handleClick1={props.handleClick1}
				setAlertMessage={props.setAlertMessage}
				handleTextFieldChange={handleTextFieldChange}
				actualizarDato={actualizarDato}
				dataDetalles={dataDetalles}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
				handleNext={props.handleNext}
				emptiesInputs={props.emptiesInputs}
				id={props.id}
				dataFetch={props.dataFetch}
			/>
		</>
	);
}
