import React from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CustomCard({ title, image, url }) {
	const { t } = useTranslation();

	return (
		<Card className='catalogos-card'>
			<CardMedia
				component='img'
				alt={title}
				height='120'
				image={image}
				title={title}
			/>
			<CardContent sx={{ paddingBottom: '0px', height: '75px' }}>
				<Typography gutterBottom variant='h5' component='h2'>
					{title}
				</Typography>
			</CardContent>
			<CardActions sx={{ justifyContent: 'flex-end', paddingTop: '0px' }}>
				<Button size='small' component={NavLink} to={url}>
					{t('catalogos.productos.verMas')}
				</Button>
			</CardActions>
		</Card>
	);
}
