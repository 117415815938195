import { Typography } from '@mui/material';
import TableTransfer from '../Tables/TableTransfer';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información de el transfer
Componente padre: ServiciosAdicionalesTable
Componente hijo:
props: props.data = informacion a desplegar //
	props.transfer = booleano que indica si se tiene transfer contratado
*/

export default function AcordionTransfer(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const resultToRender = () => {
		if (props.transfer) {
			return <TableTransfer data={props.data} />;
		} else {
			return (
				<Typography>{t('eventos.sinServicioContratado')}</Typography>
			);
		}
	};
	return resultToRender();
}
