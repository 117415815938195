import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	IconButton,
	Tooltip,
	useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import VisualizarCargasCartaPorte from '../Forms/VisualizarCargasCartaPorte';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: ListadoCargas
Componente hijo: Carga
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.actualizarDato = funcion para actualizar los datos del formulario //
	props.dataDetalles = informacion acerca de los detalles del booking //
	props.dataModalidadPago = informacion sobre la modalidad de pago //
	props.dataBulto = informacion sobre el tipo de bulto //
	props.handleNext = funcion para realizar el nextStepp //
	props.emptiesInputs = funcion para validar los campos vacios //
*/

export default function VisualizarCargaCartaPorteModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '75%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxHeight: '95%',
		minHeight: '20vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Grid item container rowSpacing={2}>
					<Grid item lg={9} md={9} sm={9} xs={9}>
						<Typography variant='h5'>
							{t('modales.visualizarCarga')}
						</Typography>
					</Grid>
					<Grid
						item
						lg={3}
						md={3}
						sm={3}
						xs={3}
						sx={{ textAlign: 'right' }}
					>
						<Tooltip title={t('botones.cerrar')}>
							<IconButton
								onClick={props.handleCloseModal}
								id='cargaModalCerrarIconButton'
								variant='outlined'
								color='inherit'
								className='cerrarIconButton'
							>
								<Close />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<VisualizarCargasCartaPorte
							infoCarga={props.infoCarga}
						/>
					</Grid>
					<Grid
						item
						sx={{
							textAlign: 'right',
							bottom: '1.5vw',
							left: '1.5vw',
						}}
					>
						<Button
							id='cargaModalCerrarButton'
							variant='outlined'
							color='error'
							onClick={props.handleCloseModal}
						>
							{t('botones.cancelar')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
