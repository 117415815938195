import React from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';

export default function BoxTitleWithTabs(props) {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					bgcolor: 'background.secondary',
					paddingLeft: 1,
					borderTopLeftRadius: 5,
					borderTopRightRadius: 5,
				}}
			>
				<Typography variant='h4'>{props.title}</Typography>
			</Box>
			<Box
				sx={{
					marginBottom: 0.5,
					bgcolor: 'background.secondary',
					paddingLeft: 1,
					borderBottomLeftRadius: 5,
					borderBottomRightRadius: 5,
				}}
			>
				<Tabs value={props.value} onChange={props.handleChange}>
					{props.tabs.map((tab, index) => (
						<Tab
							key={index}
							id={tab.id}
							label={tab.label}
							sx={{ color: 'grey' }}
						/>
					))}
				</Tabs>
			</Box>
		</>
	);
}
