import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TimelineEventosBooking from './TimelineEventosBooking';
import TimelineEventosContenedor from './TimelineEventosContenedor';
import TimelineEventosDespacho from './TimelineEventosDespacho';
import TimelineEventosTerminal from './TimelineEventosTerminal';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useEffect, useState } from 'react';

/*Descripción:Este componente se encarga de desplegar las opciones del Timeline de Maritimo Expo e Impo
Componente padre: EventosBookingMaritimoExpo //
EventosBookingMaritimoImpo
Componente hijo:TimelineEventosBooking //
	TimelineEventosterminal //
	TimelineEventosDespacho //
	TimelineEventosContenedor
props: props.data = informacion a desplegar //
	props.dataContenedor = informacion a desplegar sobre el contenedor //
	props.dataDespacho = informacion a desplegar sobre el despacho
*/

export default function TimelineSelector(props) {
	// Se crean los cosnt para el uso del TimelineSelector
	const [value, setValue] = useState('1');
	const [isLCL, setIsLCL] = useState(false);
	// useEffect para seleccionar la modalidad
	useEffect(() => {
		// Se valida la fecha de consolidacion
		if (props.data['fecha-consolidacion']) {
			// Si existe la fecha de consolidacion se asigna el valor de LCL
			setIsLCL(true);
		} else {
			// Si no existe la fecha de consolidacion no se asigna el valor de LCL
			setIsLCL(false);
		}
	}, []);

	// Se crea la funcio de renderizacion de las tabs
	function MyTabs() {
		// Se valida el valor de LCL
		if (isLCL === true) {
			// Si el valor es LCL se renderizan los componentes para consolidado
			if (props.role.includes('Cliente')) {
				return (
					<TabContext value={value} id='maritimoImpoTabContext'>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleChange} centered>
								{props.dataContenedor === '' ? (
									<></>
								) : (
									<Tab
										icon={<DirectionsBoatIcon />}
										value='1'
										sx={{ color: 'grey' }}
									/>
								)}
								<Tab
									icon={<WarehouseIcon />}
									value='2'
									sx={{ color: 'grey' }}
								/>
								{lenghtDespacho !== 0 && (
									<Tab
										icon={<AssuredWorkloadIcon />}
										value='3'
										sx={{ color: 'grey' }}
									/>
								)}
								<Tab
									icon={<LocalShippingIcon />}
									value='4'
									sx={{ color: 'grey' }}
								/>
							</TabList>
						</Box>
						<TabPanel
							value='1'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosBooking
								data={props.data}
								dataContenedor={props.dataContenedor}
							/>
						</TabPanel>
						<TabPanel
							value='2'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosTerminal
								data={props.dataContenedor}
								dataBooking={props.data}
							/>
						</TabPanel>
						<TabPanel
							value='3'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosDespacho
								data={props.dataDespacho}
							/>
						</TabPanel>
						<TabPanel
							value='4'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosContenedor
								data={props.dataContenedor}
								dataBooking={props.data}
							/>
						</TabPanel>
					</TabContext>
				);
			} else if (props.role.includes('Agente')) {
				<TabContext value={value} id='maritimoImpoTabContext'>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} centered>
							{props.dataContenedor === '' ? (
								<></>
							) : (
								<Tab
									icon={<DirectionsBoatIcon />}
									value='1'
									sx={{ color: 'grey' }}
								/>
							)}
							<Tab
								icon={<WarehouseIcon />}
								value='2'
								sx={{ color: 'grey' }}
							/>
						</TabList>
					</Box>
					<TabPanel
						value='1'
						sx={{ maxHeight: '46vh', overflowY: 'auto' }}
					>
						<TimelineEventosBooking
							data={props.data}
							dataContenedor={props.dataContenedor}
						/>
					</TabPanel>
					<TabPanel
						value='2'
						sx={{ maxHeight: '46vh', overflowY: 'auto' }}
					>
						<TimelineEventosTerminal
							data={props.dataContenedor}
							dataBooking={props.data}
						/>
					</TabPanel>
				</TabContext>;
			}
		} else {
			// Si no es LCL se renderizan los componentes para FCL
			if (props.role.includes('Cliente')) {
				return (
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleChange} centered>
								{props.dataContenedor === '' ? (
									<></>
								) : (
									<Tab
										icon={<DirectionsBoatIcon />}
										value='1'
										sx={{ color: 'grey' }}
									/>
								)}
								{lenghtDespacho !== 0 && (
									<Tab
										icon={<AssuredWorkloadIcon />}
										value='2'
										sx={{ color: 'grey' }}
									/>
								)}
								<Tab
									icon={<WarehouseIcon />}
									value='3'
									sx={{ color: 'grey' }}
								/>
								<Tab
									icon={<LocalShippingIcon />}
									value='4'
									sx={{ color: 'grey' }}
								/>
							</TabList>
						</Box>
						<TabPanel
							value='1'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosBooking
								data={props.data}
								dataContenedor={props.dataContenedor}
							/>
						</TabPanel>
						<TabPanel
							value='2'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosDespacho
								data={props.dataDespacho}
							/>
						</TabPanel>
						<TabPanel
							value='3'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosTerminal
								data={props.dataContenedor}
								dataBooking={props.data}
							/>
						</TabPanel>
						<TabPanel
							value='4'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosContenedor
								data={props.dataContenedor}
								dataBooking={props.data}
							/>
						</TabPanel>
					</TabContext>
				);
			} else if (props.role.includes('Agente')) {
				return (
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleChange} centered>
								{props.dataContenedor === '' ? (
									<></>
								) : (
									<Tab
										icon={<DirectionsBoatIcon />}
										value='1'
										sx={{ color: 'grey' }}
									/>
								)}
								<Tab
									icon={<WarehouseIcon />}
									value='3'
									sx={{ color: 'grey' }}
								/>
							</TabList>
						</Box>
						<TabPanel
							value='1'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosBooking
								data={props.data}
								dataContenedor={props.dataContenedor}
							/>
						</TabPanel>
						<TabPanel
							value='3'
							sx={{ maxHeight: '46vh', overflowY: 'auto' }}
						>
							<TimelineEventosTerminal
								data={props.dataContenedor}
								dataBooking={props.data}
							/>
						</TabPanel>
					</TabContext>
				);
			}
		}
	}

	// Se crea la variable del tamaño del arreglo del despacho
	const [lenghtDespacho, setLengthDespacho] = useState(
		Object.keys(props.dataDespacho).length
	);
	// Funcion para seleccionar el tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// useEffect para seleccionar el tab
	useEffect(() => {
		setValue('1');
		// Se asigna el tamaño del arreglo del despacho
		setLengthDespacho(Object.keys(props.dataDespacho).length);
	}, []);

	// ==========================================================================================
	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<MyTabs />
		</Box>
	);
}
