import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Save, Send } from '@mui/icons-material';
import { Grid, Tooltip, Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import InformacionModal from './Modales/InformacionModal';
import ConfirmacionEnvioModalCP from './Modales/ConfirmarEnvioModalCP';

/*Descripción:Este componente se encarga de desplegar botones y sus respectivos mensajes de alerta
Componente padre: BlResult
Componente hijo: ConfirmacionEnvio
props: props.alertMessage = Mensajes de alerta y su estilo //
	props.enviarDatosFormulario = datos guardados en tabla intermedia //
	props.confirmarDatosFormulario = Datos enviados
*/

export default function ActionButton(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [openModalConfirmacion, setOpenModalConfirmacion] = useState(false);
	const handleOpenModalConfirmacion = () => {
		setOpenModalConfirmacion(true);
	};
	const handleCloseModalConfirmacion = () => {
		setOpenModalConfirmacion(false);
	};

	// Funciones para el modal
	const [openModalInfo, setOpenModalInfo] = useState(false);
	const handleOpenModalInfo = () => {
		setOpenModalInfo(true);
	};
	const handleCloseModalInfo = () => {
		setOpenModalInfo(false);
	};

	return (
		<Grid container justifyContent={'space-between'}>
			{/* Boton Guardar--------------------------------------------- */}
			<Grid item>
				<Tooltip title={t('botones.guardar')}>
					<Button
						id='guardarCartaPorte'
						name='guardarCartaPorte'
						variant='outlined'
						color='primary'
						onClick={props.updateCartaPorte}
						sx={{
							fontSize: '12px',
							mr: 1,
							ml: 1,
						}}
					>
						<Save />
					</Button>
				</Tooltip>
			</Grid>
			{/* Boton Enviar--------------------------------------------- */}
			<Grid item>
				<Tooltip title={t('botones.enviar')}>
					<Button
						id='enviarCartaPorte'
						name='enviarCartaPorte'
						variant='outlined'
						color='primary'
						onClick={handleOpenModalConfirmacion}
						sx={{
							fontSize: '12px',
							mr: 1,
							ml: 1,
						}}
					>
						<Send />
					</Button>
				</Tooltip>
				<ConfirmacionEnvioModalCP
					openModal={openModalConfirmacion}
					hanldeCloseModal={handleCloseModalConfirmacion}
					confirmacionDatosFormulario={props.confirmarCartaPorte}
					loadingButton={props.loadingButton}
				/>
			</Grid>
			{/* Boton Informacion Modal--------------------------------------------- */}
			<Hidden lgUp>
				<Grid item>
					<Tooltip title={t('botones.informacion')}>
						<Button
							id='informacionCartaPorte'
							name='informacionCartaPorte'
							variant='outlined'
							color='primary'
							onClick={handleOpenModalInfo}
							sx={{
								fontSize: '12px',
								ml: 1,
							}}
						>
							<InfoIcon />
						</Button>
					</Tooltip>
					<InformacionModal
						openModal={openModalInfo}
						handleCloseModal={handleCloseModalInfo}
						selectedBooking={props.selectedBooking}
					/>
				</Grid>
			</Hidden>
		</Grid>
	);
}
