import { Box } from '@mui/material';
import React from 'react';
import CommonBooking from '../../../components/CommonBooking';
import ErrorBoundary from '../../../components/cards/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import BoxTitle from '../../../components/titles/BoxTitle';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla Terrestre Nacional
y mandar el endpoint a usar
Componente padre: CommonLayout.jsx
Componente hijo: CommonBooking.jsx
props: Sin uso de props
*/

export default function IndexTN() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box container>
				<BoxTitle title={t('paginas.terrestreNacional')} />
				<Box>
					<CommonBooking
						resultComponent='BookingTerrestreNacioSearchResult'
						endpoint='bookings/terrestre/nacional'
					/>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
