import { Grid, Fade, Stack, Tooltip } from '@mui/material';
import SelectSearchContacto from './SelectSearchs/SelectSearchContacto';
import SelectSearchDireccion from './SelectSearchs/SelectSearchDireccion';
import { useEffect, useState } from 'react';
import { AddIcCall, AddLocationAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AgregarContactosModal from '../../../catalogos/consignatarios/Modales/Contactos/AgregarContactosModal';
import AgregarDireccionesModal from '../../../catalogos/consignatarios/Modales/Direcciones/AgregarDireccionesModal';
import GetDataFetch from '../../../get-data/GetDataFetch';
import { useNavigate } from 'react-router';
import LoadingButton from '@mui/lab/LoadingButton';

export default function ShowSelectSearchs(props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [openModalContacto, setOpenModalContacto] = useState(false);
	const [openModalDireccion, setOpenModalDireccion] = useState(false);
	const [loading, setLoading] = useState(true);
	const [indexCiudad, setIndexCiudad] = useState(0);
	const [ciudad, setCiudad] = useState('');
	const [loadingButtonContacto, setLoadingButtonContacto] = useState(false);
	const [loadingButtonDireccion, setLoadingButtonDireccion] = useState(false);
	//Variable para agregar nueva direccion
	const [infoDireccionNew, setInfoDireccionNew] = useState({
		cod_persona:
			props.infoConsignatario !== undefined
				? props.infoConsignatario.cod_consignatario
				: '',
		calle: '',
		cod_ciudad: ciudad,
	});
	useEffect(() => {
		setInfoDireccionNew({
			...infoDireccionNew,
			cod_ciudad: ciudad,
		});
	}, [ciudad]);

	//Crea las opciones para los select search de contactos y direcciones
	const [dataOptionsContacto, setDataOptionsContacto] = useState(
		props.dataOptionContactos
			.filter(
				(option) =>
					props.infoConsignatario !== undefined &&
					option.cod_persona ===
						props.infoConsignatario.cod_consignatario
			)
			.map((option) => {
				return {
					value: option.cod_contacto,
					label: option.contacto + ' ' + option.telefono,
				};
			})
	);

	const [dataOptionsDirecciones, setDataOptionsDirecciones] = useState(
		props.dataDetallesDirecciones.direcciones
			.filter(
				(option) =>
					props.infoConsignatario !== undefined &&
					option.cod_persona ===
						props.infoConsignatario.cod_consignatario
			)
			.map((option) => {
				return {
					value: option.cod_direccion,
					label: option.calle,
				};
			})
	);

	//Funcion para obtener los datos de las ciudades
	const [dataDetallesCiudades, setDataDetallesCiudades] = useState({});

	// Funcion get para obtener las ciudades por pais
	const getCiudades = async () => {
		setLoading(true);
		let url =
			'catalogos/ciudades-por-pais?pais=' +
			props.infoConsignatario.cod_pais;
		await GetDataFetch(url, setLoading, setDataDetallesCiudades, navigate);
	};

	// useEffect para obtener las ciudades por pais
	useEffect(() => {
		if (
			props.infoConsignatario !== undefined &&
			props.infoConsignatario.cod_pais !== ''
		) {
			getCiudades();
		}
	}, [props.infoConsignatario?.cod_pais]);

	// Funcion para actualizar el contenido del contenedor de equipo
	function actualizarCiudad(ciudad) {
		setCiudad(ciudad.value + '');
		setIndexCiudad(ciudad.label + '');
	}

	// Función para abrir y cerrar el modal
	const handleCloseModalContacto = () => {
		setOpenModalContacto(false);
		setLoadingButtonContacto(false);
	};
	const handleOpenModalContacto = () => {
		setOpenModalContacto(true);
		setLoadingButtonContacto(true);
	};

	// Función para abrir y cerrar el modal
	const handleCloseModalDireccion = () => {
		setOpenModalDireccion(false);
		setLoadingButtonDireccion(false);
	};
	const handleOpenModalDireccion = () => {
		setOpenModalDireccion(true);
		setLoadingButtonDireccion(true);
	};

	const resultToRenderContactos = () => {
		if (props.dataOptionContactos !== undefined) {
			return (
				<Stack
					direction={{
						xs: 'row',
						sm: 'row',
						md: 'row',
						lg: 'row',
						xl: 'row',
					}}
					spacing={1}
					sx={{ justifyContent: 'space-between' }}
				>
					<Grid item width={'100%'}>
						<SelectSearchContacto
							infoConsignatario={props.infoConsignatario}
							setInfoContacto={props.setInfoContacto}
							infoContacto={props.infoContacto}
							required
							actualizarDato={props.actualizarContacto}
							customLabel={t('catalogos.consignatario.contacto')}
							id='contactos'
							dataOptionsContacto={dataOptionsContacto}
							dataDetallesContacto={props.dataOptionContactos}
							emptiesInputs={props.emptiesInputs}
							indexContacto={props.indexContacto}
							setIndexContacto={props.setIndexContacto}
						/>
					</Grid>
					<Grid item>
						<Tooltip title={t('botones.agregar')}>
							<LoadingButton
								variant='outlined'
								color='primary'
								id='BtnAgregarContactoBL'
								onClick={handleOpenModalContacto}
								loading={loadingButtonContacto}
								sx={{
									fontSize: '12px',
									marginTop: 3.5,
								}}
							>
								<AddIcCall />
							</LoadingButton>
						</Tooltip>
						<AgregarContactosModal
							id={props.infoConsignatario.cod_consignatario}
							dataFetch={props.dataFetchContactos}
							handleCloseModal={handleCloseModalContacto}
							openModal={openModalContacto}
							setOpenModal={setOpenModalContacto}
							setAlertMessagePage={props.setAlertMessagePage}
							handleClickPage={props.handleClickPage}
							setLoadingButtonContacto={setLoadingButtonContacto}
						/>
					</Grid>
				</Stack>
			);
		} else {
			return <></>;
		}
	};

	const resultToRenderDirecciones = () => {
		if (props.dataOptionDirecciones !== undefined) {
			return (
				<Stack
					direction={{
						xs: 'row',
						sm: 'row',
						md: 'row',
						lg: 'row',
						xl: 'row',
					}}
					spacing={1}
					sx={{ justifyContent: 'space-between' }}
				>
					<Grid item width={'100%'}>
						<SelectSearchDireccion
							dataDetallesDirecciones={
								props.dataDetallesDirecciones.direcciones
							}
							defaultValue={props.infoDireccion}
							infoDireccion={props.infoDireccion}
							infoConsignatario={props.infoConsignatario}
							setInfoDireccion={props.setInfoDireccion}
							required
							actualizarDato={props.actualizarDireccion}
							customLabel={t('catalogos.consignatario.direccion')}
							id='direcciones'
							dataOption={dataOptionsDirecciones}
							emptiesInputs={props.emptiesInputs}
							indexDireccion={props.indexDireccion}
							setIndexDireccion={props.setIndexDireccion}
						/>
					</Grid>
					<Grid item>
						<Tooltip title={t('botones.agregar')}>
							<LoadingButton
								variant='outlined'
								color='primary'
								id='BtnAgregarDireccionBL'
								loading={loadingButtonDireccion}
								onClick={handleOpenModalDireccion}
								sx={{
									fontSize: '12px',
									marginTop: 3.5,
								}}
							>
								<AddLocationAlt />
							</LoadingButton>
						</Tooltip>
						<AgregarDireccionesModal
							loading={loading}
							handleCloseModal={handleCloseModalDireccion}
							openModal={openModalDireccion}
							setOpenModal={setOpenModalDireccion}
							dataFetch={props.dataFetchDirecciones}
							id={props.infoConsignatario.cod_consignatario}
							codPais={props.infoConsignatario.cod_pais}
							dataCiudades={dataDetallesCiudades}
							actualizarCiudad={actualizarCiudad}
							infoDireccion={infoDireccionNew}
							setInfoDireccion={setInfoDireccionNew}
							indexCiudad={indexCiudad}
							setIndexCiudad={setIndexCiudad}
							setAlertMessagePage={props.setAlertMessagePage}
							handleClickPage={props.handleClickPage}
							setLoadingButtonDireccion={
								setLoadingButtonDireccion
							}
						/>
					</Grid>
				</Stack>
			);
		} else {
			return <></>;
		}
	};

	useEffect(() => {
		resultToRenderContactos();
		resultToRenderDirecciones();
		setDataOptionsDirecciones(
			props.dataDetallesDirecciones.direcciones
				.filter(
					(option) =>
						option.cod_persona ===
						props.infoConsignatario.cod_consignatario
				)
				.map((option) => {
					return {
						value: option.cod_direccion,
						label: option.calle,
					};
				})
		);
		setDataOptionsContacto(
			props.dataOptionContactos
				.filter(
					(option) =>
						option.cod_persona ===
						props.infoConsignatario.cod_consignatario
				)
				.map((option) => {
					return {
						value: option.cod_contacto,
						label: option.contacto + ', TEL.' + option.telefono,
					};
				})
		);
	}, [props.infoConsignatario]);

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			spacing={1}
		>
			<Fade in={true} timeout={500}>
				{/* Contacto del consignatario */}
				<Grid item xs={12} sm={12} md={12} lg={12}>
					{resultToRenderContactos()}
				</Grid>
			</Fade>
			<Fade in={true} timeout={500}>
				{/* Direccion del consignatario */}
				<Grid item xs={12} sm={12} md={12} lg={12}>
					{resultToRenderDirecciones()}
				</Grid>
			</Fade>
		</Stack>
	);
}
