import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Card, CardContent } from '@mui/material';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Aereo Impo
Componente padre: CommonBooking
Componente hijo: MySnackBar //
	MyInfoStepper //
	InformacionCard
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición//
	props.getBookingsList = Funcion para obtener la lista de los bookings
*/

export default function LugarDeEntrega(props) {
	const { t } = useTranslation();

	return (
		<Card elevation={0}>
			<CardContent>
				<Grid container rowSpacing={1} columnSpacing={1}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography variant='subtitle1'>
							{t('cartaPorte.lugarDeEntrega')}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography variant='body2'>
							{props.dataDetalles.vNombreEntrega &&
							props.dataDetalles.vRfcEntrega &&
							props.dataDetalles.vCalleEntrega &&
							props.dataDetalles.vCiudadEntrega &&
							props.dataDetalles.vColoniaEntrega &&
							props.dataDetalles.nCodigoPostalEntrega &&
							props.dataDetalles.vEstadoEntrega
								? props.dataDetalles.vNombreEntrega +
								  ',' +
								  props.dataDetalles.vRfcEntrega +
								  ' ' +
								  props.dataDetalles.vCalleEntrega +
								  ' ' +
								  props.dataDetalles.vCiudadEntrega +
								  ' ' +
								  props.dataDetalles.vColoniaEntrega +
								  ' ' +
								  props.dataDetalles.nCodigoPostalEntrega +
								  ' ' +
								  props.dataDetalles.vEstadoEntrega
								: null}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
