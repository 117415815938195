import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	LinearProgress,
	useMediaQuery,
} from '@mui/material';
import Carga from '../Forms/Carga';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: EditIconDataGrid
Componente hijo: Carga
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.actualizarDato = funcion para actualizar los datos del formulario //
	props.dataDetalles = informacion acerca de los detalles del booking //
	props.dataModalidadPago = informacion sobre la modalidad de pago //
	props.dataBulto = informacion sobre el tipo de bulto //
	props.handleNext = funcion para realizar el nextStepp //
	props.emptiesInputs = funcion para validar los campos vacios //
*/

export default function EditarCargaBLModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '75%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxHeight: '95%',
		minHeight: '590px',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Se crean las variables para el uso de Alert
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});
	const { vertical, horizontal } = state;
	const navigate = useNavigate();
	//Se crean los parametros para el metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const requestOptions = {
		method: 'PATCH',
		headers: myHeaders,
		body: JSON.stringify(props.dataDetalles),
		redirect: 'follow',
	};

	// Funciones para el modal
	const handleSaveModal = () => {
		// Se valida que el formulario de la carga se completo
		if (validaCarga() === true) {
			// Se hace el llamado al metodo Patch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'bill-of-lading/carga/update-carga',
				requestOptions
			)
				.then((response) => {
					if (response.status === 200) {
						// Si es correcto se actualiza el contenido y se manda el mensaje de exito
						props.setAlertMessage({
							severity: 'success',
							message: t('alertas.cargaEditada'),
						});
						props.handleClick1();
						setTimeout(() => {
							props.dataFetchBooking();
						}, 2000);
					} else if (response.status === 401) {
						// Si el token caduca se redirige al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					}
				})
				.catch((error) => console.log('error', error));
			props.setOpenModal(false);
		} else {
			// Si no se completa el formulario se muestra el Alert correspondiente
			setAlertMessage({
				severity: 'error',
				message: t('alertas.datosIncompletos'),
			});
			handleClick1();
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	// Funcion para validar que se completo el formulario de la carga
	function validaCarga() {
		var valida = true;
		Object.keys(props.dataDetalles).forEach(function (key) {
			if (props.dataDetalles[key] === '') {
				valida = false;
			}
		});
		return valida;
	}

	// Evento click botón Guardar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};

	// Funcion para cerrar el Alert
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={false}
				>
					<Grid item container rowSpacing={2}>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography variant='h5'>
								{t('modales.editarCarga')}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							{props.loading ? (
								<LinearProgress />
							) : (
								<Carga
									handleTextFieldChange={
										props.handleTextFieldChange
									}
									actualizarDato={props.actualizarDato}
									infoCarga={props.dataDetalles}
									dataModalidadPago={props.dataModalidadPago}
									dataBulto={props.dataBulto}
									handleNext={props.handleNext}
									emptiesInputs={props.emptiesInputs}
									handleKeyPress={handleKeyPress}
								/>
							)}
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Button
								id='cargaModalCerrarButton'
								variant='outlined'
								color='error'
								onClick={props.handleCloseModal}
							>
								{t('botones.cerrar')}
							</Button>
						</Grid>

						<Grid
							item
							lg={6}
							md={6}
							sm={6}
							xs={6}
							sx={{
								textAlign: 'right',
							}}
						>
							<Button
								id='cargaModalActualizarButton'
								variant='outlined'
								color='primary'
								type='submit'
							>
								{t('botones.actualizar')}
							</Button>
						</Grid>
						<Snackbar
							open={snackbar1.open}
							autoHideDuration={10000}
							onClose={handleClose1}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert
								onClose={handleClose1}
								severity={alertMessage.severity}
							>
								{alertMessage.message}
							</Alert>
						</Snackbar>
					</Grid>
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
