import { Box } from '@mui/material';
import React, { useState } from 'react';
import ErrorBoundary from '../../components/cards/ErrorBoundary';
import EstadoDeCuenta from '../../components/estado-de-cuenta/facturas/EstadoDeCuenta';
import ComplementosDePago from '../../components/estado-de-cuenta/complementos-de-pago/ComplementosDePago';
import { useTranslation } from 'react-i18next';
import BoxTitleWithTabs from '../../components/titles/BoxTitleWithTabs';
import { useLocation } from 'react-router-dom';
/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla Estado de Cuenta
Componente padre: CommonLayout.jsx
Componente hijo: EstadoDeCuenta
props: Sin uso de props
*/

export default function IndexEC() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se obtiene la ruta actual
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const rutaActiva = searchParams.get('activo');
	// Se declaran los const para su uso en el componente
	const [value, setValue] = useState(rutaActiva === 'complementos' ? 1 : 0);
	// Funcion para cambiar de tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const tabs = [
		{ id: 'TabFacturas', label: t('estadoDeCuenta.facturas') },
		{
			id: 'TabComplementoPago',
			label: t('estadoDeCuenta.complementosDePago'),
		},
	];

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box>
				<BoxTitleWithTabs
					title={t('estadoDeCuenta.titulo')}
					tabs={tabs}
					value={value}
					handleChange={handleChange}
				/>
				{/* Tab 1: Full Container */}
				<Box hidden={value !== 0}>
					{value === 0 && <EstadoDeCuenta endpoint='facturacion' />}
				</Box>
				{/* Tab 2: Consolidado */}
				<Box hidden={value !== 1}>
					{value === 1 && (
						<ComplementosDePago endpoint='complementos-pago' />
					)}
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
