import React, { useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import Select from 'react-select';
import { Typography } from '@mui/material';

/*Descripción:Este componente se encarga de desplegar la información del SelectSearch dependiendo los datos recopilados
Componente padre: NotifyParty //
	Carga //
Consignatario
Componente hijo:
	props: props.dataOption = son las opciones a desplegar //
	props.emptiesInputs = funcion para seleccionar el estilo //
	props.actualizarDato = funcion para actualizar el dato seleccionado //
	props.customLabel = titulo del selectSearch
*/

export default function SelectSearchContacto(props) {
	// Se define el valor inicial de el SelectSearch
	const [defaultValue, setDefaultValue] = useState(
		props.infoContacto
			? props.dataOptionsContacto[
					props.dataOptionsContacto.findIndex(
						(obj) => obj.value === props.infoContacto.cod_contacto
					)
			  ]
			: null
	);
	// Se define los estilos de validación de datos ingresado
	const [emptyStyles, setEmptyStyles] = useState({});

	// useEffect para la implementación de los estilos para validar si se selecciono algun valor
	useEffect(() => {
		const updatedEmptyStyles = {};
		const emptyClassMap = {
			contacto: 'emptyStyles',
		};

		Object.keys(emptyClassMap).forEach((id) => {
			updatedEmptyStyles[id] = props.emptiesInputs.includes(id)
				? `${emptyClassMap[id]} my-select-width`
				: 'my-select-width';
		});
		setEmptyStyles(updatedEmptyStyles);
	}, [props.emptiesInputs]);

	// Función para actualizar el dato seleccionado
	const handleChange = (event) => {
		props.actualizarDato(props.id, event.value);
		props.setIndexContacto(
			props.dataOptionsContacto.findIndex(
				(obj) => obj.value === event.value
			)
		);
		var nuevoInfoContacto =
			props.dataDetallesContacto[
				props.dataDetallesContacto.findIndex(
					(obj) => obj.cod_contacto === event.value
				)
			];
		props.setInfoContacto(nuevoInfoContacto);
		setDefaultValue(event);
	};

	// useEffect para actualizar el valor predeterminado del SelectSearch
	useEffect(() => {
		if (props.infoContacto !== undefined) {
			setDefaultValue(
				props.dataOptionsContacto[
					props.dataOptionsContacto.findIndex(
						(obj) => obj.value === props.infoContacto.cod_contacto
					)
				]
			);
		}
	}, [props.infoContacto]);

	// useEffect para actualizar el valor predeterminado del SelectSearch
	useEffect(() => {
		if (props.infoContacto !== undefined) {
			if (
				props.dataOptionsContacto.findIndex(
					(obj) => obj.value === props.infoContacto.cod_contacto
				) !== -1
			) {
				setDefaultValue(
					props.dataOptionsContacto[
						props.dataOptionsContacto.findIndex(
							(obj) =>
								obj.value === props.infoContacto.cod_contacto
						)
					]
				);
			} else {
				setDefaultValue(null);
			}
		}
	}, [props.dataOptionsContacto]);

	useEffect(() => {
		if (props.infoContacto !== undefined) {
			const newDefaultValue = props.dataOptionsContacto.find(
				(obj) => obj.value === props.infoContacto.cod_contacto
			);
			setDefaultValue(newDefaultValue || null);
		}
	}, [props.infoContacto]);

	// Se crean los estilos que tendrá el SelectSearch
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			height: '45px',
			border: state.isFocused ? '2px solid #002D58' : '1px solid #cccccc',
			boxShadow: state.isFocused ? 'none' : 'none',
			'&:hover': {
				border: '1px solid black',
			},
			background: props.disabled && 'white',
			fontSize: '13px',
			fontFamily: 'Source Sans Pro',
		}),
		placeholder: (provided, state) => ({
			...provided,
			position: 'absolute',
			top: state.hasValue || state.selectProps.inputValue ? -20 : '20%',
			transition: 'top 0.1s, font-size 0.1s',
			fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
			background: 'white',
			paddingLeft: '5px',
			paddingRight: '5px',
			fontFamily: 'Source Sans Pro',
		}),
		valueContainer: (provided, state) => ({
			...provided,
			overflow: 'visible',
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
		}),
		menu: (provided, state) => ({
			...provided,
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
			maxHeight: '150px',
		}),
		menuList: (provided, state) => ({
			...provided,
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
			maxHeight: '150px',
		}),
	};

	return (
		<>
			<FormControl fullWidth>
				<Typography variant='subtitle2' paddingBottom={'1%'}>
					{props.customLabel}
				</Typography>
				<Select
					labelId='demo-simple-select-required-label'
					label='Label'
					onChange={handleChange}
					autoWidth
					placeholder={props.customLabel + ' *'}
					noOptionsMessage={({ inputValue }) =>
						!inputValue ? 'value' : 'Valor no encontrado'
					}
					isSearchable={true}
					isDisabled={props.disabled}
					id={props.id}
					name={props.id}
					options={props.dataOptionsContacto}
					value={defaultValue}
					styles={customStyles}
					required={true}
				/>
			</FormControl>
		</>
	);
}
