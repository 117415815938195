import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import InfoBlCarga from './InfoBlCarga';
import { useTranslation } from 'react-i18next';
import SelectedSearchBL from '../../SelectedSearchBL';

/*Descripción:Este componente se encarga de desplegar la informacion general del booking Maritimo Expo seleccionado
Componente padre: BookingMaritimoExpoSR
Componente hijo:
props: props.data = informacion a desplegar
*/

export default function InfoBLFinal(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Box sx={{ maxHeight: '52vh', width: '100%' }}>
			<Grid container paddingBottom={1}>
				<SelectedSearchBL
					customLabel={t('sidebar.maritimo.billLading')}
					id='blSelectSearch'
					name='bls'
					dataOption={props.data.bls}
					actualizarBL={props.setSelectedBL}
				/>
			</Grid>
			<Grid container spacing={0.7}>
				<Grid item container spacing={1} lg={4} md={4} sm={6}>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.embarcador')}
						</Typography>
					</Grid>
					<Grid item lg={12} xs={12} md={12} sm={12}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{
								props.data.bls[props.selectedBL][
									'nombre_embarcador'
								]
							}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.direccion')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{
								props.data.bls[props.selectedBL][
									'direccion_embarcador'
								]
							}
							<br />
							{
								props.data.bls[props.selectedBL][
									'taxid_embarcador'
								]
							}
							<br />
							{
								props.data.bls[props.selectedBL][
									'contacto_embarcador'
								]
							}
							<br />
							{props.data.bls[props.selectedBL]['tel_embarcador']}
							<br />
							{
								props.data.bls[props.selectedBL][
									'email_embarcador'
								]
							}
						</Typography>
					</Grid>
				</Grid>
				<Grid item container spacing={1} lg={4} md={4} sm={6}>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.consignatario')}
						</Typography>
					</Grid>
					<Grid item lg={12} xs={12} md={12} sm={12}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{
								props.data.bls[props.selectedBL][
									'nombre_consignatario'
								]
							}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.direccion')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{
								props.data.bls[props.selectedBL][
									'direccion_consignatario'
								]
							}
							<br />
							{
								props.data.bls[props.selectedBL][
									'taxid_consignatario'
								]
							}
							<br />
							{
								props.data.bls[props.selectedBL][
									'contacto_consignatario'
								]
							}
							<br />
							{
								props.data.bls[props.selectedBL][
									'tel_consignatario'
								]
							}
							<br />
							{
								props.data.bls[props.selectedBL][
									'email_consignatario'
								]
							}
						</Typography>
					</Grid>
				</Grid>
				<Grid item container spacing={1} lg={4} md={4} sm={12}>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.notify')}
						</Typography>
					</Grid>
					<Grid item lg={12} xs={12} md={12} sm={12}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.bls[props.selectedBL]['nombre_notify']}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.direccion')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{
								props.data.bls[props.selectedBL][
									'direccion_notify'
								]
							}
							<br />
							{props.data.bls[props.selectedBL]['taxid_notify']}
							<br />
							{
								props.data.bls[props.selectedBL][
									'contacto_notify'
								]
							}
							<br />
							{props.data.bls[props.selectedBL]['tel_notify']}
							<br />
							{props.data.bls[props.selectedBL]['email_notify']}
						</Typography>
					</Grid>
				</Grid>
				<Grid item container spacing={1} lg={8} md={8} sm={8}>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.origenDestino')}
						</Typography>
					</Grid>
					<Grid item container spacing={1}>
						<Grid item container spacing={1} lg={6}>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.buque')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data['buque']}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.puertoCarga')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data['pol']}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.puertoDescarga')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data['pod']}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container spacing={1}>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.cargas')}
						</Typography>
					</Grid>
					<Grid item container>
						<InfoBlCarga
							data={props.data.bls[props.selectedBL]}
							selectedBooking={props.selectedBooking}
							loading={props.loading}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
