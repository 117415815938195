import React from 'react';
import {
	Grid,
	Stack,
	Checkbox,
	Typography,
	FormControlLabel,
	Tooltip,
} from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {
	Description,
	Info,
	Inventory,
	Pin,
	ReportProblem,
	Subtitles,
	VpnKey,
} from '@mui/icons-material';

ValidatorForm.addValidationRule('isDecimal', (value) => {
	// Regular expression pattern to match a decimal number
	const decimalRegex = /^-?\d*\.?\d+$/;
	if (decimalRegex.test(value)) {
		return true; // Validation passed
	}
	return false; // Validation failed
});

/*Descripción:Este componente se encarga de mostrar el formulario para agregar o editar una dirección.
Componente padre: EditarDireccionesLModal.jsx // AgregarDireccionesModal.jsx
Componente hijo: SelectSearchCiudades.jsx
props: props.actualizarDato = funcion para actualizar el estado de los campos del formulario //
props.dataCiudades = Información de las ciudades // props.actualizarCiudad = funcion para actualizar la ciudad //
props.handleTextFieldChange = funcion para actualizar el estado de los campos del formulario //
props.infoProductoCP = Información del embalaje // props.dataDetallesDirecciones = Información de las direcciones //
*/

export default function ProductoCartaPorteForm(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crea la funcion para editar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		if (name === 'vAlias') {
			if (event.target.value.length <= 100) {
				actualizarDato(name, event.target.value.toUpperCase());
			}
		} else if (name === 'vRfc') {
			if (event.target.value.length <= 13) {
				const value = event.target.value.replace(/\s/g, '');
				actualizarDato(name, value.toUpperCase());
			}
		} else {
			actualizarDato(name, event.target.value.toUpperCase());
		}
	};

	// Se crea la funcion para actualizar el valor de las cargas
	function actualizarDato(name, value) {
		props.setInfoProductoCP({
			...props.infoProductoCP,
			[name]: value,
		});
	}

	const resultToRender = () => {
		if (props.checked) {
			return (
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'row',
						lg: 'row',
						xl: 'row',
					}}
					spacing={1}
					sx={{ paddingTop: '0px' }}
				>
					<Grid item width={'100%'}>
						<Typography variant='subtitle2'>
							{t('catalogos.misEmbalajes.claveSatPeligroso')} *
						</Typography>
						<TextValidator
							validators={['required', 'minStringLength:1']}
							errorMessages={[
								t('errorMessages.campoRequerido'),
								'Clave invalida',
							]}
							onChange={handleTextFieldChange(
								'vClaveSatPeligroso'
							)}
							required
							id='vClaveSatPeligroso'
							name='vClaveSatPeligroso'
							fullWidth
							value={
								props.infoProductoCP.vClaveSatPeligroso || ''
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<ReportProblem />
									</InputAdornment>
								),
								endAdornment: (
									<Tooltip
										title={t(
											'catalogos.productosCartaPorte.informacion.claveSatPeligroso'
										)}
										arrow
										placement='right'
									>
										<Info
											className='primaryIcon'
											fontSize='medium'
										/>
									</Tooltip>
								),
							}}
						/>
					</Grid>
					<Grid item width={'100%'}>
						<Typography variant='subtitle2'>
							{t('catalogos.productosCartaPorte.vClaveUN')} *
						</Typography>
						<TextValidator
							onKeyDown={props.handleKeyPress}
							validators={['required', 'minStringLength:1']}
							errorMessages={[
								t('errorMessages.campoRequerido'),
								'Clave invalida',
							]}
							onChange={handleTextFieldChange('vClaveUN')}
							required
							id='vClaveUN'
							name='vClaveUN'
							fullWidth
							value={props.infoProductoCP.vClaveUN || ''}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Pin />
									</InputAdornment>
								),
								endAdornment: (
									<Tooltip
										title={t(
											'catalogos.productosCartaPorte.informacion.claveUN'
										)}
										arrow
										placement='right'
									>
										<Info
											className='primaryIcon'
											fontSize='medium'
										/>
									</Tooltip>
								),
							}}
						/>
					</Grid>
				</Stack>
			);
		} else {
			return <></>;
		}
	};

	useEffect(() => {
		resultToRender();
	}, [props.checked]);

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			spacing={1}
			sx={{ paddingTop: '0px' }}
		>
			<Grid item sx={{ paddingTop: '0px' }}>
				<FormControlLabel
					control={
						<Checkbox
							id='role'
							name='role'
							checked={props.checked}
							onChange={props.handleChange}
						/>
					}
					label={
						<Typography class='Checkbox-Text'>
							{t(
								'catalogos.productosCartaPorte.productoPeligroso'
							)}
						</Typography>
					}
					sx={{ paddingTop: '0px' }}
				/>
			</Grid>
			<Grid item>
				<Typography variant='subtitle2'>
					{t('catalogos.misEmbalajes.alias')} *
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['required', 'minStringLength:1']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						'Calle invalida',
					]}
					onChange={handleTextFieldChange('vAlias')}
					required
					id='vAlias'
					name='vAlias'
					fullWidth
					value={props.infoProductoCP.vAlias || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Inventory />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
				sx={{ paddingTop: '0px' }}
			>
				<Grid item width={'100%'}>
					<Typography variant='subtitle2'>
						{t('catalogos.misEmbalajes.claveSat')} *
					</Typography>
					<TextValidator
						onKeyDown={props.handleKeyPress}
						validators={['required', 'minStringLength:1']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							'Clave invalida',
						]}
						onChange={handleTextFieldChange('vClaveSat')}
						required
						id='vClaveSat'
						name='vClaveSat'
						fullWidth
						value={props.infoProductoCP.vClaveSat || ''}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<VpnKey />
								</InputAdornment>
							),
							endAdornment: (
								<Tooltip
									title={t(
										'catalogos.productosCartaPorte.informacion.claveSat'
									)}
									arrow
									placement='right'
								>
									<Info
										className='primaryIcon'
										fontSize='medium'
									/>
								</Tooltip>
							),
						}}
					/>
				</Grid>
				<Grid item width={'100%'}>
					<Typography variant='subtitle2'>
						{t(
							'catalogos.productosCartaPorte.vFraccionArancelaria'
						)}{' '}
						{t('forms.optional')}
					</Typography>
					<TextValidator
						onKeyDown={props.handleKeyPress}
						onChange={handleTextFieldChange('vFraccionArancelaria')}
						id='vClavvFraccionArancelariaeSat'
						name='vFraccionArancelaria'
						fullWidth
						value={props.infoProductoCP.vFraccionArancelaria || ''}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Subtitles />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position='end'>
									<Tooltip
										title={t(
											'cartaPorte.vFraccionArancelariaIngHelper'
										)}
										arrow
										placement='right'
									>
										<Info
											className='primaryIcon'
											fontSize='medium'
										/>
									</Tooltip>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Stack>
			{resultToRender()}
			<Grid item>
				<Typography variant='subtitle2'>
					{t('catalogos.misEmbalajes.descripcion')} *
				</Typography>
				<TextValidator
					multiline
					rows={5}
					validators={['required', 'minStringLength:1']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						'Descripción invalida',
					]}
					onChange={handleTextFieldChange('vDescripcion')}
					required
					id='vDescripcion'
					name='vDescripcion'
					fullWidth
					value={props.infoProductoCP.vDescripcion || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Description />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
		</Stack>
	);
}
