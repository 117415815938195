import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Img1 from '../../../assets/img/cards/CardFotos/Uno.png';
import { Delete, Edit } from '@mui/icons-material';
import EditarConsignatariosModal from './Modales/Consignatarios/EditarConsignatarioModal';
import EliminarConsignatarioModal from './Modales/Consignatarios/EliminarConsignatarioModal';
import { useState } from 'react';

/*Descripción:Este componente se encarga de renderizar la información del consignatario seleccionado.
Componente padre: ConsignatariosInfo.jsx
Componente hijo: EditarConsignatariosModal.jsx // EliminarConsignatarioModal.jsx
props: data: Información del consignatario //
id: id del consignatario //
dataDetallesPaises: Manda la información de los paises //
loadingPaises: Variable para el tiempo de carga //
dataGrid: Información de los consignatarios //
dataFetch: Función para obtener los datos de la API
*/

export default function ConsignatarioInfoCard(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const handleTextFieldChange = (prop) => (event) => {
		props.setConsignatarioEdit({
			...props.consignatarioEdit,
			[prop]: event.target.value.toUpperCase(),
		});
	};

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Card>
				<CardMedia
					component='img'
					alt='img'
					height='100vh'
					image={Img1}
				/>
				<CardContent>
					<Box
						sx={{
							overflowY: 'auto',
							maxHeight: '40vh',
							height: 'auto',
							width: '100%',
							paddingTop: '1%',
						}}
					>
						<Grid
							item
							container
							spacing={1}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<Grid item container>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<Typography
										variant='subtitle2'
										class='Consignatario-Card-Title'
										color='initial'
									>
										{props.data.nombre_razon}
									</Typography>
								</Grid>
							</Grid>
							<Grid item container>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<Typography
										variant='body2'
										class='Consignatario-Card'
										color='initial'
									>
										{t('booking.detalles.taxID')}
									</Typography>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<Typography
										color='initial'
										class='Consignatario-Card-Info'
									>
										{props.data.tax_id}
									</Typography>
								</Grid>
							</Grid>
							<Grid item container>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<Typography
										variant='body2'
										class='Consignatario-Card'
										color='initial'
									>
										{t('dataGrid.pais')}
									</Typography>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<Typography
										color='initial'
										class='Consignatario-Card-Info'
									>
										{props.data.pais}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</Card>
			<Grid item container rowSpacing={1} sx={{ paddingTop: '3vh' }}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Button
						variant='outlined'
						color='primary'
						id='BtnEditar'
						onClick={props.handleAgregarConsignatarioModalOpen}
						sx={{
							width: '100%',
							marginBottom: 1,
						}}
						startIcon={<Edit />}
					>
						{t('botones.editar')}
					</Button>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Button
						variant='outlined'
						id='BtnEliminar'
						color='secondary'
						onClick={handleOpen}
						sx={{
							width: '100%',
						}}
						startIcon={<Delete />}
					>
						{t('botones.eliminar')}
					</Button>
				</Grid>
				<EditarConsignatariosModal
					id={props.id}
					data={props.data}
					actualizarPais={props.actualizarPais}
					indexPais={props.indexPais}
					setIndexPais={props.setIndexPais}
					dataDetallesPaises={props.dataDetallesPaises}
					infoConsignatario={props.infoConsignatario}
					setInfoConsignatario={props.setInfoConsignatario}
					handleAgregarConsignatarioModalClose={
						props.handleAgregarConsignatarioModalClose
					}
					openAgregarConsignatarioModal={
						props.openAgregarConsignatarioModal
					}
					handleTextFieldChange={handleTextFieldChange}
					consignatarioEdit={props.consignatarioEdit}
					setOpenAgregarConsignatarioModal={
						props.setOpenAgregarConsignatarioModal
					}
					dataFetch={props.dataFetch}
					handleBackButtonClick={props.handleBackButtonClick}
					setAlertMessagePage={props.setAlertMessagePage}
					handleClickPage={props.handleClickPage}
				/>
				<EliminarConsignatarioModal
					id={props.id}
					handleCloseModal={handleClose}
					openModal={open}
					setOpenModal={setOpen}
					dataFetch={props.dataFetch}
					handleBackButtonClick={props.handleBackButtonClick}
					setAlertMessagePage={props.setAlertMessagePage}
					handleClickPage={props.handleClickPage}
				/>
			</Grid>
		</>
	);
}
