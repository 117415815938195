import React from 'react';
import { Grid, Typography, Card, CardContent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de Aereo Expo del Booking Seleccionado
Componente padre: BookingAereoExpoSR
Componente hijo:
props: props.data = informacion a desplegar
*/

export default function BookingInfoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			spacing={1}
		>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'column',
								xl: 'column',
							}}
							spacing={1}
						>
							<Grid item container>
								<Typography variant='subtitle2' color='initial'>
									{t('booking.detalles.embarcador')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.nombre')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.embarcador.nombre}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.direccion')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.embarcador.direccion}{' '}
									{props.data.embarcador.ciudad}{' '}
									{props.data.embarcador.rfc}{' '}
									{props.data.embarcador.telefono}
								</Typography>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'column',
								xl: 'column',
							}}
							spacing={1}
						>
							<Grid item container>
								<Typography variant='subtitle2' color='initial'>
									{t('booking.detalles.consignatario')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.nombre')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.consignatario.nombre}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.direccion')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.consignatario.direccion}
								</Typography>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'column',
								xl: 'column',
							}}
							spacing={1}
						>
							<Grid item container>
								<Typography variant='subtitle2' color='initial'>
									{t('booking.detalles.notify')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.nombre')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.notify.nombre}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.direccion')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.notify.direccion},{' '}
									{props.data.notify.pais}{' '}
									{props.data.notify.telefono}{' '}
									{props.data.notify.email}
								</Typography>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
			</Stack>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'column',
								xl: 'column',
							}}
							spacing={1}
						>
							<Grid item container>
								<Typography variant='subtitle2' color='initial'>
									{t('booking.detalles.informacionCarga')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.peso')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.peso}{' '}
									{t('booking.detalles.kg')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.volumen')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.volumen}{' '}
									{t('booking.detalles.cm')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.producto')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.producto}
								</Typography>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'column',
								xl: 'column',
							}}
							spacing={1}
						>
							<Grid item container>
								<Typography variant='subtitle2' color='initial'>
									{t('booking.detalles.origenDestino')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.aeropuertoOrigen')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data['aeropuerto-origen']}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.aeropuertoDestino')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data['aeropuerto-destino']}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.destinoFinal')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data['destino-final']}
								</Typography>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
			</Stack>
		</Stack>
	);
}
