import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion del contenedor relaciobada a TN
Componente padre: EventosBookingTN
Componente hijo:
props: props.dataEmpaque = informacion a desplegar sobre el empaque
*/

export default function InfoContenedor(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid item container spacing={0.5} lg={6} md={6} sm={6}>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography variant='subtitle1' color='initial'>
					{t('booking.detalles.origenDestino')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.puntoCarga')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque !== undefined &&
						props.dataEmpaque['movimiento_carga']}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('tablero.bookingsActivos.destino')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque !== undefined &&
						props.dataEmpaque['movimiento_destino']}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					Fecha estimada de posicionamiento
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque !== undefined &&
						props.dataEmpaque['fecha-est-posicionamiento']}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('eventos.recepcionCartaPorte')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque !== undefined &&
						props.dataEmpaque['fecha-carta-porte']}
				</Typography>
			</Grid>
		</Grid>
	);
}
