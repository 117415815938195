import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Save, Send, Visibility } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import ConfirmacionEnvio from './ConfirmacionEnvio';
import VerPreBLModal from '../Modales/VerPreBLModal';
import { useTranslation } from 'react-i18next';
import Hidden from '@mui/material/Hidden';
import InfoIcon from '@mui/icons-material/Info';
import InformacionModal from '../Modales/InformacionModal';

/*Descripción:Este componente se encarga de desplegar botones y sus respectivos mensajes de alerta
Componente padre: BlResult
Componente hijo: ConfirmacionEnvio
props: props.alertMessage = Mensajes de alerta y su estilo //
	props.enviarDatosFormulario = datos guardados en tabla intermedia //
	props.confirmarDatosFormulario = Datos enviados
*/

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function MySnackbar(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las constantes para el uso del SnackBar
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [snackbar2, setSnackbar2] = useState({ open: false, message: ' ' });
	//Constantes para el mensaje y color del Alert
	const message = props.alertMessage.message;
	const date = new Date().toLocaleString();
	const severity = props.alertMessage.severity;
	const allMesagge = '[' + date + '] ' + severity + ' ' + message;
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;

	// Evento click botón Guardar
	const handleClick1 = () => {
		if (props.enviarDatosFormulario) {
			props.enviarDatosFormulario();
		}
		setSnackbar1({
			open: true,
			message: allMesagge,
		});

		setSnackbar2({
			open: false,
		});
	};

	// funcion para accionar el SnackBar
	const handleClick2 = () => {
		var valueEmpty = false;
		props.handleOpenModal();
		// Valida que todos los campos del formulario sean llenados
		Object.entries(props.data).forEach(([key, value]) => {
			// Si no estan completamente llenados los marca como vacios
			if (value === '' && key !== 'fecha_confirma') {
				valueEmpty = true;
			}
		});
		// Si estan completamente llenados acciona el Alert
		if (props.data.cargas.length === 0 || valueEmpty) {
			setSnackbar2({
				open: true,
				message: allMesagge,
			});
		}
	};

	// Funcion para cerrar el modal
	const hanldeCloseModal = () => {
		props.setOpenModal(false);
	};

	//Funcion para abrir el modal del PreBL
	const [openModalPreBL, setOpenModalPreBL] = useState(false);
	const handleOpenModalPreBL = () => {
		setOpenModalPreBL(true);
	};
	const handleCloseModalPreBL = () => {
		setOpenModalPreBL(false);
	};

	//Evento click Botón Enviar / Confirmar BL
	const confirmacionDatosFormulario = () => {
		props.confirmarDatosFormulario();

		hanldeCloseModal();
		hanldeCloseModal();
		setSnackbar1({ open: false });
		setSnackbar2({ open: true });
	};

	// Funcion para cerrar el Alert
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};
	// Funcion para cerrar el Alert
	const handleClose2 = () => {
		setSnackbar2({ open: false, message: '' });
	};

	// Funcion para abrir modal de informacion
	const [openModalInfo, setOpenModalInfo] = useState(false);
	const handleOpenModalInfo = () => {
		setOpenModalInfo(true);
	};
	const handleCloseModalInfo = () => {
		setOpenModalInfo(false);
	};

	return (
		<Grid container justifyContent={'space-between'}>
			{/* Boton Visualizar--------------------------------------------- */}
			<Grid item>
				<Tooltip title={t('botones.ver')}>
					<Button
						id='visualizarPreBL'
						name='visualizarPreBL'
						variant='outlined'
						color='primary'
						onClick={handleOpenModalPreBL}
						sx={{
							fontSize: '12px',
							mr: 1,
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
				<VerPreBLModal
					openModal={openModalPreBL}
					handleCloseModal={handleCloseModalPreBL}
					selectedBooking={props.selectedBooking}
					endpoint='bill-of-lading/pre-bl-pdf?booking='
				/>
			</Grid>
			{/* Boton Guardar--------------------------------------------- */}
			<Grid item>
				<Tooltip title={t('botones.guardar')}>
					<Button
						id='guardarPreBL'
						name='guardarPreBL'
						variant='outlined'
						color='primary'
						onClick={handleClick1}
						sx={{
							fontSize: '12px',
							mr: 1,
							ml: 1,
						}}
					>
						<Save />
					</Button>
				</Tooltip>
				{/* Alert */}
				<Snackbar
					open={snackbar1.open}
					autoHideDuration={10000}
					onClose={handleClose1}
					anchorOrigin={{ vertical, horizontal }}
				>
					<Alert
						onClose={handleClose1}
						severity={props.alertMessage.severity}
					>
						{props.alertMessage.message}
					</Alert>
				</Snackbar>
			</Grid>
			{/* Boton Enviar BL--------------------------------------------- */}
			<Grid item>
				{props.confirmarDatosFormulario ? (
					<>
						<Tooltip title={t('botones.enviar')}>
							<Button
								id='enviarPreBL'
								name='enviarPreBL'
								variant='outlined'
								color='primary'
								type='submit'
								onClick={handleClick2}
								sx={{
									fontSize: '12px',
									ml: 1,
									mr: 1,
								}}
							>
								<Send />
							</Button>
						</Tooltip>

						<Snackbar
							open={snackbar2.open}
							autoHideDuration={10000}
							onClose={handleClose2}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert
								onClose={handleClose2}
								severity={props.alertMessage.severity}
							>
								{props.alertMessage.message}
							</Alert>
						</Snackbar>

						<ConfirmacionEnvio
							openModal={props.openModal}
							hanldeCloseModal={hanldeCloseModal}
							confirmacionDatosFormulario={
								confirmacionDatosFormulario
							}
						/>
					</>
				) : (
					<span></span>
				)}
			</Grid>
			{/* Boton Informacion Modal--------------------------------------------- */}
			<Hidden lgUp>
				<Grid item>
					<Tooltip title={t('botones.informacion')}>
						<Button
							id='informacionPreBL'
							name='informacionPreBL'
							variant='outlined'
							color='primary'
							onClick={handleOpenModalInfo}
							sx={{
								fontSize: '12px',
								ml: 1,
							}}
						>
							<InfoIcon />
						</Button>
					</Tooltip>

					<InformacionModal
						openModal={openModalInfo}
						handleCloseModal={handleCloseModalInfo}
						selectedBooking={props.selectedBooking}
					/>
				</Grid>
			</Hidden>
		</Grid>
	);
}
