import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion del contenedor relaciobada a TN
Componente padre: EventosBookingUSCAMEX
Componente hijo:
props: props.dataCarga = informacion a desplegar sobre la carga
*/

export default function InfoDetallesCarga(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Grid item container spacing={3} lg={12} md={12} sm={12} xs={12}>
			<Grid item lg={12} md={12} sm={12} xs={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('tablero.bookingsActivos.origen')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataCarga.origenDestino.origen}
				</Typography>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('tablero.bookingsActivos.destino')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataCarga.origenDestino.destino}
				</Typography>
			</Grid>
		</Grid>
	);
}
