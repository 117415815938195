import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	IconButton,
	Grid,
	useMediaQuery,
	Stack,
	Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: SwitchTrackingComponent
Componente hijo: BookingAereoExpoT&T //
	BookingAereoImpoT&T //
	BookingDespachoImpoT&T //
	BookingDespachoExpoT&T //
	BookingMaritimoImpoT&T //
	BookingMaritimoExpoT&T //
	BookingTerrestreNacionalT&T
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.selectedBooking = booking seleccionado o ingresado //
	props.trackingComponent = componente al que se redirige con base al booking ingresado //
	props.handelCloseModal = funcion para cuando cierre el modal
*/

export default function BusquedaModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '75%';
	// Se configura el estilo del Modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxHeight: '95%',
		minHeight: '75vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Stack direction='column' spacing={2}>
					<Stack direction='row' spacing={2}>
						<Grid item width={'100%'}>
							<Typography variant='h6'>
								{props.selectedBooking}
							</Typography>
						</Grid>
						<Grid item width={'100%'} sx={{ textAlign: 'right' }}>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={props.handleCloseModal}
									id='TrackTraceCerrarButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
					</Stack>
					<Grid item>
						{props.trackingComponent.props.selectedBooking ===
							props.selectedBooking && props.trackingComponent}
					</Grid>
				</Stack>
			</Box>
		</Modal>
	);
}
