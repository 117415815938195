import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información respectiva del Booking en el BL
Componente padre: InformacionCard
Componente hijo:
props: props.data = informacion a desplegar
*/

export default function BookingInfo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Grid item container spacing={0.5} lg={12}>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.fechaZarpe')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.eta}
				</Typography>
			</Grid>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.buque')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data['nombre-buque']}
				</Typography>
			</Grid>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.puertoCarga')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.pol}
				</Typography>
			</Grid>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.puertoDescarga')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.pod}
				</Typography>
			</Grid>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.lineaNaviera')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.linea}
				</Typography>
			</Grid>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.viaje')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.viaje}
				</Typography>
			</Grid>
		</Grid>
	);
}
