import {
	Collapse,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Box,
	List,
	Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore, Article } from '@mui/icons-material';
import { useState } from 'react';
import { Fade } from '@mui/material';
import { Tooltip } from '@mui/material';
import ExpoIcon from '../../assets/icons/CargaExpo.png';
import ImpoIcon from '../../assets/icons/CargaImpo.png';
import { useTranslation } from 'react-i18next';
import Grow from '@mui/material/Grow';

/*Descripción:Este componente se encarga de desplegar las opciones desplegables de el sidebar de Maritimo
Componente padre: ListMenu
Componente hijo:
props: props para desplegar la informacion
*/

export default function SideBarItemCollapseMaritimo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en SideBarItemCollapseMaritimo
	const [openCollapse, setOpenCollapse] = useState(false);
	// Funcion que despliega el menú de Maritimo
	const handleClickMaritimo = () => {
		setOpenCollapse(!openCollapse);
	};
	// Se crean variables locales con base a las props
	const { icon: Icon } = props;
	// Check if user role has permission to access this route
	if (!props.roles.includes(props.userRole)) {
		// User role doesn't have permission to access this route, redirect to appropriate page
		return <></>;
	}

	return (
		<Grow in={true} timeout={props.timeout}>
			<Box id='Menu-item'>
				<ListItemButton onClick={handleClickMaritimo} id={props.id}>
					<ListItemIcon id='Menu-icon'>
						<Tooltip
							title={props.primaryText}
							placement='right'
							disableHoverListener={props.openSideBar}
							arrow
						>
							{Icon && <Icon fontSize='medium' color='primary' />}
						</Tooltip>
					</ListItemIcon>
					<Fade in={props.openSideBar}>
						<ListItemText
							primary={
								<Typography variant='subtitle2'>
									{props.primaryText}
								</Typography>
							}
							id='Menu-text'
						/>
					</Fade>
					<Fade in={props.openSideBar}>
						{openCollapse ? (
							<ExpandLess fontSize='medium' color='primary' />
						) : (
							<ExpandMore fontSize='medium' color='primary' />
						)}
					</Fade>
				</ListItemButton>
				<Collapse in={openCollapse} timeout='auto' unmountOnExit>
					<List
						component='div'
						disablePadding
						id='Menu-collapse-list'
					>
						<ListItemButton
							sx={{ pl: 2.7 }}
							component={NavLink}
							to={`${props.navlink}/importacion`}
							id={`${props.id}-importacion`}
						>
							<ListItemIcon id='Menu-icon'>
								<Tooltip
									title={t('sidebar.importacion')}
									placement='right'
									disableHoverListener={props.openSideBar}
									arrow
								>
									{
										<img
											src={ImpoIcon}
											alt=''
											fontSize='medium'
										/>
									}
								</Tooltip>
							</ListItemIcon>
							<Fade in={props.openSideBar}>
								<ListItemText
									primary={
										<Typography variant='subtitle2'>
											{t('sidebar.importacion')}
										</Typography>
									}
									id='Menu-text'
								/>
							</Fade>
						</ListItemButton>
						<ListItemButton
							sx={{ pl: 2.7 }}
							component={NavLink}
							to={`${props.navlink}/exportacion`}
							id={`${props.id}-exportacion`}
						>
							<ListItemIcon id='Menu-icon'>
								<Tooltip
									title={t('sidebar.exportacion')}
									placement='right'
									disableHoverListener={props.openSideBar}
									arrow
								>
									{
										<img
											src={ExpoIcon}
											alt=''
											fontSize='medium'
										/>
									}
								</Tooltip>
							</ListItemIcon>
							<Fade in={props.openSideBar}>
								<ListItemText
									primary={
										<Typography variant='subtitle2'>
											{t('sidebar.exportacion')}
										</Typography>
									}
									id='Menu-text'
								/>
							</Fade>
						</ListItemButton>
						<ListItemButton
							sx={{ pl: 2.7 }}
							component={NavLink}
							id={`${props.id}-bill-of-lading-catalogos`}
							to={`${props.navlink}/bill-of-lading-catalogos`}
						>
							<ListItemIcon id='Menu-icon'>
								<Tooltip
									title='Bill of lading'
									placement='right'
									disableHoverListener={props.openSideBar}
									arrow
								>
									<Article fontSize='small' color='primary' />
								</Tooltip>
							</ListItemIcon>
							<Fade in={props.openSideBar}>
								<ListItemText
									primary={
										<Typography variant='subtitle2'>
											{t('sidebar.maritimo.billLading')}
										</Typography>
									}
									id='Menu-text'
								/>
							</Fade>
						</ListItemButton>
					</List>
				</Collapse>
			</Box>
		</Grow>
	);
}
