import * as React from 'react';
import {
	Box,
	CssBaseline,
	Grid,
	Link,
	Snackbar,
	Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import MyRandomLogin from '../../components/RandomImageLogin';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import LogoCard from '../../components/cards/LogoCard';
import PasswordForm from '../../components/user/PasswordForm';
import { useNavigate } from 'react-router-dom';
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de desplegar la pantalla de recuperacion de contraseña
Componente padre:
Componente hijo: Login.jsx //
	MyRandomLogin
props: Sin uso de props
*/

export default function UpdatePassword() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const navigate = useNavigate();
	const [message, setMessage] = useState('');
	const [severity, setSeverity] = useState('');
	const [open, setOpen] = useState(false);
	let location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');
	const email = searchParams.get('email');
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;
	const handleClose1 = () => {
		setOpen(false);
	};
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const [loading, setLoading] = useState(false);

	// Se hace el Data Fetch
	async function sendData(password) {
		setLoading(true);
		const requestOptionsGuardar = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify({
				email: email,
				newpassword: password,
				token: token,
			}),
			redirect: 'follow',
		};
		// Se hace la peticion al endpoint
		await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'user/update-password',
			requestOptionsGuardar
		)
			.then((response) => {
				// Se valida el estatus de la peticion
				if (response.status === 200) {
					// Se muestra el mensaje de exito
					setMessage(t('alertas.actualizadoCorrectamente'));
					setSeverity('success');
					// Abre el Alert
					setOpen(true);
					// Se redirecciona al login
					setTimeout(() => {
						navigate('/');
					}, 5000);
				} else {
					// Se muestra el mensaje de error
					setMessage(t('alertas.error'));
					setSeverity('error');
					setOpen(true);
				}
			})
			.catch((error) => console.log('error ', error));
	}

	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<CssBaseline />
			<MyRandomLogin />
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				lg={5}
				xl={4}
				component={Paper}
				elevation={6}
				square
			>
				<Box
					className='text-center'
					sx={{
						my: 4,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						overflowY: 'hidden',
					}}
					paddingTop={9}
				>
					<LogoCard />
					<Typography variant='h5'>
						{t('centroAyuda.login.actualizarContrasena')}
					</Typography>
					<Typography variant='subtitle2'>
						{t('centroAyuda.login.ingresaNuevaContrasena')}
					</Typography>
					<Box
						className='text-center'
						noValidate
						width={'75%'}
						sx={{ paddingTop: '4%' }}
					>
						<Grid item container rowSpacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<PasswordForm
									sendData={sendData}
									myHeaders={myHeaders}
									loading={loading}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								container
								sx={{
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}
							>
								<Link href='/contactanos' variant='body2'>
									<Typography variant='body2'>
										{t('inicio.apoyo')}
									</Typography>
								</Link>
							</Grid>
						</Grid>
						<Snackbar
							open={open}
							autoHideDuration={10000}
							onClose={handleClose1}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert onClose={handleClose1} severity={severity}>
								{message}
							</Alert>
						</Snackbar>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}
