import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Box, Stack, Grid } from '@mui/material';
import { RequestPage } from '@mui/icons-material';

export default function ComprobanteCard(props) {
	const { t } = useTranslation();
	// Función para dar formato a las fechas
	function DateFormatter({ date }) {
		const parts = date.split('T');
		const info = parts[0].split('-');
		return <Box>{info[2] + '-' + info[1] + '-' + info[0]} </Box>;
	}

	return (
		<Card elevation={3} className='fac-card-top paper-hover'>
			<CardContent>
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'column',
						lg: 'column',
						xl: 'column',
					}}
					spacing={1}
				>
					<Grid item>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.facturas.comprobante')}
						</Typography>
						<RequestPage color='primary' />
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.facturas.serie')} /{' '}
							{t('dataGrid.folio')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDetalles.factura.serie} /{' '}
							{props.dataDetalles.factura.folio}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.facturas.fechaEmision')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							<DateFormatter
								date={props.dataDetalles.factura.fecha}
							/>
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.facturas.formaPago')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDetalles.factura.formaPago}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.facturas.metodoPago')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDetalles.factura.metodoPago}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.facturas.version')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDetalles.factura.version}
						</Typography>
					</Grid>
				</Stack>
			</CardContent>
		</Card>
	);
}
