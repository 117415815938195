import { Box, Grid, Snackbar, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import MuiAlert from '@mui/material/Alert';
import ErrorBoundary from '../../components/ErrorBoundary';
import { useNavigate } from 'react-router-dom';
import MySkeletonPage from '../../components/Skeletons/SkeletonPage';
import DataGridUsuarios from '../../components/user/DataGridUsuarios';
import AgregarUsuario from '../../components/user/Modales/AgregarUsuario';
import GetDataFetch from '../../components/get-data/GetDataFetch';
import { useTranslation } from 'react-i18next';
import BoxTitle from '../../components/titles/BoxTitle';
import GetRoleFetch from '../../components/get-data/GetRoleFetch';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: CommonLayout.jsx
Componente hijo: DataGridUsuarios // AgregarUsuario
props: props.endpoint = endpoint al que se hará la petición
*/

export default function ListadoUsuarios(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [isChecked, setIsChecked] = useState(false);
	const [role, setRole] = useState('');
	const [newRole, setNewRole] = useState('');
	const [alertMessage, setAlertMessage] = useState({
		severity: '',
		message: '',
	});
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;
	const [openModal, setOpenModal] = useState(false);

	// Se hace el Data Fetch
	const dataFetch = async () => {
		setLoading(true);
		let url = props.endpoint;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};
	// useEffect del Data Fetch
	useEffect(() => {
		dataFetch();
	}, [props.endpoint]);

	const GetRole = async () => {
		await GetRoleFetch(setRole);
	};

	useEffect(() => {
		GetRole();
		if (role === 'Cliente-admin') {
			setNewRole('ROLE_CLIENTE_DIRECTO');
			setIsChecked(false);
		}
		if (role === 'Agente-admin') {
			setNewRole('ROLE_AGENTE_EXTRANJERO');
			setIsChecked(false);
		}
	}, [props]);

	// Función para el valor del checkbox de agregar usuario
	const handleCheckAgergarUsuario = async (event) => {
		await GetRoleFetch(setRole);
		setIsChecked(!isChecked);
		if (role === 'Cliente-admin') {
			if (isChecked) {
				setNewRole('ROLE_CLIENTE_DIRECTO');
			} else {
				setNewRole('ROLE_CLIENTE_DIRECTO_ADMIN');
			}
		} else if (role === 'Agente-admin') {
			if (isChecked) {
				setNewRole('ROLE_AGENTE_EXTRANJERO');
			} else {
				setNewRole('ROLE_AGENTE_EXTRANJERO_ADMIN');
			}
		}
	};

	const handleCheck = async (event) => {
		await GetRoleFetch(setRole);
		setIsChecked(!isChecked);
		if (role === 'Cliente-admin') {
			if (isChecked) {
				setNewRole('ROLE_CLIENTE_DIRECTO');
			} else {
				setNewRole('ROLE_CLIENTE_DIRECTO_ADMIN');
			}
		} else if (role === 'Agente-admin') {
			if (isChecked) {
				setNewRole('ROLE_AGENTE_EXTRANJERO');
			} else {
				setNewRole('ROLE_AGENTE_EXTRANJERO_ADMIN');
			}
		}
	};
	// Función para el valor del checkbox
	const handleCheck2 = async (newState) => {
		await GetRoleFetch(setRole);
		setIsChecked(newState);
		if (role === 'Cliente-admin') {
			if (isChecked) {
				setNewRole('ROLE_CLIENTE_DIRECTO_ADMIN');
			} else {
				setNewRole('ROLE_CLIENTE_DIRECTO');
			}
		} else if (role === 'Agente-admin') {
			if (isChecked) {
				setNewRole('ROLE_AGENTE_EXTRANJERO_ADMIN');
			} else {
				setNewRole('ROLE_AGENTE_EXTRANJERO');
			}
		}
	};
	// Función para abrir y cerrar el modal
	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// Funcion para abrir y cerrar el Snackbar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};
	// Función para renderizar el contenido de la pantalla
	const viewToRender = () => {
		if (dataDetalles.users.length === 0) {
			return (
				<Grid container spacing={1}>
					<Grid item container spacing={0} justifyContent={'center'}>
						<PrecisionManufacturingIcon
							fontSize='large'
							className='primaryIcon'
						/>
					</Grid>
					<Grid item container spacing={0} justifyContent={'center'}>
						<Typography variant='body1'>
							{t('dataGrid.sinUsuariosRegistrados')}
						</Typography>
					</Grid>
				</Grid>
			);
		} else {
			return (
				<DataGridUsuarios
					dataGrid={dataDetalles.users}
					role={newRole}
					handleCheck={handleCheck}
					handleCheck2={handleCheck2}
					isChecked={isChecked}
					dataFetch={dataFetch}
					handleClick1={handleClick1}
					setAlertMessage={setAlertMessage}
					handleCloseModal={handleCloseModal}
					openModal={openModal}
					setOpenModal={setOpenModal}
					handleOpenModal={handleOpenModal}
				/>
			);
		}
	};

	return (
		<ErrorBoundary>
			<Box>
				<BoxTitle title={t('paginas.usuarios')} />
				<Box
					sx={{
						width: '100%',
						bgcolor: 'background.secondary',
						borderRadius: 1,
						padding: 1,
					}}
				>
					<Grid item container marginTop={0}>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{ textAlign: 'right' }}
						>
							<AgregarUsuario
								role={role}
								newRole={newRole}
								handleCheck={handleCheckAgergarUsuario}
								isChecked={isChecked}
								dataFetch={dataFetch}
								handleClick1={handleClick1}
								setAlertMessage={setAlertMessage}
								handleCloseModal={handleCloseModal}
								openModal={openModal}
								setOpenModal={setOpenModal}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							{loading ? (
								<MySkeletonPage />
							) : (
								<>{viewToRender()}</>
							)}
						</Grid>
						<Snackbar
							open={snackbar1.open}
							autoHideDuration={10000}
							onClose={handleClose1}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert
								onClose={handleClose1}
								severity={alertMessage.severity}
							>
								{alertMessage.message}
							</Alert>
						</Snackbar>
					</Grid>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
