import { Box, Grid, Modal } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PDFViewerPreBL from '../PDFViewerPreBL';
import MySkeletonList from '../../Skeletons/SkeletonList';

// Se crean los estilos del Modal
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '100%',
	height: 600,
	overflowY: 'auto',
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: 4,
	borderRadius: '10px',
};

export default function VerPreBLModal(props) {
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [url, setURL] = useState('');
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const qrFetch = async () => {
		setLoading(true);
		// Se hace el Get Fetch de la información
		// Configuracion del header
		var myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/png');
		myHeaders.append('Authorization', 'Bearer ' + token);
		try {
			const response = await fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					props.endpoint +
					props.selectedBooking +
					(props.bl ? '&bl=' + props.bl : ''),
				{
					method: 'GET',
					headers: myHeaders,
					redirect: 'follow',
				}
			);
			if (
				response.status === 200 ||
				response.status === 500 ||
				response.status === 400
			) {
				// Obtener el Blob desde la respuesta
				const blob = await response.blob();
				// Crear la URL a partir del Blob
				const pdfBlob = blob;
				setURL(pdfBlob);
				setLoading(false);
			} else if (response.status === 401) {
				// Si el token caduca se redirige al login
				navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
			} else if (response.status === 504) {
				setLoading(false);
			}
		} catch (error) {
			console.error('Error mostrando el QR: ', error);
		}
	};

	// Hacer la petición al endpoint indicado según la url
	useEffect(() => {
		if (props.openModal) {
			qrFetch();
		}
	}, [props.endpoint, props.openModal]);

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				{loading ? (
					<MySkeletonList />
				) : (
					<Grid
						item
						container
						rowSpacing={2}
						justifyContent={'center'}
					>
						<PDFViewerPreBL
							pdfBlob={url}
							handleCloseModal={props.handleCloseModal}
							selectedBooking={props.selectedBooking}
						/>
					</Grid>
				)}
			</Box>
		</Modal>
	);
}
