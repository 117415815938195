// ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import RoleContext from './RoleContext';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

/*Descripción:Este componente se encarga de validar el acceso a las rutas del portal
Componente padre: Global
Componente hijo:
props:
*/

export default function ProtectedRoute({ element: Component, roles }) {
	var userRole = useContext(RoleContext);
	// Obtener los roles del usuario========================================
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');

	// Se valida que el token exista
	if (token !== undefined) {
		// Si existe se obtiene el rol del usuario
		const claims = jwt_decode(token);
		const rolesClaims = claims?.roles;
		const rol = rolesClaims.substring(1, rolesClaims.length - 1);
		userRole = rol;
	} else {
		// Si no existe se redirige al login
		return <Navigate replace to='/' />;
	}

	// Se valida que el rol del usuario exista
	if (!userRole) {
		// User role not yet fetched, show loading or login component
		return Component;
	}
	// Si el usuario no tiene el acceso a la ruta lo redirige al inicio
	if (roles.includes(userRole) === false) {
		// User role doesn't have permission to access this route, redirect to appropriate page
		return <Navigate replace to='/inicio' />;
	}
	// User role has permission, render the component
	return <Outlet />;
}
