import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Select from 'react-select';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información del SelectSearch dependiendo los datos recopilados
Componente padre: ConsignatarioForm.jsx
Componente hijo:
props: actualizarPais: Función para actualizar el pais // dataOption: Información de los paises //
customLabel: Nombre del label // id: id del selectSearch
*/

export default function SelectSearchPaises(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean los estilos que tendrá el SelectSearch
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			height: '45px',
			border: state.isFocused ? '2px solid #002D58' : '1px solid #cccccc',
			boxShadow: state.isFocused ? 'none' : 'none',
			'&:hover': {
				border: '1px solid black',
			},
			background: props.disabled && 'white',
			fontSize: '13px',
			fontFamily: 'Source Sans Pro',
		}),
		placeholder: (provided, state) => ({
			...provided,
			position: 'absolute',
			top: state.hasValue || state.selectProps.inputValue ? -20 : '20%',
			transition: 'top 0.1s, font-size 0.1s',
			fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
			background: 'white',
			paddingLeft: '5px',
			paddingRight: '5px',
			fontFamily: 'Source Sans Pro',
		}),
		valueContainer: (provided, state) => ({
			...provided,
			overflow: 'visible',
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
		}),
		menu: (provided, state) => ({
			...provided,
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
			maxHeight: '200px',
		}),
		menuList: (provided, state) => ({
			...provided,
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
			maxHeight: '200px',
		}),
	};

	// Se daclaran los const para su uso en el SelectSearchContenedor
	const [defaultValue, setDefaultValue] = useState(
		props.dataOptions[
			props.dataOptions.findIndex(
				(obj) => obj.value === props.defaultValue
			)
		]
	);

	// Funcion para actualizar el contenedor seleccionado
	const handleChange = (event) => {
		props.actualizarPais(event);
	};

	useEffect(() => {
		setDefaultValue(
			props.dataOptions[
				props.dataOptions.findIndex(
					(obj) => obj.value === props.defaultValue
				)
			]
		);
	}, [props.dataOptions]);

	return (
		<Box>
			<Typography variant='subtitle2' paddingBottom={'1%'}>
				{props.customLabel}
			</Typography>
			<Select
				onChange={handleChange}
				className='basic-single'
				classNamePrefix='select'
				placeholder={t(
					'catalogos.consignatario.selectSearch.seleccionar'
				)}
				noOptionsMessage={({ inputValue }) =>
					!inputValue ? 'value' : 'Valor no encontrado'
				}
				isSearchable={true}
				id={props.id}
				name={props.id}
				value={defaultValue}
				options={props.dataOptions}
				styles={customStyles}
			/>
		</Box>
	);
}
