import { Typography } from '@mui/material';
import TableCuentaAmericana from '../Tables/TableCuentaAmericana';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información de cuenta americana
Componente padre: ServiciosAdicionalesTable
Componente hijo:
props: props.data = informacion a desplegar //
	props.cuentaAmericana = booleano que indica si se tiene cuenta americana contratada
*/

export default function AcordionCuentaAmericana(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const resultToRender = () => {
		if (props.cuentaAmericana) {
			return <TableCuentaAmericana data={props.data} />;
		} else {
			return (
				<Typography>{t('eventos.sinServicioContratado')}</Typography>
			);
		}
	};
	return resultToRender();
}
