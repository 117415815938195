import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import DataGridCargasUSCAMEX from './DataGridCargasUSCAMEX';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar la informacion de USCAMEX del Booking Seleccionado
Componente padre: BookingUSCAMEXExpoFTLSearchResult
Componente hijo: DataGridCargasUSCAMEX
props: props.data = informacion a desplegar
*/

export default function InfoBookingUSCAMEX(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Box sx={{ overflowY: 'auto', height: '335px', width: '100%' }}>
			<Grid container rowSpacing={3} columnSpacing={2}>
				<Grid
					item
					container
					direction='row'
					spacing={1}
					lg={3}
					md={3}
					sm={3}
					xs={12}
				>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.embarcador')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.embarcadorConsignatario.embarcador}
						</Typography>
					</Grid>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.consignatario')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.embarcadorConsignatario.consignatario}
						</Typography>
					</Grid>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.notify')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.embarcadorConsignatario.notifyParty}
						</Typography>
					</Grid>
				</Grid>

				<Grid item container spacing={1} lg={9} md={9} sm={9} xs={12}>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.informacionCarga')}
						</Typography>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<DataGridCargasUSCAMEX dataOption={props.dataOption} />
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
