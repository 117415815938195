import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useState } from 'react';
import TimelineEventosTCAExpoFCL from './TimelineEventosTCAExpoFCL';

/*Descripción:Este componente se encarga de desplegar las opciones del Timeline de TN
Componente padre: EventosBookingTerrestreCentroame
Componente hijo: TimelineEventosContenedor
props: props.dataEmpaque = informacion a desplegar sobre el empaque
*/

export default function TimelineSelectorTCAExpoFCL(props) {
	// Se crea la constante para seleccionar el Tab
	const [value, setValue] = useState('1');
	// Funcion para delcarar las Tabs
	function MyTabs() {
		return (
			<TabContext value={value} id='maritimoImpoTabContext'>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleChange}
						aria-label='lab API tabs example'
						centered
					>
						<Tab
							icon={<LocalShippingIcon />}
							value='1'
							sx={{ color: 'grey' }}
						/>
					</TabList>
				</Box>
				<TabPanel
					value='1'
					sx={{ maxHeight: '46vh', overflowY: 'auto' }}
				>
					<TimelineEventosTCAExpoFCL
						dataEmpaque={props.dataEmpaque}
					/>
				</TabPanel>
			</TabContext>
		);
	}
	// Funcion para seleccionar el Tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<MyTabs />
		</Box>
	);
}
