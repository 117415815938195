import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import LogoIcon from '../../assets/img/logo/logo_accutrack.png';
import MiniLogoIcon from '../../assets/img/logo/logo_accutrack_mini.png';
import {
	AppBar,
	Avatar,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	SvgIcon,
	Card,
	CardMedia,
	Hidden,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { NavLink, useNavigate } from 'react-router-dom';
import { ListItemIcon, ListItemText } from '@mui/material';
import Cookies from 'universal-cookie';
import Fade from '@mui/material/Fade';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useTranslation } from 'react-i18next';
import { Help } from '@mui/icons-material';

/*Descripción:Este componente se encarga de desplegar la barra de navegacion superior
Componente padre: App.js
Componente hijo: Sidebar.jsx
props: Sin uso de props
*/

export default function Navbar(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en Navbar
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [users, setUsers] = useState([]);
	const [customer, setCustomer] = useState([]);
	// Variables necesarias para realizar el Fetch Data
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);
	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	// Data Fetch Informacion de Usuario
	const getUserInfo = async (token) => {
		// Se realiza la petición al endpoint
		const responseUserInfo = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'user/info',
			requestOptions
		);
		// Se obtiene la respuesta del endpoint
		const dataUserInfo = await responseUserInfo.json();
		if (responseUserInfo.status === 401) {
			// Si el token expira, se redirige al login
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		}
		// Se asignan los valores de la respuesta a la variable users
		setUsers(dataUserInfo);
	};

	// useEffect del Data Fetch Usuario
	useEffect(() => {
		getUserInfo(token);
		getCustomerInfo(token);
	}, []);

	// Funcion para configurar el estilo del avatar del SideBar
	function stringAvatar(name) {
		return {
			sx: {
				bgcolor: '#e73333',
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
		};
	}

	// Data Fetch Informacion de Customer
	const getCustomerInfo = async (token) => {
		setLoading(true);
		// Se realiza la petición al endpoint
		const responseCustomerInfo = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'cliente',
			requestOptions
		);
		// Se obtiene la respuesta del endpoint
		const dataCustomerInfo = await responseCustomerInfo.json();
		if (responseCustomerInfo.status === 401) {
			// Si el token expira, se redirige al login
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		}
		// Se asignan los valores de la respuesta a la variable customer
		setCustomer(dataCustomerInfo.data);
		setLoading(false);
	};

	// Funcion para abrir el menu de usuario
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	// Funcion para cerrar el menu de usuario
	const handleClose = () => {
		setAnchorEl(null);
	};
	// Funcion para cerrar sesion
	const handleLogOut = () => {
		cookies.remove('jwt_authorization');
		navigate('/');
	};
	const handleHome = () => {
		navigate('/inicio');
	};
	// Ayuda
	const handleHelp = () => {
		navigate('/help');
	};

	return (
		<AppBar
			position='sticky'
			sx={{
				top: 0,
				zIndex: (theme) => theme.zIndex.drawer + 1,
				bgcolor: 'navBar',
				width: '100%',
			}}
			elevation={0}
		>
			<Toolbar>
				<IconButton
					aria-label='open drawer'
					onClick={() => props.onClick()}
					edge='start'
					sx={{
						color: 'white',
						marginRight: 2,
					}}
				>
					{props.openSideBar ? (
						<SvgIcon style={{ transform: 'rotateY(0deg)' }}>
							<MenuOpenIcon fontSize='large' />
						</SvgIcon>
					) : (
						<SvgIcon style={{ transform: 'rotateY(180deg)' }}>
							<MenuOpenIcon fontSize='large' />
						</SvgIcon>
					)}
				</IconButton>
				<Typography
					variant='h6'
					noWrap
					component='div'
					sx={{ flexGrow: 1 }}
				>
					<Hidden smDown>
						<Card
							elevation={2}
							sx={{ maxWidth: '130px', cursor: 'pointer' }}
						>
							<CardMedia
								component='img'
								src={LogoIcon}
								alt='Logo'
								onClick={handleHome}
							/>
						</Card>
					</Hidden>
					<Hidden smUp>
						<Card
							elevation={2}
							sx={{ maxWidth: '30px', cursor: 'pointer' }}
						>
							<CardMedia
								component='img'
								src={MiniLogoIcon}
								alt='Logo'
								onClick={handleHome}
							/>
						</Card>
					</Hidden>
				</Typography>
				<Typography
					variant='span'
					component='span'
					sx={{ color: 'white', fontSize: '12px' }}
				>
					<Fade in={!loading} timeout={500}>
						<div>{loading ? '' : customer.nombre}</div>
					</Fade>
				</Typography>
				<IconButton
					onClick={handleClick}
					aria-controls='menu-appbar'
					aria-haspopup='true'
					size='large'
					color='inherit'
					sx={{ paddingRight: 0 }}
				>
					{loading ? (
						<></>
					) : (
						<Fade in={!loading} timeout={500}>
							{loading ? (
								<></>
							) : (
								<Avatar
									{...stringAvatar(
										(users.name
											? users.name[0].toUpperCase()
											: '') +
											' ' +
											(users.lastname
												? users.lastname[0].toUpperCase()
												: '')
									)}
									className='navBarAvatar'
								/>
							)}
						</Fade>
					)}
				</IconButton>
			</Toolbar>
			<Menu
				id='demo-positioned-menu'
				aria-labelledby='demo-positioned-button'
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<MenuItem
					onClick={handleClose}
					component={NavLink}
					to='/usuario/perfil'
				>
					<ListItemIcon>
						<PersonIcon />
					</ListItemIcon>
					<ListItemText>{t('header.perfil')}</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={handleHelp}
					component={NavLink}
					to='/centro-ayuda'
				>
					<ListItemIcon>
						<Help />
					</ListItemIcon>
					<ListItemText>{t('centroAyuda.ayuda')}</ListItemText>
				</MenuItem>
				<MenuItem onClick={handleLogOut}>
					<ListItemIcon>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText>{t('header.cerrarSesion')}</ListItemText>
				</MenuItem>
			</Menu>
		</AppBar>
	);
}
