import { createTheme } from '@mui/material';
import { isOverflown } from '@mui/x-data-grid/utils/domUtils';

/*Descripción:Este componente se encarga de crear el tema de el portal
Componente padre: Global
Componente hijo: Global
props: Sin uso de props
*/

export const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#002D58',
			light: '#87CEEB',
			background: '#CCCCCC',
		},
		secondary: {
			main: '#E0202A',
		},
		background: {
			default: '#e3e3e3',
			secondary: '#FFFFFF',
		},
		otherColor: {
			main: '#999',
		},
		primaryButton: {
			main: '#002D58',
		},
		accuBlue: {
			main: '#002D58',
		},
		accuRed: {
			main: '#E0202A',
		},
		navBar: {
			main: '#002D58',
		},
		text: {
			primary: '#000000',
			secondary: '#002D58',
		},
		activeBookingCard: {
			main: '#002D58',
		},
		pendingBlCard: {
			main: '#002D58',
		},
		informationCard: {
			main: '#F5F5F5',
		},
	},
	typography: {
		h3: {
			color: 'black',
			fontSize: '2.3rem',
			fontFamily: 'Open Sans, sans-serif',
			paddingTop: '5px',
		},
		h4: {
			color: 'black',
			fontSize: '1.5rem',
			fontFamily: 'Open Sans, sans-serif',
			paddingTop: '5px',
		},
		h5: {
			color: 'black',
			fontSize: '1.20rem',
			fontFamily: 'Open Sans, sans-serif',
		},
		h6: {
			color: 'black',
			fontSize: '1.22rem',
			fontWeight: 800,
			fontFamily: 'Open Sans, sans-serif',
		},
		h7: {
			color: 'black',
			fontSize: '16px',
			fontFamily: 'Open Sans, sans-serif',
		},
		subtitle1: {
			fontSize: '1rem',
			color: 'black',
			fontFamily: '"Source Sans Pro", sans-serif',
		},
		subtitle2: {
			fontSize: '0.875rem',
			color: 'black',
			fontFamily: '"Source Sans Pro", sans-serif',
		},
		body1: {
			color: 'black',
			fontSize: '0.875rem',
			fontFamily: '"Source Sans Pro", sans-serif',
		},
		body2: {
			color: 'black',
			fontSize: '0.825rem',
			fontFamily: '"Source Sans Pro", sans-serif',
		},
		button: {
			color: 'black',
			fontSize: '0.875rem',
			fontFamily: '"Source Sans Pro", sans-serif',
		},
		caption: {
			color: 'black',
			fontSize: '0.875rem',
			fontFamily: '"Source Sans Pro", sans-serif',
		},
		overline: {
			color: 'black',
			fontSize: '0.875rem',
			fontFamily: '"Source Sans Pro", sans-serif',
		},
	},
	overrides: {
		MuiTooltip: {
			tooltip: {
				fontFamily: '"Source Sans Pro", sans-serif',
			},
		},
	},
	components: {
		MuiCard: {
			styleOverrides: {
				root: {
					fontFamily: '"Source Sans Pro", sans-serif',
					backgroundColor: '#FFFFFF',
				},
			},
		},
		MuiModal: {
			styleOverrides: {
				root: {
					borderRadius: '10px',
				},
			},
		},
		MuiTabPanel: {
			styleOverrides: {
				root: {
					padding: '0px',
					paddingTop: '2px',
					paddingLeft: '17px',
					overflowY: 'auto',
					maxHeight: '60vh',
					borderRadiusBottomLeft: '5px',
					borderRadiusBottomRight: '5px',
				},
			},
		},
	},
});
