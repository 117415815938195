import {
	Typography,
	Grid,
	LinearProgress,
	Box,
	Tab,
	CssBaseline,
	Container,
	Tabs,
	Button,
	Tooltip,
	Card,
	CardContent,
} from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../../get-data/GetDataFetch';
import InfoIcon from '@mui/icons-material/Info';
import InfoBLFinal from './Info/InfoBLFinal';
import { Visibility } from '@mui/icons-material';
import VerPreBLModal from '../../../bill-of-lading/Modales/VerPreBLModal';
import { useTranslation } from 'react-i18next';
import SinBl from '../../../cards/SinBl';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Maritimo Expo
Componente padre: CommonBooking
Componente hijo: InfoBookingMaritimoExpo //
	EventosBookingMaritimoExpo //
	IndexFileUpload
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición
*/

export default function BlSearchResult(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las constantes para el uso del SR
	const [loadingBL, setLoadingBl] = useState(true);
	const [loadingPreBL, setLoadingPreBL] = useState(true);
	const [dataDetallesBL, setDataDetallesBL] = useState({});
	const [dataDetallesPreBL, setDataDetallesPreBL] = useState({});
	const [openModalPreBL, setOpenModalPreBL] = useState(false);
	const [openModalBL, setOpenModalBL] = useState(false);
	const [selectedBL, setSelectedBL] = useState(0);
	const [value, setValue] = useState('1');
	const navigate = useNavigate();

	// fetch data
	const dataFetchPreBL = async () => {
		setLoadingPreBL(true);
		// Se crea el endpoint
		let url = props.endpointPreBL + props.selectedBooking;
		// Se hace el Get Fetch para la informacion
		await GetDataFetch(
			url,
			setLoadingPreBL,
			setDataDetallesPreBL,
			navigate
		);
	};

	// fetch data
	const dataFetchBL = async () => {
		setLoadingBl(true);
		// Se crea el endpoint
		let url = props.endpointBL + props.selectedBooking;
		// Se hace el Get Fetch para la informacion
		await GetDataFetch(url, setLoadingBl, setDataDetallesBL, navigate);
	};

	// useEffect para la seleccion del booking
	useEffect(() => {
		if (props.selectedBooking !== '') {
			dataFetchBL();
			dataFetchPreBL();
		}
	}, [props.selectedBooking]);

	// Funcion para seleccionar el tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// Funcion para mostarr el pdf del prebl
	const handleOpenModalPreBL = () => {
		setOpenModalPreBL(true);
	};
	const handleOpenModalBL = () => {
		setOpenModalBL(true);
	};
	const handleCloseModalPreBL = () => {
		setOpenModalPreBL(false);
	};
	const handleCloseModalBL = () => {
		setOpenModalBL(false);
	};

	return (
		<>
			<CssBaseline />
			<Container container rowSpacing={1} maxWidth={false} disableGutters>
				<Card
					sx={{
						width: '100%',
						height: '72vh',
					}}
				>
					<CardContent>
						<Grid
							container
							sx={{
								justifyContent: 'space-between',
								paddingBottom: '10px',
							}}
							spacing={2}
						>
							<Grid item>
								<Grid container spacing={2}>
									<Grid item>
										<Typography variant='h6'>
											{props.selectedBooking}
										</Typography>
									</Grid>
									<Grid item>
										<Tooltip
											title={t('bl.informacionDraft')}
											placement='right'
											arrow
										>
											<InfoIcon
												className='primaryIcon'
												fontSize='medium'
											/>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Tooltip title={t('botones.ver')}>
									<Button
										id='verPreBL'
										name='verPreBL'
										variant='outlined'
										color='primary'
										onClick={handleOpenModalPreBL}
										sx={{
											mr: 1,
										}}
										startIcon={<Visibility />}
									>
										{t('bl.draftEnviado')}
									</Button>
								</Tooltip>
								<Tooltip title={t('botones.ver')}>
									<Button
										id='verBL'
										name='verBL'
										variant='outlined'
										color='primary'
										onClick={handleOpenModalBL}
										sx={{
											mr: 1,
										}}
										startIcon={<Visibility />}
										disabled={
											dataDetallesBL.bls &&
											dataDetallesBL.bls.length === 0
										}
									>
										{t('bl.draftFinal')}
									</Button>
								</Tooltip>
							</Grid>

							<VerPreBLModal
								openModal={openModalPreBL}
								handleCloseModal={handleCloseModalPreBL}
								selectedBooking={props.selectedBooking}
								endpoint='bill-of-lading/pre-bl-pdf?booking='
							/>
							<VerPreBLModal
								selectedBL={selectedBL}
								openModal={openModalBL}
								handleCloseModal={handleCloseModalBL}
								selectedBooking={props.selectedBooking}
								bl={
									dataDetallesBL.bls &&
									dataDetallesBL.bls[selectedBL]
										? dataDetallesBL.bls[selectedBL].bl
										: ''
								}
								endpoint={'bill-of-lading/bl-pdf?booking='}
							/>
						</Grid>
						<Grid container spacing={2}>
							<Box sx={{ width: '100%' }}>
								<TabContext value={value}>
									<Box
										sx={{
											borderBottom: 1,
											borderColor: 'divider',
											marginLeft: '16px',
										}}
									>
										<Tabs
											onChange={handleChange}
											value={value}
											aria-label='lab API tabs example'
										>
											<Tab
												label={t('bl.detallesFinales')}
												value='1'
												wrapped
												sx={{ color: 'grey' }}
											/>
										</Tabs>
									</Box>
									<TabPanel
										value='1'
										sx={{
											overflowY: 'auto',
											padding: '16px',
										}}
										justifyContent='center'
									>
										{loadingBL ? (
											<Box sx={{ width: '100%' }}>
												<LinearProgress />
											</Box>
										) : (
											<>
												{dataDetallesBL.bls &&
												dataDetallesBL.bls.length >
													0 ? (
													<InfoBLFinal
														selectedBL={selectedBL}
														setSelectedBL={
															setSelectedBL
														}
														data={dataDetallesBL}
														selectedBooking={
															props.selectedBooking
														}
														loading={loadingBL}
													/>
												) : (
													<SinBl />
												)}
											</>
										)}
									</TabPanel>
								</TabContext>
							</Box>
						</Grid>
					</CardContent>
				</Card>
			</Container>
		</>
	);
}
