import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar el Timeline de eventos terrestres ligados a Aereo Expo
Componente padre: TimelineSelectorAereoExpo
Componente hijo: TimelineItem
props: props.dataRecolecciones = informacion a desplegar sobre las recolecciones
*/

export default function TimelineEventosTerrestreExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Timeline position='alternate'>
			{props.dataRecolecciones['fecha-recoleccion'] === 'N.D.' ||
			props.dataRecolecciones['fecha-recoleccion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataRecolecciones['fecha-recoleccion']}
					title={t('eventos.recoleccion')}
				/>
			)}
			{props.dataRecolecciones['fecha-entrega-bodega'] === 'N.D.' ||
			props.dataRecolecciones['fecha-entrega-bodega'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataRecolecciones['fecha-entrega-bodega']}
					title={t('eventos.entregaBodega')}
				/>
			)}
			{props.dataRecolecciones['fecha-llegada-bodega-embarcador'] ===
				'N.D.' ||
			props.dataRecolecciones['fecha-llegada-bodega-embarcador'] ===
				undefined ? (
				<></>
			) : (
				<TimelineItem
					data={
						props.dataRecolecciones[
							'fecha-llegada-bodega-embarcador'
						]
					}
					title={t('eventos.llegadaBodegaEmbarcador')}
				/>
			)}
			{props.dataRecolecciones['fecha-salida-bodega-embarcador'] ===
				'N.D.' ||
			props.dataRecolecciones['fecha-salida-bodega-embarcador'] ===
				undefined ? (
				<></>
			) : (
				<TimelineItem
					data={
						props.dataRecolecciones[
							'fecha-salida-bodega-embarcador'
						]
					}
					title={t('eventos.salidaBodegaEmbarcador')}
				/>
			)}
			{props.dataRecolecciones['fecha-llegada-bodega'] === 'N.D.' ||
			props.dataRecolecciones['fecha-llegada-bodega'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataRecolecciones['fecha-llegada-bodega']}
					title={t('eventos.llegadaBodega')}
				/>
			)}
		</Timeline>
	);
}
