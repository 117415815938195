import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TimelineEventosAereopuertoExpo from './TimelineEventosAereopuertoExpo';
import TimelineEventosDespachoAduanalExpo from './TimelineEventosDespachoAduanalExpo';
import TimelineEventosTerrestreExpo from './TimelineEventosTerrestreExpo';
import FlightIcon from '@mui/icons-material/Flight';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useEffect, useState } from 'react';

/*Descripción:Este componente se encarga de mostrar las opciones que hay de el Timeline de Aereo Expo
Componente padre: EventosBookingAereoExpo
Componente hijo: TimelineEventosTerrestreExpo //
	TimelineEventosDespachoAduanalExpo //
	TimelineEventosAereopuertoExpo
props: props.data = informacion a desplegar //
*/

export default function TimelineSelectorAereoExpo(props) {
	// Se crean las constantes para el uso del TimelineSelector
	const [value, setValue] = useState('1');
	// Funcion para la selección de las tabs
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// Se utiliza el useEffect para que al momento de que se renderice el componente se seleccione la tab correspondiente
	useEffect(() => {
		if (props.data.bookingTN !== 'Sin Terrestre Nacional') {
			setValue('1');
		} else {
			setValue('2');
		}
	}, []);

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			{props.role.includes('Cliente') && (
				<>
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList
								onChange={handleChange}
								aria-label='lab API tabs example'
								centered
							>
								{props.data.bookingTN !==
									'Sin Terrestre Nacional' && (
									<Tab
										icon={<AirportShuttleIcon />}
										id='tabIconUno'
										value='1'
										sx={{ color: 'grey' }}
									/>
								)}
								<Tab
									icon={<WarehouseIcon />}
									id='tabIconDos'
									value='2'
									sx={{ color: 'grey' }}
								/>
								<Tab
									icon={<FlightIcon />}
									id='tabIconTres'
									value='3'
									sx={{ color: 'grey' }}
								/>
							</TabList>
						</Box>
						<TabPanel
							value='1'
							sx={{ maxHeight: '45vh', overflowY: 'auto' }}
						>
							<TimelineEventosTerrestreExpo
								data={props.data}
								dataRecolecciones={props.data.recolecciones}
								role={props.role}
							/>
						</TabPanel>
						<TabPanel
							value='2'
							sx={{ maxHeight: '45vh', overflowY: 'auto' }}
						>
							<TimelineEventosDespachoAduanalExpo
								data={props.data}
								dataPedimentos={props.data.pedimento}
								dataDespacho={props.data.despacho}
								role={props.role}
							/>
						</TabPanel>
						<TabPanel
							value='3'
							sx={{ maxHeight: '45vh', overflowY: 'auto' }}
						>
							<TimelineEventosAereopuertoExpo
								data={props.data}
								role={props.role}
							/>
						</TabPanel>
					</TabContext>
				</>
			)}
			{props.role.includes('Agente') && (
				<>
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList
								onChange={handleChange}
								aria-label='lab API tabs example'
								centered
							>
								<Tab
									icon={<WarehouseIcon />}
									id='tabIconDos'
									value='1'
									sx={{ color: 'grey' }}
								/>
								<Tab
									icon={<FlightIcon />}
									id='tabIconTres'
									value='2'
									sx={{ color: 'grey' }}
								/>
							</TabList>
						</Box>
						<TabPanel
							value='1'
							sx={{ maxHeight: '45vh', overflowY: 'auto' }}
						>
							<TimelineEventosDespachoAduanalExpo
								data={props.data}
								dataPedimentos={props.data.pedimento}
								dataDespacho={props.data.despacho}
								role={props.role}
							/>
						</TabPanel>
						<TabPanel
							value='2'
							sx={{ maxHeight: '45vh', overflowY: 'auto' }}
						>
							<TimelineEventosAereopuertoExpo
								data={props.data}
								role={props.role}
							/>
						</TabPanel>
					</TabContext>
				</>
			)}
		</Box>
	);
}
