import React from 'react';
import { Stack, Card, CardContent } from '@mui/material';
import InfoFechasAereoImpo from './Info/InfoFechasAereoImpo';
import TimelineSelectorAereoExpo from './Timeline/TimelineSelectorAereoImpo';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos del booking Aereo Impo
Componente padre: BookingAereoImpoSR
Componente hijo: InfoFechasAereoImpo //
	InfoDespachoMaritimoExpo //
	TimelineSelectorAereoImpo
props: props.data = informacion a desplegar
*/

export default function EventosBookingAereoImpo(props) {
	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'row',
				lg: 'row',
				xl: 'row',
			}}
			spacing={1}
		>
			<Card
				className={props.className}
				elevation={props.elevation}
				sx={{ width: '100%' }}
			>
				<CardContent>
					<InfoFechasAereoImpo
						dataInformacionCarga={props.data.informacionCarga}
						dataDespacho={props.data['despacho-aduanal']}
						data={props.data}
					/>
				</CardContent>
			</Card>
			<Card
				className={props.className}
				elevation={props.elevation}
				sx={{ width: '100%' }}
			>
				<CardContent>
					<TimelineSelectorAereoExpo data={props.data} />
				</CardContent>
			</Card>
		</Stack>
	);
}
