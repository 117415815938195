import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import { useTranslation } from 'react-i18next';
import Timeline from '@mui/lab/Timeline';

/*Descripción: Este componente se encarga de desplegar el timeline de eventos de Aereo Despacho Impo
Componente padre: TimelineSelectorDespachoImpo //
	TimelineSelectorDespachoExpo
Componente hijo: TimelineItem
props: props.data = informacion a desplegar //
	props.dataPedimentos = informacion a desplegar sobre los pedimentos
*/

export default function TimelineEventosAereoDespachoImpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			{props.data['fecha-alta'] === 'N.D.' ||
			props.data['fecha-alta'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-alta']}
					title={t('eventos.alta')}
				/>
			)}
			{props.data['fecha-arribo'] === 'N.D.' ||
			props.data['fecha-arribo'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-arribo']}
					title={t('eventos.arribo')}
				/>
			)}
			{props.dataPedimentos['fecha-validacion'] === 'N.D.' ||
			props.dataPedimentos['fecha-validacion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataPedimentos['fecha-validacion']}
					title={t('eventos.validacion')}
				/>
			)}
			{props.dataPedimentos['fecha-modulacion'] === 'N.D.' ||
			props.dataPedimentos['fecha-modulacion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataPedimentos['fecha-modulacion']}
					title={t('eventos.modulacion')}
				/>
			)}
			{props.dataPedimentos['fecha-reconocimiento'] === '' ||
			props.dataPedimentos['fecha-reconocimiento'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataPedimentos['fecha-reconocimiento']}
					title={t('eventos.reconocimiento')}
				/>
			)}
		</Timeline>
	);
}
