import React from 'react';
import { Stack, Card, CardContent } from '@mui/material';
import InfoFechasAereoExpo from './Info/InfoFechasAereoExpo';
import TimelineSelectorAereoExpo from './Timeline/TimelineSelectorAereoExpo';
import { useState } from 'react';
import GetRoleFetch from '../../../get-data/GetRoleFetch';
import { useEffect } from 'react';

/*Descripción:Este componente se encarga de desplegar en pantalla los datos del estatus de Aereo Expo
Componente padre: BookingAereoExpoSR
Componente hijo: InfoFechasAereoExpo //
	TimelineSelectorAereoExpo
props: props.data = informacion a desplegar //
*/

export default function EventosBookingAereoExpo(props) {
	const [role, setRole] = useState('');
	const getInfoRole = async () => {
		await GetRoleFetch(setRole);
	};

	// useEffect para obtener el rol del usuario
	useEffect(() => {
		getInfoRole();
	}, []);

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'row',
				lg: 'row',
				xl: 'row',
			}}
			spacing={1}
		>
			<Card
				className={props.className}
				elevation={props.elevation}
				sx={{ width: '100%' }}
			>
				<CardContent>
					<InfoFechasAereoExpo
						dataInformacionCarga={props.data.informacionCarga}
						dataDespacho={props.data.despacho}
						data={props.data}
						role={role}
					/>
				</CardContent>
			</Card>
			<Card
				className={props.className}
				elevation={props.elevation}
				sx={{ width: '100%' }}
			>
				<CardContent>
					<TimelineSelectorAereoExpo data={props.data} role={role} />
				</CardContent>
			</Card>
		</Stack>
	);
}
