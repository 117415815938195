import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

/*Descripción:Este componente se encarga de desplegar las opciones de el Selectsearch de Pedimentos
Componente padre: EventosBookingDespachoImpo //
	EventosBookingDespachoExpo
Componente hijo:
props: props.dataOption = informacion sobre las opciones del selectSearch
*/

export default function SelectedSearchPedimento(props) {
	// Se crean los const para el funcionamiento del SelectSearch
	const [options, setOptions] = useState([
		{ value: 0, label: props.dataOption[0]['numero-pedimento'] },
	]);

	// Funcion para actualizar el pedimento seleccionado
	const handleChange = (event) => {
		props.actualizarPedimento(event.value);
	};

	// usseEffect para agregar los pedimentos a las opciones del SelectSearch
	useEffect(() => {
		const arr = [];
		props.dataOption.map((pedimento, index) => {
			return arr.push({
				value: index,
				label: pedimento['numero-pedimento'],
			});
		});
		setOptions(arr);
	}, [props.dataOption]);

	return (
		<Box>
			<Typography variant='subtitle2' paddingBottom={'2%'}>
				{props.customLabel}
			</Typography>
			<Select
				onChange={handleChange}
				className='basic-single'
				classNamePrefix='select'
				placeholder='Seleccionar'
				noOptionsMessage={({ inputValue }) =>
					!inputValue ? 'value' : 'Valor no encontrado'
				}
				isSearchable={true}
				id={props.id}
				name={props.id}
				options={options}
				defaultValue={options[0]}
			/>
		</Box>
	);
}
