import { Box } from '@mui/material';
import React, { useState } from 'react';
import ErrorBoundary from '../../../components/cards/ErrorBoundary';
import CommonBooking from '../../../components/CommonBooking';
import { useTranslation } from 'react-i18next';
import BoxTitleWithTabs from '../../../components/titles/BoxTitleWithTabs';

/*Descripción:Este componente se encarga de renderizar el contenido de las opciones de la pantalla Despacho Terrestre
y mandar el endpoint a usar
Componente padre: CommonLayout.jsx
Componente hijo: DespachoTerrestreImpo //
	DespachoTerrestreExpo
props: Sin uso de props
*/

export default function IndexDT() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [value, setValue] = useState(0);
	// Funcion para cambiar de tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const tabs = [
		{ id: 'TabImportacion', label: t('sidebar.importacion') },
		{ id: 'TabExportacion', label: t('sidebar.exportacion') },
	];

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box>
				<BoxTitleWithTabs
					title={t('paginas.despachoTerrestre')}
					tabs={tabs}
					value={value}
					handleChange={handleChange}
				/>

				{/* Tab 1: Despacho Terrestre */}
				<Box hidden={value !== 0}>
					{value === 0 && (
						<CommonBooking
							resultComponent='BookingDespachoImpoSearchResult'
							endpoint='bookings/aduanas/terrestre/importacion'
						/>
					)}
				</Box>

				{/* Tab 2: Despacho Terrestre */}
				<Box hidden={value !== 1}>
					{value === 1 && (
						<CommonBooking
							resultComponent='BookingDespachoExpoSearchResult'
							endpoint='bookings/aduanas/terrestre/exportacion'
						/>
					)}
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
