import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

/*Descripción:Este componente se encarga de renderizar un SelectSearch para los bookings
Componente padre: CommonBooking
Componente hijo:
props: props.bookingList = Lista de los bookings a desplegar //
	props.selectedIndex = Index seleccionado //
	props.customLabel = titulo del selectSearch //
*/

export default function SelectSearchBookings(props) {
	// Se declaran los const para el uso en SelectSearchBooking
	const [options, setOptions] = useState([]);
	// Se obtiene la list a mostrar del SelectList
	const getSelectList = async () => {
		const arr = [];
		let result = props.bookingsList;
		// Se crea un mapa de los valores para mostrar en el SelectList
		result.map((item, key) => {
			return arr.push({ value: item, label: item });
		});
		// Se asignan los valores a las opciones a mostrar
		setOptions(arr);
	};
	// useEffect para la actualización de las opciones del selectSearch
	useEffect(() => {
		getSelectList();
	}, [props.selectedIndex]);

	return (
		<Box padding={1}>
			<Typography variant='subtitle2'>{props.customLabel}</Typography>
			<Select
				className='basic-single'
				classNamePrefix='select'
				placeholder='Seleccionar'
				noOptionsMessage={({ inputValue }) =>
					!inputValue ? 'noOptionsText' : 'Valor no encontrado'
				}
				isClearable={true}
				isSearchable={true}
				id={props.id}
				name={props.id}
				options={options}
				onChange={(choice) =>
					props.actualizarBooking(
						choice.value,
						options.findIndex((obj) => obj.value === choice.value)
					)
				}
				value={options[props.selectedIndex] || options[0]}
			/>
		</Box>
	);
}
