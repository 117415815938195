import React, { useEffect, useState } from 'react';
import {
	Grid,
	LinearProgress,
	Typography,
	CssBaseline,
	Container,
	Hidden,
	Card,
	CardContent,
} from '@mui/material';
import Cookies from 'universal-cookie';
import MySnackBar from '../../../../components/bill-of-lading/information-stepper/SnackBarBL';
import { useNavigate } from 'react-router-dom';
import InformacionCard from '../../../../components/bill-of-lading/InformacionCard';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import GetDataFetch from '../../../../components/get-data/GetDataFetch.jsx';
import InformationStepperWithCatalog from '../../../../components/bill-of-lading/information-stepper/InformationStepperWithCatalog.jsx';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Aereo Impo
Componente padre: CommonBooking
Componente hijo: MySnackBar //
	MyInfoStepper //
	InformacionCard
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición//
	props.getBookingsList = Funcion para obtener la lista de los bookings
*/

export default function BlResultWithCatalog(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [snackbar, setSnackbar] = useState({ open: false, message: ' ' });
	const [emptiesInputs, setEmptiesInputs] = useState([]);
	const [arrInvalid] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const navigate = useNavigate();
	const [data, setData] = useState({});
	const [dataBulto, setDataBulto] = useState([]);
	const [dataLada, setDataLada] = useState([]);
	const [dataContactos, setDataContactos] = useState([]);
	const [dataDirecciones, setDataDirecciones] = useState([]);
	const [dataDetallesPaises, setDataDetallesPaises] = useState('');
	const [loading, setLoading] = useState(true);
	const [loadingLada, setLoadingLada] = useState(true);
	const [loadingBulto, setLoadingBulto] = useState(true);
	const [loadingConsignatarios, setLoadingConsignatarios] = useState(true);
	const [loadingContactos, setLoadingContactos] = useState(true);
	const [loadingPaises, setLoadingPaises] = useState(true);
	const [loadingDirecciones, setLoadingDirecciones] = useState(true);
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};
	//Paso actual de la carta porte
	const [activeStep, setActiveStep] = useState(0);

	// Funciones para abrir y cerrar el Snackbar
	const handleOpenSnackbar = () => {
		setSnackbar({
			open: true,
			message: alertMessage,
		});
	};

	const handleCloseSnackbar = () => {
		setSnackbar({ open: false, message: '' });
	};
	///// Consignatario
	const [infoContactoConsignatario, setInfoContactoConsignatario] = useState({
		cod_persona: '',
		cod_contacto: '',
		contacto: '',
		telefono: '',
		email: '',
	});

	// Funcion para actualizar los datos del contacto
	function actualizarContactoConsignatario(name, value) {
		setInfoContactoConsignatario({
			...infoContactoConsignatario,
			[name]: value,
		});
	}

	const [infoDireccionConsignatario, setInfoDireccionConsignatario] =
		useState({
			cod_persona: '',
			cod_direccion: '',
			calle: '',
			cod_ciudad: '',
		});

	// Funcion para actualizar los datos de la direccion
	function actualizarDireccionConsignatario(name, value) {
		setInfoDireccionConsignatario({
			...infoDireccionConsignatario,
			[name]: value,
		});
	}

	///// Notify
	const [infoContactoNotify, setInfoContactoNotify] = useState({
		cod_persona: '',
		cod_contacto: '',
		contacto: '',
		telefono: '',
		email: '',
	});

	// Funcion para actualizar los datos del contacto
	function actualizarContactoNotify(name, value) {
		setInfoContactoNotify({
			...infoContactoNotify,
			[name]: value,
		});
	}

	const [infoDireccionNotify, setInfoDireccionNotify] = useState({
		cod_persona: '',
		cod_direccion: '',
		calle: '',
		cod_ciudad: '',
	});

	// Funcion para actualizar los datos de la direccion
	function actualizarDireccionNotify(name, value) {
		setInfoDireccionNotify({
			...infoDireccionNotify,
			[name]: value,
		});
	}

	// Quitar la clase CSS de campos invalidos cuando cambia de booking
	const toInvalidCss = () => {
		setEmptiesInputs([]);
	};

	// useEffect para la validacion de las clases CSS
	useEffect(() => {
		toInvalidCss();
		setSameAsConsignee(false);
	}, [props.selectedBooking]);
	// Funcion para validar si es la misma informacion Notify y Consignatario
	const [sameAsConsignee, setSameAsConsignee] = useState(false);
	const handleCheckNotify = (event) => {
		setSameAsConsignee(event.target.checked);
		updateNotifyData(event.target.checked);
	};
	// Funcion para guarda nuevo valor
	function actualizarDato(name, value) {
		setData({
			...data,
			[name]: value,
		});
	}
	// Guarda valor de Notify cuando es igual al Consignador
	async function updateNotifyData(value) {
		// Valida que el valor recopilado exista
		if (value === true) {
			saveNotifyInfo();
		}
	}
	// Funcion para guardar datos de Notify
	function saveNotifyInfo() {
		setInfoNotify(infoConsignatario);
		setInfoContactoNotify(infoContactoConsignatario);
		setInfoDireccionNotify(infoDireccionConsignatario);

		return new Promise((resolve) => {
			setTimeout(() => {
				resolve('resolved');
			}, 100);
		});
	}

	// Funcion para guardar el BL
	async function enviarDatosFormulario() {
		var tempData = {};
		for (var i in data) tempData[i] = data[i];
		delete tempData['confirmacion'];
		delete tempData['buque'];
		delete tempData['pol'];
		delete tempData['pod'];
		delete tempData['destino_final'];
		delete tempData['completed_embarcador'];
		delete tempData['completed_consignatario'];
		delete tempData['completed_notify'];
		delete tempData['completed_carga'];
		delete tempData['cargas'];
		delete tempData['imo'];
		delete tempData['folio'];
		delete tempData['viaje'];
		delete tempData['empresa'];
		delete tempData['fecha_confirma'];
		tempData['cod_consignatario'] = infoConsignatario
			? infoConsignatario.cod_consignatario
			: '';
		tempData['cod_contacto_consignatario'] =
			infoContactoConsignatario.cod_contacto;
		tempData['cod_direccion_consignatario'] =
			infoDireccionConsignatario.cod_direccion;
		tempData['cod_notify'] = infoNotify ? infoNotify.cod_consignatario : '';
		tempData['cod_contacto_notify'] = infoContactoNotify.cod_contacto;
		tempData['cod_direccion_notify'] = infoDireccionNotify.cod_direccion;

		const requestOptionsGuardar = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify(tempData),
			redirect: 'follow',
		};

		// Se hace el Data Fetch
		fetch(
			// Se hace la peticion al endpoint
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'bill-of-lading/catalogos/set-pre-bl',
			requestOptionsGuardar
		)
			.then((response) => {
				// Se valida el status de la respuesta
				if (response.status === 200) {
					// Se muestra el mesnaje de exito
					setAlertMessage({
						severity: 'success',
						message: t('alertas.blGuardado'),
					});
					// Se actualiza la lista de bookings
					setTimeout(() => {
						dataFetchBooking();
					}, 1000);
				} else if (response.status === 401) {
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					// Se muestra el mensaje de error
					setAlertMessage({
						severity: 'error',
						message: t('alertas.blGuardadorError'),
					});
				}
			})
			.catch((error) => console.log('error', error));
	}

	// Funcion para validar el formulario completo
	function actualizaCamposInvalidos(field) {
		// Comprueba los campos inválidos en el front
		arrInvalid.push(field);
	}

	// Funcion para confirmar el BL
	const confirmarDatosFormulario = () => {
		// Formulario completo y se envía al endpoint
		var raw = JSON.stringify({
			booking: props.selectedBooking,
			confirmacion: 'true',
		});
		var requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		// Se hace el Data Fetch
		fetch(
			// Se hace la peticion al endpoint
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'bill-of-lading/catalogos/confirmacion-pre-bl',
			requestOptions
		)
			.then((response) => {
				// Se valida el status de la respuesta
				if (response.status === 200) {
					// Se muestra el mesnaje de exito
					setAlertMessage({
						severity: 'success',
						message: t('alertas.blEnvio'),
					});
					// Se actualiza la lista de bookings
					setTimeout(() => {
						props.getBookingsList();
					}, 2000);
				} else if (response.status === 401) {
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					// Se muestra el mensaje de error
					setAlertMessage({
						severity: 'error',
						message: t('alertas.blEnvioError'),
					});
				}
			})
			.catch((error) => console.log('error', error));
	};

	// Data Fetch Buque
	const dataFetchBulto = async () => {
		setLoadingBulto(true);
		const data = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'catalogos/tipo-bultos',
			requestOptions
		);
		// se valida el estatus de la respuesta
		if (data.status === 200) {
			// se obtiene la informacion
			const bultos = await data.json();
			const arr = [];
			let result = bultos.data.catalogo;
			result.map((item, key) => {
				return arr.push({ value: item, label: item });
			});
			setDataBulto(arr);
			setLoadingBulto(false);
		} else if (data.status === 401) {
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		} else if (data.status === 504) {
			setLoadingBulto(false);
		}
	};

	// fetch paises lada
	const dataFetchLada = async () => {
		setLoadingLada(true);
		const data = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'catalogos/paises-lada',
			requestOptions
		);
		// se valida el estatus de la respuesta
		if (data.status === 200) {
			const lada = await data.json();
			const arr = [];
			let result = lada.data.catalogo;

			result.map((item, key) => {
				return arr.push({
					value: item.id,
					label: item.codigoAlfa + ' +' + item.lada,
				});
			});
			setDataLada(arr);
			setLoadingLada(false);
		} else if (data.status === 401) {
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		} else if (data.status === 504) {
			setLoadingLada(false);
		}
	};

	const dataFetchContactos = async () => {
		setLoadingContactos(true);
		var url = 'consignatario/contactos/cliente/all';
		await GetDataFetch(
			url,
			setLoadingContactos,
			setDataContactos,
			navigate
		);
	};

	const dataFetchDirecciones = async () => {
		setLoadingDirecciones(true);
		var url = 'consignatario/direcciones/cliente/all';
		await GetDataFetch(
			url,
			setLoadingDirecciones,
			setDataDirecciones,
			navigate
		);
	};

	// Funcion para obtener los datos de la API
	const dataFetchPaises = async () => {
		setLoadingPaises(true);
		var url = 'catalogos/paises';
		await GetDataFetch(
			url,
			setLoadingPaises,
			setDataDetallesPaises,
			navigate
		);
	};

	//fetch valores de booking
	const dataFetchBooking = async () => {
		setLoading(true);
		// Se hace la peticion al endpoint
		fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'bill-of-lading/catalogos/pre-bl/' +
				props.selectedBooking,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => setData(data.data))
			.catch((error) => console.log('error', error));
		if (data.status === 401) {
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		}
		setTimeout(async () => {
			setLoading(false);
		}, 1000);
	};

	// useEffect para obtener los datos de la API para el consignatario
	useEffect(() => {
		if (data !== undefined) {
			actualizarConsignatario(
				'cod_consignatario',
				data.cod_consignatario
			);
		}
	}, [data.cod_consignatario]);
	useEffect(() => {
		if (data !== undefined) {
			actualizarContactoConsignatario(
				'cod_contacto',
				data.cod_contacto_consignatario
			);
		}
	}, [data.cod_contacto_consignatario]);
	useEffect(() => {
		if (data !== undefined) {
			actualizarDireccionConsignatario(
				'cod_direccion',
				data.cod_direccion_consignatario
			);
		}
	}, [data.cod_direccion_consignatario]);

	// useEffect para obtener los datos de la API para el notify
	useEffect(() => {
		if (data !== undefined) {
			actualizarNotify('cod_consignatario', data.cod_notify);
		}
	}, [data.cod_notify]);
	useEffect(() => {
		if (data !== undefined) {
			actualizarContactoNotify('cod_contacto', data.cod_contacto_notify);
		}
	}, [data.cod_contacto_notify]);
	useEffect(() => {
		if (data !== undefined) {
			actualizarDireccionNotify(
				'cod_direccion',
				data.cod_direccion_notify
			);
		}
	}, [data.cod_direccion_notify]);

	useEffect(() => {
		dataFetchBulto();
		dataFetchLada();
		dataFetchConsignatarios();
		dataFetchContactos();
		dataFetchPaises();
		dataFetchDirecciones();
	}, []);

	useEffect(() => {
		setData({});

		setInfoConsignatario({
			cod_consignatario: '',
			nombre_razon: '',
			cod_pais: '',
			tax_id: '',
			pais: '',
		});
		setInfoNotify({
			cod_consignatario: '',
			nombre_razon: '',
			cod_pais: '',
			tax_id: '',
			pais: '',
		});

		dataFetchBooking();

		setActiveStep(0);
	}, [props.selectedBooking]);

	// Funcion para abrir el modal y validar que el formulario este completo
	const handleOpenModal = () => {
		var valueEmpty = false;
		// Se hace la validacion de los campos del formulario
		Object.entries(data).forEach(([key, value]) => {
			if (value === '' && key !== 'fecha_confirma') {
				valueEmpty = true;
			}
		});
		// Se hace la condicion de la validacion
		if (data.cargas.length > 0 && !valueEmpty) {
			// Se envia la informacion del formulario
			enviarDatosFormulario();
			setOpenModal(true);
		} else {
			// Se muestra el mensaje de error
			setAlertMessage({
				severity: 'error',
				message: t('alertas.blSinDatos'),
			});
		}
	};

	// Funciones para los componentes con catalogos
	// Funcion para obtener los datos de los consignatarios
	const [dataDetallesConsignatarios, setDataDetallesConsignatarios] =
		useState('');
	const [indexConsignatario, setIndexConsignatario] = useState(-1);

	const dataFetchConsignatarios = async () => {
		setLoadingConsignatarios(true);
		var url = 'consignatarios/all';
		await GetDataFetch(
			url,
			setLoadingConsignatarios,
			setDataDetallesConsignatarios,
			navigate
		);
	};

	const [infoConsignatario, setInfoConsignatario] = useState({
		cod_consignatario: '',
		nombre_razon: '',
		cod_pais: '',
		tax_id: '',
		pais: '',
	});

	// Funcion para actualizar los datos del consignatario
	function actualizarConsignatario(name, value) {
		setInfoConsignatario({
			...infoConsignatario,
			[name]: value,
		});
	}

	const [infoNotify, setInfoNotify] = useState({
		cod_consignatario: '',
		nombre_razon: '',
		cod_pais: '',
		tax_id: '',
		pais: '',
	});

	// Funcion para actualizar los datos del consignatario
	function actualizarNotify(name, value) {
		setInfoNotify({
			...infoNotify,
			[name]: value,
		});
	}

	return (
		<>
			<CssBaseline />
			<Container
				rowSpacing={1}
				sx={{
					height: '72vh',
					paddingLeft: '0px',
					paddingRight: '0px',
				}}
				maxWidth={false}
				disableGutters
			>
				<Card
					sx={{
						width: '100%',
						maxHeight: '74vh',
						overflow: 'hidden',
					}}
				>
					<CardContent
						sx={{
							padding: '10px',
						}}
					>
						<ValidatorForm instantValidate={false}>
							<Grid
								item
								container
								paddingBottom={2}
								justifyContent={'space-between'}
							>
								<Grid item>
									<Typography variant='h6'>
										{props.selectedBooking}
									</Typography>
								</Grid>
								<Grid item>
									<MySnackBar
										selectedBooking={props.selectedBooking}
										handleOpenModal={handleOpenModal}
										data={data}
										enviarDatosFormulario={
											enviarDatosFormulario
										}
										confirmarDatosFormulario={
											confirmarDatosFormulario
										}
										alertMessage={alertMessage}
										openModal={openModal}
										setOpenModal={setOpenModal}
									/>
								</Grid>
							</Grid>

							<Grid
								item
								container
								rowSpacing={2}
								columnSpacing={2}
							>
								<Grid item lg={9} sx={12} sm={12} md={12}>
									{loading ||
									loadingLada ||
									loadingBulto ||
									loadingConsignatarios ? (
										<LinearProgress />
									) : (
										<InformationStepperWithCatalog
											activeStep={activeStep}
											setActiveStep={setActiveStep}
											handleOpenSnackbar={
												handleOpenSnackbar
											}
											setAlertMessage={setAlertMessage}
											enviarDatosFormulario={
												enviarDatosFormulario
											}
											dataFetchBooking={dataFetchBooking}
											dataFetchConsignatarios={
												dataFetchConsignatarios
											}
											dataFetchContactos={
												dataFetchContactos
											}
											dataFetchDirecciones={
												dataFetchDirecciones
											}
											sameAsConsignee={sameAsConsignee}
											handleCheckNotify={
												handleCheckNotify
											}
											selectedBooking={
												props.selectedBooking
											}
											actualizarDato={actualizarDato}
											data={data}
											dataBulto={dataBulto}
											dataLada={dataLada}
											dataDetallesPaises={
												dataDetallesPaises
											}
											loadingContactos={loadingContactos}
											loadingDirecciones={
												loadingDirecciones
											}
											dataContactos={dataContactos}
											dataDirecciones={dataDirecciones}
											emptiesInputs={emptiesInputs}
											actualizaCamposInvalidos={
												actualizaCamposInvalidos
											}
											// Variables de catalogos
											dataDetallesConsignatarios={
												dataDetallesConsignatarios
											}
											indexConsignatario={
												indexConsignatario
											}
											setIndexConsignatario={
												setIndexConsignatario
											}
											infoConsignatario={
												infoConsignatario
											}
											setInfoConsignatario={
												setInfoConsignatario
											}
											actualizarConsignatario={
												actualizarConsignatario
											}
											infoNotify={infoNotify}
											setInfoNotify={setInfoNotify}
											actualizarNotify={actualizarNotify}
											infoContactoConsignatario={
												infoContactoConsignatario
											}
											setInfoContactoConsignatario={
												setInfoContactoConsignatario
											}
											infoDireccionConsignatario={
												infoDireccionConsignatario
											}
											setInfoDireccionConsignatario={
												setInfoDireccionConsignatario
											}
											actualizarContactoConsignatario={
												actualizarContactoConsignatario
											}
											actualizarDireccionConsignatario={
												actualizarDireccionConsignatario
											}
											infoContactoNotify={
												infoContactoNotify
											}
											setInfoContactoNotify={
												setInfoContactoNotify
											}
											infoDireccionNotify={
												infoDireccionNotify
											}
											setInfoDireccionNotify={
												setInfoDireccionNotify
											}
											actualizarContactoNotify={
												actualizarContactoNotify
											}
											actualizarDireccionNotify={
												actualizarDireccionNotify
											}
										/>
									)}
								</Grid>
								<Grid item container lg={3} sx={12}>
									<Hidden lgDown>
										<InformacionCard
											selectedBooking={
												props.selectedBooking
											}
										/>
									</Hidden>
								</Grid>
								<Snackbar
									open={snackbar.open}
									autoHideDuration={10000}
									onClose={handleCloseSnackbar}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
								>
									<Alert
										onClose={handleCloseSnackbar}
										severity={alertMessage.severity}
									>
										{alertMessage.message}
									</Alert>
								</Snackbar>
							</Grid>
						</ValidatorForm>
					</CardContent>
				</Card>
			</Container>
		</>
	);
}
