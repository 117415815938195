import { Delete } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EliminarDireccion from '../Modales/EliminarDireccion';

/*Descripción:Este componente se encarga de renderizar el icono de eliminar del data grid de las direcciones.
Componente padre: ListDirecciones.jsx
Componente hijo: EliminarDireccionesModal.jsx
props: dataFetch: Función para obtener los datos de la API // id: id del consignatario //
idDireccion: id de la dirección
*/

export default function DeleteDireccionIcon(props) {
	const { t } = useTranslation();
	// Funciones para el modal
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// Función llamada al dar click al icono de eliminar
	const handleDeleteClick = async () => {
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.eliminar')}>
						<IconButton
							id='deleteIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleDeleteClick}
						>
							<Delete color='secondary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<EliminarDireccion
				dataFetch={props.dataFetch}
				id={props.id}
				idDireccion={props.idDireccion}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
				setAlertMessagePage={props.setAlertMessagePage}
				handleClickPage={props.handleClickPage}
			/>
		</>
	);
}
