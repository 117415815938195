import React from 'react';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AssignmentLate } from '@mui/icons-material';

/*Descripción:Este componente se encarga de renderizar la pantalla de booking invalido
Componente padre: Global
Componente hijo:
props: Sin uso de props
*/

export default function InvalidPDF() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid container spacing={0} marginTop={10}>
			<Card elevation={0}>
				<Grid container spacing={0}>
					<Grid
						item
						container
						justifyContent={'center'}
						paddingBottom={3}
					>
						<AssignmentLate fontSize='large' color='primary' />
					</Grid>
					<Grid item container spacing={1} justifyContent={'center'}>
						<Grid container spacing={0} justifyContent={'center'}>
							<Typography
								variant='h6'
								color='initial'
								paddingBottom={2}
							>
								{t('cards.errorPDF')}
							</Typography>
						</Grid>
						<Grid container spacing={0} justifyContent={'center'}>
							<Typography variant='body1' color='initial'>
								{t('cards.errorPDFDescripcion')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	);
}
