import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos de Terminal
Componente padre: TimelineSelector
Componente hijo: TimelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosTerminal(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			{props.data['fecha-ingreso-recinto'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-ingreso-recinto']}
					title={t('eventos.ingresoRecinto')}
				/>
			)}
			{props.dataBooking['fecha-consolidacion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataBooking['fecha-consolidacion']}
					title={t('eventos.consolidacion')}
				/>
			)}
		</Timeline>
	);
}
