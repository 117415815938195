import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AcordionAlmacen from './Acordiones/AcordionAlmacen';
import AcordionOtrosServicios from './Acordiones/AcordionOtrosServicios';
import AcordionCuentaAmericana from './Acordiones/AcordionCuentaAmericana';
import AcordionSeguros from './Acordiones/AcordionSeguros';
import AcordionTransfer from './Acordiones/AcordionTransfer';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar los acordiones de los servicios adicionales
Componente padre: InfoServiciosAdicionales
Componente hijo: AcordionAlmacen // AcordionCuentaAmericana //
	AcordionSeguros // AcordionTransfer //
	AcordionOtrosServicios
props: props.data = informacion a desplegar //
	props.almacen = informacion del almacen //
	props.cuentaAmericana = informacion de la cuenta americana //
	props.seguros = informacion de los seguros //
	props.transfer = informacion del transfer //
	props.otrosProductos = informacion de otros productos
*/

// Funcion para dale estilo al acordion
const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

// Funcion para el tema y las animaciones del acordion
const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

// Funcion para el estilo de los detalles del acordion
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function ServiciosAdicionalesTable(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para el uso de ServiciosAdicionalesTable
	const [expanded, setExpanded] = useState('panel1');
	// Funcion para el cambio de acordion
	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<Box sx={{ height: '330px', overflowY: 'auto' }}>
			<Accordion
				expanded={expanded === 'panel1'}
				onChange={handleChange('panel1')}
			>
				<AccordionSummary
					aria-controls='panel1d-content'
					id='panel1d-header'
				>
					<Typography>{t('checkgroup.almacen')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<AcordionAlmacen
						data={props.data}
						almacen={props.almacen}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel2'}
				onChange={handleChange('panel2')}
			>
				<AccordionSummary
					aria-controls='panel2d-content'
					id='panel2d-header'
				>
					<Typography>{t('checkgroup.cuentaAmericana')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<AcordionCuentaAmericana
						data={props.data}
						cuentaAmericana={props.cuentaAmericana}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel3'}
				onChange={handleChange('panel3')}
			>
				<AccordionSummary
					aria-controls='panel3d-content'
					id='panel3d-header'
				>
					<Typography>
						{t('booking.detalles.seguroMercancia')}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<AcordionSeguros
						data={props.data}
						seguros={props.seguros}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel4'}
				onChange={handleChange('panel4')}
			>
				<AccordionSummary
					aria-controls='panel4d-content'
					id='panel4d-header'
				>
					<Typography>{t('checkgroup.transfer')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<AcordionTransfer
						data={props.data}
						transfer={props.transfer}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel5'}
				onChange={handleChange('panel5')}
			>
				<AccordionSummary
					aria-controls='panel5d-content'
					id='panel5d-header'
				>
					<Typography>{t('checkgroup.otrosProductos')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<AcordionOtrosServicios
						data={props.data}
						otrosProductos={props.otrosProductos}
					/>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
}
