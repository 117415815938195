import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Save } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';

/*Descripción:Este componente se encarga de desplegar el Snackbar de la actualización de datos del perfil
Componente padre:UserProfile
Componente hijo:
props: props.alertMessage.message = mensaje
	props.alertMessage.severity = tipo de mensaje
*/

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function MySnackbutton(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const message = props.alertMessage.message;
	const [loading, setLoading] = useState(false);
	const [state] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;
	// Funcion para guardar los datos y abrir el Snackbar
	const handleClick1 = () => {
		setLoading(true);
		// Se manda a llamar la funcion para guardar los datos
		props.handleSaveClick();
		// Se abre el Snackbar
		setSnackbar1({
			open: true,
			message: message,
		});
		setLoading(false);
	};
	// Funcion para cerrar el Snackbar
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	return (
		<Grid>
			{/* Boton Guardar */}
			<LoadingButton
				id='snackButtonPerfil'
				variant='outlined'
				color='primary'
				loading={loading}
				onClick={handleClick1}
				sx={{
					fontSize: '12px',
					mr: 1,
				}}
				startIcon={<Save />}
			>
				{t('botones.guardar')}
			</LoadingButton>

			{/* Alert */}
			<Snackbar
				open={snackbar1.open}
				autoHideDuration={10000}
				onClose={handleClose1}
				anchorOrigin={{ vertical, horizontal }}
			>
				<Alert
					onClose={handleClose1}
					severity={props.alertMessage.severity}
				>
					{props.alertMessage.message}
				</Alert>
			</Snackbar>
		</Grid>
	);
}
