import React, { useState } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import DeleteIconDataGrid from './ActionIcons/DeleteIconDataGrid';
import EditIconDataGrid from './ActionIcons/EditIconDataGrid';
import ViewIconDataGrid from './ActionIcons/ViewIconDataGrid';
import { useEffect } from 'react';

/*Descripción:Este componente se encarga de renderizar el contenido del data grid de los consignatarios.
Componente padre: ConsignatariosList.jsx // ConsignatariosInfo.jsx
Componente hijo: ConsignatariosInfo.jsx // AgragarConsignatarioModal.jsx
props: dataDetallesPaises: Manda la información de los paises //
loadingPaises: Variable para el tiempo de carga //
dataGrid: Información de los consignatarios //
dataFetch: Función para obtener los datos de la API
*/

export default function DataGridFiles(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const language = useTranslation().i18n.language;
	const [headCells, setHeadCells] = useState([]);

	const renderColumns = () => {
		if (language === 'en') {
			// Se configuran los encabezados del DataGrid
			setHeadCells([
				{
					id: 'vNombreDocumento',
					field: 'vNombreDocumento',
					width: 400,
					headerName: t('fileUpload.nombreDocumento'),
					editable: false,
				},
				{
					id: 'vTipoDocumentoIngles',
					field: 'vTipoDocumentoIngles',
					width: 200,
					headerName: t('fileUpload.tipoDocumento'),
					editable: false,
				},
				{
					id: 'acciones',
					field: 'actions',
					headerName: t('dataGrid.acciones'),
					sortable: false,
					width: 140,
					disableClickEventBubbling: true,
					// Se renderizan los botones especiales
					renderCell: (params) => {
						return (
							<>
								<ViewIconDataGrid
									vUrlDocumento={params.row.vUrlDocumento}
									idDocumento={params.row.idToSend}
									selectedBooking={props.selectedBooking}
									vNombreDocumento={
										params.row.vNombreDocumento
									}
									vTipoDocumentoEspaniol={
										params.row.vTipoDocumentoIngles
									}
									setAlertMessage={props.setAlertMessage}
									handleClick1={props.handleClick1}
								/>
								<EditIconDataGrid
									idDocumento={params.row.idToSend}
									dataFetchDocumentosAgregados={
										props.dataFetchDocumentosAgregados
									}
									setAlertMessage={props.setAlertMessage}
									handleClick1={props.handleClick1}
								/>
								<DeleteIconDataGrid
									idDocumento={params.row.idToSend}
									dataFetchDocumentosAgregados={
										props.dataFetchDocumentosAgregados
									}
									setAlertMessage={props.setAlertMessage}
									handleClick1={props.handleClick1}
								/>
							</>
						);
					},
				},
			]);
		} else {
			// Se configuran los encabezados del DataGrid
			setHeadCells([
				{
					id: 'vNombreDocumento',
					field: 'vNombreDocumento',
					width: 400,
					headerName: t('fileUpload.nombreDocumento'),
					editable: false,
				},
				{
					id: 'vTipoDocumentoEspaniol',
					field: 'vTipoDocumentoEspaniol',
					width: 200,
					headerName: t('fileUpload.tipoDocumento'),
					editable: false,
				},
				{
					id: 'acciones',
					field: 'actions',
					headerName: t('dataGrid.acciones'),
					sortable: false,
					width: 140,
					disableClickEventBubbling: true,
					// Se renderizan los botones especiales
					renderCell: (params) => {
						return (
							<>
								<ViewIconDataGrid
									vUrlDocumento={params.row.vUrlDocumento}
									idDocumento={params.row.idToSend}
									selectedBooking={props.selectedBooking}
									vNombreDocumento={
										params.row.vNombreDocumento
									}
									vTipoDocumentoEspaniol={
										params.row.vTipoDocumentoEspaniol
									}
									vTipoDocumentoIngles={
										params.row.vTipoDocumentoIngles
									}
									setAlertMessage={props.setAlertMessage}
									handleClick1={props.handleClick1}
								/>
								<EditIconDataGrid
									idDocumento={params.row.idToSend}
									dataFetchDocumentosAgregados={
										props.dataFetchDocumentosAgregados
									}
									setAlertMessage={props.setAlertMessage}
									handleClick1={props.handleClick1}
								/>
								<DeleteIconDataGrid
									idDocumento={params.row.idToSend}
									dataFetchDocumentosAgregados={
										props.dataFetchDocumentosAgregados
									}
									setAlertMessage={props.setAlertMessage}
									handleClick1={props.handleClick1}
								/>
							</>
						);
					},
				},
			]);
		}
	};

	useEffect(() => {
		renderColumns();
	}, [language, props.dataDetallesDocumentosAgregados]);

	const tableData = [];
	// Se hace una validacion de todos elementos del DataGrid
	for (
		let i = 0;
		i < props.dataDetallesDocumentosAgregados.documentos.length;
		i++
	) {
		const documento = props.dataDetallesDocumentosAgregados.documentos[i];
		// Se valida que el usuario exista
		if (documento) {
			const rowData = {
				id: i + 1,
				idToSend: documento.uiCodDocumento,
				uiCodTipoDocumento: documento.uiCodTipoDocumento,
				vUrlDocumento: documento.vUrlDocumento,
				vNombreDocumento: documento.vNombreDocumento,
				vTipoDocumentoEspaniol: documento.vTipoDocumentoEspaniol,
				vTipoDocumentoIngles: documento.vTipoDocumentoIngles,
			};
			tableData.push(rowData);
		}
	}

	// Funciones para la busqueda del DataGrid
	const [searchTerm, setSearchTerm] = useState('');

	const filterRows = () => {
		return tableData.filter((row) =>
			headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(searchTerm.toLowerCase());
			})
		);
	};

	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<>
			<Grid
				item
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				sx={{ paddingTop: '2%' }}
			>
				<Grid item lg={6} md={8} sm={8} xs={8}>
					<TextField
						label={t('tablero.trackTrace.buscar')}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						autoComplete='off'
						variant='outlined'
						size='small'
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Search />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			<Box sx={{ height: '350px', paddingTop: '1%' }}>
				<DataGrid
					localeText={localeText()}
					rows={filterRows()}
					columns={headCells}
					onRowDoubleClick={(params, event) => {}}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10, 20, 30]}
					sx={{
						'& .MuiDataGrid-cell': {
							fontSize: '12px',
							fontFamily: 'Source Sans Pro',
						},
						'& .MuiDataGrid-columnHeaderTitle': {
							fontSize: '13px',
							fontFamily: 'Open Sans',
						},
					}}
				/>
			</Box>
		</>
	);
}
