import { CheckCircleOutline } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import ActivarUsuarioModal from '../Modales/ActivarUsuarioModal';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el boton de eliminar
Componente padre: DataGridUsuarios
Componente hijo: ActivarUsuarioLModal
props: props.handleCheck = Funcion para validar el checkbox
*/

export default function ActivateIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [openModal, setOpenModal] = useState(false);
	// Funcion para cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// Funcion para abrir el modal
	const handleEditClick = async () => {
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.activar')}>
						<IconButton
							id='deleteIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleEditClick}
						>
							<CheckCircleOutline color='success' />
						</IconButton>
					</Tooltip>
				}
			/>
			<ActivarUsuarioModal
				handleClick1={props.handleClick1}
				setAlertMessage={props.setAlertMessage}
				dataFetch={props.dataFetch}
				id={props.id}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
		</>
	);
}
