import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
	Grid,
	StepButton,
	Checkbox,
	FormControlLabel,
	Hidden,
	Tooltip,
	Stack,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Embarcador from './Steps/Embarcador';
import ListadoCargas from './Steps/ListadoCargas';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ConsignatarioWithCatalog from './StepsWithCatalog/ConsignatarioWithCatalog';
import NotifyWithCatalog from './StepsWithCatalog/NotifyWithCatalog';

/*Descripción:Este componente se encarga de desplegar los diferentes pasos de la pantalla de BL para el
correcto llenado del formulario
Componente padre: BlResult
Componente hijo:Embarcador //
	Consignatario //
	Carga //
	NotifyParty //
	OrigenDestino
props: props.data = Datos provinientes de la información de los diferentes formularios //
	props.actualizarDato = Datos actualizados conforme se llena el formulario //
	props.dataBulto = datos de el SelectSearch de los bultos //
	props.dataAllProducts = datos del SelectSearch de los productos del cliente
	props.dataLada = datos de el SelectSearch de la lada //
	props.actualizaDatosInvalidos = datos de validacion de campos invalidos //
	props.selectedBooking = booking seleccionado o ingreso
*/

export default function InformationStepperWithCatalog(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	//Steps del Stepper
	const steps = [
		t('booking.detalles.embarcador'),
		t('booking.detalles.consignatario'),
		t('booking.detalles.notify'),
		t('booking.detalles.cargas'),
	];
	const completedSteps = [
		props.data.completed_embarcador,
		props.data.completed_consignatario,
		props.data.completed_notify,
		props.data.completed_carga,
		true,
	];

	//Boton Siguiente
	const handleNext = () => {
		props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	//Boton Atras
	const handleBack = () => {
		props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	//Uso de Label del stepper
	const handleStep = (step) => () => {
		props.setActiveStep(step);
	};

	// Se crea la funcion para modificar el texto de los Textfields de los formularios correspondientes
	const handleTextFieldChange = (name) => (event) => {
		if (
			name === 'tel_embarcador' ||
			name === 'tel_consignatario' ||
			name === 'tel_notify'
		) {
			const value = event.target.value
				.replace(/\s/g, '')
				.replace(/[^\d]/g, '');
			props.actualizarDato(name, value);
		} else {
			if (name === 'email_embarcador' || name === 'email_consignatario') {
				const value = event.target.value.toLowerCase();
				props.actualizarDato(name, value);
			} else {
				const uppercaseValue = event.target.value.toUpperCase();
				props.actualizarDato(name, uppercaseValue);
			}
		}
	};

	const handleTextFieldChangePrev = (name, value) => () => {
		props.actualizarDato(name, value);
	};

	// Funcion para validar si los campos son validos
	const verifyInvalid = (field) => (event) => {
		const isInvalid = document.querySelectorAll(
			'#' + field + '[aria-invalid="true"]'
		).length;
		if (isInvalid === 0) {
			// el campo es válido
		} else {
			// 1, el campo es invalido
			props.actualizaCamposInvalidos(field);
		}
	};

	// ================================================================

	// Informción que se despliega en el step
	function info(step) {
		if (step === 0) {
			return (
				<Embarcador
					handleTextFieldChange={handleTextFieldChange}
					handleTextFieldChangePrev={handleTextFieldChangePrev}
					data={props.data}
					dataLada={props.dataLada}
					actualizarDato={props.actualizarDato}
					handleNext={handleNext}
					emptiesInputs={props.emptiesInputs}
					completed={props.completed}
					actualizaCamposInvalidos={props.actualizaCamposInvalidos}
					verifyInvalid={verifyInvalid}
				/>
			);
		}
		if (step === 1) {
			return (
				<ConsignatarioWithCatalog
					// Variables de catalogos
					dataDetallesConsignatarios={
						props.dataDetallesConsignatarios
					}
					dataDetallesPaises={props.dataDetallesPaises}
					loadingContactos={props.loadingContactos}
					loadingDirecciones={props.loadingDirecciones}
					dataFetchContactos={props.dataFetchContactos}
					dataFetchDirecciones={props.dataFetchDirecciones}
					dataContactos={props.dataContactos}
					dataDirecciones={props.dataDirecciones}
					indexConsignatario={props.indexConsignatario}
					setIndexConsignatario={props.setIndexConsignatario}
					infoConsignatario={props.infoConsignatario}
					setInfoConsignatario={props.setInfoConsignatario}
					actualizarConsignatario={props.actualizarConsignatario}
					infoContacto={props.infoContactoConsignatario}
					setInfoContacto={props.setInfoContactoConsignatario}
					infoDireccion={props.infoDireccionConsignatario}
					setInfoDireccion={props.setInfoDireccionConsignatario}
					actualizarContacto={props.actualizarContactoConsignatario}
					actualizarDireccion={props.actualizarDireccionConsignatario}
					emptiesInputs={props.emptiesInputs}
					dataFetchConsignatarios={props.dataFetchConsignatarios}
					setAlertMessagePage={props.setAlertMessage}
					sameAsConsignee={props.sameAsConsignee}
					handleOpenSnackbar={props.handleOpenSnackbar}
				/>
			);
		}
		if (step === 2) {
			return (
				<NotifyWithCatalog
					// Variables de catalogos
					dataDetallesConsignatarios={
						props.dataDetallesConsignatarios
					}
					dataDetallesPaises={props.dataDetallesPaises}
					loadingContactos={props.loadingContactos}
					loadingDirecciones={props.loadingDirecciones}
					dataFetchContactos={props.dataFetchContactos}
					dataFetchDirecciones={props.dataFetchDirecciones}
					dataContactos={props.dataContactos}
					dataDirecciones={props.dataDirecciones}
					indexConsignatario={props.indexConsignatario}
					setIndexConsignatario={props.setIndexConsignatario}
					infoConsignatario={props.infoNotify}
					setInfoConsignatario={props.setInfoNotify}
					actualizarConsignatario={props.actualizarNotify}
					infoContacto={props.infoContactoNotify}
					setInfoContacto={props.setInfoContactoNotify}
					infoDireccion={props.infoDireccionNotify}
					setInfoDireccion={props.setInfoDireccionNotify}
					actualizarContacto={props.actualizarContactoNotify}
					actualizarDireccion={props.actualizarDireccionNotify}
					emptiesInputs={props.emptiesInputs}
					dataFetchConsignatarios={props.dataFetchConsignatarios}
					setInfoNotify={props.setInfoNotify}
					setAlertMessagePage={props.setAlertMessage}
					sameAsConsignee={props.sameAsConsignee}
					handleOpenSnackbar={props.handleOpenSnackbar}
				/>
			);
		}
		if (step === 3) {
			return (
				<ListadoCargas
					enviarDatosFormulario={props.enviarDatosFormulario}
					dataFetchBooking={props.dataFetchBooking}
					data={props.data}
					dataBulto={props.dataBulto}
					dataAllProducts={props.dataAllProducts}
					dataAllDetails={props.dataAllDetails}
					handleNext={handleNext}
					emptiesInputs={props.emptiesInputs}
					actualizarDatoProducto={props.actualizarDatoProducto}
				/>
			);
		}
	}

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			sx={{
				overflowY: 'auto',
				overflowX: 'hidden',
				maxHeight: '62vh',
				minHeight: '62vh',
				paddingRight: '2%',
				width: '100%',
			}}
			spacing={0}
		>
			<Grid item container>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Stepper
						activeStep={props.activeStep}
						nonLinear={true}
						value={props.activeStep}
					>
						{steps.map((label, index) => {
							return (
								<Step
									key={label}
									completed={completedSteps[index]}
								>
									<StepButton onClick={handleStep(index)}>
										<StepLabel>
											<Hidden smDown>{label}</Hidden>
										</StepLabel>
									</StepButton>
								</Step>
							);
						})}
					</Stepper>
				</Grid>
			</Grid>
			<Grid item container>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Fragment>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								pt: 2,
								paddingRight: '2%',
							}}
						>
							<Tooltip title={t('modales.anterior')}>
								<Button
									size='small'
									id='InfoStepperBackButton'
									variant='contained'
									disableElevation
									disabled={props.activeStep === 0}
									onClick={handleBack}
									sx={{ bgcolor: '#002D58' }}
								>
									<ArrowBackIosIcon fontSize='small' />
								</Button>
							</Tooltip>
							<Box sx={{ flex: '1 1 auto' }} />
							<Tooltip title={t('modales.siguiente')}>
								<Button
									id='InfoStepperNextButton'
									sx={{ bgcolor: '#002D58' }}
									size='small'
									variant='contained'
									disableElevation
									onClick={handleNext}
									disabled={
										props.activeStep === steps.length - 1
									}
								>
									<ArrowForwardIosIcon fontSize='small' />
								</Button>
							</Tooltip>
						</Box>
					</Fragment>
				</Grid>
			</Grid>
			<Grid
				item
				container
				sx={{ mt: 1 }}
				justifyContent={'space-between'}
			>
				<Grid item lg={8} md={8} xs={8} sm={8}>
					<Hidden smUp>
						<Typography variant='h6'>
							{steps[props.activeStep]}
						</Typography>
					</Hidden>
				</Grid>
				<Grid
					item
					lg={4}
					md={4}
					xs={4}
					sm={4}
					justifyContent={'right'}
					alignItems={'right'}
					alignContent={'right'}
				>
					{props.activeStep === 2 && (
						<FormControlLabel
							control={
								<Checkbox
									id='InfoStepperCheckbox'
									checked={props.sameAsConsignee}
									onChange={props.handleCheckNotify}
								/>
							}
							label={
								<Typography class='Checkbox-Text'>
									{t('booking.detalles.mismoConsignatario')}
								</Typography>
							}
						/>
					)}
				</Grid>
			</Grid>
			<Grid item lg={12} md={12} xs={12} sm={12}>
				{info(props.activeStep)}
			</Grid>
		</Stack>
	);
}
