import React, { useEffect, useState } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import EditIconDataGrid from './EditIconDataGrid';
import DeleteIconDataGrid from './DeleteIconDataGrid';
import { Box, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../get-data/GetDataFetch';

/*Descripción:Este componente muestra el listado de todos los productos activos para un cliente
Componente padre: ProductsList.jsx
Componente hijo:
props: props.dataGrid = información a desplegar del DataGrid //
	props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert //
	rops.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.handelCloseModal = funcion para cuando cierre el modal
*/

export default function DataGridProductos(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [opcionesUN, setOpcionesUN] = useState({});
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	// Se hace el Data Fetch
	const dataFetch2 = async () => {
		setLoading(true);
		await GetDataFetch(
			'catalogos/numerosUN/all',
			setLoading,
			setOpcionesUN,
			navigate
		);
	};

	useEffect(() => {
		dataFetch2();
	}, []);

	const tableData = [];
	// Se hace una validacion de todos elementos del DataGrid
	for (let i = 0; i < props.dataGrid.length; i++) {
		const producto = props.dataGrid[i];
		// Se valida que el producto exista
		if (producto) {
			// Se asignan los datos a las columnas correspondientes de la fila
			const rowData = {
				id: i + 1,
				nombreCorto: producto.nombreCorto,
				idBD: producto.id,
				fraccion: producto.fraccionArancelaria,
				esPeligroso: producto.esPeligroso,
			};
			// Se asigna la fila de datos
			tableData.push(rowData);
		}
	}

	// Se asignan las columnas del DataGrid
	const headCells = [
		{
			id: 'nombreCorto',
			field: 'nombreCorto',
			width: 400,
			headerName: t('catalogos.productos.nombreCorto'),
			editable: false,
		},
		{
			id: 'fraccion',
			field: 'fraccion',
			width: 300,
			headerName: t('catalogos.productos.fraccionArancelaria'),
			editable: false,
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 140,
			disableClickEventBubbling: true,
			// Se renderizan los botones especiales
			renderCell: (params) => {
				return (
					<>
						<EditIconDataGrid
							dataFetch={props.dataFetch}
							handleClick1={props.handleClick1}
							setAlertMessage={props.setAlertMessage}
							handleCloseModal={props.handleCloseModal}
							openModal={props.openModal}
							setOpenModal={props.setOpenModal}
							id={params.row.idBD}
							opcionesUN={opcionesUN}
						/>
						<DeleteIconDataGrid
							isChecked={props.isChecked}
							dataFetch={props.dataFetch}
							handleClick1={props.handleClick1}
							setAlertMessage={props.setAlertMessage}
							handleCloseModal={props.handleCloseModal}
							openModal={props.openModal}
							setOpenModal={props.setOpenModal}
							id={params.row.idBD}
						/>
					</>
				);
			},
		},
	];

	const filterRows = () => {
		return tableData.filter((row) =>
			headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(props.searchTerm.toLowerCase());
			})
		);
	};

	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<>
			{loading ? (
				<LinearProgress />
			) : (
				<Box
					sx={{
						height: '60.5vh',
						paddingTop: '7px',
						paddingBottom: '7px',
					}}
					className='ListaCargas'
				>
					<DataGrid
						localeText={localeText()}
						rows={filterRows()}
						columns={headCells}
						initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 10 },
							},
						}}
						pageSizeOptions={[10, 20, 30]}
						sx={{
							'& .MuiDataGrid-cell': {
								fontSize: '12px',
								fontFamily: 'Source Sans Pro',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								fontSize: '13px',
								fontFamily: 'Open Sans',
							},
						}}
					/>
				</Box>
			)}
		</>
	);
}
