import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../../components/get-data/GetDataFetch';
import DataGridConsignatarios from '../../../components/catalogos/consignatarios/DataGridConsignatarios';
import MySkeletonPage from '../../../components/Skeletons/SkeletonPage';
import BoxTitle from '../../../components/titles/BoxTitle';

/*Descripción:Este componente muestra los catálogos que están disponibles para este cliente.
Componente padre: CommonLayout.jsx
Componente hijo:
props: Sin uso de props
*/

export default function ConsignatariosLists() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState('');
	const [loadingPaises, setLoadingPaises] = useState(true);
	const [dataDetallesPaises, setDataDetallesPaises] = useState('');
	const navigate = useNavigate();

	// Funcion para obtener los datos de la API
	const dataFetch = async () => {
		setLoading(true);
		let url = 'consignatarios/all';
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	// Funcion para obtener los datos de la API
	const dataFetchPaises = async () => {
		setLoadingPaises(true);
		let url = 'catalogos/paises';
		await GetDataFetch(
			url,
			setLoadingPaises,
			setDataDetallesPaises,
			navigate
		);
	};

	// useEffect del Data Fetch
	useEffect(() => {
		dataFetch();
		dataFetchPaises();
	}, []);

	return (
		// Carga el contenido de la página======================
		<ErrorBoundary>
			<BoxTitle title={t('catalogos.consignatario.titulo')} />
			<Box
				sx={{
					paddingTop: '7px',
					bgcolor: 'background.secondary',
					borderRadius: 1,
					padding: 1,
				}}
			>
				{loading ? (
					<MySkeletonPage />
				) : (
					<DataGridConsignatarios
						dataDetallesPaises={dataDetallesPaises}
						loadingPaises={loadingPaises}
						dataGrid={dataDetalles}
						dataFetch={dataFetch}
					/>
				)}
			</Box>
		</ErrorBoundary>
	);
}
