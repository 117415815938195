import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, CardContent, Stack } from '@mui/material';
import TimelineSelectorDespachoExpo from './Timeline/TimelineSelectorDespachoExpo';
import SelectedSearchPedimento from '../SelectSearchPedimento';
import InfoPedimentoExpo from './Info/InfoPedimentoExpo';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos de Despacho Expo
Componente padre: BookingDespachoExpoSR
Componente hijo: SelectedSearchPedimento //
	InfoPedimentoExpo //
	TimelineSelectorDespachoExpo
props: props.data = informacion a desplegar
*/

export default function EventosBookingDespachoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean los const para los eventos del despacho
	const [dataPedimento, setDataPedimento] = useState({});

	// Funcion para actualizar los datos del pedimento
	function actualizarPedimento(index) {
		setDataPedimento(props.data.pedimentos[index]);
	}
	// useEffect para seleccionar el valor primero de los pedimentos
	useEffect(() => {
		if (props.data.pedimentos.length !== 0) {
			setDataPedimento(props.data.pedimentos[0]);
		}
	}, [props.data]);

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'row',
				lg: 'row',
				xl: 'row',
			}}
			spacing={1}
		>
			<Card
				className={props.className}
				elevation={props.elevation}
				sx={{ width: '100%' }}
			>
				<CardContent>
					<Grid container sx={{ paddingBottom: '3%' }}>
						{props.data.pedimentos.length === 0 ? (
							<Typography variant='body1' color='initial'>
								{t('booking.estatus.sinPedimento')}
							</Typography>
						) : (
							<SelectedSearchPedimento
								actualizarPedimento={actualizarPedimento}
								customLabel={t('selectSearch.pedimentos')}
								id='pedimentos'
								name='pedimentos'
								dataOption={props.data.pedimentos}
							/>
						)}
					</Grid>
					<InfoPedimentoExpo
						dataPedimento={dataPedimento}
						data={props.data}
					/>
				</CardContent>
			</Card>
			<Card
				className={props.className}
				elevation={props.elevation}
				sx={{ width: '100%' }}
			>
				<CardContent>
					<TimelineSelectorDespachoExpo
						dataPedimentos={dataPedimento}
						data={props.data}
					/>
				</CardContent>
			</Card>
		</Stack>
	);
}
