import React from 'react';
import { Grid, Typography, Card, CardContent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion general del booking Maritimo Impo seleccionado
Componente padre: BookingMaritimoImpoSR
Componente hijo:
props: props.data = informacion a desplegar
*/

export default function InfoBookingMaritimoImpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			spacing={1}
		>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Grid item container>
							<Typography variant='subtitle1' color='initial'>
								{t('booking.detalles.embarcador')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.nombre')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.embarcador.nombre}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.direccion')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.embarcador.direccion}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.taxID')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.embarcador.taxid}
							</Typography>
						</Grid>
					</CardContent>
				</Card>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Grid item container>
							<Typography variant='subtitle1' color='initial'>
								{t('booking.detalles.consignatario')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.nombre')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.consignatario.nombre}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.direccion')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.consignatario.direccion}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.rfc')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.consignatario.taxid}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.contacto')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.consignatario.contacto}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.correo')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.consignatario.email}
							</Typography>
						</Grid>
					</CardContent>
				</Card>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Grid item container>
							<Typography variant='subtitle1' color='initial'>
								{t('booking.detalles.notify')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.nombre')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.notify.nombre}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.direccion')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.notify.direccion}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.taxID')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.notify.taxid}
							</Typography>
						</Grid>
					</CardContent>
				</Card>
			</Stack>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Grid item container>
							<Typography variant='subtitle1' color='initial'>
								{t('booking.detalles.informacionCarga')}
							</Typography>
						</Grid>
						{props.data.producto ? (
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.producto')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.producto}
								</Typography>
							</Grid>
						) : (
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.producto')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.producto}
								</Typography>
							</Grid>
						)}

						{props.data.masterBL ? (
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.mbl')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.masterBL}
								</Typography>
							</Grid>
						) : (
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.mbl')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.masterBL}
								</Typography>
							</Grid>
						)}

						{props.data.informacionCarga.houseBL ? (
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.hbl')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.houseBL}
								</Typography>
							</Grid>
						) : (
							<></>
						)}

						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.peso')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.informacionCarga.peso}{' '}
								{t('booking.detalles.kg')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.volumen')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.informacionCarga.volumen}{' '}
								{t('booking.detalles.cbm')}
							</Typography>
						</Grid>
					</CardContent>
				</Card>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Grid item container>
							<Typography variant='subtitle1' color='initial'>
								{t('booking.detalles.origenDestino')}
							</Typography>
						</Grid>
						<Grid item container spacing={1}>
							<Grid item container spacing={1}>
								{props.data['nombre-buque'] !== undefined ? (
									<Grid item container>
										<Typography
											variant='body2'
											class='Datos-bookings-titulos'
											color='initial'
										>
											{t('booking.detalles.buque')}
										</Typography>
										<Typography
											color='initial'
											class='Datos-bookings'
										>
											{props.data['nombre-buque']}
										</Typography>
									</Grid>
								) : (
									<></>
								)}
							</Grid>
							<Grid item container spacing={1}>
								<Grid item container>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.puertoCarga')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.pol}
									</Typography>
								</Grid>
								<Grid item container>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.puertoDescarga')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.pod}
									</Typography>
								</Grid>
								<Grid item container>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.destinoFinal')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data['destino-final']}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Grid item container>
							<Typography variant='subtitle1' color='initial'>
								{t('booking.detalles.seguroMercancia')}
							</Typography>
						</Grid>
						<Grid item container spacing={1}>
							<Grid item container spacing={1} lg={6}>
								{props.data['numero-poliza'] !== undefined &&
								props.data['numero-poliza'] !== '' ? (
									<Grid item container>
										<Typography
											variant='body2'
											class='Datos-bookings-titulos'
											color='initial'
										>
											{t('booking.detalles.polizaSeguro')}
										</Typography>
										<Typography
											color='initial'
											class='Datos-bookings'
										>
											{props.data['numero-poliza']}
										</Typography>
									</Grid>
								) : (
									<Grid item container>
										<Typography
											variant='body2'
											class='Datos-bookings-titulos'
											color='initial'
										>
											{t('booking.detalles.polizaSeguro')}
										</Typography>
										<Typography
											color='initial'
											class='Datos-bookings'
										>
											{t(
												'booking.detalles.noContratoPolizaSeguro'
											)}
										</Typography>
									</Grid>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Stack>
		</Stack>
	);
}
