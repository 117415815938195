import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import MyImage from "../../../assets/img/cards/CardFotos/Uno.png";
import MyImage2 from "../../../assets/img/cards/CardFotos/Dos.png";
import MyImage3 from "../../../assets/img/cards/CardFotos/Tres.png";
import MyImage4 from "../../../assets/img/cards/CardFotos/Cuatro.png";
import MyImage5 from "../../../assets/img/cards/CardFotos/Cinco.png";
import MyImage6 from "../../../assets/img/cards/CardFotos/Seis.png";
import MyImage7 from "../../../assets/img/cards/CardFotos/Siete.png";
import MyImage8 from "../../../assets/img/cards/CardFotos/Ocho.png";
import MyImage9 from "../../../assets/img/cards/CardFotos/Nueve.png";
import MyImage10 from "../../../assets/img/cards/CardFotos/Diez.png";
import MyImage11 from "../../../assets/img/cards/CardFotos/Once.png";
import MyImage12 from "../../../assets/img/cards/CardFotos/Doce.png";
import { useState } from "react";
import { useEffect } from "react";

/*Descripción:Este componente se encarga de renderizar una imagen aleatoria
Componente padre: ContrataServicios
Componente hijo:
props: Sin uso de props
*/

export default function CardFotos() {
	const [bgImage, setBgImage] = useState("");

	useEffect(() => {
		const images = [
			MyImage,
			MyImage2,
			MyImage3,
			MyImage4,
			MyImage5,
			MyImage6,
			MyImage7,
			MyImage8,
			MyImage9,
			MyImage10,
			MyImage11,
			MyImage12,
		]; // Lista de imágenes disponibles
		const randomIndex = Math.floor(Math.random() * images.length); // Genera un índice aleatorio
		setBgImage(images[randomIndex]); // Establece la imagen de fondo aleatoria
	}, []);

	return (
		<Card className="default-Card-Fotos">
			<CardMedia component="img" image={bgImage} />
		</Card>
	);
}
