import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar los eventos del contenedor asociados a TN
Componente padre: TimelineSelectorTN
Componente hijo: TimelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosContenedor(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Funcion para validar los campos nulos o indefinidos
	const isInvalidEndpointField = (field) => {
		return (
			field === 'N.D.' ||
			field === undefined ||
			field === null ||
			field === ''
		);
	};

	// Funcion para renderizar los eventos
	const renderTimeLine = (field1, title1) => {
		return isInvalidEndpointField(field1) ? (
			<></>
		) : (
			<TimelineItem data={field1} title={title1} />
		);
	};

	return (
		<>
			{props.data !== undefined && (
				<Timeline position='alternate'>
					{renderTimeLine(
						props.data['fecha-llegada-planta'],
						t('eventos.llegadaPlanta')
					)}

					{renderTimeLine(
						props.data['fecha-salida-planta'],
						t('eventos.salidaPlanta')
					)}
					{renderTimeLine(
						props.data['fecha-llegada-puerto'],
						t('eventos.llegadaPuerto')
					)}
				</Timeline>
			)}
		</>
	);
}
