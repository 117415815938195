import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import SelectSearchPaises from '../SelectSearchPaises';
import InputAdornment from '@mui/material/InputAdornment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ArticleIcon from '@mui/icons-material/Article';

/*Descripción: Este componente se encarga de renderizar el formulario para agregar o editar un usuario.
Componente padre: AgregarConsignatarioModal.jsx // EditarConsignatarioModal.jsx
Componente hijo: SelectSearchPaises.jsx
props: actualizarDato: Función para actualizar los datos // actualizarPais: Función para actualizar el pais //
handleTextFieldChange: Función para cambiar el valor de los campos // dataDetallesPaises: Manda la información de los paises //
dataFetch: Función para obtener los datos de la API // dataDetallesPaises: Manda la información de los paises //
infoConsignatario: Información del consignatario // setInfoConsignatario: Función para actualizar la información del consignatario //
edit: Variable para saber si se esta editando o agregando un usuario
*/

export default function ConsignatarioForm(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loadingPaises, setLoadingPaises] = useState(true);
	const [dataOptionsPaises, setDataOptionsPaises] = useState([]);

	useEffect(() => {
		setLoadingPaises(true);
		const arr = [];
		props.dataDetallesPaises.catalogo.map((pais, index) => {
			// Se valida que el index de las secuencias exista
			return arr.push({
				value: pais.id,
				label: pais.pais,
			});
		});
		setLoadingPaises(false);
		setDataOptionsPaises(arr);
	}, []);

	const resultToRender = () => {
		if (props.edit) {
			return (
				<>
					<Grid
						container
						rowSpacing={1.5}
						columnSpacing={{ md: 2, lg: 2 }}
					>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant='subtitle2'>
								{t('catalogos.consignatario.nombre')}
							</Typography>
							<TextValidator
								onKeyDown={props.handleKeyPress}
								validators={['required', 'minStringLength:3']}
								errorMessages={[
									t('errorMessages.campoRequerido'),
									t('errorMessages.nombreInvalido'),
								]}
								onChange={props.handleTextFieldChange(
									'nombre_razon'
								)}
								required
								id='nombre_razon'
								name='nombre_razon'
								rows={3}
								fullWidth
								value={
									props.infoConsignatario.nombre_razon || ''
								}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<BusinessCenterIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant='subtitle2'>
								{t('booking.detalles.taxID')}
							</Typography>
							<TextValidator
								onKeyDown={props.handleKeyPress}
								validators={['required', 'minStringLength:1']}
								errorMessages={[
									t('errorMessages.campoRequerido'),
									t('errorMessages.taxIDInvalido'),
								]}
								onChange={props.handleTextFieldChange('tax_id')}
								required
								id='tax_id'
								name='tax_id'
								variant='outlined'
								fullWidth
								value={props.infoConsignatario.tax_id || ''}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<ArticleIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</>
			);
		} else {
			return (
				<>
					<Grid
						container
						rowSpacing={1.5}
						columnSpacing={{ md: 2, lg: 2 }}
						mb={3}
					>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<SelectSearchPaises
								required
								actualizarDato={props.actualizarDato}
								customLabel={t(
									'catalogos.consignatario.selectSearch.paises'
								)}
								id='paises'
								dataOptions={dataOptionsPaises}
								actualizarPais={props.actualizarPais}
								name='paises'
								indexPais={props.indexPais}
								handleTextFieldChange={
									props.handleTextFieldChange
								}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant='subtitle2'>
								{t('catalogos.consignatario.nombre')}
							</Typography>
							<TextValidator
								onKeyDown={props.handleKeyPress}
								validators={['required', 'minStringLength:3']}
								errorMessages={[
									t('errorMessages.campoRequerido'),
									t('errorMessages.nombreInvalido'),
								]}
								onChange={props.handleTextFieldChange(
									'nombre_razon'
								)}
								required
								id='nombre_razon'
								name='nombre_razon'
								rows={3}
								fullWidth
								value={
									props.infoConsignatario.nombre_razon || ''
								}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<BusinessCenterIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant='subtitle2'>
								{t('booking.detalles.taxID')}
							</Typography>
							<TextValidator
								onKeyDown={props.handleKeyPress}
								validators={['required']}
								errorMessages={[
									t('errorMessages.campoRequerido'),
									t('errorMessages.taxIDInvalido'),
									t('errorMessages.taxIDInvalido'),
								]}
								onChange={props.handleTextFieldChange('tax_id')}
								required
								id='tax_id'
								name='tax_id'
								variant='outlined'
								fullWidth
								value={props.infoConsignatario.tax_id || ''}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<ArticleIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</>
			);
		}
	};

	return resultToRender();
}
