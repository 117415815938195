import { AddIcCall, Search } from '@mui/icons-material';
import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DeleteIconDataGrid from './ActionIcons/Contactos/DeleteIconDataGrid';
import EditIconDataGrid from './ActionIcons/Contactos/EditIconDataGrid';
import { useState } from 'react';
import AgregarContactosModal from './Modales/Contactos/AgregarContactosModal';

/*Descripción:Este componente se encarga de renderizar el contenido del data grid de los contactos.
Componente padre: ConsignatariosInfo.jsx
Componente hijo: AgregarContactosModal.jsx // DeleteIconDataGrid.jsx // EditIconDataGrid.jsx
props: data: Información del consignatario //
id: id del consignatario //
dataFetch: Función para obtener los datos de la API
*/

export default function ListContactos(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [openModalEdit, setOpenModalEdit] = useState(false);

	// Función para abrir y cerrar el modal
	const handleCloseModalEdit = () => {
		setOpenModalEdit(false);
	};
	// Configuraciones modal para agregar
	const [openModalAdd, setOpenModalAdd] = useState(false);
	// Función para abrir y cerrar el modal
	const handleOpenModalAdd = () => {
		setOpenModalAdd(true);
	};
	const handleCloseModalAdd = () => {
		setOpenModalAdd(false);
	};

	// Se configuran los encabezados del DataGrid
	const headCells = [
		{
			id: 'contacto',
			field: 'contacto',
			width: 290,
			headerName: t('dataGrid.contacto'),
			editable: false,
		},
		{
			id: 'telefono',
			field: 'telefono',
			width: 160,
			headerName: t('dataGrid.telefono'),
			editable: false,
		},
		{
			id: 'email',
			field: 'email',
			width: 230,
			headerName: t('dataGrid.correo'),
			editable: false,
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 140,
			disableClickEventBubbling: true,
			// Se renderizan los botones especiales
			renderCell: (params) => {
				return (
					<>
						<EditIconDataGrid
							id={props.id}
							idContacto={params.row.idToSend}
							dataFetch={props.dataFetch}
							handleCloseModal={handleCloseModalEdit}
							openModal={openModalEdit}
							setOpenModal={setOpenModalEdit}
							setAlertMessagePage={props.setAlertMessagePage}
							handleClickPage={props.handleClickPage}
						/>
						<DeleteIconDataGrid
							id={props.id}
							idContacto={params.row.idToSend}
							dataFetch={props.dataFetch}
							setAlertMessagePage={props.setAlertMessagePage}
							handleClickPage={props.handleClickPage}
						/>
					</>
				);
			},
		},
	];

	const tableData = [];
	// Se hace una validacion de todos elementos del DataGrid
	for (let i = 0; i < props.data.contactos.length; i++) {
		const contacto = props.data.contactos[i];
		// Se valida que el usuario exista
		if (contacto) {
			const rowData = {
				id: i + 1,
				idToSend: contacto.cod_contacto,
				contacto: contacto.contacto,
				telefono: contacto.telefono,
				email: contacto.email,
			};
			tableData.push(rowData);
		}
	}

	// Funciones para la busqueda del DataGrid
	const [searchTerm, setSearchTerm] = useState('');

	const filterRows = () => {
		return tableData.filter((row) =>
			headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(searchTerm.toLowerCase());
			})
		);
	};

	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<>
			<Grid
				item
				container
				xs={12}
				sm={12}
				md={12}
				lg={12}
				sx={{ paddingTop: '2%' }}
			>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<TextField
						label={t('tablero.trackTrace.buscar')}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						autoComplete='off'
						variant='outlined'
						size='small'
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Search />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					sm={6}
					md={6}
					lg={6}
					sx={{ textAlign: 'right' }}
				>
					<Button
						variant='outlined'
						color='primary'
						id='BtnAgregarCargaBL'
						onClick={handleOpenModalAdd}
						sx={{
							fontSize: '12px',

							marginBottom: 1,
						}}
						startIcon={<AddIcCall />}
					>
						{t('botones.agregar')}
					</Button>
				</Grid>
			</Grid>
			<Box sx={{ height: '50vh', paddingTop: '1%' }}>
				<DataGrid
					localeText={localeText()}
					rows={filterRows()}
					columns={headCells}
					onRowDoubleClick={(params, event) => {}}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10, 20, 30]}
					sx={{
						'& .MuiDataGrid-cell': {
							fontSize: '12px',
							fontFamily: 'Source Sans Pro',
						},
						'& .MuiDataGrid-columnHeaderTitle': {
							fontSize: '13px',
							fontFamily: 'Open Sans',
						},
					}}
				/>
			</Box>
			<AgregarContactosModal
				id={props.id}
				dataFetch={props.dataFetch}
				handleCloseModal={handleCloseModalAdd}
				openModal={openModalAdd}
				setOpenModal={setOpenModalAdd}
				setAlertMessagePage={props.setAlertMessagePage}
				handleClickPage={props.handleClickPage}
			/>
		</>
	);
}
