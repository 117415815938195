import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventosBookingDespachoImpo from './EventosBookingDespachoImpo';
import BookingInfoImpo from './Info/InfoBookingImpo';
import GetDataFetch from '../../../get-data/GetDataFetch';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import BookingWithTabs from '../../../titles/BookingWithTabs';
import MantenimientoCard from '../../../cards/MantenimientoCard';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Despacho Impo
Componente padre: CommonBooking
Componente hijo: BookingInfoImpo //
	EventosBookingDespachoImpo //
	IndexFileUpload
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición
*/

export default function BookingDespachoSearchResult(props) {
	// Se crean las constantes para el uso del SR
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	// Se crean los const para la validacion de los roles
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [userRole, setUserRole] = useState('');
	const [showContent2, setShowContent] = useState(false);
	const [dataDetallesDocumentos, setDataDetallesDocumentos] = useState();
	const [infoDocumento, setInfoDocumento] = useState({
		uiCodTipoDocumento: '',
		vDescripcionEspaniol: '',
		vDescripcionIngles: '',
	});
	const [documento, setDocumento] = useState('');
	const [indexDocumento, setIndexDocumento] = useState('0');
	const [loadingDocumentos, setLoadingDocumentos] = useState(true);
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});

	// Funcion para actualizar el contenido del Documento
	function actualizarDocumento(documento) {
		setDocumento(documento.value + '');
		setIndexDocumento(documento.label + '');
	}

	// useEffect para validar el rol de el usuario
	useEffect(() => {
		if (token !== undefined) {
			const claims = jwt_decode(token);
			const rolesClaims = claims?.roles;
			const rol = rolesClaims.substring(1, rolesClaims.length - 1);
			setUserRole(rol);
			//======================================================================
			if (rol === 'ROLE_CLIENTE_DIRECTO_ADMIN') {
				setShowContent(true);
			}
		}
	}, []);

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		// Se crea el endpoint
		var url = props.endpoint + '/detalles?booking=' + props.selectedBooking;
		// Se hace el Get Fetch de la información
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	// useEffect para seleccionar el booking
	useEffect(() => {
		if (props.selectedBooking !== '') {
			dataFetch();
		}
	}, [props.selectedBooking]);

	return (
		<>
			<BookingWithTabs
				selectedBooking={props.selectedBooking}
				TabPanelDetalles={
					<BookingInfoImpo
						data={dataDetalles}
						selectedBooking={props.selectedBooking}
						loading={loading}
					/>
				}
				TabPanelEstatus={
					<EventosBookingDespachoImpo
						data={dataDetalles}
						selectedBooking={props.selectedBooking}
						loading={loading}
						className='paper-hover'
					/>
				}
				TabPanelServiciosAdicionales={'NA'}
				TabPanelDocumentos={
					<MantenimientoCard />
					// <FileUploadModal
					// 	modal={false}
					// 	selectedBooking={props.selectedBooking}
					// 	dataDetallesDocumentos={dataDetallesDocumentos}
					// 	infoDocumento={infoDocumento}
					// 	actualizarDocumento={actualizarDocumento}
					// 	documento={documento}
					// 	indexDocumento={indexDocumento}
					// 	setDocumento={setDocumento}
					// 	setIndexDocumento={setIndexDocumento}
					// 	setInfoDocumento={setInfoDocumento}
					// 	state={state}
					// />
				}
				loading={loading}
				loadingDocumentos={false}
			/>
		</>
	);
}
