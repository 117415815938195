import { Email } from '@mui/icons-material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/*Descripción:Este componente se encarga de renderizar la pagina de Acerca de Accutrack
Componente padre: App.js
Componente hijo: Login.jsx
props: Sin uso de props
*/

export default function AboutUs() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const navigate = useNavigate();
	// Funcion para redirigir al inicio
	function onClickHome() {
		navigate('/');
	}
	// Funcion para redirigir al correo
	const Mailto = ({ email }) => {
		return <a href={`mailto:${email}`}>{email}</a>;
	};

	return (
		<Box className='Aligner text-center height-100'>
			<Box>
				<Avatar
					sx={{ m: 1, bgcolor: '#E0202A' }}
					className='margin-0-auto'
				>
					<Email />
				</Avatar>
				<Typography component='h1' variant='h5' className='margin-1-0'>
					{t('centroAyuda.contactanos')}
				</Typography>
				<Typography component='p' className='margin-bottom-1'>
					{t('centroAyuda.escribenosA')}
				</Typography>
				<Mailto email={process.env.REACT_APP_CONTACT_EMAIL} />
				<Box sx={{ paddingTop: '2%' }}>
					<Button onClick={onClickHome} variant='text'>
						{t('botones.inicioSesion')}
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
