import { AddLocationAlt, Search } from '@mui/icons-material';
import {
	Box,
	Button,
	Grid,
	LinearProgress,
	InputAdornment,
	TextField,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AgregarDireccionesModal from './Modales/Direcciones/AgregarDireccionesModal';
import EditIconDataGrid from './ActionIcons/Direcciones/EditIconDataGrid';
import DeleteIconDataGrid from './ActionIcons/Direcciones/DeleteIconDataGrid';
import GetDataFetch from '../../get-data/GetDataFetch';
import { useNavigate } from 'react-router';

/*Descripción:Este componente se encarga de renderizar el contenido del data grid de las direcciones.
Componente padre: ConsignatariosInfo.jsx
Componente hijo: AgregarDireccionesModal.jsx // EditIconDataGrid.jsx // DeleteIconDataGrid.jsx
props: data: Información del consignatario // id: id del consignatario //
codPais: Código del país del consignatario // dataFetch: Función para obtener los datos de la API
*/

export default function ListDirecciones(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [openModalEdit, setOpenModalEdit] = useState(false);
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	const [indexCiudad, setIndexCiudad] = useState(0);
	const [ciudad, setCiudad] = useState('');
	const [infoDireccion, setInfoDireccion] = useState({
		cod_persona: props.id,
		calle: '',
		cod_ciudad: ciudad,
	});

	// Función para abrir y cerrar el modal
	const handleCloseModalEdit = () => {
		setOpenModalEdit(false);
	};
	// Configuraciones modal para eliminar
	const [openModalDelete, setOpenModalDelete] = useState(false);

	// Función para abrir y cerrar el modal
	const handleCloseModalDelete = () => {
		setOpenModalDelete(false);
	};
	// Configuraciones modal para agregar
	const [openModalAdd, setOpenModalAdd] = useState(false);
	// Función para abrir y cerrar el modal
	const handleOpenModalAdd = () => {
		setOpenModalAdd(true);
	};
	const handleCloseModalAdd = () => {
		setOpenModalAdd(false);
	};

	// Funcion get para obtener las ciudades por pais
	const getCiudades = async () => {
		setLoading(true);
		let url = 'catalogos/ciudades-por-pais?pais=' + props.paisToSend;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	// useEffect para obtener las ciudades por pais
	useEffect(() => {
		if (props.infoConsignatario.cod_pais !== '') {
			getCiudades();
		}
	}, [props.paisToSend]);

	// Funcion para actualizar el contenido del contenedor de equipo
	function actualizarCiudad(ciudad) {
		setCiudad(ciudad.value + '');
		setIndexCiudad(ciudad.label + '');
	}

	useEffect(() => {
		setInfoDireccion({
			...infoDireccion,
			cod_ciudad: ciudad,
		});
	}, [ciudad]);

	const headCells = [
		{
			id: 'calle',
			field: 'calle',
			width: 350,
			headerName: t('dataGrid.calle'),
			editable: false,
		},
		{
			id: 'ciudad',
			field: 'ciudad',
			width: 160,
			headerName: t('dataGrid.ciudad'),
			editable: false,
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 140,
			disableClickEventBubbling: true,
			// Se renderizan los botones especiales
			renderCell: (params) => {
				return (
					<>
						<EditIconDataGrid
							id={props.id}
							idDireccion={params.row.idToSend}
							codPais={props.codPais}
							dataFetch={props.dataFetch}
							handleCloseModal={handleCloseModalEdit}
							openModal={openModalEdit}
							setOpenModal={setOpenModalEdit}
							dataCiudades={dataDetalles}
							setAlertMessagePage={props.setAlertMessagePage}
							handleClickPage={props.handleClickPage}
						/>
						<DeleteIconDataGrid
							id={props.id}
							idDireccion={params.row.idToSend}
							dataFetch={props.dataFetch}
							handleCloseModal={handleCloseModalDelete}
							openModal={openModalDelete}
							setOpenModal={setOpenModalDelete}
							setAlertMessagePage={props.setAlertMessagePage}
							handleClickPage={props.handleClickPage}
						/>
					</>
				);
			},
		},
	];

	const tableData = [];
	// Se hace una validacion de todos elementos del DataGrid
	for (let i = 0; i < props.data.direcciones.length; i++) {
		const direccion = props.data.direcciones[i];
		// Se valida que el usuario exista
		if (direccion) {
			const rowData = {
				id: i + 1,
				idToSend: direccion.cod_direccion,
				calle: direccion.calle,
				cod_direccion: direccion.cod_direccion,
				cod_ciudad: direccion.cod_ciudad,
				ciudad: direccion.ciudad,
			};
			tableData.push(rowData);
		}
	}

	// Funciones para la busqueda del DataGrid
	const [searchTerm, setSearchTerm] = useState('');

	const filterRows = () => {
		return tableData.filter((row) =>
			headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(searchTerm.toLowerCase());
			})
		);
	};

	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<>
			{loading ? (
				<Box sx={{ height: '50vh', paddingTop: '1%' }}>
					<LinearProgress marginTop={1} />
				</Box>
			) : (
				<>
					<Grid
						item
						container
						xs={12}
						sm={12}
						md={12}
						lg={12}
						sx={{ paddingTop: '2%' }}
					>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<TextField
								label={t('tablero.trackTrace.buscar')}
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								autoComplete='off'
								variant='outlined'
								size='small'
								fullWidth
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<Search />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid
							item
							xs={6}
							sm={6}
							md={6}
							lg={6}
							sx={{ textAlign: 'right' }}
						>
							<Button
								variant='outlined'
								color='primary'
								id='BtnAgregarCargaBL'
								onClick={handleOpenModalAdd}
								sx={{
									fontSize: '12px',

									marginBottom: 1,
								}}
								startIcon={<AddLocationAlt />}
							>
								{t('botones.agregar')}
							</Button>
						</Grid>
					</Grid>
					<Box sx={{ height: '50vh', paddingTop: '1%' }}>
						<DataGrid
							localeText={localeText()}
							rows={filterRows()}
							columns={headCells}
							initialState={{
								pagination: {
									paginationModel: { page: 0, pageSize: 10 },
								},
							}}
							pageSizeOptions={[10, 20, 30]}
							sx={{
								'& .MuiDataGrid-cell': {
									fontSize: '12px',
									fontFamily: 'Source Sans Pro',
								},
								'& .MuiDataGrid-columnHeaderTitle': {
									fontSize: '13px',
									fontFamily: 'Open Sans',
								},
							}}
						/>
						<AgregarDireccionesModal
							handleCloseModal={handleCloseModalAdd}
							openModal={openModalAdd}
							setOpenModal={setOpenModalAdd}
							dataFetch={props.dataFetch}
							id={props.id}
							codPais={props.codPais}
							dataCiudades={dataDetalles}
							actualizarCiudad={actualizarCiudad}
							infoDireccion={infoDireccion}
							setInfoDireccion={setInfoDireccion}
							indexCiudad={indexCiudad}
							setIndexCiudad={setIndexCiudad}
							setAlertMessagePage={props.setAlertMessagePage}
							handleClickPage={props.handleClickPage}
						/>
					</Box>
				</>
			)}
		</>
	);
}
