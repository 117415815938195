import { Box, Typography, Grid, Button } from '@mui/material';
import { Component } from 'react';
import ErrorIcon from '@mui/icons-material/Error';

/*Descripción:Este componente se encarga de desplegar una pantalla de error
Componente padre: Global
Componente hijo: Global
props: Sin uso de props
*/

function onClickHome() {
	window.location.reload();
}
class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return {
			hasError: true,
			error,
		};
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service like AppSignal
		// logErrorToMyService(error, errorInfo);
	}

	render() {
		const { hasError } = this.state;

		if (hasError) {
			// You can render any custom fallback UI
			return (
				<Box sx={{ alignItems: 'center', paddingTop: '17%' }}>
					<Grid container spacing={0} justifyContent='center'>
						<ErrorIcon fontSize='large' sx={{ color: 'red' }} />
					</Grid>
					<Grid container spacing={0} justifyContent='center'>
						<Typography variant='h5'>Algo salió mal</Typography>
					</Grid>
					<Grid container spacing={0} justifyContent='center'>
						<Typography variant='subtitle2'>
							Por favor vuelva a intentarlo más tarde
						</Typography>
					</Grid>
					<Grid container spacing={0} justifyContent='center'>
						<Button onClick={onClickHome} variant='text'>
							Refrescar el sitio
						</Button>
					</Grid>
				</Box>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
