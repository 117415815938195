import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TimelineEventosAereopuertoImpo from './TimelineEventosAereopuertoImpo';
import TimelineEventosDespachoAduanalImpo from './TimelineEventosDespachoAduanalImpo';
import TimelineEventosTerrestreImpo from './TimelineEventosTerrestreImpo';
import FlightIcon from '@mui/icons-material/Flight';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import TrafficIcon from '@mui/icons-material/Traffic';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useState } from 'react';

/*Descripción:Este componente se encarga de desplegar las opciones del Timeline de los eventos de Aereo Impo
Componente padre: EventosBookingAereoImpo
Componente hijo:TimelineEventosAereoImpo //
	TimelineEventosDespachoAduanalImpo //
	TimelineEventosTerrestreImpo
props: props.data = informacion a desplegar //
*/

export default function TimelineSelectorAereoImpo(props) {
	// Constante y funcion para la selección de las tabs
	const [value, setValue] = useState('1');
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleChange}
						aria-label='lab API tabs example'
						centered
					>
						<Tab
							icon={<FlightIcon />}
							value='1'
							sx={{ color: 'grey' }}
						/>

						{props.data['despacho-aduanal'] !== '' ||
							props.data['despacho-aduanal'] !==
								undefined(
									<Tab
										icon={<TrafficIcon />}
										value='2'
										sx={{ color: 'grey' }}
									/>
								)}
						{props.data.tn !== '' && (
							<Tab
								icon={<AirportShuttleIcon />}
								value='3'
								sx={{ color: 'grey' }}
							/>
						)}
					</TabList>
				</Box>
				<TabPanel
					value='1'
					sx={{ maxHeight: '340px', overflowY: 'auto' }}
				>
					<TimelineEventosAereopuertoImpo data={props.data} />
				</TabPanel>
				<TabPanel
					value='2'
					sx={{ maxHeight: '340px', overflowY: 'auto' }}
				>
					<TimelineEventosDespachoAduanalImpo
						data={props.data}
						dataPedimentos={props.data.pedimento}
						dataDespacho={props.data['despacho-aduanal']}
					/>
				</TabPanel>
				<TabPanel
					value='3'
					sx={{ maxHeight: '340px', overflowY: 'auto' }}
				>
					<TimelineEventosTerrestreImpo
						data={props.data}
						dataTerrestre={props.data.terrestre}
					/>
				</TabPanel>
			</TabContext>
		</Box>
	);
}
