import { Box } from '@mui/material';
import React from 'react';
import ErrorBoundary from '../../../../../cards/ErrorBoundary';
import { useState } from 'react';
import ServiciosAdicionalesTable from './ServiciosAdicionalesTable';
import { useEffect } from 'react';
import SinServiciosAdicionales from '../../../../../cards/SinServiciosAdicionales';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: BookingUSCAMEXExpoFTLSR
Componente hijo: ServiciosAdicionalesTable // SinServiciosAdicionales
props: props.selectedBooking = booking seleccionado //
	props.data = informacion a desplegar
*/

export default function InfoServiciosAdicionales(props) {
	// Se declaran los const para el uso de los servicios adicionales
	const [almacen, setAlmacen] = useState(false);
	const [cuentaAmericana, setCuentaAmericana] = useState(false);
	const [seguros, setSeguros] = useState(false);
	const [transfer, setTransfer] = useState(false);
	const [otrosProductos, setOtrosProductos] = useState(false);

	useEffect(() => {
		// Se verifica si el booking existe
		if (props.selectedBooking !== '') {
			// Se verifica si el booking tiene transfer
			if (props.data.transfer !== '') {
				// Se valida el tamaño del arreglo de los transfer
				if (props.data.transfer.length > 0) {
					// Se valida si el transfer tiene eventos
					if (
						props.data.transfer[0].eventos[0] !== undefined ||
						props.data.transfer[0].eventos.length > 0 ||
						props.data.transfer[0].eventos.length !== undefined
					) {
						// Se valida si el transfer tiene fecha de realización
						if (
							props.data.transfer[0].eventos
								.fechaRealizoServicio !== '' ||
							props.data.transfer[0].eventos
								.fechaRealizoServicio !== undefined
						) {
							// Se habilitan los datos del transfer
							setTransfer(true);
						}
					}
				}
			}
			// Se valida si el booking tiene almacen
			if (props.data.almacen !== '') {
				// Se valida el tamaño del arreglo de los almacenes
				if (props.data.almacen.length > 0) {
					// Se valida si el almacen tiene fecha de solicitud
					if (props.data.almacen[0].fechaSolicitudServicio !== '') {
						// Se habilitan los datos del almacen
						setAlmacen(true);
					}
				}
			}
			// Se valida si el booking tiene seguros
			if (props.data.seguros !== '') {
				// Se valida el tamaño del arreglo de los seguros
				if (props.data.seguros.length > 0) {
					// Se valida si el seguro tiene folio
					if (props.data.seguros[0].folio !== '') {
						// Se habilitan los datos del seguro
						setSeguros(true);
					}
				}
			}
			// Se valida si el booking tiene cuenta americana
			if (props.data.cuentaAmericana !== '') {
				// Se valida el tamaño del arreglo de las cuentas americanas
				if (props.data.cuentaAmericana.length > 0) {
					// Se valida si la cuenta americana tiene eventos
					if (props.data.cuentaAmericana.eventos.length > 0) {
						// Se valida si la cuenta americana tiene fecha de realización
						if (
							props.data.cuentaAmericana.eventos[0]
								.fechaRealizoServicio !== ''
						) {
							// Se habilitan los datos de la cuenta americana
							setCuentaAmericana(true);
						}
					}
				}
			}
			// Se valida si el booking tiene otros productos
			if (props.data.otrosProductos !== '') {
				// Se valida el tamaño del arreglo de los otros productos
				if (props.data.otrosProductos.length > 0) {
					// Se habilitan los datos de los otros productos
					setOtrosProductos(true);
				}
			}
		}
	}, [props.selectedBooking]);

	// Se valida que servicio adicional se va a renderizar
	const resultToRender = () => {
		// Se valida los servicios habilitados
		if (
			almacen ||
			cuentaAmericana ||
			seguros ||
			transfer ||
			otrosProductos
		) {
			// Si existen servicios habilitados los muestra
			return (
				<ServiciosAdicionalesTable
					data={props.data}
					almacen={almacen}
					cuentaAmericana={cuentaAmericana}
					seguros={seguros}
					transfer={transfer}
					otrosProductos={otrosProductos}
				/>
			);
		} else {
			// Si no existen servicios habilitados muestra el mensaje de que no existen servicios adicionales
			return <SinServiciosAdicionales />;
		}
	};

	return (
		<ErrorBoundary>
			<Box>{resultToRender()}</Box>
		</ErrorBoundary>
	);
}
