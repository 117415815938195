import {
	Box,
	Button,
	IconButton,
	Grid,
	Snackbar,
	TextField,
	InputAdornment,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../../components/get-data/GetDataFetch';
import DataGridProductos from '../../../components/catalogos/productos/DataGridProductos';
import MySkeletonPage from '../../../components/Skeletons/SkeletonPage';
import { PlaylistAdd, Search, ArrowBack } from '@mui/icons-material';
import AgregarProducto from '../../../components/catalogos/productos/Modales/AgregarProducto';
import MuiAlert from '@mui/material/Alert';
import { NavLink } from 'react-router-dom';
import BoxTitle from '../../../components/titles/BoxTitle';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente muestra el catálogo de Productos disponibles para el cliente.
Componente padre: CommonLayout.jsx
Componente hijo: DataGridProductos.jsx
props: props
*/

export default function ProductsLists() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [isChecked] = useState(false);
	const [alertMessage, setAlertMessage] = useState({
		severity: '',
		message: '',
	});
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;
	const [openModal, setOpenModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	// Se hace el Data Fetch
	const dataFetch = async () => {
		setLoading(true);
		let url = 'catalogos/productos-por-cliente/all';
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};
	// useEffect del Data Fetch
	useEffect(() => {
		dataFetch();
	}, []);

	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// Funcion para abrir y cerrar el Snackbar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};
	// Función para renderizar el contenido de la pantalla
	const viewToRender = () => {
		return (
			<DataGridProductos
				searchTerm={searchTerm}
				dataGrid={dataDetalles.productos}
				isChecked={isChecked}
				dataFetch={dataFetch}
				handleClick1={handleClick1}
				setAlertMessage={setAlertMessage}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
		);
	};

	return (
		<ErrorBoundary>
			<Box>
				<BoxTitle title={t('catalogos.productos.titulo')} />
				<Box
					sx={{
						width: '100%',
						bgcolor: 'background.secondary',
						borderRadius: 1,
						padding: 1,
					}}
				>
					<Grid item container marginTop={0}>
						<Grid item container>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								sx={{
									textAlign: 'left',
									paddingBottom: '0.5%',
								}}
							>
								<IconButton
									size='small'
									component={NavLink}
									to='/configuracion/catalogos'
									className='cerrarIconButton'
								>
									<ArrowBack />
								</IconButton>
							</Grid>
							<Grid
								item
								lg={6}
								md={6}
								sm={6}
								xs={6}
								sx={{ paddingTop: '8px', paddingBottom: '4px' }}
							>
								<TextField
									label={t('tablero.trackTrace.buscar')}
									value={searchTerm}
									onChange={(e) =>
										setSearchTerm(e.target.value)
									}
									autoComplete='off'
									variant='outlined'
									size='small'
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Search />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={6}
								sx={{ paddingTop: '8px', textAlign: 'right' }}
							>
								<Button
									variant='outlined'
									color='primary'
									id='BtnAgregarUsuario'
									sx={{
										fontSize: '12px',
										marginBottom: 1,
									}}
									onClick={handleOpenModal}
									startIcon={<PlaylistAdd />}
								>
									{t('botones.agregar')}
								</Button>
								<AgregarProducto
									isChecked={isChecked}
									dataFetch={dataFetch}
									handleClick1={handleClick1}
									setAlertMessage={setAlertMessage}
									handleCloseModal={handleCloseModal}
									openModal={openModal}
									setOpenModal={setOpenModal}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							{loading ? (
								<MySkeletonPage />
							) : (
								<>{viewToRender()}</>
							)}
						</Grid>
						<Snackbar
							open={snackbar1.open}
							autoHideDuration={10000}
							onClose={handleClose1}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert
								onClose={handleClose1}
								severity={alertMessage.severity}
							>
								{alertMessage.message}
							</Alert>
						</Snackbar>
					</Grid>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
