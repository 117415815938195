import { Edit } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../../../get-data/GetDataFetch';
import EditarDireccionesLModal from '../../Modales/Direcciones/EditarDireccionesModal';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el icono de eliminar en el data grid de las direcciones.
Componente padre: ListDirecciones.jsx
Componente hijo: EditarDireccionesModal.jsx
props: id: id del consignatario // idDireccion: id de la dirección //
dataCiudades: data de las ciudades // dataFetch: función para actualizar el data grid
*/

export default function EditIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las constantes para el funcionamiendo del modal
	const [dataDetalles, setDataDetalles] = useState({});
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [ciudad, setCiudad] = useState('');
	const [infoDireccion, setInfoDireccion] = useState({
		cod_persona: props.id,
		cod_direccion: '',
		calle: '',
		cod_ciudad: ciudad,
	});
	// Funcion para cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Se hace el fetch para obtener los datos ya existentes en la DB
	const dataFetch = async () => {
		const url =
			'consignatario/direcciones/direccion?id=' +
			props.id +
			'&idDireccion=' +
			props.idDireccion;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
		setInfoDireccion({
			cod_direccion: dataDetalles.cod_direccion,
			cod_ciudad: dataDetalles.cod_ciudad,
			calle: dataDetalles.calle,
			cod_persona: props.id,
		});
	};

	useEffect(() => {
		dataFetch();
	}, [props.idDireccion]);

	// Funcion para editar y actualizar los valores en el modal
	const handleTextFieldChange = (name) => (event) => {
		actualizarDato(name, event.target.value.toUpperCase());
	};
	// Funcion para actualizar los datos del modal
	function actualizarDato(name, value) {
		setInfoDireccion({
			...infoDireccion,
			[name]: value,
		});
	}

	useEffect(() => {
		setInfoDireccion({
			...infoDireccion,
			cod_ciudad: ciudad,
		});
	}, [ciudad]);

	// Función llamada al dar click al icono de editar
	const handleEditClick = async () => {
		await dataFetch();
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.editar')}>
						<IconButton
							id='editIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleEditClick}
						>
							<Edit color='primary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<EditarDireccionesLModal
				dataCiudades={props.dataCiudades}
				loading={loading}
				dataFetch={props.dataFetch}
				handleTextFieldChange={handleTextFieldChange}
				actualizarDato={actualizarDato}
				infoDireccion={infoDireccion}
				dataDetalles={dataDetalles}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
				ciudad={ciudad}
				setCiudad={setCiudad}
				setInfoDireccion={setInfoDireccion}
				setAlertMessagePage={props.setAlertMessagePage}
				handleClickPage={props.handleClickPage}
			/>
		</>
	);
}
