import React from 'react';
import { Box, Stack } from '@mui/material';
import LugarDeEntrega from '../../enviados/Cards/LugarDeEntrega';
import LugarDeRecoleccion from '../../enviados/Cards/LugarDeRecoleccion';
import CargasCartaPorte from '../../enviados/Cards/CargasCartaPorte';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Aereo Impo
Componente padre: CommonBooking
Componente hijo: MySnackBar //
	MyInfoStepper //
	InformacionCard
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición//
	props.getBookingsList = Funcion para obtener la lista de los bookings
*/

export default function ResumenCartaPorte(props) {
	return (
		<Box paddingTop={1}>
			<Stack direction='column' sx={{ justifyContent: 'space-between' }}>
				<Stack
					direction='row'
					sx={{ justifyContent: 'space-between' }}
					alignItems='center'
					spacing={1}
				>
					{/* Lugar de recoleccion */}
					<LugarDeRecoleccion dataDetalles={props.dataDetalles} />
					{/* Lugar de entrega */}
					<LugarDeEntrega dataDetalles={props.dataDetalles} />
				</Stack>
				{/* Cargas */}
				<CargasCartaPorte
					dataDetalles={props.dataDetalles}
					selectedBooking={props.selectedBooking}
					hideEdit={true}
				/>
			</Stack>
		</Box>
	);
}
