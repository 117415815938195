import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingInfo from './BookingInfo';
import { Grid, Box, Typography } from '@mui/material';
import GetDataFetch from '../get-data/GetDataFetch';
import MySkeletonList from '../Skeletons/SkeletonList';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información de la carga
Componente padre: BlResult
Componente hijo: BookingInfo
props: props.selectedBooking = booking seleccionado o ingreso
*/

export default function InformacionCard(props) {
	// Se crean las constantes para el uso del Card
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	const { t } = useTranslation();

	// fetch data
	const dataFetch = async () => {
		//Se comienza el loading del contenido
		setLoading(true);
		var url;
		if (props.selectedBooking.includes('MC')) {
			// Si el booking es MC se asigna el Endpoint lcl
			url =
				'bookings/maritimo/exportacion/lcl/detalles?booking=' +
				props.selectedBooking;
		} else {
			// Si el booking es MA se asigna el Endpoint fcl
			url =
				'bookings/maritimo/exportacion/fcl/detalles?booking=' +
				props.selectedBooking;
		}
		// Se hace el Get Fetch con los datos
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	// Se hace el llamado a la funcion para obtener los datos
	useEffect(() => {
		dataFetch();
	}, [props.selectedBooking]);

	return (
		<Grid container direction='column' spacing={0.5}>
			<Grid item container>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '5px',
						backgroundColor: 'informationCard.main',
					}}
				>
					<InfoIcon color='primary' style={{ marginLeft: '5px' }} />
					<Typography
						variant='body2'
						class='Datos-bookings-informacion'
						color='initial'
						style={{ margin: '10px' }}
					>
						{t('bl.informacion')}
					</Typography>
				</Box>
			</Grid>
			<Grid item container>
				<Card
					sx={{
						width: '100%',
						backgroundColor: 'informationCard.main',
					}}
					elevation={0}
				>
					<CardContent>
						{loading ? (
							<Grid container justifyContent='center'>
								<MySkeletonList />
							</Grid>
						) : (
							<BookingInfo data={dataDetalles} />
						)}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
