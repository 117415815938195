import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, Button, Grid, useMediaQuery } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: DeleteIconDataGrid
Componente hijo: Carga
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.actualizarDato = funcion para actualizar los datos del formulario //
	props.dataDetalles = informacion acerca de los detalles del booking //
	props.dataModalidadPago = informacion sobre la modalidad de pago //
	props.dataBulto = informacion sobre el tipo de bulto //
	props.handleNext = funcion para realizar el nextStepp //
	props.emptiesInputs = funcion para validar los campos vacios //
*/

export default function EliminarCargaBLModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '250';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 450,
		minWidth: minWidth,
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: 4,
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const navigate = useNavigate();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Se crean los parametros del metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const requestOptions = {
		method: 'PATCH',
		headers: myHeaders,
		redirect: 'follow',
	};
	// Funciones para el modal
	const handleSaveModal = () => {
		// Se hace el llamado al metodo Patch
		fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'bill-of-lading/carga/delete-carga?idCarga=' +
				props.id,
			requestOptions
		)
			.then((response) => {
				if (response.status === 200) {
					// Si es correcto se actualiza el contenido y se muestra el mensaje de exito
					props.setOpenModal(false);
					props.setAlertMessage({
						severity: 'success',
						message: t('alertas.cargaElimiada'),
					});
					props.handleClick1();
					setTimeout(() => {
						props.dataFetchBooking();
					}, 2000);
				} else if (response.status === 401) {
					// Si el token caduca se redirige al login
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					// Si ocurre un error se muestra el Alert correspondiente
					props.setAlertMessage({
						severity: 'error',
						message: t('alertas.cargaEliminadaError'),
					});
				}
			})
			.catch((error) => console.log('error', error));
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Grid item container rowSpacing={2}>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						{' '}
						<Grid item>
							<Typography variant='h5'>
								{t('modales.eliminarCarga')}
							</Typography>
						</Grid>
						<Grid item>
							<Delete
								aria-label='add an alarm'
								color='error'
								fontSize='large'
							/>
						</Grid>
					</Grid>

					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Typography variant='subtitle2'>
							{t('modales.eliminarCargaDescripcion')}
						</Typography>
					</Grid>
					<Grid item lg={6} md={6} sm={6} xs={6}>
						<Button
							id='cargaModalCerrarButton'
							variant='outlined'
							color='primary'
							onClick={props.handleCloseModal}
						>
							{t('botones.cerrar')}
						</Button>
					</Grid>

					<Grid
						item
						lg={6}
						md={6}
						sm={6}
						xs={6}
						sx={{
							textAlign: 'right',
						}}
					>
						<Button
							id='cargaModalEliminarButton'
							variant='outlined'
							color='secondary'
							onClick={handleSaveModal}
						>
							{t('botones.eliminar')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
