import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useState } from 'react';
import TimelineEventosAereoDespachoImpo from '../../Impo/Timeline/TimelineEventosAereoDespachoImpo';
import TimelineEventosAereoMaritimoDespachoImpo from '../../Impo/Timeline/TimelineEventosMaritimoDespachoImpo';
import TimelineEventosDespachoTerrestreExpo from './TimelineEventosDespachoTerrestreExpo';

/*Descripción:Este componente se encarga de desplegar las opciones de Timeline de Despacho Expo
Componente padre: EventosBookingDespachoExpo
Componente hijo:TimelineEventosAereoDespachoImpo //
	TimelineEventosAereoMaritimoDespachoImpo //
	TimelineEventosDespachoTerrestreExpo
props: props.data = informacion a desplegar //
	props.dataPedimentos = informacion a desplegar sobre los pedimentos
*/

export default function TimelineSelectorDespachoExpo(props) {
	// Se crean las constates del TimelineSelector
	const [value, setValue] = useState('1');
	var icon, timeline;
	// Funcion para seleccionar el tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// Valida el tipo de trafico
	if (props.data.trafico === 'Aéreo') {
		// Si es aereo, muestra el componente de aereo
		icon = <FlightIcon />;
		timeline = (
			<TimelineEventosAereoDespachoImpo
				dataPedimentos={props.dataPedimentos}
				data={props.data}
			/>
		);
	} else if (props.data.trafico === 'Marítimo') {
		// Si es maritimo, muetra el componenete de maritimo
		icon = <DirectionsBoatIcon />;
		timeline = (
			<TimelineEventosAereoMaritimoDespachoImpo
				dataPedimentos={props.dataPedimentos}
				data={props.data}
			/>
		);
	} else if (props.data.trafico === 'Terrestre') {
		// Si es terrestre, muestra el componente de terrestre
		icon = <LocalShippingIcon />;
		timeline = (
			<TimelineEventosDespachoTerrestreExpo
				dataPedimentos={props.dataPedimentos}
				data={props.data}
			/>
		);
	}

	return (
		<Box
			sx={{
				width: '100%',
				typography: 'body1',
			}}
		>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleChange}
						aria-label='lab API tabs example'
						centered
					>
						<Tab
							icon={icon}
							id='tabIconUno'
							value='1'
							sx={{ color: 'grey' }}
						/>
					</TabList>
				</Box>
				<TabPanel
					value='1'
					sx={{ maxHeight: '340px', overflowY: 'auto' }}
				>
					{timeline}
				</TabPanel>
			</TabContext>
		</Box>
	);
}
