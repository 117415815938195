import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar la información del despacho de Maritimo Expo
Componente padre: EventosBookingMaritimoExpo //
	EventosBookingMaritimoImpo
Componente hijo:
props: props.data = informacion a desplegar //
	props.dataDespacho = informacion a desplegar sobre el despacho //
	props.dataContenedor = informacion a desplegar sobre el contenedor
*/

export default function InfoDespacho(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid item container rowSpacing={1} lg={6} md={6} sm={6}>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.fechaEstimadaPuertoDescarga')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data['eta-pod']}
				</Typography>
			</Grid>
			{props.role.includes('Cliente') && (
				<>
					<Grid item lg={12} md={12} sm={12} xs={4}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.estatus.despacho')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDespacho['registro'] !== undefined
								? props.dataDespacho['registro']
								: 'Servicio no contratado con nosotros'}
						</Typography>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={4}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.estatus.terrestre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataContenedor.bookingTN}
						</Typography>
					</Grid>
					{props.dataDespacho['registro'] !== undefined && (
						<Grid item lg={12} md={12} sm={12} xs={4}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.estatus.pedimento')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.dataDespacho['numero-pedimento'] !==
									undefined &&
									props.dataDespacho['numero-pedimento']}
							</Typography>
						</Grid>
					)}
				</>
			)}
		</Grid>
	);
}
