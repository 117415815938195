import * as React from 'react';
import { Grid } from '@mui/material';
import BusquedaModal from './BusquedaModal';
import { useState, useEffect } from 'react';
import BookingMaritimoExpoTrackAndTrace from './BookingMaritimoExpoTrackAndTrace';
import BookingAereoExpoTrackAndTrace from './BookingAereoExpoTrackAndTrace';
import BookingDespachoExpoTrackAndTrace from './BookingDespachoExpoTrackAndTrace';
import BookingDespachoImpoTrackAndTrace from './BookingDespachoImpoTrackAndTrace';
import BookingAereoImpoTrackAndTrace from './BookingAereoImpoTrackAndTrace';
import BookingMaritimoImpoTrackAndTrace from './BookingMaritimoImpoTrackAndTrace';
import BookingTerrestreNacionalTrackAndTrace from './BookingTerrestreNacionalTrackAndTrace';
import BookingTerrestreCentroameTrackAndTrace from './BookingTerrestreCentroameTrackAndTrace';
import BookingUSCAMEXTrackAndTrace from './BookingUSCAMEXTrackAndTrace';

/*Descripción:Este componente se encarga de la logica del reconocimiento de la estructura del booking
Componente padre: TrackingCard //
	ActiveBookingDataGrid //
	InfoBookingTN
Componente hijo: BusquedaModal //
	BookingAereoExpoT&T //
	BookingAereoImpoT&T //
	BookingDespachoImpoT&T //
	BookingDespachoExpoT&T //
	BookingMaritimoImpoT&T //
	BookingMaritimoExpoT&T //
	BookingTerrestreNacionalT&T
props: props.palabra = el booking ingresado //
	props.letrasArray = Identificadores del tipo de booking
*/

export default function SwitchTrackingComponent(props) {
	//Se crea el estado para el componente que se va a mostrar
	const [trackingComponent, setTrackingComponent] = useState(<></>);
	const [bookingInvalid, setBookingInvalid] = useState(false);
	const [loadingButton, setLoadingButton] = useState(true);
	let tempArray = [];

	const handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			props.handleCloseModal();
		}
	};
	//Se hace el switch para validar el tipo de booking que se ingresó
	const handleOpenModal = () => {
		// Se valida el tamaño del arreglo del booking ingresado
		if (props.palabra.length >= 15) {
			// Se valida el tipo de booking
			if (props.palabra.length === 18) {
				// Si es un RO se obtienen el primer caracter, el tercero y el cuarto
				tempArray =
					props.letrasArray[0] +
					props.letrasArray[2] +
					props.letrasArray[3];
			} else if (props.palabra.length === 17) {
				// Si es un Booking "Normal" se obtienen el segundo y el tercer caracter
				tempArray = props.letrasArray[1] + props.letrasArray[2];
			} else if (props.palabra.length === 15) {
				// Si es un despacho se obtienen el segundo y el tercer caracter
				tempArray =
					props.letrasArray[0] +
					props.letrasArray[1] +
					props.letrasArray[2];
			}
			// Se valida el tipo de booking
			if (
				(props.letrasArray[3] === 'I' && props.palabra.length === 17) ||
				(props.letrasArray[0] === 'R' &&
					props.letrasArray[4] === 'I' &&
					props.palabra.length === 18) ||
				(props.letrasArray[3] === 'I' && props.palabra.length === 15)
			) {
				// Si es un booking de importación se valida el tipo de servicio
				switch (tempArray) {
					case 'NL':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/impo/ltl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'NF':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/impo/ftl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'RMC':
						console.log('RMC Impo');
						break;
					case 'RMA':
						console.log('RMA Impo');
						break;
					case 'MC':
						setTrackingComponent(
							<BookingMaritimoImpoTrackAndTrace
								endpoint='bookings/maritimo/importacion/lcl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'MA':
						setTrackingComponent(
							<BookingMaritimoImpoTrackAndTrace
								endpoint='bookings/maritimo/importacion/fcl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'AE':
						setTrackingComponent(
							<BookingAereoImpoTrackAndTrace
								endpoint='bookings/aereo/importacion'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'FT':
						console.log('FT Impo');
						break;
					case 'LT':
						setTrackingComponent(
							<BookingTerrestreCentroameTrackAndTrace
								endpoint='bookings/terrestre/centroamerica/exportacion/lcl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'PMO':
					case 'ALT':
					case 'ENS':
					case 'VER':
					case 'PRO':
					case 'LZC':
					case 'MAZ':
					case 'MZO':
						setTrackingComponent(
							<BookingDespachoImpoTrackAndTrace
								endpoint='bookings/aduanas/maritimo/importacion'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'RSA':
					case 'TJA':
					case 'NVL':
					case 'CHS':
					case 'PMX':
					case 'NGL':
					case 'LAR':
						setTrackingComponent(
							<BookingDespachoImpoTrackAndTrace
								endpoint='bookings/aduanas/aereo/importacion'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'QRO':
					case 'NLU':
					case 'AMY':
					case 'AGL':
					case 'AMX':
						setTrackingComponent(
							<BookingDespachoImpoTrackAndTrace
								endpoint='bookings/aduanas/aereo/importacion'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					default:
						console.log('Unknown Impo');
						break;
				}
			} else if (
				props.letrasArray[3] === 'E' &&
				props.palabra.length !== 18
			) {
				// Si es un booking de exportación se valida el tipo de servicio
				switch (tempArray) {
					case 'NL':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/expo/ltl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'NF':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/expo/ftl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'MC':
						setTrackingComponent(
							<BookingMaritimoExpoTrackAndTrace
								endpoint='bookings/maritimo/exportacion/lcl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'MA':
						setTrackingComponent(
							<BookingMaritimoExpoTrackAndTrace
								endpoint='bookings/maritimo/exportacion/fcl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'AE':
						setTrackingComponent(
							<BookingAereoExpoTrackAndTrace
								endpoint='bookings/aereo/exportacion'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'FT':
						setTrackingComponent(
							<BookingTerrestreCentroameTrackAndTrace
								endpoint='bookings/terrestre/centroamerica/exportacion/fcl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'LT':
						setTrackingComponent(
							<BookingTerrestreCentroameTrackAndTrace
								endpoint='bookings/terrestre/centroamerica/exportacion/lcl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'PMO':
					case 'ALT':
					case 'ENS':
					case 'VER':
					case 'PRO':
					case 'LZC':
					case 'MAZ':
					case 'MZO':
						setTrackingComponent(
							<BookingDespachoExpoTrackAndTrace
								endpoint='bookings/aduanas/maritimo/exportacion'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'RSA':
					case 'TJA':
					case 'NVL':
					case 'CHS':
					case 'PMX':
					case 'NGL':
					case 'LAR':
						setTrackingComponent(
							<BookingDespachoExpoTrackAndTrace
								endpoint='bookings/aduanas/aereo/exportacion'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'QRO':
					case 'NLU':
					case 'AMY':
					case 'AGL':
					case 'AMX':
						setTrackingComponent(
							<BookingDespachoExpoTrackAndTrace
								endpoint='bookings/aduanas/aereo/exportacion'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					default:
						console.log('Unknown  Expo');
						break;
				}
			} else if (
				props.letrasArray[3] === 'N' &&
				props.palabra.length === 17
			) {
				// Si es un booking de nacional se valida el tipo de servicio
				switch (tempArray) {
					case 'TN':
						setTrackingComponent(
							<BookingTerrestreNacionalTrackAndTrace
								endpoint='bookings/terrestre/nacional'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								setLoadingButton={setLoadingButton}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					default:
						console.log('Unknown');
						break;
				}
			} else if (
				props.palabra.length === 17 &&
				props.letrasArray[3] === 'D'
			) {
				// Si es un booking de Domestico se valida el tipo de servicio
				switch (tempArray) {
					case 'NL':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/domestico/ltl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'NF':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/domestico/ftl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					default:
						console.log('Unknown');
						break;
				}
			} else if (
				props.palabra.length === 17 &&
				props.letrasArray[3] === 'F'
			) {
				// Si es un Frontera-Mexico se valida el tipo de servicio
				switch (tempArray) {
					case 'NL':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/frontera-mexico/ltl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'NF':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/frontera-mexico/ftl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					default:
						console.log('Unknown');
						break;
				}
			} else if (
				props.palabra.length === 17 &&
				props.letrasArray[3] === 'M'
			) {
				// Si es un Mexico-Frontera se valida el tipo de servicio
				switch (tempArray) {
					case 'NL':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/mexico-frontera/ltl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					case 'NF':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/mexico-frontera/ftl'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					default:
						console.log('Unknown');
						break;
				}
			} else if (props.palabra.length === 17) {
				// Si es un booking de Intermodal se valida el tipo de servicio
				switch (tempArray) {
					case 'NI':
						setTrackingComponent(
							<BookingUSCAMEXTrackAndTrace
								endpoint='bookings/terrestre/uscamex/impo/intermodal'
								selectedBooking={props.palabra}
								setBookingInvalid={setBookingInvalid}
								bookingInvalid={bookingInvalid}
								// props del FileUpload
								openModalFiles={props.openModalFiles}
								handleCloseModalFiles={
									props.handleCloseModalFiles
								}
								dataDetallesDocumentos={
									props.dataDetallesDocumentos
								}
								infoDocumento={props.infoDocumento}
								actualizarDocumento={props.actualizarDocumento}
								documento={props.documento}
								indexDocumento={props.indexDocumento}
								setDocumento={props.setDocumento}
								setIndexDocumento={props.setIndexDocumento}
								setInfoDocumento={props.setInfoDocumento}
								state={props.state}
							/>
						);
						break;
					default:
						console.log('Unknown');
						break;
				}
			}
		}
	};
	// useEffect para seleccional el componente a renderizar y abrir el modal
	useEffect(() => {
		setTrackingComponent(<></>);
		handleOpenModal();
	}, [props.palabra]);

	return (
		<Grid container spacing={1}>
			<Grid item lg={3} md={3} sm={3}>
				<BusquedaModal
					selectedBooking={props.palabra}
					openModal={props.openModal}
					handleCloseModal={props.handleCloseModal}
					trackingComponent={trackingComponent}
					handleKeyDown={handleKeyDown}
					bookingInvalid={bookingInvalid}
					loadingButton={loadingButton}
					state={props.state}
				/>
			</Grid>
		</Grid>
	);
}
