import { Box, Grid, Hidden } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BlSearchResult from '../components/booking/maritimo/exportacion/BlSearchResult';
import BookingSearchBar from './booking/BookingSearchBar';
import BookingMaritimoExpoSearchResult from './booking/maritimo/exportacion/BookingMaritimoExpoSearchResult';
import BookingMaritimoImpoSearchResult from './booking/maritimo/importacion/BookingMaritimoImpoSearchResult';
import BookingAereoImpoSearchResult from './booking/aereo/Impo/BookingAereoImpoSearchResult';
import BookingAereoExpoSearchResult from './booking/aereo/Expo/BookingAereoExpoSearchResult';
import BookingTerrestreNacioSearchResult from './booking/terrestre/Nacional/BookingTerrestreNacioSearchResult';
import BookingDespachoImpoSearchResult from './booking/despacho/Impo/BookingDespachoImpoSearchResult';
import BookingDespachoExpoSearchResult from './booking/despacho/Expo/BookingDespachoExpoSearchResult';
import Fade from '@mui/material/Fade';
import SelectSearchBookings from '../components/booking/SelectSearchBookings';
import Cookies from 'universal-cookie';
import MySkeletonPage from './Skeletons/SkeletonPage';
import ContrataServicios from './ContrataServicios';
import BookingTerrestreCentroameExpoSearchResult from './booking/terrestre/Centroamerica/BookingTerrestreCentroameSearchResult';
import BookingUSCAMEXExpoFTLSearchResult from './booking/terrestre/USCAMEX/BookingUSCAMEXExpoFTLSearchResult';
import { useTranslation } from 'react-i18next';
import BlResultWithCatalog from '../views/Maritimo/Exportacion/Bl/BlResultWithCatalog';
import EnviadoCartaPorte from './carta-porte/enviados/EnviadoCartaPorte';
import PendienteCartaPorte from './carta-porte/pendiente/PendienteCartaPorte';

/*Descripción:Este componente se encarga  de la renderización de los bookings dependiendo las modalidades
Componente padre: IndexBL //
	Index AI //
	Index AE //
	IndexDI //
	IndexDE //
	IndexDT //
	IndexTN //
	IndexMI //
	IndexME //
	IndexEC //
	IndexFileUpload
Componente hijo: BookingMaritimoExpoSR //
	BookingMaritimoImpoSR //
	BookingAereoExpoSR //
	BookingAereoImpoSR //
	BookingTerrestreCentroameSR //
	BL //
	BookingTerrestreNacioSR //
	BookingDespachoImpoSR //
	BookingDespachoExpoSR //
	ContrataServicos //
	BookingSearchBar //
	SelectSearchBooking
props: Sin uso de props
*/

export default function CommonBooking({ resultComponent, endpoint }) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [loading, setLoading] = useState(true);
	const [checked, setChecked] = useState(false);
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	var [selectedBooking, setSelectedBooking] = useState('');
	const [bookingsList, setBookings] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState('0');
	const [lipActivo, setLipActivo] = useState();
	const navigate = useNavigate();

	// Se declara la función para obtener la lista de bookings
	const getBookingsList = async () => {
		setLoading(true);
		var myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Authorization', 'Bearer ' + token);

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};
		// Se hace la peticion al endpoint
		const responseBookingsList = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + endpoint,
			requestOptions
		);
		// Se valida la respuesta de la petición
		if (responseBookingsList.status === 200) {
			const dataBookingsList = await responseBookingsList.json();
			actualizarBooking(dataBookingsList['data']['bookings'][0]);
			setBookings(dataBookingsList.data.bookings);
			setChecked(true);
			setLoading(false);
		} else if (responseBookingsList.status === 500) {
			// Se valida que el usuario de Lip este activado
			setLipActivo(true);
			setLoading(false);
		} else if (responseBookingsList.status === 401) {
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		} else if (responseBookingsList.status === 504) {
			setLoading(false);
		}
	};
	// useEffect para declarar la función para actualizar el booking seleccionado
	useEffect(() => {
		getBookingsList();
	}, []);
	// Se declara la función para actualizar el booking seleccionado
	function actualizarBooking(booking, index) {
		setSelectedIndex(index);
		setSelectedBooking(booking);
	}
	// Funcion para indicar el tipo de booking obtenido
	const resultToRender = () => {
		switch (resultComponent) {
			case 'BookingMaritimoExpoSearchResult':
				return (
					<BookingMaritimoExpoSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'BookingMaritimoImpoSearchResult':
				return (
					<BookingMaritimoImpoSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'BlResultWithCatalog':
				return (
					<BlResultWithCatalog
						selectedBooking={selectedBooking}
						getBookingsList={getBookingsList}
					/>
				);
			case 'BlResultWithBl':
				return (
					<BlSearchResult
						selectedBooking={selectedBooking}
						getBookingsList={getBookingsList}
						endpointBL='bill-of-lading/bl/'
						endpointPreBL='bill-of-lading/pre-bl/'
					/>
				);
			case 'BookingAereoImpoSearchResult':
				return (
					<BookingAereoImpoSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'BookingAereoExpoSearchResult':
				return (
					<BookingAereoExpoSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'BookingTerrestreNacioSearchResult':
				return (
					<BookingTerrestreNacioSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'BookingDespachoImpoSearchResult':
				return (
					<BookingDespachoImpoSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'BookingDespachoExpoSearchResult':
				return (
					<BookingDespachoExpoSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'BookingTerrestreCentroameExpoSearchResult':
				return (
					<BookingTerrestreCentroameExpoSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'BookingUSCAMEXExpoFTLSearchResult':
				return (
					<BookingUSCAMEXExpoFTLSearchResult
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
					/>
				);
			case 'PendienteCartaPorte':
				return (
					<PendienteCartaPorte
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
						getBookingsList={getBookingsList}
					/>
				);
			case 'EnviadoCartaPorte':
				return (
					<EnviadoCartaPorte
						selectedBooking={selectedBooking}
						bookingsList={bookingsList}
						endpoint={endpoint}
						getBookingsList={getBookingsList}
					/>
				);
			default:
				return <h1>No component found</h1>;
		}
	};

	return (
		<>
			{loading ? (
				<MySkeletonPage />
			) : (
				<>
					{bookingsList.length === 0 || lipActivo === true ? (
						<Fade in={checked}>
							<Box>
								<ContrataServicios />
							</Box>
						</Fade>
					) : (
						<Fade in={checked}>
							<Box className='appContainer'>
								<Grid container>
									{/* Menu para version escritorio */}
									<Hidden mdDown>
										<Grid
											item
											md={3}
											lg={2.22}
											xl={1.92}
											sx={{
												backgroundColor:
													'background.secondary',
												borderRadius: '5px',
												marginRight: '0.6%',
												boxShadow:
													'0 4px 8px rgba(0, 0, 0, 0.1)',
											}}
										>
											<BookingSearchBar
												selectedIndex={selectedIndex}
												setSelectedIndex={
													setSelectedIndex
												}
												actualizarBooking={
													actualizarBooking
												}
												setBookings={setBookings}
												bookingsList={bookingsList}
												endpointToUse={endpoint}
											/>
										</Grid>
									</Hidden>
									{/* Menu para version movil */}
									<Hidden mdUp>
										<Grid // Sólo se muestra en móviles
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											sx={{
												backgroundColor:
													'background.secondary',
												borderRadius: '5px',
												padding: '1%',
												marginBottom: '1.5%',
											}}
											id='buscar_Booking'
										>
											<SelectSearchBookings
												selectedIndex={selectedIndex}
												actualizarBooking={
													actualizarBooking
												}
												bookingsList={bookingsList}
												customLabel={t(
													'booking.buscarBooking'
												)}
												id='cargaTipoBultos'
											/>
										</Grid>
									</Hidden>
									{/* Se renderiza el componente seleccionado */}
									<Grid
										item
										xs={12}
										sm={12}
										md={8.9}
										lg={9.7}
										xl={10}
									>
										<>{resultToRender()}</>
									</Grid>
								</Grid>
							</Box>
						</Fade>
					)}
				</>
			)}
		</>
	);
}
