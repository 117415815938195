import React from 'react';
import {
	Grid,
	Typography,
	Button,
	Card,
	CardContent,
	Stack,
} from '@mui/material';
import { useState } from 'react';
import SwitchTrackingComponent from '../../../../track-and-trace/SwitchTrackingComponent';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar la informacion de Terrestre Nacional del Booking Seleccionado
Componente padre: BookingTerrestreNacioSR
Componente hijo: SwitchTrackingComponent
props: props.data = informacion a desplegar
*/

export default function BookingInfo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean los const para el uso de la informacion del Booking
	const despachoRel = props.data.bookingRelacion;
	const [openModal, setOpenModal] = useState(false);
	const [letrasArray, setLetrasArray] = useState([]);
	const [palabra, setPalabra] = useState('');
	// Funciones para el modal
	const handleOpenModal = () => {
		if (despachoRel.length >= 15) {
			setOpenModal(true);
		}
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// Funcion para validar el tipo de booking relacionado
	function caracteresClave() {
		let letras = [];
		//Dependiendo del tamaño del array, se determinan los puntos relevantes para identificar el tipo de booking
		if (despachoRel.length === 18) {
			// Si es un RO se eligen los 3 primeros valores y el ultimo
			letras = [despachoRel.slice(0, 4), despachoRel.slice(-1)];
		} else if (despachoRel.length === 17) {
			// Si es un Booking "Normal" se eligen los primeros 2 valores y el ultimo
			letras = [despachoRel.slice(0, 3), despachoRel.slice(-1)];
		} else if (despachoRel.length === 15) {
			// Si es un despacho se eligen los primeros 2 valores y el ultimo
			letras = [despachoRel.slice(0, 3), despachoRel.slice(-1)];
		}
		// Se hace un array de los valores recopilados
		const letrasArray = letras.join('').split('');
		// Se asignan a la variable letrasArray
		setLetrasArray(letrasArray);
		// Se asigna el booking a la variable palabra
		setPalabra(despachoRel);
	}
	// Funcion para validar el tipo de booking y abrir el modal
	const handleClick = () => {
		caracteresClave();
		handleOpenModal();
	};

	return (
		<>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Grid
							item
							container
							spacing={1}
							lg={6}
							md={6}
							sm={6}
							xs={12}
						>
							<Grid item container>
								<Typography variant='subtitle1' color='initial'>
									{t('booking.detalles.embarcador')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.nombre')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.embarcador.nombre}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.direccion')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.embarcador.direccion}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.rfc')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.embarcador.rfc}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.correo')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.embarcador.email}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Grid
							item
							container
							spacing={0}
							lg={6}
							md={6}
							sm={6}
							xs={12}
						>
							<Grid item container>
								<Typography variant='subtitle1' color='initial'>
									{t('booking.detalles.informacionCarga')}
								</Typography>
							</Grid>

							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.producto')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.producto}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.peso')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.peso}{' '}
									{t('booking.detalles.ton')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.volumen')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.volumen}{' '}
									{t('booking.detalles.cbm')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.empaque')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.empaque}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.plataforma')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.informacionCarga.plataformas}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							container
							spacing={1}
							lg={6}
							md={6}
							sm={6}
							xs={12}
						>
							<Grid item container>
								<Typography variant='subtitle1' color='initial'>
									{t(
										'booking.detalles.serviciosRelacionados'
									)}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('tablero.bookingsActivos.booking')}
								</Typography>
								{props.data.bookingRelacion !== null &&
								props.data.bookingRelacion !== '' ? (
									<Button
										variant='text'
										onClick={() => handleClick()}
										className='padding-left-0'
									>
										{despachoRel}
									</Button>
								) : (
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{t(
											'booking.detalles.sinServiciosRelacionados'
										)}
									</Typography>
								)}
							</Grid>
						</Grid>

						{props.data.seguroMercancia !== undefined &&
						props.data.seguroMercancia !== '' &&
						props.data.seguroMercancia !== 'N' ? (
							<Grid
								item
								container
								spacing={1}
								lg={6}
								md={6}
								sm={6}
								xs={12}
							>
								<Grid item container>
									<Typography
										variant='subtitle1'
										color='initial'
									>
										{t('booking.detalles.seguroMercancia')}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.seguro')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.seguroMercancia}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.polizaSeguro')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.numeroPoliza}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.montoAsegurado')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.valorMercancia}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.requiereCustodia')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.requiereCustodia}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.tipoCustodia')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.tipoCustodia}
									</Typography>
								</Grid>
							</Grid>
						) : (
							<Grid
								item
								container
								spacing={1}
								lg={4}
								md={4}
								sm={4}
								xs={12}
							>
								<Grid item container>
									<Typography
										variant='subtitle1'
										color='initial'
									>
										{props.data.seguroMercancia}
									</Typography>
								</Grid>
								<Grid item container>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.detalles.sinSeguro')}
									</Typography>
								</Grid>
							</Grid>
						)}
					</CardContent>
				</Card>
			</Stack>

			<SwitchTrackingComponent
				palabra={palabra}
				openModal={openModal}
				handleCloseModal={handleCloseModal}
				letrasArray={letrasArray}
			/>
		</>
	);
}
