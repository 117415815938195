import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useState } from 'react';
import TimelineEventosUSCAMEX from './TimelineEventosUSCAMEX';

/*Descripción:Este componente se encarga de desplegar las opciones del Timeline de TN
Componente padre: EventosBookingUSCAMEX
Componente hijo: TimelineEventosUSCAMEX
props: props.dataCarga = informacion a desplegar sobre la carga
*/

export default function TimelineSelectorDetallesCarga(props) {
	// Se declara el cosnt para el uso de las Tabs
	const [value, setValue] = useState('1');
	// Funcion para renderizar el contenido de las Tabs
	function MyTabs() {
		return (
			<TabContext value={value} id='maritimoImpoTabContext'>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleChange}
						aria-label='lab API tabs example'
						centered
					>
						<Tab
							icon={<LocalShippingIcon />}
							value='1'
							sx={{ color: 'grey' }}
						/>
					</TabList>
				</Box>
				<TabPanel
					value='1'
					sx={{ maxHeight: '46vh', overflowY: 'auto' }}
				>
					<TimelineEventosUSCAMEX dataCarga={props.dataCarga} />
				</TabPanel>
			</TabContext>
		);
	}
	// Funcion para cambiar el valor de las Tabs
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<MyTabs />
		</Box>
	);
}
