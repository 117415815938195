import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar los eventos de Despacho Aduanal relacionados a Aereo Expo
Componente padre: TimelineSelectorAereoExpo
Componente hijo: TimelineItem
props: props.data = informacion a desplegar //
	props.dataPedimentos = informacion a desplegar sobre los pedimentos
*/

export default function TimelineEventosDespachoAduanalExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Timeline position='alternate'>
			{props.data['fecha-ingreso-terminal'] === 'N.D.' ||
			props.data['fecha-ingreso-terminal'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-ingreso-terminal']}
					title={t('eventos.ingresoTerminal')}
				/>
			)}
			{props.role.includes('Cliente') && (
				<>
					{props.dataPedimentos['fecha-validacion-pedimento'] ===
						'N.D.' ||
					props.dataPedimentos['fecha-validacion-pedimento'] ===
						undefined ? (
						<></>
					) : (
						<TimelineItem
							data={
								props.dataPedimentos[
									'fecha-validacion-pedimento'
								]
							}
							title={t('eventos.validacionPedimento')}
						/>
					)}
					{props.dataDespacho['fecha-modulacion'] === 'N.D.' ||
					props.dataDespacho['fecha-modulacion'] === undefined ? (
						<></>
					) : (
						<TimelineItem
							data={props.dataDespacho['fecha-modulacion']}
							title={t('eventos.modulacion')}
						/>
					)}
				</>
			)}
		</Timeline>
	);
}
