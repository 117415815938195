import React, { useState } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import EditIconDataGrid from './ActionIcons/EditDireccionIcon';
import DeleteIconDataGrid from './ActionIcons/DeleteDireccionIcon';
import {
	Box,
	Button,
	Grid,
	InputAdornment,
	TextField,
	Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddLocationAlt, Search } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: ListadoUsuarios
Componente hijo:
props: props.dataGrid = información a desplegar del DataGrid //
	props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert //
	rops.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.handelCloseModal = funcion para cuando cierre el modal
*/

export default function DataGridMisDirecciones(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crea la funcion para editar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		actualizarDato(name, event.target.value.toUpperCase());
	};

	// Se crea la funcion para actualizar el valor de la direccion
	function actualizarDato(name, value) {
		props.setInfoDireccion({
			...props.setInfoDireccion,
			[name]: value,
		});
	}

	const tableData = [];
	// Se asignan los datos a las columnas correspondientes de la fila
	for (let i = 0; i < props.dataGrid.length; i++) {
		const direccion = props.dataGrid[i];
		const rowData = {
			id: i + 1,
			vAlias: direccion.vAlias,
			vNombreLugarRecolecionEntrega:
				direccion.vNombreLugarRecolecionEntrega,
			vRfc: direccion.vRfc,
			vCalle: direccion.vCalle,
			nCodigoPostal: direccion.nCodigoPostal,
			vColonia: direccion.vColonia,
			vCiudad: direccion.vCiudad,
			vEstado: direccion.vEstado,
			uiCodLugarRecolecionEntrega: direccion.uiCodLugarRecolecionEntrega,
		};
		// Se asigna la fila de datos
		tableData.push(rowData);
	}

	// Se asignan las columnas del DataGrid
	const headCells = [
		{
			id: 'vAlias',
			field: 'vAlias',
			width: 180,
			headerName: t('dataGrid.alias'),
			editable: false,
		},
		{
			id: 'vRfc',
			field: 'vRfc',
			headerName: t('dataGrid.rfc'),
			width: 120,
			editable: false,
		},
		{
			id: 'vCalle',
			field: 'vCalle',
			headerName: t('dataGrid.calle'),
			width: 200,
			editable: false,
		},
		{
			id: 'vCiudad',
			field: 'vCiudad',
			headerName: t('dataGrid.ciudad'),
			width: 150,
			editable: false,
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 140,
			disableClickEventBubbling: true,
			// Se renderizan los botones especiales
			renderCell: (params) => {
				return (
					<>
						<EditIconDataGrid
							dataFetch={props.dataFetch}
							handleClickPage={props.handleClickPage}
							setAlertMessagePage={props.setAlertMessagePage}
							handleCloseModal={props.handleCloseModal}
							openModal={props.openModal}
							setOpenModal={props.setOpenModal}
							id={params.row.uiCodLugarRecolecionEntrega}
							handleTextFieldChange={handleTextFieldChange}
							actualizarDato={actualizarDato}
							setInfoDireccion={props.setInfoDireccion}
							infoDireccion={props.infoDireccion}
						/>
						<DeleteIconDataGrid
							handleCheck={props.handleCheck}
							dataFetch={props.dataFetch}
							handleClickPage={props.handleClickPage}
							setAlertMessagePage={props.setAlertMessagePage}
							handleCloseModal={props.handleCloseModal}
							openModal={props.openModal}
							setOpenModal={props.setOpenModal}
							id={params.row.uiCodLugarRecolecionEntrega}
						/>
					</>
				);
			},
		},
	];

	// Funciones para la busqueda del DataGrid
	const [searchTerm, setSearchTerm] = useState('');

	const filterRows = () => {
		return tableData.filter((row) =>
			headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(searchTerm.toLowerCase());
			})
		);
	};

	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<>
			<Grid item container sx={{ paddingTop: '1%' }}>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<TextField
						label={t('tablero.trackTrace.buscar')}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						autoComplete='off'
						variant='outlined'
						size='small'
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Search />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={6}
					sx={{ textAlign: 'right', justifyContent: 'flex-end' }}
				>
					<Stack
						direction='row'
						spacing={1}
						justifyContent='flex-end'
					>
						<Button
							variant='outlined'
							color='primary'
							id='BtnAgregarUsuario'
							sx={{
								fontSize: '12px',

								marginBottom: 1,
							}}
							onClick={props.setOpenModalCargaTemplate}
							startIcon={<FileUploadIcon />}
						>
							{t('botones.subirPlantilla')}
						</Button>
						<Button
							variant='outlined'
							color='primary'
							id='BtnAgregarUsuario'
							sx={{
								fontSize: '12px',

								marginBottom: 1,
							}}
							onClick={props.handleOpenModal}
							startIcon={<AddLocationAlt />}
						>
							{t('botones.agregar')}
						</Button>
					</Stack>
				</Grid>
			</Grid>
			<Box
				sx={{
					height: '60vh',
					paddingTop: '7px',
					paddingBottom: '7px',
					width: '100%',
				}}
			>
				<DataGrid
					localeText={localeText()}
					rows={filterRows()}
					columns={headCells}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10, 20, 30]}
					sx={{
						'& .MuiDataGrid-cell': {
							fontSize: '12px',
							fontFamily: 'Source Sans Pro',
						},
						'& .MuiDataGrid-columnHeaderTitle': {
							fontSize: '13px',
							fontFamily: 'Open Sans',
						},
					}}
				/>
			</Box>
		</>
	);
}
