import React from 'react';
import { Box, Typography } from '@mui/material';

export default function BoxTitle(props) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				bgcolor: 'background.secondary',
				paddingLeft: 1,
				borderRadius: 1,
				paddingTop: 1,
				paddingBottom: 2,
				marginBottom: 1,
			}}
		>
			<Typography variant='h4'>{props.title}</Typography>
		</Box>
	);
}
