import { Typography } from '@mui/material';
import TableOtrosServicios from '../Tables/TableOtrosServicios';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información de otros productos relacionados
Componente padre: ServiciosAdicionalesTable
Componente hijo:
props: props.data = informacion a desplegar //
	props.otrosProductos = booleano que indica si se tiene otros productos contratados
*/

export default function AcordionOtrosServicios(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const resultToRender = () => {
		if (props.otrosProductos) {
			return <TableOtrosServicios data={props.data} />;
		} else {
			return (
				<Typography>{t('eventos.sinServicioContratado')}</Typography>
			);
		}
	};
	return resultToRender();
}
