import { Download } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el icono de eliminar en el DataGrid de los contactos.
Componente padre: ListContactos.jsx
Componente hijo: EliminarContactosModal.jsx
props: dataFetch: Función para obtener los datos de la API // id: id del consignatario //
idContacto: id del contacto // handleCloseModal: Función para cerrar el modal //
openModal: Variable para abrir el modal // setOpenModal: Función para abrir el modal //
*/

export default function ViewIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	const checkDocument = async (url) => {
		const response = await fetch(url);
		if (response.status === 200) {
			return true;
		} else {
			return false;
		}
	};

	// Funcion para descargar el pdf
	const downloadDocument = (fileName, url) => {
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	// Función llamada al dar click al icono de eliminar
	const handleDeleteClick = async () => {
		if (await checkDocument(props.vUrlDocumento)) {
			downloadDocument(props.vNombreDocumento, props.vUrlDocumento);
			props.setAlertMessage({
				type: 'success',
				message: t('alertas.documentoDescargado'),
			});
			props.handleClick1();
		} else {
			props.setAlertMessage({
				severity: 'error',
				message: t('alertas.documentoDescargadoError'),
			});
			props.handleClick1();
		}
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('modales.descargar')}>
						<IconButton
							id='deleteIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleDeleteClick}
						>
							<Download color='primary' />
						</IconButton>
					</Tooltip>
				}
			/>
		</>
	);
}
