import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, Button, Grid, useMediaQuery } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

/*Descripción:Este componente se encarga de mostrar el modal de eliminar producto
Componente padre: DeleteIconDatasGrid
Componente hijo:
props:props.openModal = funcion para abrir el modal //
	props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert //
	props.dataFetch = props para actualizar la información mostrada //
*/

export default function EliminarProductoModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '250';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 450,
		minWidth: minWidth,
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: 4,
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const navigate = useNavigate();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const requestOptions = {
		method: 'PATCH',
		headers: myHeaders,
		redirect: 'follow',
	};

	// Se crea la funcion para desactivar el usuario
	const handleSaveModal = () => {
		// Se hace la peticion al endpoint
		fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'catalogos/productos-por-cliente/delete?id=' +
				props.id,
			requestOptions
		)
			.then((response) => {
				// Se valida el status de la peticion
				if (response.status === 200) {
					// Se cierra el modal
					props.setOpenModal(false);
					// Se muestra el mensaje de exito
					props.setAlertMessage({
						severity: 'success',
						message: t('catalogos.productos.productoDesactivado'),
					});
					// Se abre el Alert
					props.handleClick1();
					// Se actualiza la informacion mostrada
					props.dataFetch();
				} else if (response.status === 401) {
					// Si el token caduca se redirige al login
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					// Se muestra el mensaje de error
					props.setAlertMessage({
						severity: 'error',
						message: t(
							'catalogos.productos.productoDesactivadoError'
						),
					});
					// Se abre el Alert
					props.handleClick1();
					// Se actualiza la informacion mostrada
					props.dataFetch();
				}
			})
			.catch((error) => console.log('error', error));
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Grid item container rowSpacing={2}>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							<Typography variant='h5'>
								{t('catalogos.productos.eliminarProducto')}
							</Typography>
						</Grid>
						<Grid item>
							<Delete
								aria-label='add an alarm'
								color='error'
								fontSize='large'
							/>
						</Grid>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Typography variant='subtitle2'>
							{t(
								'catalogos.productos.eliminarProductoDescripcion'
							)}
						</Typography>
					</Grid>
					<Grid item lg={6} md={6} sm={6} xs={6}>
						<Button
							id='cargaModalCerrarButton'
							variant='outlined'
							color='primary'
							onClick={props.handleCloseModal}
						>
							{t('botones.cancelar')}
						</Button>
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						sm={6}
						xs={6}
						sx={{
							textAlign: 'right',
						}}
					>
						<Button
							id='cargaModalEliminarButton'
							variant='outlined'
							color='secondary'
							onClick={handleSaveModal}
						>
							{t('catalogos.productos.eliminar')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
