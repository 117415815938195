import { Box, Button, Grid, Snackbar, Tooltip } from '@mui/material';
import React from 'react';
import { useState, useEffect } from 'react';
import { PlaylistAdd } from '@mui/icons-material';
import AgregarCargaBLCatalogoModal from '../../Cargas/Modales/AgregarCargaBLCatalogoModal';
import AgregarCargaBLModal from '../../Cargas/Modales/AgregarCargaBLModal';
import AgregarCargaExcelTemplate from '../../Cargas/Modales/AgregarCargaExcelTemplate';
import AgregarCargaExcelAvantor from '../../Cargas/Modales/AgregarCargaExcelAvantor';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DataGridCargasBL from '../DataGridCargasBL';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import GetDataIdClienteToken from '../../../get-data/GetDataIdClienteToken';
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: CommonLayout.jsx //
Componente hijo: FileUploadModal
props: props.selectedBooking = booking seleccionado
*/

export default function ListadoCargas(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las constantes para el funcionamiento del Alert
	const [alertMessage, setAlertMessage] = useState({
		severity: '',
		message: '',
	});
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;

	// Se obtiene el id del cliente
	const [cliente, setCliente] = useState('');

	useEffect(() => {
		GetDataIdClienteToken(setCliente);
	}, []);

	// Se crean las funciones del modal
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Se crean las funciones del modal con catalogo
	const [openModalCatalogo, setOpenModalCatalogo] = useState(false);
	const handleOpenModalCatalogo = () => {
		setOpenModalCatalogo(true);
	};
	const handleCloseModalCatalogo = () => {
		setOpenModalCatalogo(false);
	};

	//Se crea funcion para abrir modal de carga con template
	const [openModalCargaTemplate, setOpenModalCargaTemplate] = useState(false);
	const handleOpenModalCargaTemplate = () => {
		if (cliente === '20458') {
			setOpenModalCargaTemplateAvantor(true);
		} else {
			setOpenModalCargaTemplate(true);
		}
	};
	const handleCloseModalCargaTemplate = () => {
		setOpenModalCargaTemplate(false);
	};

	//Se crea funcion para abrir modal de carga con template avantor
	const [openModalCargaTemplateAvantor, setOpenModalCargaTemplateAvantor] =
		useState(false);

	const handleCloseModalCargaTemplateAvantor = () => {
		setOpenModalCargaTemplateAvantor(false);
	};

	// Evento click botón Guardar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};

	// funcion para cerrar el Alert
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	const viewToRender = () => {
		return (
			<DataGridCargasBL
				handleClick1={handleClick1}
				setAlertMessage={setAlertMessage}
				dataFetchBooking={props.dataFetchBooking}
				dataGrid={props.data.cargas}
				selectedBooking={props.data.booking}
				data={props.data}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
				dataModalidadPago={props.dataModalidadPago}
				dataBulto={props.dataBulto}
				dataAllProducts={props.dataAllProducts}
				handleNext={props.handleNext}
				emptiesInputs={props.emptiesInputs}
			/>
		);
	};

	useEffect(() => {
		viewToRender();
	}, [props.data.cargas]);

	return (
		<Box>
			<Grid item container marginTop={1}>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ textAlign: 'right' }}
				>
					<Grid
						item
						container
						spacing={2}
						justifyContent={'flex-end'}
					>
						<Grid item>
							<Tooltip title={t('botones.agregarConTemplate')}>
								<Button
									variant='outlined'
									color='primary'
									id='BtnAgregarCargaCatalogoBL'
									sx={{
										fontSize: '12px',

										marginBottom: 1,
									}}
									onClick={handleOpenModalCatalogo}
								>
									<NoteAddIcon />
								</Button>
							</Tooltip>
							<AgregarCargaBLCatalogoModal
								handleClick1={handleClick1}
								setAlertMessage={setAlertMessage}
								dataFetchBooking={props.dataFetchBooking}
								dataGrid={props.data.cargas}
								selectedBooking={props.data.booking}
								data={props.data}
								handleCloseModal={handleCloseModalCatalogo}
								openModal={openModalCatalogo}
								setOpenModal={setOpenModalCatalogo}
								dataModalidadPago={props.dataModalidadPago}
								dataBulto={props.dataBulto}
								dataAllProducts={props.dataAllProducts}
								dataAllDetails={props.dataAllDetails}
								handleNext={props.handleNext}
								emptiesInputs={props.emptiesInputs}
								actualizarDatoProducto={
									props.actualizarDatoProducto
								}
								enviarDatosFormulario={
									props.enviarDatosFormulario
								}
							/>
						</Grid>
						<Grid item>
							<Tooltip title={t('botones.agregar')}>
								<Button
									variant='outlined'
									color='primary'
									id='BtnAgregarCargaBL'
									sx={{
										fontSize: '12px',
										marginBottom: 1,
									}}
									onClick={handleOpenModal}
								>
									<PlaylistAdd />
								</Button>
							</Tooltip>
							<AgregarCargaBLModal
								handleClick1={handleClick1}
								setAlertMessage={setAlertMessage}
								dataFetchBooking={props.dataFetchBooking}
								dataGrid={props.data.cargas}
								selectedBooking={props.data.booking}
								data={props.data}
								handleCloseModal={handleCloseModal}
								openModal={openModal}
								setOpenModal={setOpenModal}
								dataModalidadPago={props.dataModalidadPago}
								dataBulto={props.dataBulto}
								dataAllProducts={props.dataAllProducts}
								dataAllDetails={props.dataAllDetails}
								handleNext={props.handleNext}
								emptiesInputs={props.emptiesInputs}
								actualizarDatoProducto={
									props.actualizarDatoProducto
								}
								enviarDatosFormulario={
									props.enviarDatosFormulario
								}
							/>
						</Grid>
						<Grid item>
							{cliente === '20458' && (
								<>
									<Tooltip
										title={t('botones.subirPlantilla')}
									>
										<Button
											variant='outlined'
											color='primary'
											id='BtnAgregarCargaExcelBL'
											sx={{
												fontSize: '12px',
												marginBottom: 1,
											}}
											onClick={
												handleOpenModalCargaTemplate
											}
										>
											<FileUploadIcon />
										</Button>
									</Tooltip>
									<AgregarCargaExcelTemplate
										handleClick1={handleClick1}
										setAlertMessage={setAlertMessage}
										enviarDatosFormulario={
											props.enviarDatosFormulario
										}
										dataGrid={props.data.cargas}
										selectedBooking={props.data.booking}
										data={props.data}
										handleCloseModal={
											handleCloseModalCargaTemplate
										}
										openModal={openModalCargaTemplate}
										setOpenModal={setOpenModalCargaTemplate}
									/>
									<AgregarCargaExcelAvantor
										handleClick1={handleClick1}
										setAlertMessage={setAlertMessage}
										enviarDatosFormulario={
											props.enviarDatosFormulario
										}
										dataGrid={props.data.cargas}
										selectedBooking={props.data.booking}
										data={props.data}
										handleCloseModal={
											handleCloseModalCargaTemplateAvantor
										}
										openModal={
											openModalCargaTemplateAvantor
										}
										setOpenModal={
											setOpenModalCargaTemplateAvantor
										}
									/>
								</>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					{viewToRender()}
				</Grid>
				<Snackbar
					open={snackbar1.open}
					autoHideDuration={10000}
					onClose={handleClose1}
					anchorOrigin={{ vertical, horizontal }}
				>
					<Alert
						onClose={handleClose1}
						severity={alertMessage.severity}
					>
						{alertMessage.message}
					</Alert>
				</Snackbar>
			</Grid>
		</Box>
	);
}
