import React from 'react';
import { Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion del despacho de Maritimo Expo
Componente padre: EventosBookingMaritimoExpo
Componente hijo:
props: props.data = informacion a desplegar //
	props.dataDespacho = informacion a desplegar sobre el despacho //
*/

export default function InfoDespachoMaritimoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid item container rowSpacing={1} lg={6} md={6} sm={6}>
			{props.role.includes('Cliente') && (
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.terrestre')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data.bookingTN}
					</Typography>
				</Grid>
			)}
			{props.role.includes('Cliente') && (
				<>
					<Grid item lg={12} md={12} sm={12} xs={4}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.estatus.despacho')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDespacho['registro'] !== undefined
								? props.dataDespacho['registro']
								: 'Servicio no contratado con nosotros'}
						</Typography>
					</Grid>
					{props.dataDespacho['registro'] !== undefined && (
						<Grid item lg={12} md={12} sm={12} xs={4}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.estatus.pedimento')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.dataDespacho['numero-pedimento'] !==
									undefined &&
									props.dataDespacho['numero-pedimento']}
							</Typography>
						</Grid>
					)}
				</>
			)}
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.descripcionContenedor')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataContenedor.descripcion}
				</Typography>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.selloDeContenedor')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataContenedor.sello}
				</Typography>
			</Grid>
			{props.dataDespacho['fecha-reconocimiento'] !== '' &&
				props.dataDespacho['fecha-reconocimiento'] !== undefined && (
					<Grid item lg={12} md={12} sm={12} xs={4}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('eventos.resultadoReconocimiento')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDespacho['resultado-reconocimiento'] !==
							'' ? (
								<>
									{props.dataDespacho[
										'resultado-reconocimiento'
									] === 'V' ? (
										<CircleIcon color='success' />
									) : (
										<>
											{props.dataDespacho[
												'resultado-reconocimiento'
											] === 'R' && (
												<CircleIcon
													sx={{ color: 'red' }}
												/>
											)}{' '}
										</>
									)}
								</>
							) : (
								<></>
							)}
						</Typography>
					</Grid>
				)}
			<Grid item lg={12} md={12} sm={12} xs={4}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.incoterm')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.incoterm}
				</Typography>
			</Grid>
		</Grid>
	);
}
