import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, Button, Grid } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de mostrar el modal para eliminar un consignatario.
Componente padre: ConsignatarioInfoCard.jsx
Componente hijo: Sin hijos
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.id = id del consignatario //
	props.dataFetch = funcion para obtener los datos de la API //
	props.setOpenModal = funcion para abrir el modal //
	props.handleBackButtonClick = funcion para volver a la pagina anterior //
*/

// Se crea el estilo del modal
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 450,
	minWidth: 250,
	minHeight: 170,
	maxHeight: '95%',
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: '2vw',
	borderRadius: '10px',
};

export default function EliminarDocumentoModal(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const navigate = useNavigate();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Se crean los parametros del metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	var raw = JSON.stringify({
		cod_documento: props.idDocumento,
	});
	var requestOptions = {
		method: 'PATCH',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	// Funciones para el modal
	const handleSaveModal = () => {
		// Se hace el llamado al metodo Patch
		fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'booking/documentos/delete',
			requestOptions
		)
			.then((response) => {
				if (response.status === 200) {
					// Si es correcto se actualiza el contenido y se muestra el mensaje de exito
					props.setAlertMessage({
						type: 'success',
						message: t('alertas.documentoEliminado'),
					});
					props.handleClick1();
					props.setOpenModal(false);
					props.dataFetchDocumentosAgregados();
				} else if (response.status === 401) {
					// Si el token caduca se redirige al login
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					props.setAlertMessage({
						severity: 'error',
						message: t('alertas.documentoEliminadoError'),
					});
					props.handleClick1();
				}
			})
			.catch((error) => console.log('error', error));
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Grid item container rowSpacing={2}>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							<Typography variant='h5'>
								{t('modales.eliminarDocumento')}
							</Typography>
						</Grid>
						<Grid item>
							<Delete
								aria-label='add an alarm'
								color='error'
								fontSize='large'
							/>
						</Grid>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Typography variant='subtitle2'>
							{t('modales.eliminarDocumentoDescripcion')}
						</Typography>
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						sm={6}
						xs={6}
						sx={{ textAlign: 'left' }}
					>
						<Button
							id='cargaModalCerrarButton'
							variant='outlined'
							color='primary'
							onClick={props.handleCloseModal}
						>
							{t('botones.cerrar')}
						</Button>
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						sm={6}
						xs={6}
						sx={{ textAlign: 'right' }}
					>
						<Button
							id='cargaModalEliminarButton'
							variant='outlined'
							color='secondary'
							onClick={handleSaveModal}
						>
							{t('botones.eliminar')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
