import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos de contenedor
Componente padre: TimelineSelector
Componente hijo: TimelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosContenedor(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			{props.data['fecha-llegada-planta-cliente'] === 'N.D.' ||
			props.data['fecha-llegada-planta-cliente'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-llegada-planta-cliente']}
					title={t('eventos.llegadaPlanta')}
				/>
			)}
			{props.data['fecha-salida-planta-cliente'] === 'N.D.' ||
			props.data['fecha-salida-planta-cliente'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-salida-planta-cliente']}
					title={t('eventos.salidaPlanta')}
				/>
			)}
			{props.data['fecha-llegada-puerto'] === 'N.D.' ||
			props.data['fecha-llegada-puerto'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-llegada-puerto']}
					title={t('eventos.llegadaPuerto')}
				/>
			)}
		</Timeline>
	);
}
