import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	IconButton,
	Tooltip,
} from '@mui/material';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DatosUsuarioForm from './DatosUsuarioForm';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: ListadoUsuarios
Componente hijo: DatosUsuarioForm
props: props.openModal = funcion para abrir el modal //
	props.handelCloseModal = funcion para cuando cierre el modal //
	props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert
*/

// Se crean los estilos del modal
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: '25%',
	maxWidth: '50%',
	maxHeight: '95%',
	minHeight: '53vh',
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: '1.5vw',
	overflow: 'auto',
	borderRadius: '10px',
};

export default function AgregarUsuario(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const navigate = useNavigate();
	const [isInvalid, setIsInvalid] = useState(true);
	const token = cookies.get('jwt_authorization');
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});
	const { vertical, horizontal } = state;
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const [infoUsuario, setInfoUsuario] = useState({
		name: '',
		lastname: '',
		username: '',
		phone: '',
		enabled: 1,
		role: '',
	});
	useEffect(() => {
		if (props.newRole === '') {
			if (props.role === 'Cliente-admin') {
				setInfoUsuario({
					...infoUsuario,
					role: 'ROLE_CLIENTE_DIRECTO',
				});
			} else if (props.role === 'Agente-admin') {
				setInfoUsuario({
					...infoUsuario,
					role: 'ROLE_AGENTE_EXTRANJERO',
				});
			}
		} else {
			setInfoUsuario({ ...infoUsuario, role: props.newRole });
		}
	}, [props, props.role, props.newRole]);
	const [loadingButton, setLoadingButton] = useState(false);
	// Funcion para actualizar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		if (name === 'phone') {
			const value = event.target.value
				.replace(/\s/g, '')
				.replace(/[^\d]/g, '');
			actualizarDato(name, value);
		} else {
			actualizarDato(name, event.target.value);
		}
	};
	// Funcion para actualizar el estado de los datos del usuario
	function actualizarDato(name, value) {
		setInfoUsuario({
			...infoUsuario,
			[name]: value,
		});
	}
	// Funcion para validar que todos los campos esten llenos
	function validaCarga() {
		setLoadingButton(true);
		var valida = true;
		Object.keys(infoUsuario).forEach(function (key) {
			if (infoUsuario[key] === '' || isInvalid === 'true') {
				valida = false;
			}
		});
		return valida;
	}
	// Funcion para abrir y cerrar el Snackbar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};
	// Funcion para guardar el usuario
	const handleSaveModal = () => {
		setLoadingButton(true);
		// Hace la validacion de los datos ingresados en el formulario
		if (validaCarga() === true) {
			// Hace el fetch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL + 'user-admin/add',
				requestOptions
			)
				.then((response) => {
					if (response.status === 200) {
						// Se hace la actualizacion de los datos mostrados
						props.dataFetch();
						// Cierra el modal
						props.setOpenModal(false);
						// Muestra el mensaje de exito
						props.setAlertMessage({
							severity: 'success',
							message: t('alertas.usuarioAgregado'),
						});
						// Abre el Alert
						props.handleClick1();
						setTimeout(() => {
							setLoadingButton(false);
						}, 2000);
					} else if (response.status === 401) {
						// Si el caduca el token se redirige al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					}
					// Si no se pudo guardar el usuario
					else {
						// Cierra el modal
						props.setOpenModal(false);
						// Muestra el mensaje de error
						props.setAlertMessage({
							severity: 'error',
							message: t('alertas.usuarioAgregadoError'),
						});
						// Abre el Alert
						props.handleClick1();
						setTimeout(() => {
							setLoadingButton(false);
						}, 2000);
					}
				})
				.catch((error) => 'error: ' + error);
		}
		// Si no se llenaron todos los campos
		else {
			// Muestra el mensaje de error
			setAlertMessage({
				severity: 'error',
				message: t('alertas.validaDatosUsuario'),
			});
			// Abre el Alert
			handleClick1();
			setTimeout(() => {
				setLoadingButton(false);
			}, 2000);
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	// Funcion para hacer la validacion de los campos del formulario
	const handleSubmit = (event) => {
		if (event.nativeEvent.target.ariaInvalid === 'true') {
			setIsInvalid(event.nativeEvent.target.ariaInvalid);
		}
	};

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(infoUsuario),
		redirect: 'follow',
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={true}
				>
					<Grid item container rowSpacing={2}>
						<Grid item lg={9} md={9} sm={9} xs={9}>
							<Typography variant='h5'>
								{t('modales.agregarUsuario')}
							</Typography>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={props.handleCloseModal}
									id='cargaModalCerrarIconButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{ paddingTop: '0px' }}
						>
							{/*Aqui va el codigo de formulario */}
							<DatosUsuarioForm
								agregarUsuario={true}
								handleCheck={props.handleCheck}
								isChecked={props.isChecked}
								handleTextFieldChange={handleTextFieldChange}
								actualizarDato={actualizarDato}
								handleNext={handleSubmit}
								infoUsuario={infoUsuario}
								handleKeyPress={handleKeyPress}
								role={props.role}
								newRole={props.newRole}
							/>
						</Grid>
						<Grid
							item
							lg={6}
							md={6}
							sm={6}
							xs={6}
							sx={{ textAlign: 'left' }}
						>
							<Button
								id='cargaModalCerrarButton'
								variant='outlined'
								color='error'
								onClick={props.handleCloseModal}
							>
								{t('botones.cerrar')}
							</Button>
						</Grid>
						<Grid
							item
							lg={6}
							md={6}
							sm={6}
							xs={6}
							sx={{ textAlign: 'right' }}
						>
							<LoadingButton
								loading={loadingButton}
								id='cargaModalAgregarButton'
								variant='outlined'
								color='primary'
								type='submit'
							>
								{t('botones.agregar')}
							</LoadingButton>
						</Grid>
						<Snackbar
							open={snackbar1.open}
							autoHideDuration={10000}
							onClose={handleClose1}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert
								onClose={handleClose1}
								severity={alertMessage.severity}
							>
								{alertMessage.message}
							</Alert>
						</Snackbar>
					</Grid>
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
