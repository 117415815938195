import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

/*Descripción:Este componente se encarga de desplegar la informacion del contenedor del booking seleccionado
Componente padre: EventosBookingUSCAMEX
Componente hijo:
props: props.dataOption = informacion sobre las opciones del selectSearch
*/

export default function SelectSearchSecuencia(props) {
	// Se daclaran los const para su uso en el SelectSearchContenedor
	var selectDefault = '';
	// Se valida que existan las opciones del contenedor
	if (props.dataOption[0].secuencia !== undefined) {
		// Si existen las opciones se asigna el primer valor del contenedor como el mostrado por defecto
		selectDefault = props.selectedSecuencia;
	} else {
		// Si no existen las opciones se muestra el mensaje correspondiente
		selectDefault = 'Sin información';
	}
	// Se declara el valor por defecto de las opciones
	const [options, setOptions] = useState([
		{
			value: 0,
			label: selectDefault,
		},
	]);
	// Funcion para actualizar el contenedor seleccionado
	const handleChange = (event) => {
		props.actualizarContenedor(event.value);
	};
	// useEffect para la actualización de las opciones del selectSearch
	useEffect(() => {
		const arr = [];
		props.dataOption.map((index, secuencia) => {
			// Se valida que el index de las secuencias exista
			return arr.push({
				value: index['secuencia'],
				label: secuencia + 1,
			});
		});
		// Se asignan los valores a las opciones a mostrar
		setOptions(arr);
	}, [props.dataOption]);

	return (
		<Box>
			<Typography variant='subtitle2' paddingBottom={'2%'}>
				{props.customLabel}
			</Typography>
			<Select
				onChange={handleChange}
				className='basic-single'
				classNamePrefix='select'
				placeholder='Seleccionar'
				noOptionsMessage={({ inputValue }) =>
					!inputValue ? 'value' : 'Valor no encontrado'
				}
				isSearchable={false}
				id={props.id}
				name={props.id}
				options={options}
				defaultValue={options || { value: 0, label: 1 }}
			/>
		</Box>
	);
}
