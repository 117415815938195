import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectSearchEntrega from '../SelectSearch/SelectSearchEntrega';
import { Grid, Stack } from '@mui/material';
import AddLocationAlt from '@mui/icons-material/AddLocationAlt';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
import AgregarDireccion from '../../../catalogos/mis-direcciones/Modales/AgregarDireccion';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Aereo Impo
Componente padre: CommonBooking
Componente hijo: MySnackBar //
	MyInfoStepper //
	InformacionCard
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición//
	props.getBookingsList = Funcion para obtener la lista de los bookings
*/

export default function LugarDeEntrega(props) {
	const { t } = useTranslation();
	//Loading del boton de agregar consignatario
	const [loadingButtonDireccion, setLoadingButtonDireccion] = useState(false);

	//Funcion para abrir el modal
	const handleOpenModal = () => {
		props.setOpenModal(true);
		setLoadingButtonDireccion(true);
	};

	//Funcion para cerrar el modal
	const handleCloseModal = () => {
		props.setOpenModal(false);
		setLoadingButtonDireccion(false);
	};

	// Se crea el modelo de los datos de la carga
	const [infoDireccion, setInfoDireccion] = useState({
		vAlias: '',
		vNombreLugarRecolecionEntrega: '',
		vRfc: '',
		vCalle: '',
		nCodigoPostal: '',
		vColonia: '',
		vCiudad: '',
		vEstado: '',
	});

	//Opciones de mis direcciones
	const dataOptionsMisDirecciones = props.dataMisDirecciones.direcciones.map(
		(option) => {
			return {
				value: option.uiCodLugarRecolecionEntrega,
				label:
					'(ID:' +
					option.uiCodLugarRecolecionEntrega +
					') ' +
					option.vAlias +
					', ' +
					option.vCalle +
					', ' +
					option.vColonia +
					', ' +
					option.vCiudad +
					', ' +
					option.vEstado +
					', ' +
					option.nCodigoPostal,
			};
		}
	);

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			sx={{ minWidth: '360px' }}
			spacing={1}
			paddingTop={1}
		>
			<Stack
				direction={{
					xs: 'row',
					sm: 'row',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
				sx={{ justifyContent: 'space-between' }}
			>
				{/* Lugar de recoleccion */}
				<Grid item width={'100%'}>
					<SelectSearchEntrega
						actualizarCartaPorte={props.actualizarCartaPorte}
						dataDetalles={props.dataDetalles}
						required
						customLabel={t('cartaPorte.lugarDeEntrega')}
						id='lugarEntrega'
						dataOptionsMisDirecciones={dataOptionsMisDirecciones}
					/>
				</Grid>
				<Grid item>
					<Tooltip title={t('botones.agregar')}>
						<LoadingButton
							variant='outlined'
							color='primary'
							id='BtnAgregarDireccion'
							onClick={handleOpenModal}
							loading={loadingButtonDireccion}
							sx={{
								fontSize: '12px',
								marginTop: 3.5,
							}}
						>
							<AddLocationAlt />
						</LoadingButton>
					</Tooltip>
				</Grid>
				<AgregarDireccion
					dataFetch={props.dataFetchMisDirecciones}
					handleClickPage={props.handleOpenSnackBar}
					setAlertMessagePage={props.setAlertMessage}
					handleCloseModal={handleCloseModal}
					openModal={props.openModal}
					setOpenModal={props.setOpenModal}
					setInfoDireccion={setInfoDireccion}
					infoDireccion={infoDireccion}
				/>
			</Stack>
		</Stack>
	);
}
