import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Language } from '@mui/icons-material';

export default function LanguajeButtonsInicio() {
	const { i18n } = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setSelectedLanguage(lng);
	};

	return (
		<Box style={{ display: 'flex', alignItems: 'center' }}>
			<Language />
			<Button
				variant='text'
				sx={{
					color: selectedLanguage === 'es' ? 'black' : 'grey',
					padding: 0,
					minWidth: '32px',
				}}
				onClick={() => changeLanguage('es')}
			>
				ES
			</Button>
			<span style={{ color: 'black' }}>|</span>
			<Button
				variant='text'
				sx={{
					color: selectedLanguage === 'en' ? 'black' : 'grey',
					padding: 0,
					minWidth: '32px',
				}}
				onClick={() => changeLanguage('en')}
			>
				EN
			</Button>
		</Box>
	);
}
