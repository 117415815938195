import { Visibility } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../../../get-data/GetDataFetch';
import VisualizarCargaCartaPorteModal from '../Modales/VisualizarCargaCartaPorteModal';

/*Descripción:Este componente se encarga de renderizar el icono de eliminar en el data grid de las direcciones.
Componente padre: ListDirecciones.jsx
Componente hijo: EditarDireccionesModal.jsx
props: id: id del consignatario // idDireccion: id de la dirección //
dataCiudades: data de las ciudades // dataFetch: función para actualizar el data grid
*/

export default function VisualizarCargasCartaPorteIcon(props) {
	// Funcion para traducir la pagina en español o ingles
	// Se crean las constantes para el funcionamiendo del modal
	const [dataDetalles, setDataDetalles] = useState({});
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	// Se crea el modelo de los datos de la carga
	const [infoCarga, setInfoCarga] = useState({
		uiCodCartaPorteCarga: props.id,
		vEmbalaje: '',
		vUnidadDeMedida: '',
		nCantidad: 0,
		vClaveProductoSat: '',
		vDescripcionMercancia: '',
		nPeso: 0,
		vFraccionArancelaria: '',
		bPeligroso: false,
		vClaveMaterialPeligroso: '',
		vClaveEmbalajePeligroso: '',
		vUNPeligroso: '',
		vUUID: '',
	});

	// Funcion para cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Se hace el fetch para obtener los datos ya existentes en la DB
	const dataFetch = async () => {
		const url =
			'bookings/terrestre/nacional/cartaPorte/cargas/id?id=' + props.id;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	useEffect(() => {
		if (dataDetalles !== undefined) {
			setInfoCarga({
				uiCodCartaPorteCarga: dataDetalles.uiCodCartaPorteCarga,
				vEmbalaje: dataDetalles.vEmbalaje,
				vUnidadDeMedida: dataDetalles.vUnidadDeMedida,
				nCantidad: dataDetalles.nCantidad,
				vClaveProductoSat: dataDetalles.vClaveProductoSat,
				vDescripcionMercancia: dataDetalles.vDescripcionMercancia,
				nPeso: dataDetalles.nPeso,
				vFraccionArancelaria: dataDetalles.vFraccionArancelaria,
				bPeligroso: dataDetalles.bPeligroso,
				vClaveMaterialPeligroso: dataDetalles.vClaveMaterialPeligroso,
				vClaveEmbalajePeligroso: dataDetalles.vClaveEmbalajePeligroso,
				vUNPeligroso: dataDetalles.vUNPeligroso,
				vUUID: dataDetalles.vUUID,
			});
		}
	}, [dataDetalles]);

	useEffect(() => {
		dataFetch();
	}, [props.id]);

	// Función llamada al dar click al icono de editar
	const handleEditClick = async () => {
		await dataFetch();
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={'Visualizar'}>
						<IconButton
							id='editIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleEditClick}
						>
							<Visibility color='primary' />
						</IconButton>
					</Tooltip>
				}
			/>
			{loading ? (
				<></>
			) : (
				<VisualizarCargaCartaPorteModal
					infoCarga={infoCarga}
					dataDetalles={dataDetalles}
					disabled={true}
					handleCloseModal={handleCloseModal}
					openModal={openModal}
					setOpenModal={setOpenModal}
				/>
			)}
		</>
	);
}
