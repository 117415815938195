import {
	Collapse,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Box,
	List,
	Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore, Group } from '@mui/icons-material';
import { useState } from 'react';
import { Fade } from '@mui/material';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { Grow } from '@mui/material';

/*Descripción:Este componente se encarga de desplegar las opciones desplegables de el sidebar de Aduanas
Componente padre: ListMenu
Componente hijo:
props: props para desplegar la informacion
*/

export default function SideBarItemCollapseConfig(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en SideBarItemCollapseConfig
	const [openCollapse, setOpenCollapse] = useState(false);
	// Funcion que despliega el menú de configuracion
	const handleClickMaritimo = () => {
		setOpenCollapse(!openCollapse);
	};

	// Valida si el rol del usuario tiene permisos para acceder a la ruta
	if (!props.roles.includes(props.userRole)) {
		// User role doesn't have permission to access this route, redirect to appropriate page
		return <></>;
	}

	// Se crean variables locales con base a las props
	const { icon: Icon } = props;
	return (
		<Grow in={true} timeout={props.timeout}>
			<Box id='Menu-item'>
				<ListItemButton onClick={handleClickMaritimo} id={props.id}>
					<ListItemIcon id='Menu-icon'>
						<Tooltip
							title={props.primaryText}
							placement='right'
							disableHoverListener={props.openSideBar}
							arrow
						>
							{Icon && <Icon fontSize='medium' color='primary' />}
						</Tooltip>
					</ListItemIcon>
					<Fade in={props.openSideBar}>
						<ListItemText
							primary={
								<Typography variant='subtitle2'>
									{props.primaryText}
								</Typography>
							}
							id='Menu-text'
						/>
					</Fade>
					<Fade in={props.openSideBar}>
						{openCollapse ? (
							<ExpandLess fontSize='medium' color='primary' />
						) : (
							<ExpandMore fontSize='medium' color='primary' />
						)}
					</Fade>
				</ListItemButton>
				<Collapse in={openCollapse} timeout='auto' unmountOnExit>
					<List
						component='div'
						disablePadding
						id='Menu-collapse-list'
					>
						<ListItemButton
							sx={{ pl: 2.7 }}
							component={NavLink}
							to={`${props.navlink}/usuarios`}
							id={`${props.id}-usuarios`}
						>
							<ListItemIcon id='Menu-icon'>
								<Tooltip
									title={t('sidebar.configuracion.usuarios')}
									placement='right'
									disableHoverListener={props.openSideBar}
									arrow
								>
									<Group fontSize='small' color='primary' />
								</Tooltip>
							</ListItemIcon>
							<Fade in={props.openSideBar}>
								<ListItemText
									primary={
										<Typography variant='subtitle2'>
											{t(
												'sidebar.configuracion.usuarios'
											)}
										</Typography>
									}
									id='Menu-text'
								/>
							</Fade>
						</ListItemButton>
						{props.userRole.includes('Agente') ? (
							<></>
						) : (
							<ListItemButton
								sx={{ pl: 2.7 }}
								component={NavLink}
								to={`${props.navlink}/catalogos`}
								id={`${props.id}-catalogos`}
							>
								<ListItemIcon id='Menu-icon'>
									<Tooltip
										title={t(
											'sidebar.configuracion.catalogos'
										)}
										placement='right'
										disableHoverListener={props.openSideBar}
										arrow
									>
										<CollectionsBookmarkIcon
											fontSize='small'
											color='primary'
										/>
									</Tooltip>
								</ListItemIcon>
								<Fade in={props.openSideBar}>
									<ListItemText
										primary={
											<Typography variant='subtitle2'>
												{t(
													'sidebar.configuracion.catalogos'
												)}
											</Typography>
										}
										id='Menu-text'
									/>
								</Fade>
							</ListItemButton>
						)}
					</List>
				</Collapse>
			</Box>
		</Grow>
	);
}
