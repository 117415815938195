import React from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Box, Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import EditCargasCartaPorteIcon from './ActionIcons/EditCargasCartaPorteIcon';
import VisualizarCargasCartaPorteIcon from './ActionIcons/VisualizarCargasCartaPorteIcon';

/*Descripción:Este componente se encarga de renderizar el DataGrid de las cargas del InfoStepper
Componente padre: ListadoCargas
Componente hijo: EditIconDataGrid // DeleteIconDataGrid
props: props.dataGrid = información a desplegar del DataGrid
*/

export default function DataGridCargasCartaPorte(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crea un arreglo con la información de las cargas
	const tableData = [];
	for (let i = 0; i < props.dataCargas.length; i++) {
		const carga = props.dataCargas[i];
		if (carga) {
			const rowData = {
				id: carga.uiCodCartaPorteCarga,
				vDescripcionMercancia: carga.vDescripcionMercancia,
				vClaveProductoSat: carga.vClaveProductoSat,
				nPeso: carga.nPeso,
				bPeligroso: carga.bPeligroso,
			};
			tableData.push(rowData);
		}
	}

	// Se cran las columnas del DataGrid
	const headCells = [
		{
			id: 'vDescripcionMercancia',
			field: 'vDescripcionMercancia',
			width: 260,
			headerName: t('booking.detalles.producto'),
			editable: false,
			// Se renderizan los botones especiales
			renderCell: (params) => {
				if (params.row.bPeligroso === true) {
					return (
						<>
							<Typography variant='body2'>
								{params.row.vDescripcionMercancia}
							</Typography>
							&nbsp; &nbsp;
							<Tooltip title='Carga peligrosa' arrow>
								<ReportProblemIcon color='accuRed' />
							</Tooltip>
						</>
					);
				}
				return (
					<Typography variant='body2'>
						{params.row.vDescripcionMercancia}
					</Typography>
				);
			},
		},
		{
			id: 'vClaveProductoSat',
			field: 'vClaveProductoSat',
			width: 160,
			headerName: t('cartaPorte.cargas.vClaveProductoSat'),
			editable: false,
		},
		{
			id: 'nPeso',
			field: 'nPeso',
			width: 160,
			headerName: t('booking.detalles.peso'),
			editable: false,
			// Se renderizan los botones especiales
			renderCell: (params) => {
				return (
					<>
						<Typography variant='body2'>
							{params.row.nPeso}
						</Typography>
						&nbsp;
						<Typography variant='body2'>kg</Typography>
					</>
				);
			},
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 140,
			disableClickEventBubbling: true,
			// Se renderizan los botones especiales
			renderCell: (params) => {
				return (
					<>
						<EditCargasCartaPorteIcon
							dataFetch={props.dataFetch}
							id={params.row.id}
							data={props.dataDetalles}
							alertMessage={props.alertMessage}
							setAlertMessage={props.setAlertMessage}
							handleOpenSnackBar={props.handleOpenSnackBar}
							handleCloseSnackbar={props.handleCloseSnackbar}
							hideEdit={props.hideEdit}
						/>
						{props.hideEdit ? (
							<></>
						) : (
							<VisualizarCargasCartaPorteIcon
								dataFetch={props.dataFetch}
								id={params.row.id}
								data={props.dataDetalles}
								alertMessage={props.alertMessage}
								setAlertMessage={props.setAlertMessage}
								handleOpenSnackBar={props.handleOpenSnackBar}
								handleCloseSnackbar={props.handleCloseSnackbar}
								hideEdit={props.hideEdit}
							/>
						)}
					</>
				);
			},
		},
	];

	// Funcion para traducir el DataGrid
	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<Box className='ListaCargas' sx={{ height: '38vh' }}>
			<DataGrid
				localeText={localeText()}
				rows={tableData}
				columns={headCells}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 5 },
					},
				}}
				pageSizeOptions={[5, 10, 20]}
				sx={{
					'& .MuiDataGrid-cell': {
						fontSize: '12px',
						fontFamily: 'Source Sans Pro',
					},
					'& .MuiDataGrid-columnHeaderTitle': {
						fontSize: '13px',
						fontFamily: 'Open Sans',
					},
				}}
			/>
		</Box>
	);
}
