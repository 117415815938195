import { Box } from '@mui/material';
import React from 'react';
import ErrorBoundary from '../../components/ErrorBoundary';
import CentroAyudaTableTable from '../../components/centro-de-ayuda/CentroAyudaTable';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla Estado de Cuenta
Componente padre: App.js
Componente hijo:
props: Sin uso de props
*/

export default function IndexCentroDeAyuda() {
	return (
		<ErrorBoundary>
			<Box>
				<Box
					sx={{
						padding: '1%',
						height: '100%',
						bgcolor: 'background.secondary',
					}}
				>
					<CentroAyudaTableTable />
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
