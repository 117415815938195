import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { Description, Info, Inventory2, Subtitles } from '@mui/icons-material';

/*Descripción:Este componente que muestra el frm para agregar un producto
Componente padre: AgregarProducto // EditarProductoModal
Componente hijo:
props: props.handleTextFieldChange = metodo para actualizar el texto de un Textfield //
	props.infoProducto = informacion a desplegar del producto //
*/

export default function DatosProductoForm(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid container rowSpacing={1.5} columnSpacing={{ md: 1, lg: 1 }}>
			<Grid item xs={12} sm={12} md={6} lg={6}>
				<Typography variant='subtitle2'>
					{t('catalogos.productos.nombre')} *
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['required']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						t('catalogos.productos.descripcionInvalida'),
					]}
					onChange={props.handleTextFieldChange('nombreCorto')}
					required
					id='nombreCorto'
					name='nombreCorto'
					fullWidth
					value={props.infoProducto.nombreCorto || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Inventory2 />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={6}>
				<Typography variant='subtitle2'>
					{t('booking.estatus.fraccionArancelaria')}{' '}
					{t('forms.optional')}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					onChange={props.handleTextFieldChange(
						'fraccionArancelaria'
					)}
					id='fraccionArancelaria'
					name='fraccionArancelaria'
					variant='outlined'
					fullWidth
					value={props.infoProducto.fraccionArancelaria || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Subtitles />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								<Tooltip
									title={t(
										'booking.estatus.fraccionArancelariaHelper'
									)}
									arrow
									placement='right'
								>
									<Info
										className='primaryIcon'
										fontSize='medium'
									/>
								</Tooltip>
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('booking.estatus.descMercEsp')} *
				</Typography>
				<TextValidator
					validators={['required']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						t('catalogos.productos.descripcionInvalida'),
					]}
					onChange={props.handleTextFieldChange('productoEsp')}
					required
					id='productoEsp'
					name='productoEsp'
					multiline
					rows={1}
					fullWidth
					value={props.infoProducto.productoEsp || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Description />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('booking.estatus.descMercIng')} *
				</Typography>
				<TextValidator
					validators={['required']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						t('catalogos.productos.descripcionInvalida'),
					]}
					onChange={props.handleTextFieldChange('productoIng')}
					required
					id='productoIng'
					name='productoIng'
					multiline
					rows={7}
					fullWidth
					value={props.infoProducto.productoIng || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Description />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								<Tooltip
									title={t(
										'booking.estatus.descMercIngHelper'
									)}
									arrow
									placement='right'
								>
									<Info
										className='primaryIcon'
										fontSize='medium'
									/>
								</Tooltip>
							</InputAdornment>
						),
					}}
				/>
			</Grid>
		</Grid>
	);
}
