import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos de Despacho
Componente padre: TimelineSelector
Componente hijo: TimelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosDespacho(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			<TimelineItem
				data={
					props.data['fecha-validacion'] !== undefined
						? props.data['fecha-validacion']
						: ''
				}
				title={t('eventos.validacion')}
			/>
			<TimelineItem
				data={
					props.data['fecha-modulacion'] !== undefined
						? props.data['fecha-modulacion']
						: ''
				}
				title={t('eventos.modulacion')}
			/>
			{props.data['fecha-modulacion'] !== undefined &&
				props.data['fecha-modulacion'] !== '' &&
				props.data['fecha-reconocimiento'] !== '' && (
					<TimelineItem
						data={
							props.data['fecha-reconocimiento'] !== undefined
								? props.data['fecha-reconocimiento']
								: ''
						}
						title={t('eventos.reconocimiento')}
					/>
				)}

			<TimelineItem
				data={
					props.data['fecha-liberacion'] !== undefined
						? props.data['fecha-liberacion']
						: ''
				}
				title={t('eventos.liberacion')}
			/>
		</Timeline>
	);
}
