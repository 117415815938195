import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar los eventos de Terrestre relacionados a Despacho Impo y Expo
Componente padre: TimelineSelectorDespachoImpo
Componente hijo: TimelineItem
props: props.data = informacion a desplegar //
	props.dataPedimentos = informacion a desplegar sobre los pedimentos
*/

export default function TimelineEventosTerrestreExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			{props.data['fecha-revalidacion'] === 'N.D.' ||
			props.data['fecha-revalidacion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-revalidacion']}
					title={t('eventos.revalidacion')}
				/>
			)}
			{props.dataPedimentos['fecha-previo'] === 'N.D.' ||
			props.dataPedimentos['fecha-previo'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataPedimentos['fecha-previo']}
					title={t('eventos.previo')}
				/>
			)}
			{props.dataPedimentos['fecha-pago-pedimento'] === 'N.D.' ||
			props.dataPedimentos['fecha-pago-pedimento'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataPedimentos['fecha-pago-pedimento']}
					title={t('eventos.pagoPedimento')}
				/>
			)}
			{props.dataPedimentos['fecha-modulacion'] === 'N.D.' ||
			props.dataPedimentos['fecha-modulacion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataPedimentos['fecha-modulacion']}
					title={t('eventos.modulacion')}
				/>
			)}
			{props.dataPedimentos['fecha-reconocimiento'] === 'N.D.' ||
			props.dataPedimentos['fecha-reconocimiento'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataPedimentos['fecha-reconocimiento']}
					title={t('eventos.reconocimiento')}
				/>
			)}
			{props.dataPedimentos['fecha-liberacion'] === 'N.D.' ||
			props.dataPedimentos['fecha-liberacion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataPedimentos['fecha-liberacion']}
					title={t('eventos.liberacion')}
				/>
			)}
		</Timeline>
	);
}
