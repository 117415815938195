import { Edit } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditarCargaBLModal from '../Modales/EditarCargaBLModal';
import GetDataFetch from '../../../get-data/GetDataFetch';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el icono de eliminar en el DataGrid de las cargas
Componente padre: DataGridCargasBL
Componente hijo: EditarCargasBLModal
props: props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert //
	props.dataFetchBooking = props para actualizar la información mostrada //
	props.id = Información del ID de la fila
*/

export default function EditIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las constantes para el funcionamiendo del modal
	const [dataDetalles, setDataDetalles] = useState({});
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);

	// Funcion para cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Se hace el fetch para obtener los datos ya existentes en la DB
	const dataFetch = async () => {
		const url = 'bill-of-lading/carga/get-carga?idCarga=' + props.id;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	// Funcion para editar y actualizar los valores en el modal
	const handleTextFieldChange = (name) => (event) => {
		const valorEnMayusculas = event.target.value.toUpperCase();
		actualizarDato(name, valorEnMayusculas);
	};

	// Funcion para actualizar los datos del modal
	function actualizarDato(name, value) {
		setDataDetalles({
			...dataDetalles,
			[name]: value,
		});
	}

	// Función llamada al dar click al icono de editar
	const handleEditClick = async () => {
		await dataFetch();
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.editar')}>
						<IconButton
							id='editIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleEditClick}
						>
							<Edit color='primary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<EditarCargaBLModal
				loading={loading}
				handleClick1={props.handleClick1}
				setAlertMessage={props.setAlertMessage}
				dataFetchBooking={props.dataFetchBooking}
				handleTextFieldChange={handleTextFieldChange}
				actualizarDato={actualizarDato}
				selectedBooking={props.data.booking}
				data={props.data}
				dataDetalles={dataDetalles}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
				dataModalidadPago={props.dataModalidadPago}
				dataBulto={props.dataBulto}
				handleNext={props.handleNext}
				emptiesInputs={props.emptiesInputs}
			/>
		</>
	);
}
