import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useState } from 'react';
import SwitchTrackingComponent from '../../../../track-and-trace/SwitchTrackingComponent';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar la informacion de Terrestre Centroamerica del Booking Seleccionado
Componente padre: BookingTerrestreCentroameExpoSearchResult
Componente hijo: SwitchTrackingComponent
props: props.data = informacion a desplegar
*/

export default function InfoBookingTCAExpoFCL(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las const para el uso de la informacion del Booking
	const [openModal, setOpenModal] = useState(false);
	const [letrasArray] = useState([]);
	const [palabra] = useState('');
	// Funcion de cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	return (
		<Box sx={{ overflowY: 'auto', maxHeight: '53vh', width: '100%' }}>
			<Grid container rowSpacing={3} columnSpacing={2}>
				<Grid
					item
					container
					direction='column'
					spacing={1}
					lg={6}
					md={6}
					sm={6}
				>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.embarcador')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.shipper.nombre}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.direccion')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.shipper.direccion}
						</Typography>
					</Grid>

					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.rfc')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.shipper.rfc}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.correo')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.shipper.email}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					container
					direction='column'
					spacing={1}
					lg={6}
					md={6}
					sm={6}
				>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.consignatario')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.consignee.nombre}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.direccion')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.consignee.direccion}
						</Typography>
					</Grid>

					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.rfc')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.consignee.rfc}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.correo')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.consignee.email}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					container
					direction='column'
					spacing={1}
					lg={6}
					md={6}
					sm={6}
				>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.notify')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.nombre')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.notifParty.nombre}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.contacto')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.notifParty.contacto}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('dataGrid.telefono')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.notifParty.telefono}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					container
					direction='column'
					spacing={1}
					lg={6}
					md={6}
					sm={6}
				>
					<Grid item container>
						<Typography variant='subtitle1' color='initial'>
							{t('booking.detalles.informacionCarga')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.cantidad')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.informacionCarga.cantidad}
						</Typography>
					</Grid>

					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.peso')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.informacionCarga.peso}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.detalles.volumen')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data.informacionCarga.volumen}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<SwitchTrackingComponent
				palabra={palabra}
				openModal={openModal}
				handleCloseModal={handleCloseModal}
				letrasArray={letrasArray}
			/>
		</Box>
	);
}
