import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

export default function TableSeguros(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para el uso de TableSeguros
	let rows = [];
	// Se hace un ciclo para recorrer el arreglo de los seguros
	for (let i = 0; i < props.data.seguros.length; i++) {
		const carga = props.data.seguros.eventos[i];
		if (carga) {
			// Si existe la carga, se asigna cada valor a un table
			const rowData = [carga.fechaRealizoServicio];
			rows.push(rowData);
		}
	}

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell align='left'>
							{t('eventos.realizoServicio')}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							key={row.name}
							sx={{
								'&:last-child td, &:last-child th': {
									border: 0,
								},
							}}
						>
							<TableCell align='left'>{row[0]}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
