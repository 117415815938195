import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import SelectSearch from '../SelectSearch';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información del step y formulario Embarcador
Componente padre: InformationStepper
Componente hijo:
props: props.handleTextFieldChangePrev = propiedad para actualizar el texto ingresado //
	props.handleNext = funcion para ir al siguiente step //
	props.verifyInvalid = funcion para validar el campo //
	props.data = información guardada a desplegar en el formulario //
	props.emptiesInputs = funcion para seleccionar el estilo //
	props.dataLada = información del selectSearch de la lada //
	props.ref = informacion de referencia de los validadores
*/

export default function Embarcador(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// funcion para validar el llenado del formulario y sus estilos
	const validFirstTime = () => {
		const inputs = document.querySelectorAll(
			'#formEmbarcador input, #formEmbarcador select, #formEmbarcador textarea'
		);
		// Valida cada Textfield del formulario
		inputs.forEach((input) => {
			const inputName = input.name;
			const inputValue = input.value;
			props.handleTextFieldChangePrev(inputName, inputValue);
		});
	};
	useEffect(() => {
		validFirstTime();
	});

	return (
		<Box component='form' id='formEmbarcador' paddingTop={1}>
			<Grid container rowSpacing={2} columnSpacing={{ md: 2, lg: 2 }}>
				{/* Nombre del embarcador */}
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextValidator
						disabled
						ref={props.ref_nombre_embarcador}
						onChange={props.handleTextFieldChange(
							'nombre_embarcador'
						)}
						onBlur={props.verifyInvalid('nombre_embarcador')}
						validators={['required', 'minStringLength:3']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							t('errorMessages.nombreInvalido'),
						]}
						id='nombre_embarcador'
						required
						name='nombre_embarcador'
						label={t('booking.detalles.nombre')}
						variant='outlined'
						fullWidth
						value={props.data.nombre_embarcador || ''}
						className={
							props.emptiesInputs.includes('nombre_embarcador')
								? 'emptySelect'
								: ''
						}
					/>
				</Grid>
				{/* Contacto del embarcador */}
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextValidator
						ref={props.ref_contacto_embarcador}
						validators={['required', 'minStringLength:3']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							t('errorMessages.contactoInvalido'),
						]}
						onChange={props.handleTextFieldChange(
							'contacto_embarcador'
						)}
						onBlur={props.verifyInvalid('contacto_embarcador')}
						required
						id='contacto_embarcador'
						name='contacto_embarcador'
						label={t('booking.detalles.contacto')}
						variant='outlined'
						fullWidth
						value={props.data.contacto_embarcador || ''}
						className={
							props.emptiesInputs.includes('contacto_embarcador')
								? 'emptySelect'
								: ''
						}
					/>
				</Grid>

				{/* Lada del embarcador */}
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<SelectSearch
						defaultValue={props.data.tel_lada_embarcador}
						required
						isClearable={true}
						isSearchable={true}
						actualizarDato={props.actualizarDato}
						endpoint='catalogos/paises-lada'
						id='tel_lada_embarcador'
						customLabel={t('selectSearch.lada')}
						emptiesInputs={props.emptiesInputs}
						dataOption={props.dataLada}
					/>
				</Grid>
				{/* Telefono del embarcador */}
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextValidator
						ref={props.ref_tel_embarcador}
						validators={[
							'required',
							'isNumber',
							'minNumber:1000000000',
							'maxNumber:9999999999',
						]}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							t('errorMessages.noEsNumero'),
							t('errorMessages.numeroInvalido'),
							t('errorMessages.numeroInvalido'),
						]}
						onChange={props.handleTextFieldChange('tel_embarcador')}
						onBlur={props.verifyInvalid('tel_embarcador')}
						required
						id='tel_embarcador'
						name='tel_embarcador'
						label={t('dataGrid.telefono')}
						variant='outlined'
						fullWidth
						value={props.data.tel_embarcador || ''}
						type='tel'
						className={
							props.emptiesInputs.includes('tel_embarcador')
								? 'emptySelect'
								: ''
						}
					/>
				</Grid>

				{/* Correo electronico del embarcador */}
				<Grid item xs={12} sm={12} md={8} lg={8}>
					<TextValidator
						ref={props.ref_email_embarcador}
						validators={['required', 'isEmail']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							t('errorMessages.correoInvalido'),
						]}
						onChange={props.handleTextFieldChange(
							'email_embarcador'
						)}
						onBlur={props.verifyInvalid('email_embarcador')}
						required
						id='email_embarcador'
						name='email_embarcador'
						label={t('dataGrid.correo')}
						variant='outlined'
						fullWidth
						value={props.data.email_embarcador || ''}
						type='email'
						className={
							props.emptiesInputs.includes('email_embarcador')
								? 'emptySelect'
								: ''
						}
					/>
				</Grid>
				{/* Tax ID del embarcador */}
				<Grid item xs={12} sm={12} md={4} lg={4}>
					<TextValidator
						disabled
						ref={props.ref_taxid_embarcador}
						validators={[
							'required',
							'minStringLength:12',
							'maxStringLength:13',
						]}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							t('errorMessages.rfcInvalido'),
							t('errorMessages.rfcInvalido'),
						]}
						onChange={props.handleTextFieldChange(
							'taxid_embarcador'
						)}
						onBlur={props.verifyInvalid('taxid_embarcador')}
						required
						id='taxid_embarcador'
						name='taxid_embarcador'
						label={t('booking.detalles.rfc')}
						variant='outlined'
						fullWidth
						value={props.data.taxid_embarcador || ''}
						className={
							props.emptiesInputs.includes('taxid_embarcador')
								? 'emptySelect'
								: ''
						}
					/>
				</Grid>

				{/* Direccion del embarcador */}
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<TextValidator
						ref={props.ref_direccion_embarcador}
						validators={['required', 'minStringLength:5']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							t('errorMessages.direccionInvalida'),
						]}
						onChange={props.handleTextFieldChange(
							'direccion_embarcador'
						)}
						onBlur={props.verifyInvalid('direccion_embarcador')}
						required
						id='direccion_embarcador'
						name='direccion_embarcador'
						label={t('booking.detalles.direccion')}
						multiline
						rows={3}
						fullWidth
						value={props.data.direccion_embarcador || ''}
						className={
							props.emptiesInputs.includes('direccion_embarcador')
								? 'emptySelect'
								: ''
						}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
