import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';

ValidatorForm.addValidationRule('isDecimal', (value) => {
	// Regular expression pattern to match a decimal number
	const decimalRegex = /^-?\d*\.?\d+$/;
	if (decimalRegex.test(value)) {
		return true; // Validation passed
	}
	return false; // Validation failed
});

/*Descripción:Este componente se encarga de mostrar el formulario para agregar o editar una dirección.
Componente padre: EditarDireccionesLModal.jsx // AgregarDireccionesModal.jsx
Componente hijo: SelectSearchCiudades.jsx
props: props.actualizarDato = funcion para actualizar el estado de los campos del formulario //
props.dataCiudades = Información de las ciudades // props.actualizarCiudad = funcion para actualizar la ciudad //
props.handleTextFieldChange = funcion para actualizar el estado de los campos del formulario //
props.infoDireccion = Información de la dirección // props.dataDetallesDirecciones = Información de las direcciones //
*/

export default function MisDireccionesForms(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crea la funcion para editar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		if (name === 'nCodigoPostal') {
			if (event.target.value.length <= 5) {
				const value = event.target.value
					.replace(/\s/g, '')
					.replace(/[^\d]/g, '');
				actualizarDato(name, value);
			}
		} else if (name === 'vAlias') {
			if (event.target.value.length <= 100) {
				actualizarDato(name, event.target.value.toUpperCase());
			}
		} else if (name === 'vRfc') {
			if (event.target.value.length <= 13) {
				const value = event.target.value.replace(/\s/g, '');
				actualizarDato(name, value.toUpperCase());
			}
		} else {
			actualizarDato(name, event.target.value.toUpperCase());
		}
	};

	// Se crea la funcion para actualizar el valor de las cargas
	function actualizarDato(name, value) {
		props.setInfoDireccion({
			...props.infoDireccion,
			[name]: value,
		});
	}

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			spacing={1}
		>
			<Grid item>
				<Typography variant='subtitle2'>
					{t('catalogos.misDirecciones.alias', {
						obligatorio: '*',
					})}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['required', 'minStringLength:5']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						'Calle invalida',
					]}
					onChange={handleTextFieldChange('vAlias')}
					required
					id='vAlias'
					name='vAlias'
					fullWidth
					value={props.infoDireccion.vAlias || ''}
				/>
			</Grid>
			<Grid item container justifyContent='space-between'>
				<Grid item xl={8.8} lg={7.8} md={7.8} sm={12} xs={12}>
					<Typography variant='subtitle2'>
						{t('catalogos.misDirecciones.nombre', {
							obligatorio: '*',
						})}
					</Typography>
					<TextValidator
						onKeyDown={props.handleKeyPress}
						validators={['required', 'minStringLength:1']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							'Nombre invalido',
						]}
						onChange={handleTextFieldChange(
							'vNombreLugarRecolecionEntrega'
						)}
						required
						id='vNombreLugarRecolecionEntrega'
						name='vNombreLugarRecolecionEntrega'
						fullWidth
						value={
							props.infoDireccion.vNombreLugarRecolecionEntrega ||
							''
						}
					/>
				</Grid>
				<Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
					<Typography variant='subtitle2'>
						{t('catalogos.misDirecciones.rfc', {
							obligatorio: '*',
						})}
					</Typography>
					<TextValidator
						onKeyDown={props.handleKeyPress}
						validators={['required', 'minStringLength:1']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							'RFC invalido',
						]}
						onChange={handleTextFieldChange('vRfc')}
						required
						id='vRfc'
						name='vRfc'
						fullWidth
						value={props.infoDireccion.vRfc || ''}
					/>
				</Grid>
			</Grid>
			<Grid item>
				<Typography variant='subtitle2'>
					{t('catalogos.misDirecciones.calle', {
						obligatorio: '*',
					})}
				</Typography>
				<TextValidator
					validators={['required', 'minStringLength:1']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						'Calle invalida',
					]}
					onChange={handleTextFieldChange('vCalle')}
					required
					id='vCalle'
					name='vCalle'
					fullWidth
					value={props.infoDireccion.vCalle || ''}
				/>
			</Grid>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Grid item width={'100%'}>
					<Typography variant='subtitle2'>
						{t('catalogos.misDirecciones.colonia', {
							obligatorio: '*',
						})}
					</Typography>
					<TextValidator
						validators={['required', 'minStringLength:1']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							'Colonia invalida',
						]}
						onChange={handleTextFieldChange('vColonia')}
						required
						id='vColonia'
						name='vColonia'
						fullWidth
						value={props.infoDireccion.vColonia || ''}
					/>
				</Grid>
				<Grid item width={'100%'}>
					<Typography variant='subtitle2'>
						{t('catalogos.misDirecciones.ciudad', {
							obligatorio: '*',
						})}
					</Typography>
					<TextValidator
						validators={['required', 'minStringLength:1']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							'Ciudad invalida',
						]}
						onChange={handleTextFieldChange('vCiudad')}
						required
						id='vCiudad'
						name='vCiudad'
						fullWidth
						value={props.infoDireccion.vCiudad || ''}
					/>
				</Grid>
			</Stack>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Grid item width={'100%'}>
					<Typography variant='subtitle2'>
						{t('catalogos.misDirecciones.estado', {
							obligatorio: '*',
						})}
					</Typography>
					<TextValidator
						validators={['required', 'minStringLength:1']}
						errorMessages={[
							t('errorMessages.campoRequerido'),
							'Estado invalido',
						]}
						onChange={handleTextFieldChange('vEstado')}
						required
						id='vEstado'
						name='vEstado'
						fullWidth
						value={props.infoDireccion.vEstado || ''}
					/>
				</Grid>
				<Grid item width={'100%'}>
					<Typography variant='subtitle2'>
						{t('catalogos.misDirecciones.codigoPostal') + ' *'}
					</Typography>
					<TextValidator
						onKeyDown={props.handleKeyPress}
						validators={['required']}
						errorMessages={[t('errorMessages.campoRequerido')]}
						onChange={handleTextFieldChange('nCodigoPostal')}
						required
						id='nCodigoPostal'
						name='nCodigoPostal'
						fullWidth
						value={props.infoDireccion.nCodigoPostal || ''}
					/>
				</Grid>
			</Stack>
		</Stack>
	);
}
