import { DataGrid, esES } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

export default function PagosDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crea un arreglo con la informacion de la factura
	let tableData = [];

	for (let i = 0; i < props.dataDetalles.doctoRelacionado.length; i++) {
		const factura = props.dataDetalles.doctoRelacionado[i];
		// Se valida que la factura exista
		if (factura) {
			// Si existe se asignan los valores correspondientes a las columnas del DataGrid
			const rowData = {
				id: i + 1,
				serie: factura.serie + '/' + factura.folio,
				idDocumento: factura.idDocumento,
				monedaDR: factura.monedaDR,
				impPagado:
					'$' +
					Number(factura.impPagado).toLocaleString('en-US', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}),
			};
			// Se agrega la información a la fila
			tableData.push(rowData);
		}
	}

	// Funciones para la busqueda del DataGrid
	const [searchTerm, setSearchTerm] = useState('');
	const filterRows = () => {
		return tableData.filter((row) =>
			props.headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(searchTerm.toLowerCase());
			})
		);
	};

	// Funcion para traducir el DataGrid
	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};
	return (
		<>
			<Grid container spacing={2}>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<TextField
						label={t('tablero.trackTrace.buscar')}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						autoComplete='off'
						variant='outlined'
						size='small'
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Search />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<DataGrid
						localeText={localeText()}
						rows={filterRows()}
						columns={props.headCells}
						initialState={{
							pagination: {
								paginationModel: {
									page: 0,
									pageSize: 20,
								},
							},
						}}
						pageSizeOptions={[20, 50, 100]}
						sx={{
							'& .MuiDataGrid-cell': {
								fontSize: '12px',
								fontFamily: 'Source Sans Pro',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								fontSize: '13px',
								fontFamily: 'Open Sans',
							},
						}}
					/>
				</Grid>
			</Grid>
		</>
	);
}
