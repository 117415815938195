import Cookies from 'universal-cookie';

/*Descripción:Este componente se encarga de realizar el fetch del metodo GET
Componente padre: Global
Componente hijo:
props: Sin uso de props, se ocupan las variables enpoint= Endpoint al que apuntará//
setLoading= Función para mostrar o no la animación de cargando //
setDataDetalles=Funcion para guardar los datos obtenidos con el método get //
navigate= función para redireccionar a una ruta especifica
*/

export default async function GetDataFetchAlt(
	endpoint,
	setLoading,
	setDataDetalles,
	navigate
) {
	// Obtiene el token de la cookie
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Configuracion del header
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	// Configuracion del request
	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	// fetch data
	const tempDataDetalles = await fetch(
		process.env.REACT_APP_API_ACCUTRACK_URL + endpoint,
		requestOptions
	);
	// Se valida el valor del estatus
	if (tempDataDetalles.status === 200 || tempDataDetalles.status === 500) {
		const data = await tempDataDetalles.json();
		// Se asignan los datos recopilados
		setDataDetalles(data.data);
		setLoading(false);
	} else if (tempDataDetalles.status === 401) {
		// Si el token caduca se redirige al login
		navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
	} else if (tempDataDetalles.status === 504) {
		setLoading(false);
	}
}
