import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MySnackbutton from '../../SnackButtonPerfil';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import CallIcon from '@mui/icons-material/Call';

/*Descripción:Este componente se encarga de renderizar el card de la modificacón de los datos del usuario
Componente padre: UserProfile
Componente hijo: MySnackbutton
props: props.handleSaveClick = acción al clickear //
	props.alertMessage = Mensaje a desplegar //
	props.handleChange = Cambios de los parametros del textfield //
	props.newData = los nuevos datos ingresados
*/

export default function CardModificaDatos(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	return (
		<Card sx={{ width: '100%' }}>
			<CardContent>
				<Grid
					container
					sx={{ paddingBottom: '2%' }}
					justifyContent='space-between'
				>
					<Grid item>
						<Typography gutterBottom variant='h5' component='div'>
							{t('cards.modificaDatos')}
						</Typography>
					</Grid>
					<Grid item>
						<MySnackbutton
							handleSaveClick={props.handleSaveClick}
							alertMessage={props.alertMessage}
						/>
					</Grid>
				</Grid>
				<Grid container rowSpacing={2} columnSpacing={2}>
					<Grid item lg={6} md={12} sm={12} xs={12}>
						<ValidatorForm
							onSubmit={props.handleSaveClick}
							instantValidate={false}
						>
							<Typography variant='subtitle2'>
								{t('booking.detalles.nombre')}
							</Typography>
							<TextValidator
								onKeyDown={handleKeyPress}
								validators={['required', 'minStringLength:3']}
								errorMessages={[
									t('errorMessages.nombreRequerido'),
									t('errorMessages.nombreInvalido'),
								]}
								onChange={props.handleNombreChange}
								name='nombre-perfil'
								required
								fullWidth
								id='nombre-perfil'
								variant='outlined'
								value={props.newName}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<PermContactCalendarIcon />
										</InputAdornment>
									),
								}}
							/>
						</ValidatorForm>
					</Grid>
					<Grid item lg={6} md={12} sm={12} xs={12}>
						<ValidatorForm onSubmit={props.handleSaveClick}>
							<Typography variant='subtitle2'>
								{t('booking.detalles.apellido')}
							</Typography>
							<TextValidator
								onKeyDown={handleKeyPress}
								validators={['required', 'minStringLength:3']}
								errorMessages={[
									t('errorMessages.apellidoRequerido'),
									t('errorMessages.apellidoInvalido'),
								]}
								onChange={props.handleApellidoChange}
								required
								fullWidth
								id='apellido-perfil'
								name='apellido-perfil'
								variant='outlined'
								value={props.newLastName}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<PermContactCalendarIcon />
										</InputAdornment>
									),
								}}
							/>
						</ValidatorForm>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<ValidatorForm onSubmit={props.handleSaveClick}>
							<Typography variant='subtitle2'>
								{t('booking.detalles.telefono')}
							</Typography>
							<TextValidator
								onKeyDown={handleKeyPress}
								validators={[
									'required',
									'isNumber',
									'minStringLength:9',
									'maxStringLength:10',
								]}
								errorMessages={[
									t('errorMessages.campoRequerido'),
									t('errorMessages.noEsNumero'),
									t('errorMessages.numeroInvalido'),
									t('errorMessages.numeroInvalido'),
								]}
								onChange={props.handlePhoneChange}
								required
								fullWidth
								id='numero-perfil'
								name='numero-perfil'
								variant='outlined'
								value={props.newPhone}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<CallIcon />
										</InputAdornment>
									),
								}}
							/>
						</ValidatorForm>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
