import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	Tooltip,
	useMediaQuery,
	IconButton,
	Stack,
} from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GetDocumentFetch from '../../../get-data/GetDocumentFetch';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: ListadoCargas
Componente hijo: Carga
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.actualizarDato = funcion para actualizar los datos del formulario //
	props.dataDetalles = informacion acerca de los detalles del booking //
	props.dataModalidadPago = informacion sobre la modalidad de pago //
	props.dataBulto = informacion sobre el tipo de bulto //
	props.handleNext = funcion para realizar el nextStepp //
	props.emptiesInputs = funcion para validar los campos vacios //
*/

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export default function AgergarDireccionExcelTemplate(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '40%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: '60%',
		maxHeight: '95%',
		minHeight: '20vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const navigate = useNavigate();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [file, setFile] = useState(null);
	const [fileExtension, setFileExtension] = useState(null);
	const [fileName, setFileName] = useState(null);
	//Loading
	const [loadingDescarga, setLoadingDescarga] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);
	const avalibleExtensions = ['xlsx'];
	//Headers
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);
	// funciones para el modal
	const handleSaveModal = () => {
		setLoadingButton(true);
		if (avalibleExtensions.includes(fileExtension)) {
			var formdata = new FormData();
			formdata.append('archivo', file);

			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow',
			};
			// Se hace el llamado al metodo Patch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'catalogos/direcciones/excel/create',
				requestOptions
			)
				.then((response) => {
					props.handleCloseModal();
					if (response.status === 200) {
						// Si es correcto se actualiza el contenido y se manda el mensaje de exito
						props.setAlertMessagePage({
							message: t('alertas.direccionGuardada'),
							severity: 'success',
						});
						props.handleClickPage();
						setTimeout(() => {
							props.dataFetch();
						}, 2000);
						setTimeout(() => {
							setLoadingButton(false);
						}, 2000);
					} else if (response.status === 401) {
						// Si el token expiro se redirecciona al login
						setLoading(false);
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						// Si es incorrecto se manda el mensaje de error
						props.setAlertMessagePage({
							message: t('alertas.direccionGuardadaError'),
							severity: 'error',
						});
						props.handleClickPage();
						setLoadingButton(false);
					}
				})
				.catch((error) => console.log('error', error));
			setFile(null);
			setFileExtension(null);
			setFileName(null);
			setLoadingButton(false);
		}
	};

	//Se crea funcion para conseguir el template de direccion
	const handleFileChange = (event) => {
		setFile(event.target.files[0]);
		setFileExtension(event.target.files[0].name.split('.').pop());
		setFileName(event.target.files[0].name);
	};

	//Se crea funcion para conseguir el template de direccion
	const dataFetchTemplate = async () => {
		const url = 'excel/templates/descarga/catMisDirecciones';
		const fileExtension = '.xlsx';
		const fileName = 'Template Mis Direcciones';
		await GetDocumentFetch(
			url,
			setLoadingDescarga,
			fileExtension,
			'xlsx',
			navigate,
			fileName
		);
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Stack direction='column' spacing={2}>
					<Stack
						direction='row'
						sx={{ justifyContent: 'space-between' }}
					>
						<Grid item>
							<Typography variant='h5'>
								{t('modales.agregarDireccion')}
							</Typography>
						</Grid>
						<Grid item>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={props.handleCloseModal}
									id='cargaModalCerrarIconButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
					</Stack>
					<Grid item>
						<Typography variant='subtitle1'>
							{t('fileUpload.seleccionaArchivo')}
						</Typography>
					</Grid>
					<Stack
						direction='row'
						sx={{ justifyContent: 'space-between' }}
					>
						<Grid item>
							<LoadingButton
								variant='outlined'
								color='primary'
								id='BtnAgregarCargaBL'
								sx={{
									fontSize: '12px',
									marginBottom: 1,
								}}
								onClick={dataFetchTemplate}
								loading={loadingDescarga}
								startIcon={<FileDownloadIcon />}
							>
								{t('botones.descargarTemplate')}
							</LoadingButton>
						</Grid>
						<Grid
							item
							sx={{
								textAlign: 'right',
							}}
						>
							<Button
								variant='outlined'
								color='primary'
								component='label'
								id='BtnAgregarCargaBL'
								sx={{
									fontSize: '12px',
									paddingTop: '2%',
									marginBottom: 1,
								}}
								startIcon={<FileUploadIcon />}
							>
								{t('botones.subir')}
								<VisuallyHiddenInput
									type='file'
									accept='.xlsx'
									onChange={handleFileChange}
								/>
							</Button>
						</Grid>
					</Stack>
					<Grid
						item
						sx={{
							textAlign: 'right',
						}}
					>
						<Typography variant='subtitle2'>{fileName}</Typography>
					</Grid>
					<Stack
						direction='row'
						sx={{ justifyContent: 'space-between' }}
					>
						<Grid item>
							<Button
								id='cargaModalCerrarButton'
								variant='outlined'
								color='error'
								onClick={props.handleCloseModal}
							>
								{t('botones.cancelar')}
							</Button>
						</Grid>
						<Grid
							item
							sx={{
								textAlign: 'right',
							}}
						>
							<LoadingButton
								loading={loadingButton}
								id='cargaModalAgregarButton'
								variant='outlined'
								color='primary'
								onClick={handleSaveModal}
								disabled={file === null}
							>
								{t('botones.agregar')}
							</LoadingButton>
						</Grid>
					</Stack>
				</Stack>
			</Box>
		</Modal>
	);
}
