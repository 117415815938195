import * as React from 'react';
import { Stack, Button } from '@mui/material';
import EstadisticaCard from './EstadisticaCard';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import PendingActions from '@mui/icons-material/PendingActions';
import Insights from '@mui/icons-material/Insights';
import GetDataFetch from '../../get-data/GetDataFetch';
import GetRoleFetch from '../../get-data/GetRoleFetch';

export default function CarruselCardsEstadisticas() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loadingBLPendiente, setLoadingBLPendiente] = useState(true);
	const [dataDetallesBLPendiente, setDataDetallesBLPendiente] = useState({});
	const [role, setRole] = useState('');
	// const [loadingCartaPortePendiente, setLoadingCartaPortePendiente] =
	// 	useState(true);
	// const [
	// 	dataDetallesCartaPortePendiente,
	// 	setDataDetallesCartaPortePendiente,
	// ] = useState({});
	const [loadingBookingsActivos, setLoadingBookingsActivos] = useState(true);
	const [dataDetallesBookingsActivos, setDataDetallesBookingsActivos] =
		useState({});
	const navigate = useNavigate();

	// useEffect para obtener los datos de los BL pendientes
	useEffect(() => {
		GetRoleFetch(setRole);
	}, []);

	useEffect(() => {
		if (role.includes('Cliente')) {
			dataFetchBookingsActivos();
			dataFetchBLPendientes();
		} else if (role.includes('Agente')) {
			dataFetchBookingsActivos();
		}
	}, [role]);

	// dataFetch para obtener los datos de los BL pendientes
	const dataFetchBLPendientes = async () => {
		setLoadingBLPendiente(true);
		let url = 'estaditicas/total-bill-of-lading-pendientes';
		await GetDataFetch(
			url,
			setLoadingBLPendiente,
			setDataDetallesBLPendiente,
			navigate
		);
	};

	// // dataFetch para obtener los datos de las car
	// const dataFetchCartaPortePendiente = async () => {
	// 	setLoadingCartaPortePendiente(true);
	// 	let url = 'estaditicas/total-carta-porte-pendientes';
	// 	await GetDataFetch(
	// 		url,
	// 		setLoadingCartaPortePendiente,
	// 		setDataDetallesCartaPortePendiente,
	// 		navigate
	// 	);
	// };

	// dataFetch para obtener los datos de los bookings activos
	const dataFetchBookingsActivos = async () => {
		setLoadingBookingsActivos(true);
		let url = 'estaditicas/total-active-bookings-service';
		await GetDataFetch(
			url,
			setLoadingBookingsActivos,
			setDataDetallesBookingsActivos,
			navigate
		);
	};

	return (
		<Stack
			direction={{
				xs: 'row',
				sm: 'row',
				md: 'row',
				lg: 'row',
				xl: 'row',
			}}
			spacing={1}
		>
			<EstadisticaCard
				role={role}
				roles={['Cliente', 'Cliente-admin']}
				color='pendingBlCard.main'
				loading={loadingBLPendiente}
				numero={dataDetallesBLPendiente.total}
				texto={t('cards.blPendientes')}
				icono={
					<PendingActions color='pendingBlCard' fontSize='large' />
				}
				button={
					<Button
						size='small'
						component={NavLink}
						to='/maritimo/bill-of-lading-catalogos'
					>
						{t('tablero.estadoresumen.verMas')}
					</Button>
				}
			/>
			{/* <EstadisticaCard
				color='pendingBlCard.main'
				loading={loadingCartaPortePendiente}
				numero={dataDetallesCartaPortePendiente.total}
				texto={t('cards.cartaPortePendientes')}
				icono={
					<WorkHistoryIcon color='pendingBlCard' fontSize='large' />
				}
				button={
					<Button
						size='small'
						component={NavLink}
						to='/terrestre/nacional/carta-porte'
					>
						{t('tablero.estadoresumen.verMas')}
					</Button>
				}
			/> */}
			<EstadisticaCard
				role={role}
				roles={['Cliente', 'Cliente-admin', 'Agente', 'Agente-admin']}
				color='activeBookingCard.main'
				loading={loadingBookingsActivos}
				numero={dataDetallesBookingsActivos.total}
				texto={t('cards.totalBookingsActivos')}
				icono={<Insights color='activeBookingCard' fontSize='large' />}
				button={<></>}
			/>
		</Stack>
	);
}
