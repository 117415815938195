import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Stack } from '@mui/material';
import { Business, HomeWork } from '@mui/icons-material';

export default function EmisorCard(props) {
	const { t } = useTranslation();

	return (
		<Card elevation={3} className='fac-card-top paper-hover'>
			<CardContent>
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'column',
						lg: 'column',
						xl: 'column',
					}}
					spacing={2}
				>
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'column',
							lg: 'column',
							xl: 'column',
						}}
						spacing={0.5}
					>
						<Typography variant='subtitle1'>
							{t('booking.facturas.emisor')}
						</Typography>
						<Business color='primary' />
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDetalles.emisor.nombre}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDetalles.emisor.rfc}
						</Typography>
					</Stack>
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'column',
							lg: 'column',
							xl: 'column',
						}}
						spacing={0.5}
					>
						<Typography variant='subtitle1'>
							{t('booking.facturas.receptor')}
						</Typography>
						<HomeWork color='primary' />
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDetalles.receptor.nombre}
						</Typography>

						<Typography color='initial' class='Datos-bookings'>
							{props.dataDetalles.receptor.rfc}
						</Typography>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
}
