import React from 'react';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

/*Descripción:Este componente se encarga de hacer una pantalla de carga de un Titulo
Componente padre: Global
Componente hijo: Global
props: Sin uso de props
*/

export default function MySkeletonTitle() {
	return (
		<Typography width={'230px'} variant='h3'>
			<Skeleton />
		</Typography>
	);
}
