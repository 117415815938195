import * as React from 'react';
import {
	Grid,
	Fade,
	Stack,
	Card,
	CardContent,
	Typography,
	CardActions,
	Button,
	Tooltip,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import EstadoDeCuentaItem from './EstadoDeCuentaItem';
import CircularProgress from '@mui/material/CircularProgress';
import GetDataFetch from '../get-data/GetDataFetch';
import { useTranslation } from 'react-i18next';
import { AttachMoney, Euro, Info, Receipt } from '@mui/icons-material';

/*Descripción:Este componente se encarga de mostrar el card del estado de cuenta del cliente
Componente padre: Inicio.jsx
Componente hijo: EstadoDeCuentaItem //
	EstadoDeCuenta //
	Card
props: props.endpoint = endpoint al que se hará la petición
*/

export default function EstadoDeCuentaCard(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		let url = props.endpoint + '/total-deuda';
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	useEffect(() => {
		dataFetch();
	}, []);

	return (
		<Card sx={{ width: '100%' }} className='paper-hover'>
			<CardContent>
				<Stack
					direction={{
						xs: 'row',
						sm: 'row',
						md: 'row',
						lg: 'row',
						xl: 'row',
					}}
					padding={0}
					paddingBottom={1.5}
					spacing={1}
				>
					<Grid item>
						<Receipt color='primary' fontSize='large' />
					</Grid>
					<Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
						<Grid container justifyContent={'space-between'}>
							<Grid items>
								<Typography
									gutterBottom
									variant='h5'
									component='div'
								>
									{t('tablero.estadoresumen.titulo')}
								</Typography>
							</Grid>
							<Grid item>
								<Stack direction='row'>
									<Tooltip
										title={t(
											'tablero.estadoresumen.informacion'
										)}
										placement='right'
										leaveDelay={300}
										arrow
									>
										<Info
											color='primary'
											fontSize='medium'
										/>
									</Tooltip>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
				</Stack>
				{loading ? (
					<Grid
						container
						justifyContent='center'
						sx={{ minWidth: '100%' }}
					>
						<CircularProgress />
					</Grid>
				) : (
					<Fade in={true} timeout={500}>
						<Grid container spacing={2}>
							<EstadoDeCuentaItem
								dataDetallesIntercom={
									dataDetalles['facturas-intercom'].mxn
								}
								dataDetallesSAT={
									dataDetalles['facturas-sat'].mxn
								}
								dataDetallesFAST={
									dataDetalles['facturas-fast'].mxn
								}
								moneda='MXN'
								icon={AttachMoney}
							/>
							<EstadoDeCuentaItem
								dataDetallesIntercom={
									dataDetalles['facturas-intercom'].usd
								}
								dataDetallesSAT={
									dataDetalles['facturas-sat'].usd
								}
								dataDetallesFAST={
									dataDetalles['facturas-fast'].usd
								}
								moneda='USD'
								icon={AttachMoney}
							/>
							<EstadoDeCuentaItem
								dataDetallesIntercom={
									dataDetalles['facturas-intercom'].eur
								}
								dataDetallesSAT={
									dataDetalles['facturas-sat'].eur
								}
								dataDetallesFAST={
									dataDetalles['facturas-fast'].eur
								}
								moneda='EUR'
								icon={Euro}
							/>
						</Grid>
					</Fade>
				)}
			</CardContent>
			<CardActions
				sx={{
					justifyContent: 'flex-end',
					minHeight: '52px',
					maxHeight: '52px',
					paddingBottom: '1px',
				}}
			>
				<Button size='small' component={NavLink} to='/estado-de-cuenta'>
					{t('tablero.estadoresumen.verMas')}
				</Button>
			</CardActions>
		</Card>
	);
}
