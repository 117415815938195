import React from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Grid,
	Stepper,
	Step,
	StepLabel,
	StepButton,
	Hidden,
	Typography,
	Stack,
	Box,
	Tooltip,
	Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LugarDeRecoleccion from './Steps/LugarDeRecoleccion';
import LugarDeEntrega from './Steps/LugarDeEntrega';
import ListadoCargas from './Steps/ListadoCargas';
import ResumenCartaPorte from './Steps/ResumenCartaPorte';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Aereo Impo
Componente padre: CommonBooking
Componente hijo: MySnackBar //
	MyInfoStepper //
	InformacionCard
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición//
	props.getBookingsList = Funcion para obtener la lista de los bookings
*/

export default function InformationStepper(props) {
	//Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	//Steps del Stepper
	const steps = [
		t('cartaPorte.recoleccion'),
		t('cartaPorte.entrega'),
		t('cartaPorte.carga'),
		t('cartaPorte.resumen'),
	];
	//Arreglo de completitud de los steps
	const completedSteps = [
		props.dataDetalles.completedLugarRecoleccion,
		props.dataDetalles.completedLugarEntrega,
	];
	//Boton Siguiente
	const handleNext = () => {
		props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	//Boton Atras
	const handleBack = () => {
		props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	//Uso de Label del stepper
	const handleStep = (step) => () => {
		props.setActiveStep(step);
	};

	// Informción que se despliega en el step
	function info(step) {
		switch (step) {
			case 0:
				return (
					<LugarDeRecoleccion
						updateCartaPorte={props.updateCartaPorte}
						actualizarCartaPorte={props.actualizarCartaPorte}
						alertMessage={props.alertMessage}
						setAlertMessage={props.setAlertMessage}
						handleOpenSnackBar={props.handleOpenSnackBar}
						handleCloseSnackbar={props.handleCloseSnackbar}
						dataMisDirecciones={props.dataMisDirecciones}
						openModal={props.openModal}
						setOpenModal={props.setOpenModal}
						dataFetchMisDirecciones={props.dataFetchMisDirecciones}
						dataDetalles={props.dataDetalles}
					/>
				);
			case 1:
				return (
					<LugarDeEntrega
						actualizarCartaPorte={props.actualizarCartaPorte}
						alertMessage={props.alertMessage}
						setAlertMessage={props.setAlertMessage}
						handleOpenSnackBar={props.handleOpenSnackBar}
						handleCloseSnackbar={props.handleCloseSnackbar}
						dataMisDirecciones={props.dataMisDirecciones}
						openModal={props.openModal}
						setOpenModal={props.setOpenModal}
						dataFetchMisDirecciones={props.dataFetchMisDirecciones}
						dataDetalles={props.dataDetalles}
					/>
				);
			case 2:
				return (
					<ListadoCargas
						dataFetch={props.dataFetch}
						updateCartaPorte={props.updateCartaPorte}
						alertMessage={props.alertMessage}
						setAlertMessage={props.setAlertMessage}
						handleOpenSnackBar={props.handleOpenSnackBar}
						handleCloseSnackbar={props.handleCloseSnackbar}
						dataDetalles={props.dataDetalles}
						selectedBooking={props.selectedBooking}
					/>
				);
			case 3:
				return (
					<ResumenCartaPorte
						dataDetalles={props.dataDetalles}
						selectedBooking={props.selectedBooking}
						alertMessage={props.alertMessage}
						setAlertMessage={props.setAlertMessage}
						handleOpenSnackBar={props.handleOpenSnackBar}
						handleCloseSnackbar={props.handleCloseSnackbar}
						dataFetch={props.dataFetch}
					/>
				);
			default:
				return <></>;
		}
	}

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			sx={{
				overflowY: 'auto',
				overflowX: 'hidden',
				maxHeight: '62vh',
				minHeight: '62vh',
				paddingRight: '2%',
				width: '100%',
			}}
			spacing={0}
		>
			<Grid item container>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Stepper
						activeStep={props.activeStep}
						nonLinear={true}
						value={props.activeStep}
					>
						{steps.map((label, index) => {
							return (
								<Step
									key={label}
									completed={completedSteps[index]}
								>
									<StepButton onClick={handleStep(index)}>
										<StepLabel>
											<Hidden smDown>{label}</Hidden>
										</StepLabel>
									</StepButton>
								</Step>
							);
						})}
					</Stepper>
				</Grid>
			</Grid>
			<Grid item container>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Fragment>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								pt: 2,
								paddingRight: '2%',
							}}
						>
							<Tooltip title={t('modales.anterior')}>
								<Button
									size='small'
									id='InfoStepperBackButton'
									variant='contained'
									disableElevation
									disabled={props.activeStep === 0}
									onClick={handleBack}
									sx={{ bgcolor: '#002D58' }}
								>
									<ArrowBackIosIcon fontSize='small' />
								</Button>
							</Tooltip>
							<Box sx={{ flex: '1 1 auto' }} />
							<Tooltip title={t('modales.siguiente')}>
								<Button
									id='InfoStepperNextButton'
									sx={{ bgcolor: '#002D58' }}
									size='small'
									variant='contained'
									disableElevation
									onClick={handleNext}
									disabled={
										props.activeStep === steps.length - 1
									}
								>
									<ArrowForwardIosIcon fontSize='small' />
								</Button>
							</Tooltip>
						</Box>
					</Fragment>
				</Grid>
			</Grid>
			<Grid
				item
				container
				sx={{ mt: 1 }}
				justifyContent={'space-between'}
			>
				<Grid item lg={8} md={8} xs={8} sm={8}>
					<Hidden smUp>
						<Typography variant='h6'>
							{steps[props.activeStep]}
						</Typography>
					</Hidden>
				</Grid>
			</Grid>
			<Grid item lg={12} md={12} xs={12} sm={12}>
				{info(props.activeStep)}
			</Grid>
		</Stack>
	);
}
