import { Delete } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import EliminarCargaBLModal from '../Modales/EliminarCargaBLModal';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el icono de eliminar en el DataGrid de las cargas
Componente padre: DataGridCargasBL
Componente hijo: EliminarCargasBLModal
props: props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert //
	props.dataFetchBooking = props para actualizar la información mostrada //
	props.id = Información del ID de la fila
*/

export default function DeleteIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Funciones para el modal
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// Función llamada al dar click al icono de eliminar
	const handleDeleteClick = async () => {
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.eliminar')}>
						<IconButton
							id='deleteIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleDeleteClick}
						>
							<Delete color='secondary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<EliminarCargaBLModal
				handleClick1={props.handleClick1}
				setAlertMessage={props.setAlertMessage}
				dataFetchBooking={props.dataFetchBooking}
				id={props.id}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
		</>
	);
}
