import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, Button, Grid } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Block } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: DeleteIconDatasGrid
Componente hijo:
props:props.openModal = funcion para abrir el modal //
	props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert //
	props.dataFetch = props para actualizar la información mostrada //
*/

// Se crean los estilos para el Modal
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 450,
	minWidth: 250,
	minHeight: 170,
	maxHeight: '95%',
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: '2vw',
	borderRadius: '10px',
};

export default function EliminarUsuarioLModal(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const navigate = useNavigate();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const requestOptions = {
		method: 'PATCH',
		headers: myHeaders,
		redirect: 'follow',
	};

	// Se crea la funcion para desactivar el usuario
	const handleSaveModal = () => {
		// Se hace la peticion al endpoint
		fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'user-admin/user-desactivar?email=' +
				props.id,
			requestOptions
		)
			.then((response) => {
				// Se valida el status de la peticion
				if (response.status === 200) {
					// Se cierra el modal
					props.setOpenModal(false);
					// Se muestra el mensaje de exito
					props.setAlertMessage({
						severity: 'success',
						message: t('alertas.usuarioDesactivado'),
					});
					// Se abre el Alert
					props.handleClick1();
					// Se actualiza la informacion mostrada
					props.dataFetch();
				} else if (response.status === 401) {
					// Si el token caduca se redirige al login
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					// Se muestra el mensaje de error
					props.setAlertMessage({
						severity: 'error',
						message: t('alertas.usuarioDesactivadoError'),
					});
					// Se abre el Alert
					props.handleClick1();
					// Se actualiza la informacion mostrada
					props.dataFetch();
				}
			})
			.catch((error) => console.log('error', error));
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Grid item container rowSpacing={2}>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							<Typography variant='h5'>
								{t('modales.desactivarUsuario')}
							</Typography>
						</Grid>
						<Grid item>
							<Block
								aria-label='add an alarm'
								color='error'
								fontSize='large'
							/>
						</Grid>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Typography variant='subtitle2'>
							{t('modales.desactivarUsuarioDescripcion')}
						</Typography>
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						sm={6}
						xs={6}
						sx={{
							textAlign: 'right',
							position: 'absolute',
							bottom: '1.5vw',
							left: '1.5vw',
						}}
					>
						<Button
							id='cargaModalCerrarButton'
							variant='outlined'
							color='primary'
							onClick={props.handleCloseModal}
						>
							{t('botones.cerrar')}
						</Button>
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						sm={6}
						xs={6}
						sx={{
							textAlign: 'right',
							position: 'absolute',
							bottom: '1.5vw',
							right: '1.5vw',
						}}
					>
						<Button
							id='cargaModalEliminarButton'
							variant='outlined'
							color='secondary'
							onClick={handleSaveModal}
						>
							{t('botones.desactivar')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
