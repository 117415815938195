import Cookies from 'universal-cookie';

/*Descripción:Este componente se encarga de realizar el fetch del metodo GET de los T&T
Componente padre: Track & Trace
Componente hijo:
props: Sin uso de props, se ocupan las variables enpoint= Endpoint al que apuntará//
setLoading= Función para mostrar o no la animación de cargando //
setDataDetalles=Funcion para guardar los datos obtenidos con el método get //
navigate= función para redireccionar a una ruta especifica
*/

export default async function GetDocumentFetch(
	endpoint,
	setLoading,
	fileExtension,
	fileType,
	navigate,
	fileName
) {
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');

	// Funcion para descargar el archivo zip de los documentos
	const downloadBlobAsFile = (blob, fileName) => {
		const blobUrl = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = blobUrl;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(blobUrl);
	};

	setLoading(true);
	// Configuracion del header
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/' + fileType);
	myHeaders.append('Authorization', 'Bearer ' + token);
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + endpoint,
			{
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow',
			}
		);
		// Se valida el valor del estatus
		if (response.status === 200 || response.status === 500) {
			const blob = await response.blob();
			downloadBlobAsFile(blob, fileName + fileExtension);
			setLoading(false);
		} else if (response.status === 401) {
			// Si el token caduca se redirige al login
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		} else if (response.status === 504) {
			setLoading(false);
		} else if (!response.ok) {
			throw new Error('Error downloading the file.');
		} else {
			throw new Error('Error downloading the file.');
		}
	} catch (error) {
		console.error('Error descargando el archivo: ', error);
	}
}
