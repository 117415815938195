import * as React from 'react';
import { Close } from '@mui/icons-material';
import {
	Box,
	CssBaseline,
	Grid,
	IconButton,
	Link,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import MyRandomLogin from '../../components/RandomImageLogin';
import Cookies from 'universal-cookie';
import { useState } from 'react';
import LogoCard from '../../components/cards/LogoCard';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import LanguajeButtonsInicio from '../../components/navbar/LanguajeButtonsInicio';
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de desplegar la pantalla de ingreso de correo para la recuperacion de contraseña
Componente padre:Login.jsx
Componente hijo: MyRandomLogin
props: Sin uso de props
*/

export default function RecuperaContra() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [message, setMessage] = useState('');
	const [severity, setSeverity] = useState('');
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const [email, setEmail] = useState([]);
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;
	const handleClose1 = () => {
		setOpen(false);
	};

	// Se hace la funcion para realizar el cambio de contraseña
	const handleSubmit = (e) => {
		setLoading(true);
		e.preventDefault();
		// Se hacen las validaciones del captcha y de la contraseña
		if (email !== '') {
			setSeverity('success');
			setOpen(true);
			sendMail(email);
		} else if (email === '') {
			setMessage(t('alertas.ingresaCorreo'));
			setSeverity('error');
			setOpen(true);
			setLoading(false);
		} else {
			setMessage(t('alertas.ingresaDatos'));
			setSeverity('error');
			setOpen(true);
			setLoading(false);
		}
	};

	// Se hace el Data Fetch
	async function sendMail(email) {
		setLoading(true);
		const requestOptionsGuardar = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify({
				email: email,
			}),
			redirect: 'follow',
		};
		// Se hace la peticion al endpoint
		await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'user/solicitar-reestablecer-contrasena',
			requestOptionsGuardar
		)
			.then((response) => {
				// Se valida el estatus de la respuesta
				if (response.status === 200) {
					// Se muestra el mensaje de exito
					setMessage(t('alertas.recuperarContrasenia'));
					setSeverity('success');
					setOpen(true);
					setLoading(false);
				} else if (response.status === 504) {
					// Se muestra el mensaje de error
					setMessage(t('alertas.seEnvioCorreoError'));
					setSeverity('error');
					setOpen(true);
					setLoading(false);
				} else {
					// Se muestra el mensaje de error
					setMessage(t('alertas.seEnvioCorreoError'));
					setSeverity('error');
					setOpen(true);
					setLoading(false);
				}
			})
			.catch((error) => console.log('error ', error));
	}

	// Se hace la funcion para el cambio de estado del correo
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<CssBaseline />
			<MyRandomLogin />
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				lg={5}
				xl={4}
				component={Paper}
				elevation={6}
				square
			>
				<Grid
					container
					paddingTop={1}
					justifyContent='flex-end'
					paddingRight={1}
				>
					<LanguajeButtonsInicio />
				</Grid>
				<Box
					sx={{
						my: '12vh',
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<LogoCard />
					<Typography variant='h5' marginTop={4}>
						{t('centroAyuda.login.actualizarContrasena')}
					</Typography>
					<Typography variant='subtitle2'>
						{t('alertas.ingresaCorreo')}
					</Typography>
					<Box
						component='form'
						noValidate
						width={'75%'}
						sx={{ mt: 0.5 }}
					>
						<TextField
							onChange={handleEmailChange}
							margin='normal'
							required
							fullWidth
							id='email'
							label={t('booking.detalles.correo')}
							name='email'
							autoComplete='email'
							autoFocus
							className='margin-bottom-29'
						/>
						<LoadingButton
							loading={loading}
							id='login-button'
							type='submit'
							fullWidth
							variant='contained'
							onClick={handleSubmit}
							sx={{
								marginBottom: '29px',
								backgroundColor: '#002D58',
							}}
						>
							{t('botones.recuperar')}
						</LoadingButton>
						<Grid container>
							<Grid
								container
								sx={{
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}
							>
								<Link href='/contactanos' variant='body2'>
									{t('centroAyuda.contactanos')}
								</Link>
							</Grid>
							<Grid
								container
								sx={{
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}
							>
								<Link href='/' variant='body2'>
									{t('botones.inicioSesion')}
								</Link>
							</Grid>
						</Grid>
					</Box>
					<Snackbar
						open={open}
						autoHideDuration={10000}
						anchorOrigin={{ vertical, horizontal }}
					>
						<Alert
							action={
								<IconButton
									aria-label='close'
									color='inherit'
									size='small'
									onClick={() => {
										setOpen(false);
									}}
								>
									<Close fontSize='inherit' />
								</IconButton>
							}
							sx={{ mb: 2, width: '304px' }}
							severity={severity}
						>
							{message}
						</Alert>
					</Snackbar>
				</Box>
			</Grid>
		</Grid>
	);
}
