import React from 'react';
import { Card, CardMedia } from '@mui/material';
import Logo from '../../assets/img/logo/logo_accutrack.png';

/*Descripción:Este componente se encarga de renderizar el logo del portal
Componente padre: Login
Componente hijo:
props: Sin uso de props
*/

export default function LogoCard() {
	return (
		<Card elevation={0}>
			<CardMedia
				component='img'
				alt='Logo Accutrack'
				height='75'
				image={Logo}
			/>
		</Card>
	);
}
