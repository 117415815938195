import React from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import CallIcon from '@mui/icons-material/Call';

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: AgregarUsuario // EditarUsuarioModal
Componente hijo:
props: props.handleTextFieldChange = metodo para actualizar el texto de un Textfield //
	props.infoUsuario = informacion a desplegar del usuario //
*/

export default function DatosUsuarioForm(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid container rowSpacing={1.5}>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<FormControlLabel
					control={
						<Checkbox
							id='role'
							name='role'
							checked={props.isChecked}
							onChange={props.handleCheck}
						/>
					}
					label={
						<Typography class='Checkbox-Text'>
							{t(
								'centroAyuda.administracion.users.administrador'
							)}
						</Typography>
					}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('booking.detalles.nombre')}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['required', 'minStringLength:3']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						t('errorMessages.nombreInvalido'),
					]}
					onChange={props.handleTextFieldChange('name')}
					required
					id='name'
					name='name'
					rows={3}
					fullWidth
					value={props.infoUsuario.name || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PermContactCalendarIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('booking.detalles.apellido')}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['required', 'minStringLength:3']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						t('errorMessages.apellidoInvalido'),
					]}
					onChange={props.handleTextFieldChange('lastname')}
					required
					id='lastname'
					name='lastname'
					rows={3}
					fullWidth
					value={props.infoUsuario.lastname || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PermContactCalendarIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('booking.detalles.correo')}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['required', 'isEmail']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						t('errorMessages.correoInvalido'),
					]}
					onChange={props.handleTextFieldChange('username')}
					required
					id='username'
					name='username'
					variant='outlined'
					value={props.infoUsuario.username || ''}
					fullWidth
					type='email'
					disabled={!props.agregarUsuario}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<AlternateEmailIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('booking.detalles.telefono')}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={[
						'required',
						'isNumber',
						'minNumber:1000000000',
						'maxNumber:9999999999',
					]}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						t('errorMessages.noEsNumero'),
						t('errorMessages.numeroInvalido'),
						t('errorMessages.numeroInvalido'),
					]}
					onChange={props.handleTextFieldChange('phone')}
					required
					id='phone'
					name='phone'
					variant='outlined'
					fullWidth
					value={props.infoUsuario.phone || ''}
					type='tel'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<CallIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
		</Grid>
	);
}
