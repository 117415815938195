import React, { useEffect } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import EditIconDataGrid from '../Cargas/ActionIcons/EditIconDataGrid';
import DeleteIconDataGrid from '../Cargas/ActionIcons/DeleteIconDataGrid';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el DataGrid de las cargas del InfoStepper
Componente padre: ListadoCargas
Componente hijo: EditIconDataGrid // DeleteIconDataGrid
props: props.dataGrid = información a desplegar del DataGrid
*/

export default function DataGridCargasBL(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crea un arreglo con la información de las cargas
	const tableData = [];
	for (let i = 0; i < props.dataGrid.length; i++) {
		const carga = props.dataGrid[i];
		if (carga) {
			const rowData = {
				id: carga.idCarga,
				descripcion_esp: carga.descripcion_esp,
				peso: carga.peso,
				volumen: carga.volumen,
			};
			tableData.push(rowData);
		}
	}

	useEffect(() => {}, [props.dataGrid]);

	// Se cran las columnas del DataGrid
	const headCells = [
		{
			id: 'descripcion_esp',
			field: 'descripcion_esp',
			width: 160,
			headerName: t('booking.detalles.producto'),
			editable: false,
		},
		{
			id: 'peso',
			field: 'peso',
			width: 160,
			headerName: t('booking.detalles.peso'),
			editable: false,
		},
		{
			id: 'volumen',
			field: 'volumen',
			width: 160,
			headerName: t('booking.detalles.volumen'),
			editable: false,
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 140,
			disableClickEventBubbling: true,

			renderCell: (params) => {
				return (
					<>
						<EditIconDataGrid
							handleClick1={props.handleClick1}
							setAlertMessage={props.setAlertMessage}
							dataFetchBooking={props.dataFetchBooking}
							id={params.row.id}
							data={props.data}
							dataModalidadPago={props.dataModalidadPago}
							dataBulto={props.dataBulto}
							handleNext={props.handleNext}
							emptiesInputs={props.emptiesInputs}
						/>

						<DeleteIconDataGrid
							handleClick1={props.handleClick1}
							setAlertMessage={props.setAlertMessage}
							dataFetchBooking={props.dataFetchBooking}
							id={params.row.id}
							data={props.data}
						/>
					</>
				);
			},
		},
	];

	// Funcion para traducir el DataGrid
	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<Box className='ListaCargas' sx={{ height: '41vh' }}>
			<DataGrid
				localeText={localeText()}
				rows={tableData}
				columns={headCells}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 5 },
					},
				}}
				pageSizeOptions={[5, 10, 20]}
				sx={{
					'& .MuiDataGrid-cell': {
						fontSize: '12px',
						fontFamily: 'Source Sans Pro',
					},
					'& .MuiDataGrid-columnHeaderTitle': {
						fontSize: '13px',
						fontFamily: 'Open Sans',
					},
				}}
			/>
		</Box>
	);
}
