import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar los eventos de Aereopuerto relacionados a Aereo Expo
Componente padre: TielineSelectorAereoExpo
Componente hijo: TimelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosAereopuertoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Timeline position='alternate'>
			{props.data['fecha-cierre-vuelo'] === 'N.D.' ||
			props.data['fecha-cierre-vuelo'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-cierre-vuelo']}
					title={t('eventos.cierreVuelo')}
				/>
			)}
			{props.data['fecha-etd'] === 'N.D.' ||
			props.data['fecha-etd'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-etd']}
					title={t('eventos.salida')}
				/>
			)}
			{props.data['fecha-destino-final'] === 'N.D.' ||
			props.data['fecha-destino-final'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-destino-final']}
					title={t('eventos.arriboDestino')}
				/>
			)}
			{props.data['fecha-descarga'] === 'N.D.' ||
			props.data['fecha-descarga'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-descarga']}
					title={t('eventos.descarga')}
				/>
			)}
		</Timeline>
	);
}
