import { Grid, Typography, Card, CardContent } from '@mui/material';
import CardFotos from './user/Cards/CardFotos';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar una pantalla cuando no hay booking asociado al modulo
Componente padre: CommonBooking
Componente hijo: CardFotos
props: Sin uso de props
*/

export default function ContrataServicios() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Card elevation={0}>
			<CardContent>
				<Grid container spacing={3} sx={{ paddingLeft: '1%' }}>
					<Grid
						className='Aligner '
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<CardFotos />
					</Grid>
					<Grid
						className='Aligner'
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<Typography variant='h5'>
							{t('errorMessages.sinBookingAsociadoModalidad')}
						</Typography>
					</Grid>
					<Grid
						className='Aligner'
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<Typography variant='subtitle1'>
							{t(
								'errorMessages.acercateCustomerServiciosDisponibles'
							)}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
