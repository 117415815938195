import React, { useState } from 'react';
import { Grid, InputAdornment, Stack, Tooltip } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import SelectSearchEmbalajes from '../../SelectSearch/SelectSearchEmbalajes';
import SelectSearchProductos from '../../SelectSearch/SelectSearchProductos';
import LoadingButton from '@mui/lab/LoadingButton';
import { AddToPhotos, Info, PlaylistAdd } from '@mui/icons-material';
import AgregarEmbalaje from '../../../../catalogos/mis-embalajes/Modales/AgregarEmbalaje';
import AgregarProductoCP from '../../../../catalogos/productos-cartaporte/Modales/AgregarProductoCP';

ValidatorForm.addValidationRule('isDecimal', (value) => {
	// Regular expression pattern to match a decimal number
	const decimalRegex = /^-?\d*\.?\d+$/;
	if (decimalRegex.test(value)) {
		return true; // Validation passed
	}
	return false; // Validation failed
});

/*Descripción:Este componente se encarga de desplegar la información del step y formulario Carga
Componente padre: EditarCargaBLModal // AgregarCargaBLModal
Componente hijo:
props: props.handleTextFieldChange = propiedad para actualizar el texto ingresado //
	props.handleNext = funcion para ir al siguiente step //
	props.data = información guardada a desplegar en el formulario //
	props.emptiesInputs = funcion para seleccionar el estilo //
	props.ref = informacion de referencia de los validadores //
	props.actualizarDato = funcion para actualizar el dato seleccionado //
	props.dataBulto = informacion del selectSearch de los bultos
*/

export default function Cargas(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loadingButtonEmbalaje, setLoadingButtonEmbalaje] = useState(false);
	const [loadingButtonProducto, setLoadingButtonProducto] = useState(false);
	const [openModalEmbalaje, setOpenModalEmbalaje] = useState(false);
	const [openModalProducto, setOpenModalProducto] = useState(false);
	const [infoEmbalaje, setInfoEmbalaje] = useState({
		vAlias: '',
		vDescripcion: '',
		vClaveSat: '',
		bPeligroso: 0,
		vClaveSatPeligroso: '',
	});
	const [infoProductoCP, setInfoProductoCP] = useState({
		vAlias: '',
		vDescripcion: '',
		vClaveSat: '',
		bPeligroso: 0,
		vClaveSatPeligroso: '',
		vClaveUN: '',
	});

	//Funcion para abrir el modal de agregar embalaje
	const handleOpenModalEmbalaje = () => {
		setOpenModalEmbalaje(true);
		setLoadingButtonEmbalaje(true);
	};

	//Funcion para cerrar el modal de agregar embalaje
	const handleCloseModalEmbalaje = () => {
		setOpenModalEmbalaje(false);
		setLoadingButtonEmbalaje(false);
	};

	//Funcion para abrir el modal de agregar producto
	const handleOpenModalProducto = () => {
		setOpenModalProducto(true);
		setLoadingButtonProducto(true);
	};

	//Funcion para cerrar el modal de agregar producto
	const handleCloseModalProducto = () => {
		setOpenModalProducto(false);
		setLoadingButtonProducto(false);
	};

	//Opciones de mis embalajes
	const dataOptionsMisEmbalajes = props.dataMisEmbalajes.embalajes.map(
		(option) => {
			if (option.bPeligroso === true) {
				return {
					value: option.uiCodEmbalaje,
					label:
						'(ID:' +
						option.uiCodEmbalaje +
						') ' +
						option.vAlias +
						', ' +
						option.vClaveSat +
						', ' +
						option.vDescripcion +
						'⚠️',
				};
			} else {
				return {
					value: option.uiCodEmbalaje,
					label:
						'(ID:' +
						option.uiCodEmbalaje +
						') ' +
						option.vAlias +
						', ' +
						option.vClaveSat +
						', ' +
						option.vDescripcion,
				};
			}
		}
	);

	//Opciones de mis productos
	const dataOptionsMisProductos = props.dataMisProductos.cargas.map(
		(option) => {
			if (option.bPeligroso === true) {
				return {
					value: option.uiCodCarga,
					label:
						'(ID:' +
						option.uiCodCarga +
						') ' +
						option.vAlias +
						', ' +
						option.vClaveSat +
						', ' +
						option.vDescripcion +
						', ' +
						option.vFraccionArancelaria +
						'⚠️',
				};
			} else {
				return {
					value: option.uiCodCarga,
					label:
						'(ID:' +
						option.uiCodCarga +
						') ' +
						option.vAlias +
						', ' +
						option.vClaveSat +
						', ' +
						option.vDescripcion +
						', ' +
						option.vFraccionArancelaria,
				};
			}
		}
	);

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			spacing={1}
		>
			<Stack
				direction={{
					xs: 'row',
					sm: 'row',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
				sx={{ justifyContent: 'space-between' }}
			>
				<Grid item width={'100%'}>
					<SelectSearchEmbalajes
						dataDetalles={props.dataMisEmbalajes}
						actualizarDato={props.actualizarDato}
						required
						customLabel={
							t('catalogos.misEmbalajes.embalaje') + ' *'
						}
						id='uiCodEmbalaje'
						dataOptionsMisEmbalajes={dataOptionsMisEmbalajes}
					/>
				</Grid>
				<Grid item paddingTop={4} paddingLeft={1}>
					<Tooltip title={t('modales.agregarEmbalaje')} arrow>
						<LoadingButton
							loading={loadingButtonEmbalaje}
							variant='outlined'
							color='primary'
							id='BtnAgregarEmbalaje'
							sx={{
								fontSize: '12px',

								marginBottom: 1,
							}}
							onClick={handleOpenModalEmbalaje}
						>
							<AddToPhotos />
						</LoadingButton>
					</Tooltip>
				</Grid>
				<AgregarEmbalaje
					dataFetch={props.dataFetchMisEmbalajes}
					handleClickPage={props.handleOpenSnackBar}
					setAlertMessagePage={props.setAlertMessage}
					handleCloseModal={handleCloseModalEmbalaje}
					openModal={openModalEmbalaje}
					setOpenModal={setOpenModalEmbalaje}
					setInfoEmbalaje={setInfoEmbalaje}
					infoEmbalaje={infoEmbalaje}
				/>
			</Stack>
			<Stack
				direction={{
					xs: 'row',
					sm: 'row',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
				paddingBottom={1}
				sx={{ justifyContent: 'space-between' }}
			>
				<Grid item width={'100%'}>
					<SelectSearchProductos
						dataDetalles={props.dataMisProductos}
						actualizarDato={props.actualizarDato}
						required
						customLabel={
							t('catalogos.productosCartaPorte.producto') + ' *'
						}
						id='uiCodProducto'
						dataOptionsMisProductos={dataOptionsMisProductos}
					/>
				</Grid>
				<Grid item paddingTop={4} paddingLeft={1}>
					<Tooltip title={t('modales.agregarProducto')} arrow>
						<LoadingButton
							variant='outlined'
							color='primary'
							id='BtnAgregarProducto'
							loading={loadingButtonProducto}
							sx={{
								fontSize: '12px',

								marginBottom: 1,
							}}
							onClick={handleOpenModalProducto}
						>
							<PlaylistAdd />
						</LoadingButton>
					</Tooltip>
				</Grid>
				<AgregarProductoCP
					dataFetch={props.dataFetchMisProductos}
					handleClickPage={props.handleOpenSnackBar}
					setAlertMessagePage={props.setAlertMessage}
					handleCloseModal={handleCloseModalProducto}
					openModal={openModalProducto}
					setOpenModal={setOpenModalProducto}
					infoProductoCP={infoProductoCP}
					setInfoProductoCP={setInfoProductoCP}
				/>
			</Stack>
			<Grid item width={'100%'} paddingBottom={1}>
				<TextValidator
					ref={props.nPeso}
					validators={['required', 'isNumber', 'minNumber:1']}
					errorMessages={['El campo es requerido']}
					onChange={props.handleTextFieldChange('nPeso')}
					required
					id='nPeso'
					name='nPeso'
					type='number'
					label={t('cartaPorte.cargas.nPeso')}
					fullWidth
					value={props.infoCarga.nPeso || 0}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>KG</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item width={'100%'} paddingBottom={1}>
				<TextValidator
					ref={props.nCantidad}
					validators={['required', 'isNumber', 'minNumber:1']}
					errorMessages={['El campo es requerido']}
					onChange={props.handleTextFieldChange('nCantidad')}
					required
					id='nCantidad'
					name='nCantidad'
					type='number'
					label={t('cartaPorte.cargas.nCantidad')}
					fullWidth
					value={props.infoCarga.nCantidad || 0}
				/>
			</Grid>
			<Grid item width={'100%'}>
				<TextValidator
					ref={props.vUUID}
					onChange={props.handleTextFieldChange('vUUID')}
					id='vUUID'
					name='vUUID'
					label={
						t('cartaPorte.cargas.vUUID') + ' ' + t('forms.optional')
					}
					fullWidth
					value={props.infoCarga.vUUID || ''}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<Tooltip
									title={t('cartaPorte.vUUIDIngHelper')}
									arrow
									placement='right'
								>
									<Info
										className='primaryIcon'
										fontSize='medium'
									/>
								</Tooltip>
							</InputAdornment>
						),
					}}
				/>
			</Grid>
		</Stack>
	);
}
