import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

/*Descripción:Este componente se encarga de realizar el fetch del rol del usuario
Componente padre: Global
Componente hijo:
props: Sin uso de props, se ocupan las variables enpoint= Endpoint al que apuntará//
setLoading= Función para mostrar o no la animación de cargando //
setDataDetalles=Funcion para guardar los datos obtenidos con el método get //
navigate= función para redireccionar a una ruta especifica
*/

export default async function GetDataIdClienteToken(setCliente) {
	// Obtiene el token de la cookie
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');

	if (token !== undefined) {
		const claims = jwt_decode(token);
		const cliente = claims?.customer;
		setCliente(cliente);
	}
}
