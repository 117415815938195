import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	useMediaQuery,
	IconButton,
	Tooltip,
} from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import ConsignatarioForm from '../../Forms/ConsignatarioForm';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

/*Descripción:Este componente se encarga de renderizar el modal para editar los consignatarios.
Componente padre: ConsignatariosInfoCard.jsx
Componente hijo: ConsignatarioForm.jsx
props: data: Información del consignatario // id: id del consignatario //
dataDetallesPaises: Manda la información de los paises // loadingPaises: Variable para el tiempo de carga //
dataGrid: Información de los consignatarios // dataFetch: Función para obtener los datos de la API
handleCloseModal: Función para cerrar el modal // openModal: Variable para abrir el modal //
setOpenModal: Función para abrir el modal // infoConsignatario: Información del consignatario //
handleTextFieldChange: Función para cambiar el valor de los campos // actualizarDato: Función para actualizar los datos //
actualizarPais: Función para actualizar el pais // handleBackButtonClick: Función para regresar a la pantalla anterior //
consignatarioEdit: Información del consignatario // setConsignatarioEdit: Función para actualizar la información del consignatario //
handleCloseModal: Función para cerrar el modal // openModal: Variable para abrir el modal //
setOpenModal: Función para abrir el modal // dataDetallesPaises: Manda la información de los paises //
dataFetch: Función para obtener los datos de la API // dataDetallesPaises: Manda la información de los paises //
*/

export default function EditarConsignatariosModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '60%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: '60%',
		maxHeight: '95%',
		minHeight: '40vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const navigate = useNavigate();
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);

	// Se crea la funcion para guardar el usuario
	const handleSaveModal = () => {
		const requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify(props.consignatarioEdit),
			redirect: 'follow',
		};
		// Se hace la validación de los campos del formulario
		if (validaCarga() === true) {
			// Se realiza el Data Fetch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'consignatarios/update',
				requestOptions
			)
				.then((response) => {
					// Se valida el estatus de la peticion
					if (response.status === 200) {
						// Se actualiza la informacion mostrada
						props.dataFetch();
						// Se crea el mensaje de exito
						props.setAlertMessagePage({
							message: t('alertas.consignatarioEditado'),
							severity: 'success',
						});
						props.handleClickPage();
						props.handleBackButtonClick();
					} else if (response.status === 401) {
						// Si el token caduca se redirige al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						// Se crea el mensaje de error
						props.setAlertMessagePage({
							message: t('alertas.consignatarioEditadoError'),
							severity: 'error',
						});
						props.handleClickPage();
					}
				})
				.catch((error) => console.log('error', error));
			// Se cierra el modal
			props.setOpenAgregarConsignatarioModal(false);
		} else {
			// Se crea el mensaje de error al no llenar el formulario
			props.setAlertMessagePage({
				message: t('alertas.consignatarioSinDatos'),
				severity: 'error',
			});
			props.handleClickPage();
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	// Se crea la funcion para validar que todos los campos esten llenos
	function validaCarga() {
		var valida = true;
		Object.keys(props.consignatarioEdit).forEach(function (key) {
			if (props.consignatarioEdit[key] === '') {
				valida = false;
			}
		});
		return valida;
	}

	return (
		<Modal
			open={props.openAgregarConsignatarioModal}
			onClose={props.handleAgregarConsignatarioModalClose}
		>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={true}
				>
					<Grid item container rowSpacing={5}>
						<Grid item container rowSpacing={3}>
							<Grid item lg={9} md={9} sm={9} xs={9}>
								<Typography variant='h5'>
									{t('modales.editarConsignatario')}
								</Typography>
							</Grid>
							<Grid
								item
								lg={3}
								md={3}
								sm={3}
								xs={3}
								sx={{ textAlign: 'right' }}
							>
								<Tooltip title={t('botones.cerrar')}>
									<IconButton
										onClick={
											props.handleAgregarConsignatarioModalClose
										}
										id='cargaModalCerrarIconButton'
										variant='outlined'
										color='inherit'
										className='cerrarIconButton'
									>
										<Close />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<ConsignatarioForm
									actualizarPais={props.actualizarPais}
									handleTextFieldChange={
										props.handleTextFieldChange
									}
									actualizarDato={props.actualizarDato}
									handleCloseModal={props.handleCloseModal}
									openModal={props.openModal}
									setOpenModal={
										props.setOpenAgregarConsignatarioModal
									}
									dataDetallesPaises={
										props.dataDetallesPaises
									}
									infoConsignatario={props.consignatarioEdit}
									setConsignatarioEdit={
										props.setConsignatarioEdit
									}
									edit={true}
									handleKeyPress={handleKeyPress}
								/>
							</Grid>
						</Grid>
						<Grid item container>
							<Grid
								item
								lg={6}
								md={6}
								sm={6}
								xs={6}
								sx={{
									textAlign: 'right',
									position: 'absolute',
									bottom: '1.5vw',
									left: '1.5vw',
								}}
							>
								<Button
									id='cargaModalCerrarButton'
									variant='outlined'
									color='error'
									onClick={
										props.handleAgregarConsignatarioModalClose
									}
								>
									{t('botones.cancelar')}
								</Button>
							</Grid>
							<Grid
								item
								lg={6}
								md={6}
								sm={6}
								xs={6}
								sx={{
									textAlign: 'right',
									position: 'absolute',
									bottom: '1.5vw',
									right: '1.5vw',
								}}
							>
								<Button
									id='cargaModalActualizarButton'
									variant='outlined'
									color='primary'
									type='submit'
								>
									{t('botones.actualizar')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
