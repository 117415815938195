import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar los eventos del Booking relacionados al TN
Componente padre: TimelineSelectorUSCAMEX // TimelineSelectorDetallesCarga
Componente hijo: TimelineItem
props: props.dataCarga = informacion a desplegar de la carga
*/

export default function TimelineEventosUSCAMEX(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Timeline position='alternate'>
			{props.dataCarga.eventos.fechaPosicionamiento === 'NA' ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataCarga.eventos.fechaPosicionamiento}
					title={t('eventos.posicionamiento')}
				/>
			)}
			{props.dataCarga.eventos.fechaRecoleccion === 'NA' ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataCarga.eventos.fechaRecoleccion}
					title={t('eventos.recoleccion')}
				/>
			)}
			{props.dataCarga.eventos.fechaCruceFrontera === 'NA' ||
			props.dataCarga.eventos.fechaCruceFrontera === '' ||
			props.dataCarga.eventos.fechaCruceFrontera === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataCarga.eventos.fechaCruceFrontera}
					title={t('eventos.cruceFrontera')}
				/>
			)}
			{props.dataCarga.eventos.fechaCruceFronteraB === 'NA' ||
			props.dataCarga.eventos.fechaCruceFronteraB === '' ||
			props.dataCarga.eventos.fechaCruceFronteraB === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataCarga.eventos.fechaCruceFronteraB}
					title={t('eventos.cruceFrontera')}
				/>
			)}
			{props.dataCarga.eventos.fechaEntrega === 'NA' ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataCarga.eventos.fechaEntrega}
					title={t('eventos.entrega')}
				/>
			)}
		</Timeline>
	);
}
