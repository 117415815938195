import React, { useEffect, useState } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import {
	Box,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	Snackbar,
	Tooltip,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import ConsignatariosInfo from './ConsignatariosInfo';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { ArrowBack, Search, Visibility } from '@mui/icons-material';
import AgregarConsignatarioModal from './Modales/Consignatarios/AgregarConsignatarioModal';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de renderizar el contenido del data grid de los consignatarios.
Componente padre: ConsignatariosList.jsx // ConsignatariosInfo.jsx
Componente hijo: ConsignatariosInfo.jsx // AgragarConsignatarioModal.jsx
props: dataDetallesPaises: Manda la información de los paises //
loadingPaises: Variable para el tiempo de carga //
dataGrid: Información de los consignatarios //
dataFetch: Función para obtener los datos de la API
*/

export default function DataGridConsignatarios(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [click, setClick] = useState('dataGrid');
	const [dataToSend, setDataToSend] = useState('');
	const [pais, setPais] = useState('');
	const [indexPais, setIndexPais] = useState('');
	const [openModal, setOpenModal] = useState(false);
	// Se crean los const para el uso de los alerts globales
	const [alertMessagePage, setAlertMessagePage] = useState({
		type: '',
		message: '',
	});
	const [snackbarPage, setSnackbarPage] = useState({
		open: false,
		message: ' ',
	});
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;

	// Funciones para abrir y cerrar el Snackbar
	const handleClickPage = () => {
		setSnackbarPage({
			open: true,
			message: alertMessagePage,
		});
	};
	const handleClosePage = () => {
		setSnackbarPage({ open: false, message: '' });
	};
	// Se crea el modelo de los datos del consignatario
	const [infoConsignatario, setInfoConsignatario] = useState({
		nombre_razon: '',
		tax_id: '',
		pais: '',
	});

	// Función para abrir y cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const tableData = [];
	// Se hace una validacion de todos elementos del DataGrid
	for (let i = 0; i < props.dataGrid.consignatarios.length; i++) {
		const consignatario = props.dataGrid.consignatarios[i];
		// Se valida que el usuario exista
		if (consignatario) {
			const rowData = {
				id: i + 1,
				idToSend: consignatario.cod_consignatario,
				nombre_razon: consignatario.nombre_razon,
				tax_id: consignatario.tax_id,
				pais: consignatario.pais,
				cod_pais: consignatario.cod_pais,
			};
			tableData.push(rowData);
		}
	}

	// Se asignan las columnas del DataGrid
	const headCells = [
		{
			id: 'idToSend',
			field: 'idToSend',
			headerName: 'ID',
			width: 70,
			editable: false,
		},
		{
			id: 'nombre_razon',
			field: 'nombre_razon',
			width: 450,
			headerName: t('dataGrid.nombre'),
			editable: false,
		},
		{
			id: 'tax_id',
			field: 'tax_id',
			width: 150,
			headerName: t('booking.detalles.taxID'),
			editable: false,
		},
		{
			id: 'pais',
			field: 'pais',
			width: 100,
			headerName: t('dataGrid.pais'),
			editable: false,
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 100,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				return (
					<IconButton
						id='verMasIcon'
						className='primary'
						aria-label='add an alarm'
						sx={{ cursor: 'pointer' }}
						onClick={() => {
							sendInfo(params.row);
						}}
					>
						<Tooltip
							id='verMasIcon'
							title={t('catalogos.consignatario.verMas')}
						>
							<Visibility id='verMasIcon' color='primary' />
						</Tooltip>
					</IconButton>
				);
			},
		},
	];

	// Funciones para la busqueda del DataGrid
	const [searchTerm, setSearchTerm] = useState('');

	const filterRows = () => {
		return tableData.filter((row) =>
			headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(searchTerm.toLowerCase());
			})
		);
	};

	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	const sendInfo = (row) => {
		setClick('selectCards');
		setDataToSend(row);
	};

	// Funcion para actualizar el contenido del pais
	function actualizarPais(pais) {
		setPais(pais.value + '');
		setIndexPais(pais.label + '');
	}

	// useEffect para actualizar la informacion del consignatario
	useEffect(() => {
		setInfoConsignatario({
			...infoConsignatario,
			pais: pais,
		});
	}, [pais]);

	const viewToRender = () => {
		if (click === 'selectCards') {
			return (
				<>
					<ConsignatariosInfo
						id={dataToSend.idToSend}
						data={dataToSend}
						paisToSend={dataToSend.cod_pais}
						actualizarPais={actualizarPais}
						indexPais={indexPais}
						setIndexPais={setIndexPais}
						dataDetallesPaises={props.dataDetallesPaises}
						infoConsignatario={infoConsignatario}
						setInfoConsignatario={setInfoConsignatario}
						setAlertMessagePage={setAlertMessagePage}
						handleClickPage={handleClickPage}
					/>
				</>
			);
		} else if (click === 'dataGrid') {
			return (
				<>
					<Grid item container>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							sx={{ textAlign: 'left', paddingBottom: '0.5%' }}
						>
							<Tooltip title={t('botones.atras')}>
								<IconButton
									size='small'
									component={NavLink}
									to='/configuracion/catalogos'
									className='cerrarIconButton'
								>
									<ArrowBack />
								</IconButton>
							</Tooltip>
						</Grid>

						<Grid
							item
							lg={6}
							md={6}
							sm={6}
							xs={6}
							sx={{ paddingTop: '8px', paddingBottom: '4px' }}
						>
							<TextField
								label={t('tablero.trackTrace.buscar')}
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								autoComplete='off'
								variant='outlined'
								size='small'
								fullWidth
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<Search />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid
							item
							xs={6}
							sm={6}
							md={6}
							lg={6}
							sx={{ textAlign: 'right', paddingTop: '8px' }}
						>
							<Button
								variant='outlined'
								color='primary'
								id='BtnAgregarCargaBL'
								onClick={handleOpenModal}
								sx={{
									fontSize: '12px',
									marginBottom: 1,
								}}
								startIcon={<DomainAddIcon />}
							>
								{t('botones.agregar')}
							</Button>
						</Grid>
					</Grid>
					<Box
						sx={{
							height: '60vh',
							paddingTop: '7px',
							paddingBottom: '7px',
							width: '100%',
						}}
					>
						<DataGrid
							disableRowSelectionOnClick
							localeText={localeText()}
							rows={filterRows()}
							columns={headCells}
							initialState={{
								pagination: {
									paginationModel: {
										page: 0,
										pageSize: 10,
									},
								},
							}}
							pageSizeOptions={[10, 20, 30]}
							sx={{
								'& .MuiDataGrid-cell': {
									fontSize: '12px',
									fontFamily: 'Source Sans Pro',
								},
								'& .MuiDataGrid-columnHeaderTitle': {
									fontSize: '13px',
									fontFamily: 'Open Sans',
								},
							}}
						/>
					</Box>
					<AgregarConsignatarioModal
						infoConsignatario={infoConsignatario}
						setInfoConsignatario={setInfoConsignatario}
						actualizarPais={actualizarPais}
						indexPais={indexPais}
						setIndexPais={setIndexPais}
						dataDetallesPaises={props.dataDetallesPaises}
						handleCloseModal={handleCloseModal}
						openModal={openModal}
						handleClose={handleCloseModal}
						setOpenModal={setOpenModal}
						dataFetch={props.dataFetch}
						id={props.id}
						setAlertMessagePage={setAlertMessagePage}
						handleClickPage={handleClickPage}
						setSnackbarPage={setSnackbarPage}
					/>
				</>
			);
		}
	};

	useEffect(() => {
		viewToRender();
	}, [click]);

	return (
		<>
			{viewToRender()}
			<Snackbar
				open={snackbarPage.open}
				autoHideDuration={10000}
				onClose={handleClosePage}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClosePage}
					severity={alertMessagePage.severity}
				>
					{alertMessagePage.message}
				</Alert>
			</Snackbar>
		</>
	);
}
