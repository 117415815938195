import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	InputAdornment,
	Grid,
	TextField,
} from '@mui/material';
import CentroAyudaCard from './Cards/CentroAyudaCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import MyImage from '../../assets/img/logo/logo_accutrack.png';
import { Search } from '@mui/icons-material';
import GetDataFetch from '../get-data/GetDataFetch';
import MySkeletonPage from '../Skeletons/SkeletonPage';
import { useEffect } from 'react';
import Grow from '@mui/material/Grow';
import LanguajeButtonsInicio from '../navbar/LanguajeButtonsInicio';

export default function CentroAyudaTableTable(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [dataDettallesCards, setDataDettallesCards] = useState([]);
	const [loading, setLoading] = useState(true);

	// Se hace el Data Fetch
	const dataFetch = async () => {
		setLoading(true);
		let url = 'centro-de-ayuda/all';
		await GetDataFetch(url, setLoading, setDataDettallesCards, navigate);
	};

	// useEffect para hacer el Data Fetch
	useEffect(() => {
		dataFetch();
	}, []);

	const navigate = useNavigate();
	function onClickHome() {
		navigate('/');
	}

	const inicioButton = () => {
		if (token === undefined) {
			return (
				<Button
					onClick={onClickHome}
					variant='text'
					sx={{ paddingTop: '1%' }}
				>
					{t('botones.inicioSesion')}
				</Button>
			);
		} else {
			<></>;
		}
	};

	// Searchbar
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredCards, setFilteredCards] = useState([]);

	const handleSearch = (event) => {
		const term = event.target.value.toLowerCase();
		setSearchTerm(term);
		// Filtra los cards basándose en el término de búsqueda
		const filtered = dataDettallesCards.tutoriales.filter((card) => {
			return (
				card.titulo_esp.toLowerCase().includes(term) ||
				card.descripcion_esp.toLowerCase().includes(term) ||
				card.titulo_eng.toLowerCase().includes(term) ||
				card.descripcion_eng.toLowerCase().includes(term)
			);
		});
		setFilteredCards(filtered);
	};

	return (
		<>
			{loading ? (
				<MySkeletonPage />
			) : (
				<Grid
					item
					container
					rowSpacing={1}
					columnSpacing={1}
					sx={{ paddingTop: '1%' }}
				>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Card elevation={4} className='contact-home-card '>
							<Grid
								item
								container
								sx={{
									paddingTop: '1%',
									paddingRight: '1%',
									paddingLeft: '1%',
								}}
							>
								<Grid
									item
									xs={6}
									sm={6}
									md={6}
									lg={6}
									sx={{ textAlign: 'left' }}
								>
									<LanguajeButtonsInicio />
								</Grid>
								<Grid
									item
									xs={6}
									sm={6}
									md={6}
									lg={6}
									sx={{ textAlign: 'right' }}
								>
									{inicioButton()}
								</Grid>
							</Grid>
							<CardMedia
								style={{
									height: '80px',
									width: 'auto',
									marginLeft: 'auto',
									marginRight: 'auto',
									paddingTop: '1%',
								}}
								component='img'
								image={MyImage}
								alt='logo'
							/>
							<CardContent>
								<Box
									sx={{
										textAlign: 'center',
									}}
								>
									<Typography
										gutterBottom
										variant='h5'
										component='div'
									>
										{t('centroAyuda.necesitasAyuda')}
									</Typography>
								</Box>
								<Box sx={{ margin: 'auto', width: '75%' }}>
									<TextField
										id='searchBarCentroAyuda'
										autoComplete='disabled'
										label={t('centroAyuda.buscaTutorial')}
										variant='outlined'
										fullWidth
										value={searchTerm}
										onChange={handleSearch}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<Search />
												</InputAdornment>
											),
										}}
									/>
								</Box>
								<Box
									sx={{
										paddingTop: '1%',
										textAlign: 'center',
									}}
								>
									<Typography
										variant='body2'
										color='text.secondary'
									>
										{t('centroAyuda.escribenosA')}{' '}
										{process.env.REACT_APP_CONTACT_EMAIL}
									</Typography>
								</Box>
							</CardContent>
						</Card>
					</Grid>

					{searchTerm !== '' && filteredCards.length > 0 ? (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Box
									sx={{
										overflowY: 'auto',
										height: '500px',
										width: '100%',
									}}
								>
									<Grid
										container
										spacing={{ xs: 2 }}
										columns={{ xs: 12, sm: 8, md: 15 }}
									>
										{filteredCards.map(
											(cardProps, index) => (
												<Grid
													item
													xs={6}
													sm={4}
													md={5}
													key={index}
												>
													<CentroAyudaCard
														{...cardProps}
													/>
												</Grid>
											)
										)}
									</Grid>
								</Box>
							</Grid>
						</>
					) : (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Box
									sx={{
										overflowY: 'auto',
										height: '500px',
										width: '100%',
									}}
								>
									<Grid
										container
										spacing={{ xs: 2 }}
										columns={{ xs: 12, sm: 8, md: 15 }}
									>
										{dataDettallesCards.tutoriales.map(
											(cardProps, index) => (
												<>
													<Grow
														in={true}
														timeout={
															index > 7
																? 7 * 500
																: 500 * index
														}
													>
														<Grid
															item
															xs={6}
															sm={4}
															md={3}
															key={index}
														>
															<CentroAyudaCard
																{...cardProps}
															/>
														</Grid>
													</Grow>
												</>
											)
										)}
									</Grid>
								</Box>
							</Grid>
						</>
					)}
				</Grid>
			)}
		</>
	);
}
