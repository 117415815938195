import { Email } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import MailSenderModal from '../Modales/MailSenderModal';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el boton de editar
Componente padre: DataGridUsuarios
Componente hijo: MailSenderModal
props: props.id = Información del ID de la fila //
	props.handleCheck = Funcion para validar el checkbox
*/

export default function EmailSenderIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	// Funcion para abrir y cerrar el Modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	const handleEditClick = async () => {
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.reenviarCorreo')}>
						<IconButton
							id='emailIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleEditClick}
						>
							<Email color='primary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<MailSenderModal
				handleClick1={props.handleClick1}
				setAlertMessage={props.setAlertMessage}
				dataFetch={props.dataFetch}
				id={props.id}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
		</>
	);
}
