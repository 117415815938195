import React, { useEffect, useState } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import EditIconDataGrid from './ActionIcons/EditIconDataGrid';
import DeleteIconDataGrid from './ActionIcons/DeleteIconDataGrid';
import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material';
import ActivateIconDataGrid from './ActionIcons/ActivateIconDataGrid';
import EmailSenderIconDataGrid from './ActionIcons/MailSenderIconDataGrid';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { PersonAddAlt1, Search } from '@mui/icons-material';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: ListadoUsuarios
Componente hijo:
props: props.dataGrid = información a desplegar del DataGrid //
	props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert //
	rops.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.handelCloseModal = funcion para cuando cierre el modal
*/

export default function DataGridUsuarios(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Obtener los roles del usuario
	const cookies = new Cookies();
	const [user, setUserRole] = useState('');

	useEffect(() => {
		var token = cookies.get('jwt_authorization');
		if (token !== undefined) {
			const claims = jwt_decode(token);
			const userClaims = claims?.sub;
			const commaIndex = userClaims.indexOf(',');
			const user = userClaims.substring(
				commaIndex + 1,
				userClaims.length
			);
			setUserRole(user);
		}
	}, []);

	const tableData = [];
	// Se hace una validacion de todos elementos del DataGrid
	for (let i = 0; i < props.dataGrid.length; i++) {
		const usuario = props.dataGrid[i];
		// Se valida que el usuario exista
		if (usuario) {
			// Se valida que el usuario tenga un last-active
			if (usuario['last-active'] === '') {
				// Se valida que el usuario este habilitado
				if (usuario.enabled === 1) {
					// Se asignan los datos a las columnas correspondientes de la fila
					const rowData = {
						id: i + 1,
						username: usuario.username,
						name: usuario.name,
						lastname: usuario.lastname,
						phone: usuario.phone,
						enabled: t('dataGrid.habilitado'),
						lastActive: t('dataGrid.usuarioNoRegistrado'),
					};
					// Se asigna la fila de datos
					tableData.push(rowData);
				} else {
					// Si el usuario no esta habilitado se muestra el dato correspondiente
					const rowData = {
						id: i + 1,
						username: usuario.username,
						name: usuario.name,
						lastname: usuario.lastname,
						phone: usuario.phone,
						enabled: t('dataGrid.deshabilitado'),
						lastActive: t('dataGrid.usuarioNoRegistrado'),
					};
					// Se asigna la fila de datos
					tableData.push(rowData);
				}
			} else {
				// Si el usuario esta habilidato y si tiene un last-active
				if (usuario.enabled === 1) {
					// Se asignan los datos a las columnas correspondientes de la fila
					const rowData = {
						id: i + 1,
						username: usuario.username,
						name: usuario.name,
						lastname: usuario.lastname,
						phone: usuario.phone,
						enabled: t('dataGrid.habilitado'),
						lastActive: usuario['last-active'],
					};
					// Se aigna la fila de datos
					tableData.push(rowData);
				} else {
					// Si el usuario no esta habilitado y si tiene un last-active
					const rowData = {
						id: i + 1,
						username: usuario.username,
						name: usuario.name,
						lastname: usuario.lastname,
						phone: usuario.phone,
						enabled: t('dataGrid.deshabilitado'),
						lastActive: usuario['last-active'],
					};
					// Se asigna la fila de datos
					tableData.push(rowData);
				}
			}
		}
	}

	// Se asignan las columnas del DataGrid
	const headCells = [
		{
			id: 'username',
			field: 'username',
			width: 230,
			headerName: t('dataGrid.correo'),
			editable: false,
		},
		{
			id: 'name',
			field: 'name',
			width: 145,
			headerName: t('dataGrid.nombre'),
			editable: false,
		},
		{
			id: 'lastname',
			field: 'lastname',
			width: 145,
			headerName: t('dataGrid.apellidos'),
			editable: false,
		},
		{
			id: 'enabled',
			field: 'enabled',
			width: 120,
			headerName: t('dataGrid.estatus'),
			editable: false,
		},
		{
			id: 'lastActive',
			field: 'lastActive',
			width: 160,
			headerName: t('dataGrid.ultimaConexion'),
			editable: false,
		},
		{
			id: 'acciones',
			field: 'actions',
			headerName: t('dataGrid.acciones'),
			sortable: false,
			width: 140,
			disableClickEventBubbling: true,
			// Se renderizan los botones especiales
			renderCell: (params) => {
				return (
					<>
						{user !== params.row.username && (
							<>
								<>
									<EditIconDataGrid
										role={props.role}
										handleCheck={props.handleCheck}
										handleCheck2={props.handleCheck2}
										isChecked={props.isChecked}
										dataFetch={props.dataFetch}
										handleClick1={props.handleClick1}
										setAlertMessage={props.setAlertMessage}
										handleCloseModal={
											props.handleCloseModal
										}
										openModal={props.openModal}
										setOpenModal={props.setOpenModal}
										id={params.row.username}
									/>
								</>
								{params.row.enabled ===
									t('dataGrid.habilitado') && (
									<>
										<DeleteIconDataGrid
											role={props.role}
											handleCheck={props.handleCheck}
											isChecked={props.isChecked}
											dataFetch={props.dataFetch}
											handleClick1={props.handleClick1}
											setAlertMessage={
												props.setAlertMessage
											}
											handleCloseModal={
												props.handleCloseModal
											}
											openModal={props.openModal}
											setOpenModal={props.setOpenModal}
											id={params.row.username}
										/>
										<EmailSenderIconDataGrid
											role={props.role}
											handleCheck={props.handleCheck}
											isChecked={props.isChecked}
											dataFetch={props.dataFetch}
											handleClick1={props.handleClick1}
											setAlertMessage={
												props.setAlertMessage
											}
											handleCloseModal={
												props.handleCloseModal
											}
											openModal={props.openModal}
											setOpenModal={props.setOpenModal}
											id={params.row.username}
										/>
									</>
								)}
								{params.row.enabled ===
									t('dataGrid.deshabilitado') && (
									<>
										<ActivateIconDataGrid
											role={props.role}
											handleCheck={props.handleCheck}
											isChecked={props.isChecked}
											dataFetch={props.dataFetch}
											handleClick1={props.handleClick1}
											setAlertMessage={
												props.setAlertMessage
											}
											handleCloseModal={
												props.handleCloseModal
											}
											openModal={props.openModal}
											setOpenModal={props.setOpenModal}
											id={params.row.username}
										/>
									</>
								)}
							</>
						)}
					</>
				);
			},
		},
	];

	// Funciones para la busqueda del DataGrid
	const [searchTerm, setSearchTerm] = useState('');

	const filterRows = () => {
		return tableData.filter((row) =>
			headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(searchTerm.toLowerCase());
			})
		);
	};

	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<>
			<Grid item container sx={{ paddingTop: '1%' }}>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<TextField
						label={t('tablero.trackTrace.buscar')}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						autoComplete='off'
						variant='outlined'
						size='small'
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Search />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={6}
					sx={{ textAlign: 'right' }}
				>
					<Button
						variant='outlined'
						color='primary'
						id='BtnAgregarUsuario'
						sx={{
							fontSize: '12px',

							marginBottom: 1,
						}}
						onClick={props.handleOpenModal}
						startIcon={<PersonAddAlt1 />}
					>
						{t('botones.agregar')}
					</Button>
				</Grid>
			</Grid>
			<Box
				sx={{ height: '66vh', paddingTop: '1%', paddingBottom: '7px' }}
			>
				<DataGrid
					localeText={localeText()}
					rows={filterRows()}
					columns={headCells}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10, 20, 30]}
					sx={{
						'& .MuiDataGrid-cell': {
							fontSize: '12px',
							fontFamily: 'Source Sans Pro',
						},
						'& .MuiDataGrid-columnHeaderTitle': {
							fontSize: '13px',
							fontFamily: 'Open Sans',
						},
					}}
				/>
			</Box>
		</>
	);
}
