import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid, LinearProgress } from "@mui/material";
import MyImage from "../../../assets/img/login/img-login-one.jpg";
import MyImage2 from "../../../assets/img/login/img-login-two.png";
import MyImage3 from "../../../assets/img/login/img-login-three.png";
import MyImage4 from "../../../assets/img/login/img-login-four.png";
import MyImage5 from "../../../assets/img/login/img-login-five.png";
import MyImage6 from "../../../assets/img/login/img-login-six.png";
import MyImage7 from "../../../assets/img/login/img-login-seven.png";
import MyImage8 from "../../../assets/img/login/img-login-eight.png";
import MyImage9 from "../../../assets/img/login/img-login-nine.png";
import MyImage10 from "../../../assets/img/login/img-login-ten.png";
import MyImage11 from "../../../assets/img/login/img-login-eleven.png";
import { useState } from "react";
import { useEffect } from "react";
import { LocalPhone, AlternateEmail } from "@mui/icons-material";
import LanguajeButtonsInicio from "../../navbar/LanguajeButtonsInicio.jsx";

/*Descripción:Este componente se encarga de renderizar el card de la info del usuario
Componente padre: UserProfile
Componente hijo:
props: props.user = Info de el usuario
*/

export default function CardUser(props) {
	const [bgImage, setBgImage] = useState("");

	useEffect(() => {
		const images = [
			MyImage,
			MyImage2,
			MyImage3,
			MyImage4,
			MyImage5,
			MyImage6,
			MyImage7,
			MyImage8,
			MyImage9,
			MyImage10,
			MyImage11,
		]; // Lista de imágenes disponibles
		const randomIndex = Math.floor(Math.random() * images.length); // Genera un índice aleatorio
		setBgImage(images[randomIndex]); // Establece la imagen de fondo aleatoria
	}, []);

	return (
		<Card sx={{ width: "100%" }}>
			<CardMedia
				component="img"
				height="170"
				image={bgImage}
				alt="green iguana"
			/>
			{props.loading ? (
				<CardContent>
					<LinearProgress />
				</CardContent>
			) : (
				<CardContent>
					<Grid container spacing={12} justifyContent="space-between">
						<Grid item >
							<Typography gutterBottom variant="h5" component="div">
								{props.users.name + " " + props.users.lastname}
							</Typography>
							<Grid container spacing={2}>
								<Grid item spacing={0}>
									<AlternateEmail color="primary" />
								</Grid>
								<Grid item spacing={0}>
									<Typography variant="body1" color="text.secondary">
										{props.users.username}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item spacing={0}>
									<LocalPhone color="primary" />
								</Grid>
								<Grid item spacing={0}>
									<Typography variant="body1" color="text.secondary">
										{props.users.phone}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item >
							<LanguajeButtonsInicio />
						</Grid>
					</Grid>
				</CardContent>
			)}
		</Card>
	);
}
