import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import ErrorBoundary from '../../components/cards/ErrorBoundary';
import FileUploadModal from '../../components/file-upload/FileUploadModal';
import { useState } from 'react';
import { CloudUpload } from '@mui/icons-material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: CommonLayout.jsx //
	BookingMaritimoExpoSR //
	BookingMaritimoImpoSR //
	BookingAereoExpoSR //
	BookingAereoImpoSR //
	BL //
	BookingTerrestreNacioSR //
	BookingDespachoImpoSR //
	BookingTerrestreCentroameExpoSearchResult//
	BookingDespachoExpoSR //
	BookingUSCAMEXExpoFTLSR
Componente hijo: FileUploadModal
props: props.selectedBooking = booking seleccionado
*/

export default function IndexFileUpload(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	return (
		<Box sx={{ height: '46vh' }}>
			<Grid container spacing={3} sx={{ paddingLeft: '1%' }}>
				<Grid className='Aligner ' item lg={12} md={12} sm={12} xs={12}>
					<ConstructionIcon fontSize='large' color='primary' />
				</Grid>
				<Grid className='Aligner' item lg={12} md={12} sm={12} xs={12}>
					<Typography variant='h5'>
						{t('cards.estamosTrabajando')}
					</Typography>
				</Grid>
				<Grid className='Aligner' item lg={12} md={12} sm={12} xs={12}>
					<Typography variant='subtitle1'>
						{t('cards.prontoPodras')}
					</Typography>
				</Grid>
			</Grid>
			<FileUploadModal
				selectedBooking={props.selectedBooking}
				openModal={openModal}
				handleCloseModal={handleCloseModal}
			/>
		</Box>
	);
}
