import { Delete } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import EliminarContactosModal from '../../Modales/Contactos/EliminarContactosModal';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el icono de eliminar en el DataGrid de los contactos.
Componente padre: ListContactos.jsx
Componente hijo: EliminarContactosModal.jsx
props: dataFetch: Función para obtener los datos de la API // id: id del consignatario //
idContacto: id del contacto // handleCloseModal: Función para cerrar el modal //
openModal: Variable para abrir el modal // setOpenModal: Función para abrir el modal //
*/

export default function DeleteIconDataGrid(props) {
	const { t } = useTranslation();
	// Funciones para el modal
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// Función llamada al dar click al icono de eliminar
	const handleDeleteClick = async () => {
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.eliminar')}>
						<IconButton
							id='deleteIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleDeleteClick}
						>
							<Delete color='secondary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<EliminarContactosModal
				dataFetch={props.dataFetch}
				id={props.id}
				idContacto={props.idContacto}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
				setAlertMessagePage={props.setAlertMessagePage}
				handleClickPage={props.handleClickPage}
			/>
		</>
	);
}
