import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información de los eventos de Aereo Impo
Componente padre: EVentosBookingAereoImpo
Componente hijo:
props: props.data = informacion a desplegar //
	props.dataInformacionCarga = informacion a desplegar sobre la carga //
	props.dataDespacho = informacion a desplegar sobre el despacho
*/

export default function InfoFechasAereoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Box sx={{ overflowY: 'auto', maxHeight: '380px', width: '100%' }}>
			<Grid item container rowSpacing={3} lg={12} md={12} sm={12} xs={12}>
				<Grid item lg={4} md={4} sm={4} xs={4}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.hawb')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.dataInformacionCarga['hawb'] !== undefined
							? props.dataInformacionCarga['hawb']
							: t('booking.estatus.sinHAWB')}
					</Typography>
				</Grid>
				<Grid item lg={4} md={4} sm={4} xs={4}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.mawb')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.dataInformacionCarga['mawb'] !== undefined
							? props.dataInformacionCarga['mawb']
							: t('booking.estatus.sinMAWB')}
					</Typography>
				</Grid>
				<Grid item lg={4} md={4} sm={4} xs={4}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.vuelo')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['vuelo'] !== undefined
							? props.data['vuelo']
							: t('booking.estatus.sinVuelo')}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.incoterm')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.dataInformacionCarga['descripcion-incoterm'] !==
						undefined
							? props.dataInformacionCarga['descripcion-incoterm']
							: t('booking.estatus.sinIncoterm')}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.vueloDeConexion')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['vuelo-conexion'] !== ''
							? props.data['vuelo-conexion']
							: t('booking.estatus.sinVueloConexion')}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.via')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['via'] !== undefined
							? props.data['via']
							: t('booking.estatus.incoterm')}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.detalles.aerolinea')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['aerolinea'] !== undefined
							? props.data['aerolinea']
							: t('booking.detalles.sinAerolinea')}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.ciudadOrigen')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['poo'] !== undefined
							? props.data['poo']
							: t('booking.estatus.sinPOO')}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.detalles.aeropuertoOrigen')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['aod'] !== undefined
							? props.data['aod']
							: t('booking.detalles.sinAOD')}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.aereopuertoDestino')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['aoa'] !== undefined
							? props.data['aoa']
							: t('booking.detalles.sinAOA')}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.detalles.destinoFinal')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['destino-final'] !== undefined
							? props.data['destino-final']
							: t('booking.detalles.sinDestinoFinal')}
					</Typography>
				</Grid>

				{props.dataDespacho.length === undefined ? (
					<></>
				) : (
					<Grid item lg={6} md={6} sm={6} xs={6}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.estatus.modulacion')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.dataDespacho['resultado-modulacion'] !==
							undefined ? (
								<>
									{props.dataDespacho[
										'resultado-modulacion'
									] === 'V' ? (
										<CircleIcon color='success' />
									) : (
										<>
											{props.dataDespacho[
												'resultado-modulacion'
											] === 'R' && (
												<CircleIcon
													sx={{ color: 'red' }}
												/>
											)}{' '}
										</>
									)}
								</>
							) : (
								<>
									<CircleIcon color='disabled' />
								</>
							)}
						</Typography>
					</Grid>
				)}
				{props.dataDespacho['resultado-modulacion'] !== 'R' ? (
					<></>
				) : (
					<Grid item lg={6} md={6} sm={6} xs={6}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.estatus.cargaPeligrosa')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data['carga-peligrosa'] !== undefined ? (
								<>
									{props.data['carga-peligrosa'] === 'N' ? (
										<WarningIcon color='disabled' />
									) : (
										<>
											{props.data['carga-peligrosa'] ===
												'S' && (
												<WarningIcon
													sx={{ color: 'red' }}
												/>
											)}{' '}
										</>
									)}
								</>
							) : (
								<>
									<WarningIcon color='disabled' />
								</>
							)}
						</Typography>
					</Grid>
				)}

				{props.data['carga-peligrosa'] !== '' ||
					props.data['carga-peligrosa'] !==
						undefined(
							<>
								<Grid item lg={6} md={6} sm={6} xs={6}>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.estatus.cargaPeligrosa')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data['id-carga-peligrosa'] !==
										undefined
											? props.data['id-carga-peligrosa']
											: t(
													'booking.estatus.sinIdCargaPeligrosa'
											  )}
									</Typography>
								</Grid>
								<Grid item lg={6} md={6} sm={6} xs={6}>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('booking.estatus.unbr')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data['UNBR'] !== undefined
											? props.data['UNBR']
											: t('booking.estatus.sinUNBR')}
									</Typography>
								</Grid>
							</>
						)}
			</Grid>
		</Box>
	);
}
