import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import InfoContenedorTCAExpoFCL from './Info/InfoContenedorTCAExpoFCL';
import SelectSearchSecuencia from '../../SelectSearchSecuencia';
import TimelineSelectorTCAExpoFCL from './Timeline/TimelineSelectorTCAExpoFCL';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de el estatus de el booking seleccionado y su Timeline
Componente padre: BookingTerrestreCentroameExpoSearchResult
Componente hijo:SelectedSearchContenedor //
	InfoContenedor //
	TimelineSelector //
props: props.data = informacion a desplegar
*/

export default function EventosBookingTerrestreCentroame(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean los const para el uso de la informacion de los Bookings
	let estado;
	if (props.data.empaques === undefined) {
		estado = 0;
	} else {
		estado = props.data.empaques[0];
	}
	const [dataEmpaque, setEmpaque] = useState(estado);
	const [indexEmpaque, setIndexEmpaque] = useState(0);
	let indice;
	const [selectedSecuencia, setSelectedSecuencia] = useState(1);

	// Funcion para renderizar la informacion del booking si es que existen empaques
	function ContentToRender() {
		if (props.data.empaques === undefined) {
			return <Box>{t('eventos.noTieneRegistroCarga')}</Box>;
		} else {
			return (
				<Grid container spacing={1}>
					<Grid
						item
						sx={{ paddingBottom: '1%' }}
						lg={6}
						md={6}
						sm={6}
						xs={12}
					>
						<Grid container sx={{ paddingBottom: '3%' }}>
							{props.data.empaques === undefined ? (
								<Box>{t('eventos.sinCargaAsignada')}</Box>
							) : (
								<Box></Box>
							)}

							{props.data.empaques.length === 0 ? (
								<Typography variant='body1' color='initial'>
									{t('eventos.sinCargaAsignada')}
								</Typography>
							) : props.data.empaques === undefined ? (
								<Box></Box>
							) : (
								<SelectSearchSecuencia
									actualizarContenedor={actualizarContenedor}
									selectedSecuencia={selectedSecuencia}
									indexEmpaque={indexEmpaque}
									customLabel={t('selectSearch.carga')}
									id='secuencia'
									name='secuencia'
									dataOption={props.data.empaques}
								/>
							)}
						</Grid>
						<Grid container spacing={2}>
							{props.data.empaques === undefined ? (
								<></>
							) : (
								<InfoContenedorTCAExpoFCL
									dataEmpaque={dataEmpaque}
									data={props.data}
								/>
							)}
						</Grid>
					</Grid>
					<Grid
						item
						spacing={1}
						sx={{ paddingTop: '1%' }}
						lg={6}
						md={6}
						sm={6}
						xs={12}
					>
						<TimelineSelectorTCAExpoFCL
							dataEmpaque={dataEmpaque}
							data={props.data}
						/>
					</Grid>
				</Grid>
			);
		}
	}

	// Funcion para actualizar la informacion del contenedor
	function actualizarContenedor(index) {
		indice = index;
		setEmpaque(props.data.empaques[indice - 1]);
		setIndexEmpaque(indice);
		setSelectedSecuencia(indice);
	}

	return (
		<>
			<ContentToRender />
		</>
	);
}
