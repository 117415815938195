import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Grid, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información de los detalles del estatus de Aereo Expo
Componente padre: EventosBookingAereoExpo
Componente hijo:
props: props.data = informacion a desplegar //
	props.dataDespacho = informacion a desplegar sobre el despacho //
	props.dataInformacionCarga = informacion a desplegar sobre la carga
*/

export default function InfoFechasAereoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Box sx={{ overflowY: 'auto', maxHeight: '380px', width: '100%' }}>
			<Grid item container rowSpacing={3} lg={12} md={12} sm={12} xs={12}>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.hawb')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.dataInformacionCarga['hawb']}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.mawb')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.dataInformacionCarga['mawb']}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.vuelo')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['vuelo'] !== undefined
							? props.data['vuelo']
							: 'Sin vuelo'}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.detalles.aerolinea')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data.aerolinea}
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.detalles.destinoFinal')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['destino-final'] !== undefined
							? props.data['destino-final']
							: t('booking.detalles.sinDestinoFinal')}
					</Typography>
				</Grid>

				{props.data['vuelo-conexion'] === '' ||
				props.data['vuelo-conexion'] === undefined ? (
					<></>
				) : (
					<Grid item lg={6} md={6} sm={6} xs={6}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('booking.estatus.vueloDeConexion')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.data['vuelo-conexion']}
						</Typography>
					</Grid>
				)}
				{props.role.includes('Cliente') && (
					<>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.estatus.pedimento')}
							</Typography>
							{props.data.pedimento['pedimento'] === 'N.D.' ||
							props.data.pedimento['pedimento'] === undefined ? (
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{t('booking.estatus.sinPedimento')}
								</Typography>
							) : (
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.pedimento['pedimento']}
								</Typography>
							)}
						</Grid>
					</>
				)}

				<Grid item lg={6} md={6} sm={6} xs={6}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('booking.estatus.incoterm')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.data['incoterm']}
					</Typography>
				</Grid>
				{props.role.includes('Cliente') && (
					<>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.bookingTN')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.data['bookingTN']}
							</Typography>
						</Grid>

						{props.data.pedimento['pedimento'] === 'N.D.' ||
						props.data.pedimento['pedimento'] === undefined ? (
							<></>
						) : (
							<Grid item lg={6} md={6} sm={6} xs={6}>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.estatus.modulacion')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.dataDespacho[
										'resultado-modulacion'
									] !== undefined ? (
										<>
											{props.dataDespacho[
												'resultado-modulacion'
											] === 'V' ? (
												<CircleIcon color='success' />
											) : (
												<>
													{props.dataDespacho[
														'resultado-modulacion'
													] === 'R' && (
														<CircleIcon
															sx={{
																color: 'red',
															}}
														/>
													)}{' '}
												</>
											)}
										</>
									) : (
										<>
											<CircleIcon color='disabled' />
										</>
									)}
								</Typography>
							</Grid>
						)}
					</>
				)}

				{props.data['carga-peligrosa'] === undefined &&
				props.data['carga-peligrosa'] === '' ? (
					<>
						{props.data['carga-peligrosa'] === 'N' ? (
							<></>
						) : (
							<Grid item lg={6} md={6} sm={6} xs={6}>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.estatus.cargaPeligrosa')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data['carga-peligrosa'] === 'S' && (
										<Warning sx={{ color: 'red' }} />
									)}{' '}
								</Typography>
							</Grid>
						)}
					</>
				) : (
					<></>
				)}
			</Grid>
		</Box>
	);
}
