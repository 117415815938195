import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	LinearProgress,
	useMediaQuery,
	Tooltip,
	IconButton,
} from '@mui/material';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import CargasTemplate from '../Forms/CargasTemplate';
import GetDataFetch from '../../../../get-data/GetDataFetch';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: ListadoCargas
Componente hijo: Carga
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.actualizarDato = funcion para actualizar los datos del formulario //
	props.dataDetalles = informacion acerca de los detalles del booking //
	props.dataModalidadPago = informacion sobre la modalidad de pago //
	props.dataBulto = informacion sobre el tipo de bulto //
	props.handleNext = funcion para realizar el nextStepp //
	props.emptiesInputs = funcion para validar los campos vacios //
*/

export default function AgregarCargaTemplateCartaPorte(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '60%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxHeight: '95%',
		minHeight: '40vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loadingButton, setLoadingButton] = useState(false);
	const navigate = useNavigate();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [loadingMisEmbalajes, setloadingMisEmbalajes] = useState(false);
	const [dataMisEmbalajes, setDataMisEmbalajes] = useState([]);
	const [loadingMisProductos, setloadingMisProductos] = useState(false);
	const [dataMisProductos, setDataMisProductos] = useState([]);

	//Data fetch mis embalajes
	const dataFetchMisEmbalajes = async () => {
		//Se comienza el loading del contenido
		setloadingMisEmbalajes(true);
		var url = 'catalogos/embalajes/all';
		// Se hace el Get Fetch con los datos
		await GetDataFetch(
			url,
			setloadingMisEmbalajes,
			setDataMisEmbalajes,
			navigate
		);
	};

	//Data fetch mis productos}
	const dataFetchMisProductos = async () => {
		//Se comienza el loading del contenido
		setloadingMisProductos(true);
		var url = 'catalogos/cargas-carta-porte/all';
		// Se hace el Get Fetch con los datos
		await GetDataFetch(
			url,
			setloadingMisProductos,
			setDataMisProductos,
			navigate
		);
	};

	// useEffect para obtener la información de mis embalajes y mis productos
	useEffect(() => {
		dataFetchMisEmbalajes();
		dataFetchMisProductos();
	}, []);

	// Se crea el modelo de los datos de la carga
	const [infoCarga, setInfoCarga] = useState({
		vBooking: props.selectedBooking,
		uiCodCartaPorte: props.dataDetalles.uiCodCartaPorte,
		uiCodEmbalaje: '',
		uiCodProducto: '',
		nCantidad: 0,
		nPeso: 0,
		vUUID: '',
	});

	//Se crean los parametros para el metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(infoCarga),
		redirect: 'follow',
	};

	// Se crea la funcion para editar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		actualizarDato(name, event.target.value.toUpperCase());
	};

	// Se crea la funcion para actualizar el valor de las cargas
	function actualizarDato(name, value) {
		setInfoCarga({
			...infoCarga,
			[name]: value,
		});
	}

	// Se crea funcion para el checkbox
	const handleCheckBox = (event) => {
		var value = event.target.checked;
		setInfoCarga({
			...infoCarga,
			bPeligroso: value,
		});
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	function validaSS() {
		const embalaje = dataMisEmbalajes.embalajes.find(
			(obj) => obj.uiCodEmbalaje === infoCarga.uiCodEmbalaje
		);
		const producto = dataMisProductos.cargas.find(
			(obj) => obj.uiCodCarga === infoCarga.uiCodProducto
		);

		if (embalaje.bPeligroso === producto.bPeligroso) {
			return true;
		} else {
			return false;
		}
	}

	// funcion para validar que el formulario este completo
	function validaCarga() {
		var valida = true;
		Object.keys(infoCarga).forEach(function (key) {
			if (infoCarga[key] === '') {
				if (key === 'vUUID' && infoCarga[key] === '') {
					valida = true;
				} else {
					valida = false;
				}
			} else {
				valida = true;
			}
		});
		return valida;
	}

	// funciones para el modal
	const handleSaveModal = () => {
		setLoadingButton(true);
		// Se valida que el formulario este completo
		if (validaSS()) {
			if (validaCarga()) {
				// Se hace el llamado al metodo Patch
				fetch(
					process.env.REACT_APP_API_ACCUTRACK_URL +
						'bookings/terrestre/nacional/cartaPorte/cargas/catalogos/create',
					requestOptions
				)
					.then((response) => {
						props.handleCloseModalCargaCartaPorte();
						if (response.status === 200) {
							// Si es correcto se actualiza el contenido y se manda el mensaje de exito
							props.setAlertMessage({
								severity: 'success',
								message: t('alertas.cargaGuardada'),
							});
							props.handleOpenSnackBar();
							setTimeout(() => {
								props.updateCartaPorte();
							}, 2000);
							setLoadingButton(false);
						} else if (response.status === 401) {
							// Si el token expiro se redirecciona al login
							navigate('/', {
								state: process.env.REACT_APP_ONE_VALID_TOKEN,
							});
						} else {
							// Si hay un error sobre la carga, se muestra el Alert correspondiente
							props.setAlertMessage({
								severity: 'error',
								message: t('alertas.cargaGuardadaError'),
							});
							props.handleCloseModalCargaCartaPorte();
							props.handleOpenSnackBar();
							setLoadingButton(false);
						}
					})
					.catch((error) => console.log('error', error));
				setInfoCarga({
					vBooking: props.selectedBooking,
					uiCodCartaPorte: props.dataDetalles.uiCodCartaPorte,
					uiCodEmbalaje: '',
					uiCodProducto: '',
					nCantidad: 0,
					nPeso: 0,
					vUUID: '',
				});
				setLoadingButton(false);
			} else {
				// Si no esta completo se muestra el mensaje de error
				props.setAlertMessage({
					severity: 'error',
					message: t('alertas.camposIncompletos'),
				});
				props.handleOpenSnackBar();
				setLoadingButton(false);
			}
		} else {
			props.setAlertMessage({
				severity: 'error',
				message: t('modales.validacionAgregarCargaCP'),
			});
			props.handleOpenSnackBar();
			setLoadingButton(false);
		}
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={false}
				>
					{loadingMisEmbalajes || loadingMisProductos ? (
						<LinearProgress />
					) : (
						<Grid item container rowSpacing={2}>
							<Grid item lg={9} md={9} sm={9} xs={9}>
								<Typography variant='h5'>
									{t('modales.agregarCarga')}
								</Typography>
							</Grid>
							<Grid
								item
								lg={3}
								md={3}
								sm={3}
								xs={3}
								sx={{ textAlign: 'right' }}
							>
								<Tooltip title={t('botones.cerrar')}>
									<IconButton
										onClick={
											props.handleCloseModalCargaCartaPorte
										}
										id='cargaModalCerrarIconButton'
										variant='outlined'
										color='inherit'
										className='cerrarIconButton'
									>
										<Close />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<CargasTemplate
									handleCheckBox={handleCheckBox}
									handleTextFieldChange={
										handleTextFieldChange
									}
									actualizarDato={actualizarDato}
									infoCarga={infoCarga}
									data={props.data}
									dataModalidadPago={props.dataModalidadPago}
									dataBulto={props.dataBulto}
									emptiesInputs={props.emptiesInputs}
									handleKeyPress={handleKeyPress}
									dataMisEmbalajes={dataMisEmbalajes}
									dataMisProductos={dataMisProductos}
									dataFetchMisEmbalajes={
										dataFetchMisEmbalajes
									}
									dataFetchMisProductos={
										dataFetchMisProductos
									}
									setAlertMessage={props.setAlertMessage}
									handleOpenSnackBar={
										props.handleOpenSnackBar
									}
								/>
							</Grid>
							<Grid
								container
								spacing={1}
								direction='row'
								justifyContent='space-between'
								alignItems='flex-end'
								sx={{ paddingTop: '2vw' }}
							>
								<Grid
									item
									sx={{
										textAlign: 'right',
										bottom: '1.5vw',
										left: '1.5vw',
									}}
								>
									<Button
										id='cargaModalCerrarButton'
										variant='outlined'
										color='error'
										onClick={
											props.handleCloseModalCargaCartaPorte
										}
									>
										{t('botones.cancelar')}
									</Button>
								</Grid>
								<Grid
									item
									sx={{
										textAlign: 'right',
										bottom: '1.5vw',
										right: '1.5vw',
									}}
								>
									<LoadingButton
										loading={loadingButton}
										id='cargaModalAgregarButton'
										variant='outlined'
										color='primary'
										type='submit'
									>
										{t('botones.agregar')}
									</LoadingButton>
								</Grid>
							</Grid>
						</Grid>
					)}
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
