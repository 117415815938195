import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

/*Descripción:Este componente se encarga de hacer una pantalla de carga de una pantalla
Componente padre: Global
Componente hijo: Global
props: Sin uso de props
*/

export default function MySkeletonPage() {
	return (
		<Backdrop
			open={true}
			sx={{
				color: '#002D58',
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
		>
			<CircularProgress color='inherit' />
		</Backdrop>
	);
}
