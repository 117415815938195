import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { Fade } from '@mui/material';

/*Descripción:Este componente se encarga de la estructura de el Timeline
Componente padre: TimelineEventosContenedor //
	TimelineEventosTerminal //	TimelineEventosDespacho //
	TimelineEventosTerrestreExpo//	TimelineEventosBooking //
	TimelineEventosDespachoAduanalExpo//	TimelineSelectorTN //
	TimelineEventosAereopuertoExpo //	TimelineEventosAereopuertoImpo //
	TimelineEventosDespachoAduanalImpo //	TimelineEventosTerrestreImpo //
	TimelineEventosAereoDespachoImpo //	TimelineEventosMaritimoDespachoImpo //
	TimelineEventosTerrestreExpo //	TimelineEventosDespachoTerrestreExpo //
	TimelineEventosDespachoAduanalExpo //	TimelineEventosBooking //
	TimelineEventosContenedor //	TimelineEventosDespacho //
	TimelineEventosTCAExpoFCL //	TimelineEventosTerminal //
	TimelineEventosUSCAMEX
Componente hijo:
props: props.data = informacion a desplegar //
	props.icon = icono a desplegar //
	props.title = titulo a desplegar
*/

export default function TimelineEventosBooking(props) {
	return (
		<>
			{props.data === '' ? (
				<TimelineItem>
					<TimelineSeparator>
						<Fade in={true} timeout={1000}>
							<TimelineConnector />
						</Fade>
						<Fade in={true} timeout={1000}>
							<TimelineDot />
						</Fade>
						<Fade in={true} timeout={1000}>
							<TimelineConnector />
						</Fade>
					</TimelineSeparator>
					<TimelineContent sx={{ py: '12px', px: 2 }}>
						<Typography variant='subtitle2' component='span'>
							{props.title}
						</Typography>
						<Typography variant='body2'>{props.data}</Typography>
					</TimelineContent>
				</TimelineItem>
			) : (
				<TimelineItem>
					<TimelineSeparator>
						<Fade in={true} timeout={1000}>
							<TimelineConnector sx={{ bgcolor: '#e73333' }} />
						</Fade>
						<Fade in={true} timeout={1000}>
							<TimelineDot sx={{ bgcolor: '#e73333' }} />
						</Fade>
						<Fade in={true} timeout={1000}>
							<TimelineConnector sx={{ bgcolor: '#e73333' }} />
						</Fade>
					</TimelineSeparator>
					<TimelineContent sx={{ py: '12px', px: 2 }}>
						<Typography variant='subtitle2' component='span'>
							{props.title}
						</Typography>
						<Typography variant='body2'>{props.data}</Typography>
					</TimelineContent>
				</TimelineItem>
			)}
		</>
	);
}
