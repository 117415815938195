import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SelectSearchSecuencia from '../../SelectSearchSecuencia';
import InfoEquipoUSCAMEX from './Info/InfoEquipoUSCAMEX';
import TimelineSelectorUSCAMEX from './Timeline/TimelineSelectorUSCAMEX';
import InfoDetallesCarga from './Info/InfoDetallesCarga';
import TimelineSelectorDetallesCarga from './Timeline/TimelineSelectorDetallesCarga';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de el estatus de el booking seleccionado y su Timeline
Componente padre: BookingUSCAMEXExpoFTLSearchResult
Componente hijo:SelectSearchSecuencia // InfoEquipoUSCAMEX //
	TimelineSelectorUSCAMEX // InfoDetallesCarga //
	TimelineSelectorDetallesCarga
props: props.data = informacion a desplegar
*/

export default function EventosBookingUSCAMEX(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const y las variables para su uso en EventosBookingUSCAMEX
	let estado;
	// Se valida si el equipo aplica
	if (props.data.equipo === 'NA') {
		// Si el equipo no aplica se asignan los detalles de la carga
		estado = props.data.detallesCarga[0];
	} else {
		// Si el equipo aplica se asignan los detalles del equipo
		estado = props.data.equipo[0];
	}
	const [dataCarga, setCarga] = useState(estado);
	const [indexCarga, setIndexCarga] = useState(0);
	let indice;
	const [selectedSecuencia, setSelectedSecuencia] = useState(1);

	// Funcion para renderizar el contenido de USCAMEX
	function ContentToRender() {
		// Se valida si el equipo aplica
		if (props.data.equipo !== 'NA') {
			// Se valida si el equipo existe
			if (props.data.equipo === undefined) {
				// Si no existe el equipo se renderiza el mensaje pertinente
				return <Box>{t('eventos.bookingSinRegistroEquipo')}</Box>;
			} else {
				// Si existe el equipo se renderiza el contenido de InfoEquipoUSCAMEX
				return (
					<Grid container spacing={1}>
						<Grid
							item
							sx={{ paddingBottom: '1%' }}
							lg={6}
							md={6}
							sm={6}
							xs={12}
						>
							<Grid container sx={{ paddingBottom: '3%' }}>
								{props.data.equipo === undefined ? (
									<Box>{t('eventos.sinEquipoAsinado')}</Box>
								) : (
									<Box></Box>
								)}

								{props.data.equipo.length === 0 ? (
									<Typography variant='body1' color='initial'>
										{t('eventos.sinEquipoAsinado')}
									</Typography>
								) : props.data.equipo === undefined ? (
									<Box></Box>
								) : (
									<SelectSearchSecuencia
										actualizarContenedor={
											actualizarContenedor
										}
										selectedSecuencia={selectedSecuencia}
										indexCarga={indexCarga}
										customLabel={t('selectSearch.equipo')}
										id='equipo'
										name='equipo'
										dataOption={props.data.equipo}
									/>
								)}
							</Grid>
							<Grid container spacing={2}>
								{props.data.equipo === undefined ? (
									<></>
								) : (
									<InfoEquipoUSCAMEX
										data={props.data}
										dataCarga={dataCarga}
									/>
								)}
							</Grid>
						</Grid>
						<Grid
							item
							spacing={1}
							sx={{ paddingTop: '1%' }}
							lg={6}
							md={6}
							sm={6}
							xs={12}
						>
							<TimelineSelectorUSCAMEX
								data={props.data}
								dataCarga={dataCarga}
							/>
						</Grid>
					</Grid>
				);
			}
		}
		// Se valida si los detalles de carga aplica
		else if (props.data.detallesCarga !== 'NA') {
			// Se valida si los detalles de carga existen
			if (props.data.detallesCarga === undefined) {
				// Si no existen los detalles de carga se renderiza el mensaje pertinente
				return <Box>{t('eventos.sinRegistroDetallesCarga')}</Box>;
			} else {
				// Si existen los detalles de carga se renderiza el contenido de InfoDetallesCarga
				return (
					<Grid container spacing={1}>
						<Grid
							item
							sx={{ paddingBottom: '1%' }}
							lg={6}
							md={6}
							sm={6}
							xs={12}
						>
							<Grid container sx={{ paddingBottom: '3%' }}>
								{props.data.detallesCarga === undefined ? (
									<Box>
										{t('eventos.sinDetallesCargaAsignada')}
									</Box>
								) : (
									<Box></Box>
								)}

								{props.data.detallesCarga.length === 0 ? (
									<Typography variant='body1' color='initial'>
										{t('eventos.sinDetallesCargaAsignada')}
									</Typography>
								) : props.data.detallesCarga === undefined ? (
									<Box></Box>
								) : (
									<SelectSearchSecuencia
										actualizarContenedor={
											actualizarContenedorImpo
										}
										selectedSecuencia={selectedSecuencia}
										indexCarga={indexCarga}
										customLabel={t('selectSearch.equipo')}
										id='equipo'
										name='equipo'
										dataOption={props.data.detallesCarga}
									/>
								)}
							</Grid>
							<Grid container spacing={2}>
								{props.data.detallesCarga === undefined ? (
									<></>
								) : (
									<InfoDetallesCarga
										data={props.data}
										dataCarga={dataCarga}
									/>
								)}
							</Grid>
						</Grid>
						<Grid
							item
							spacing={1}
							sx={{ paddingTop: '1%' }}
							lg={6}
							md={6}
							sm={6}
							xs={12}
						>
							<TimelineSelectorDetallesCarga
								data={props.data}
								dataCarga={dataCarga}
							/>
						</Grid>
					</Grid>
				);
			}
		}
	}
	// Funcion para actualizar el contenido del contenedor de equipo
	function actualizarContenedor(index) {
		indice = index;
		setCarga(props.data.equipo[indice - 1]);
		setIndexCarga(indice);
		setSelectedSecuencia(indice);
	}
	// Funcion para actualizar el contenido del contenedor de detalles de carga
	function actualizarContenedorImpo(index) {
		indice = index;
		setCarga(props.data.detallesCarga[indice - 1]);
		setIndexCarga(indice);
		setSelectedSecuencia(indice);
	}

	return (
		<>
			<ContentToRender />
		</>
	);
}
