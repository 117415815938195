import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	LinearProgress,
	useMediaQuery,
	IconButton,
	Tooltip,
} from '@mui/material';
import Cookies from 'universal-cookie';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import ContactoForm from '../../Forms/ContactoForm';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

/*Descripción:Este componente se encarga de mostrar el modal de editar un contacto.
Componente padre: EditIconDataGrid.jsx
Componente hijo: ContactoForm.jsx
props: props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.id = id del consignatario //
	props.idContacto = id del contacto //
	props.dataFetch = funcion para obtener los datos de la API //
	props.setOpenModal = funcion para abrir el modal //
	props.handleBackButtonClick = funcion para volver a la pagina anterior //
	props.dataGrid = Información de los consignatarios //
	props.dataDetallesPaises = Manda la información de los paises //
	props.loadingPaises = Variable para el tiempo de carga //
	props.data = Información del consignatario //
	props.dataDetallesContactos = Información de los contactos //
	actualizarDato = funcion para actualizar la información del consignatario //
*/

export default function EditarContactosModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '60%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: '60%',
		maxHeight: '95%',
		minHeight: '40vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	var myHeaders = new Headers();
	const navigate = useNavigate();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);

	// Se crea la funcion para guardar el usuario
	const handleSaveModal = () => {
		const requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify(props.infoContacto),
			redirect: 'follow',
		};
		// Se hace la validación de los campos del formulario
		if (validaCarga() === true) {
			// Se realiza el Data Fetch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'consignatario/contactos/update',
				requestOptions
			)
				.then((response) => {
					// Se valida el estatus de la peticion
					if (response.status === 200) {
						// Se actualiza la informacion mostrada
						props.setOpenModal(false);
						props.dataFetch();
						props.setAlertMessagePage({
							message: t('alertas.contactoEditado'),
							severity: 'success',
						});
						props.handleClickPage();
					} else if (response.status === 401) {
						// Si el token caduca se redirige al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						// Se crea el mensaje de error al no llenar el formulario
						props.setAlertMessagePage({
							message: t('alertas.contactoEditadoError'),
							severity: 'error',
						});
					}
				})
				.catch((error) => console.log('error', error));
			// Se cierra el modal
			props.setOpenModal(false);
		} else {
			// Se crea el mensaje de error al no llenar el formulario
			props.setAlertMessagePage({
				message: t('alertas.contactoSinDatos'),
				severity: 'error',
			});
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	// Se crea la funcion para validar que todos los campos esten llenos
	function validaCarga() {
		var valida = true;
		Object.keys(props.infoContacto).forEach(function (key) {
			if (props.infoContacto[key] === '' && key !== 'email') {
				valida = false;
			}
		});
		return valida;
	}

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={true}
				>
					<Grid item container rowSpacing={1}>
						<Grid item container rowSpacing={2}>
							<Grid item lg={9} md={9} sm={9} xs={9}>
								<Typography variant='h5'>
									{t('modales.editarContacto')}
								</Typography>
							</Grid>
							<Grid
								item
								lg={3}
								md={3}
								sm={3}
								xs={3}
								sx={{ textAlign: 'right' }}
							>
								<Tooltip title={t('botones.cerrar')}>
									<IconButton
										onClick={props.handleCloseModal}
										id='cargaModalCerrarIconButton'
										variant='outlined'
										color='inherit'
										className='cerrarIconButton'
									>
										<Close />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								{props.loading ? (
									<LinearProgress />
								) : (
									<ContactoForm
										handleTextFieldChange={
											props.handleTextFieldChange
										}
										actualizarDato={props.actualizarDato}
										infoContacto={props.infoContacto}
										handleCloseModal={
											props.handleCloseModal
										}
										openModal={props.openModal}
										setOpenModal={props.setOpenModal}
										handleKeyPress={handleKeyPress}
									/>
								)}
							</Grid>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Button
								id='cargaModalCerrarButton'
								variant='outlined'
								color='error'
								onClick={props.handleCloseModal}
							>
								{t('botones.cancelar')}
							</Button>
						</Grid>

						<Grid
							item
							lg={6}
							md={6}
							sm={6}
							xs={6}
							sx={{
								textAlign: 'right',
							}}
						>
							<Button
								id='cargaModalActualizarButton'
								variant='outlined'
								color='primary'
								type='submit'
							>
								{t('botones.actualizar')}
							</Button>
						</Grid>
					</Grid>
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
