import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid, LinearProgress } from '@mui/material';
import { LocalPhone } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import ArticleIcon from '@mui/icons-material/Article';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el card de la info del cliente
Componente padre: UserProfile
Componente hijo:
props: props.client = Info de el cliente
*/

export default function CardClient(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Card sx={{ width: '100%', height: '100%' }}>
			{props.loading ? (
				<CardContent>
					<LinearProgress />
				</CardContent>
			) : (
				<CardContent>
					<Typography gutterBottom variant='h5' component='div'>
						{props.client.nombre}
					</Typography>
					<Grid
						container
						spacing={2}
						paddingBottom={1}
						sx={{ paddingTop: '2%' }}
					>
						<Grid item>
							<BusinessIcon color="primary" />
						</Grid>
						<Grid item lg={10} md={10} sm={10} xs={10}>
							<Typography variant='body1' color='text.secondary'>
								{props.client.direccion}
								{props.client.colonia}
								{props.client.ciudad} {t('cards.cp')}{' '}
								{props.client.codigo_postal}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2} paddingBottom={1}>
						<Grid item spacing={0}>
							<ArticleIcon color="primary" />
						</Grid>
						<Grid item spacing={0}>
							<Typography variant='body1' color='text.secondary'>
								{props.client.rfc}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item spacing={0}>
							<LocalPhone color="primary" />
						</Grid>
						<Grid item spacing={0}>
							<Typography variant='body1' color='text.secondary'>
								{props.client.telefono}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			)}
		</Card>
	);
}
