import { Edit } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditarContactosModal from '../../Modales/Contactos/EditarContactosModal';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import GetDataFetch from '../../../../get-data/GetDataFetch';

/*Descripción:Este componente se encarga de renderizar el boton de editar
Componente padre: ListContactos.jsx
Componente hijo: EditarContactosModal.jsx
props: id: id del consignatario // idContacto: id del contacto //
dataFetch: Función para obtener los datos de la API
*/

export default function EditIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const [openModal, setOpenModal] = useState(false);
	const [infoContacto, setInfoContacto] = useState({
		cod_persona: props.id,
		cod_contacto: '',
		contacto: '',
		telefono: '',
		email: '',
	});
	const navigate = useNavigate();
	// Funcion para cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Se hace el fetch para obtener los datos ya existentes en la DB
	const dataFetch = async () => {
		const url =
			'consignatario/contactos/contacto?id=' +
			props.id +
			'&idContacto=' +
			props.idContacto;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
		setInfoContacto({
			cod_persona: props.id,
			cod_contacto: dataDetalles.cod_contacto,
			contacto: dataDetalles.contacto,
			telefono: dataDetalles.telefono,
			email: dataDetalles.email,
		});
	};

	useEffect(() => {
		dataFetch();
	}, [props.idContacto]);

	// Funcion para actualizar el texto del Textfield
	const handleTextFieldChange = (name) => (event) => {
		if (name === 'telefono') {
			const value = event.target.value
				.replace(/\s/g, '')
				.replace(/[^\d]/g, '');
			actualizarDato(name, value);
		} else {
			if (name === 'email') {
				actualizarDato(name, event.target.value.toLowerCase());
			} else {
				actualizarDato(name, event.target.value.toUpperCase());
			}
		}
	};
	// Funcion para actualizar el estado del componente
	function actualizarDato(name, value) {
		setInfoContacto({
			...infoContacto,
			[name]: value,
		});
	}
	// Funcion para manejar el click del boton de editar
	const handleEditClick = async () => {
		// Fetch Data
		await dataFetch();
		// Abre el modal
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.editar')}>
						<IconButton
							id='editIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleEditClick}
						>
							<Edit color='primary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<EditarContactosModal
				loading={loading}
				id={props.id}
				dataFetch={props.dataFetch}
				handleTextFieldChange={handleTextFieldChange}
				actualizarDato={actualizarDato}
				infoContacto={infoContacto}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
				setAlertMessagePage={props.setAlertMessagePage}
				handleClickPage={props.handleClickPage}
			/>
		</>
	);
}
