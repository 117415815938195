import React, { useState } from 'react';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import VideoModal from '../VideoModal';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function CentroAyudaCard(props) {
	// Funcion para traducir la pagina en español o ingles
	const language = useTranslation().i18n.language;
	const [open, setOpen] = useState(false);
	const [info, setInfo] = useState({});
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const resultLanguage = () => {
		if (language === 'en') {
			setInfo({
				titulo: props.titulo_eng,
				descripcion: props.descripcion_eng,
				url: props.url_eng,
			});
		} else {
			setInfo({
				titulo: props.titulo_esp,
				descripcion: props.descripcion_esp,
				url: props.url_esp,
			});
		}
	};

	// useEffect para hacer la modificacion del idioma
	useEffect(() => {
		resultLanguage();
	}, [language]);

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Tooltip title='Haz click para visualizar' followCursor>
				<Card
					elevation={4}
					sx={{
						width: '100%',
						backgroundColor: '#52a4f13d',
						cursor: 'pointer',
					}}
					className='helpCenterCard hvr-underline-from-right'
					onClick={handleOpen}
				>
					<CardContent sx={{ height: '28vh' }}>
						<Typography
							gutterBottom
							variant='subtitle1'
							component='div'
						>
							{info.titulo}
						</Typography>
						<Typography variant='body2' color='text.secondary'>
							{info.descripcion}
						</Typography>
					</CardContent>
				</Card>
			</Tooltip>
			<VideoModal
				titulo={info.titulo}
				videoUrl={info.url}
				handleClose={handleClose}
				open={open}
			/>
		</Box>
	);
}
