import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';

/*Descripción: Este componente se encarga de desplegar una pantalla de error
Componente padre: Global
Componente hijo: Global
props: Sin uso de props
*/

export default function ErrorBoundary(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	// Funcion para refrescar la pagina
	function onClickHome() {
		window.location.reload();
	}

	const hasError = false;

	// Se valida si existe algun error
	if (hasError) {
		// Si se obtiene un error se renderiza la pantalla
		return (
			<Box sx={{ alignItems: 'center', paddingTop: '17%' }}>
				<Grid container spacing={0} justifyContent='center'>
					<ErrorIcon fontSize='large' sx={{ color: 'red' }} />
				</Grid>
				<Grid container spacing={0} justifyContent='center'>
					<Typography variant='h5'>{t('cards.salioMal')}</Typography>
				</Grid>
				<Grid container spacing={0} justifyContent='center'>
					<Typography variant='subtitle2'>
						{t('alertas.intentarDeNuevo')}
					</Typography>
				</Grid>
				<Grid container spacing={0} justifyContent='center'>
					<Button onClick={onClickHome} variant='text'>
						{t('botones.refrescar')}
					</Button>
				</Grid>
			</Box>
		);
	}

	// Si no hay error, muestra los children
	return props.children;
}
