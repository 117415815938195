import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TimelineEventosContenedor from './TimelineEventosContenedor';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useState } from 'react';

/*Descripción:Este componente se encarga de desplegar las opciones del Timeline de TN
Componente padre: EventosBookingTN
Componente hijo: TimelineEventosContenedor
props: props.dataEmpaque = informacion a desplegar sobre el empaque
*/

export default function TimelineSelector(props) {
	// Const para la seleccion del Tab
	const [value, setValue] = useState('1');
	// Funcion para las opciones del Tab
	function MyTabs() {
		return (
			<TabContext value={value} id='maritimoImpoTabContext'>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleChange}
						aria-label='lab API tabs example'
						centered
					>
						<Tab
							icon={<LocalShippingIcon />}
							value='1'
							sx={{ color: 'grey' }}
						/>
					</TabList>
				</Box>
				<TabPanel
					value='1'
					sx={{ maxHeight: '46vh', overflowY: 'auto' }}
				>
					<TimelineEventosContenedor data={props.dataEmpaque} />
				</TabPanel>
			</TabContext>
		);
	}
	// Funcion para la seleccion del Tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<MyTabs />
		</Box>
	);
}
