import { Download, QrCode, Visibility } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, Tooltip } from '@mui/material';
import { useState } from 'react';
import QRFacturaModal from '../Modal/QRFacturaModal';
import { useNavigate, useLocation } from 'react-router-dom';
import PDFFacturaModal from '../Modal/PDFFacturaModal';
import LoadingButton from '@mui/lab/LoadingButton';
import GetDocumentFetch from '../../get-data/GetDocumentFetch';
import { useTranslation } from 'react-i18next';

export default function PreviewFacturaCard(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se cran los const para el uso del componente
	const [loading, setLoading] = useState(false);
	// Obtiene el token de la cookie
	const navigate = useNavigate();
	const [openModalQR, setOpenModalQR] = useState(false);
	const [openModalPDF, setOpenModalPDF] = useState(false);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	// Obtener los parámetros recibidos en la url
	const facturaFinal = searchParams.get('folio'); // ruta xml o num factura
	const anio = searchParams.get('year');
	const mes = searchParams.get('month');
	// funcionamiento del Modal
	const handleOpenModalQR = () => {
		setOpenModalQR(true);
	};
	const handleCloseModalQR = () => {
		setOpenModalQR(false);
	};
	const handleOpenModalPDF = () => {
		setOpenModalPDF(true);
	};
	const handleCloseModalPDF = () => {
		setOpenModalPDF(false);
	};

	// fetch file download
	const downloadFetch = async () => {
		const url =
			'facturacion/factura/zip?factura=' +
			facturaFinal +
			'&anio=' +
			anio +
			'&mes=' +
			mes;
		const fileExtension = '.zip';
		const fileName = props.indexFactura;
		await GetDocumentFetch(
			url,
			setLoading,
			fileExtension,
			'zip',
			navigate,
			fileName
		);
	};

	const resultToRender = () => {
		return (
			<>
				<Grid item>
					<Tooltip title={t('modales.descargar')}>
						<LoadingButton
							loading={loading}
							id='downloadButton'
							variant='outlined'
							color='primary'
							disabled={false}
							onClick={downloadFetch}
						>
							<Download />
						</LoadingButton>
					</Tooltip>
				</Grid>
				<Grid item>
					<Button
						id='verFacturaButton'
						variant='outlined'
						color='primary'
						onClick={handleOpenModalPDF}
					>
						<Tooltip title={t('estadoDeCuenta.verFactura')}>
							<Visibility />
						</Tooltip>
					</Button>
				</Grid>
				<Grid item>
					<Button
						id='qrButton'
						variant='outlined'
						color='primary'
						onClick={handleOpenModalQR}
					>
						<Tooltip title={t('estadoDeCuenta.verQR')}>
							<QrCode />
						</Tooltip>
					</Button>
				</Grid>
			</>
		);
	};

	return (
		<Card elevation={0} sx={{ width: '100%' }}>
			<CardContent>
				<Grid
					item
					container
					spacing={2}
					justifyContent={'space-between'}
				>
					{resultToRender()}
				</Grid>
				<QRFacturaModal
					endpoint={'facturacion/factura/qr?factura='}
					factura={facturaFinal}
					openModal={openModalQR}
					setOpenModal={setOpenModalQR}
					handleCloseModal={handleCloseModalQR}
					mes={mes}
					anio={anio}
				/>
				<PDFFacturaModal
					endpoint={'facturacion/factura/pdf?factura='}
					factura={facturaFinal}
					openModal={openModalPDF}
					setOpenModal={setOpenModalPDF}
					handleCloseModal={handleCloseModalPDF}
					mes={mes}
					anio={anio}
				/>
			</CardContent>
		</Card>
	);
}
