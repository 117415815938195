import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Grid, Box, Stack } from '@mui/material';
import numeral from 'numeral';
import { Paid } from '@mui/icons-material';

export default function TotalesCard(props) {
	const { t } = useTranslation();

	// Funcion para dar formato moneda
	function CurrencyFormatter({ amount }) {
		const formattedAmount = numeral(amount).format('$0,0.00');
		return <Box>{formattedAmount}</Box>;
	}

	return (
		<Card elevation={3} className='fac-card-top paper-hover'>
			<CardContent>
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'column',
						lg: 'column',
						xl: 'column',
					}}
					spacing={2}
				>
					<Paid color='primary' />
					<Grid container justifyContent='space-between'>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.facturas.subtotal')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant='subtitle2' color='initial'>
								<CurrencyFormatter
									amount={props.dataDetalles.factura.subTotal}
								/>
							</Typography>
						</Grid>
					</Grid>
					<Grid container justifyContent='space-between'>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.facturas.impuestos')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant='subtitle2' color='initial'>
								{props.dataDetalles.factura.impuestos !==
								null ? (
									<CurrencyFormatter
										amount={
											props.dataDetalles.factura.impuestos
												.totalImpuestosTrasladados || 0
										}
									/>
								) : (
									<CurrencyFormatter amount={0} />
								)}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						justifyContent='space-between'
						className='border-top-gris'
					>
						<Grid item paddingTop={1}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('dataGrid.total')}
							</Typography>
						</Grid>
						<Grid item paddingTop={1}>
							<Typography variant='subtitle2' color='initial'>
								<CurrencyFormatter
									amount={props.dataDetalles.factura.total}
								/>
							</Typography>
						</Grid>
					</Grid>
					<Grid container justifyContent='space-between'>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('dataGrid.moneda')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant='subtitle2' color='initial'>
								{props.dataDetalles.factura.moneda}
							</Typography>
						</Grid>
					</Grid>
				</Stack>
			</CardContent>
		</Card>
	);
}
