import { Box } from '@mui/material';
import React, { useState } from 'react';
import CommonBooking from '../../../../components/CommonBooking';
import ErrorBoundary from '../../../../components/cards/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import BoxTitleWithTabs from '../../../../components/titles/BoxTitleWithTabs';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla Terrestre Nacional
y mandar el endpoint a usar
Componente padre: CommonLayout.jsx
Componente hijo: CommonBooking.jsx
props: Sin uso de props
*/

export default function IndexCartaPorte() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const tabs = [
		{ id: 'TabPendientes', label: t('cartaPorte.pendientes') },
		{ id: 'TabEnviados', label: t('cartaPorte.enviados') },
	];

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box>
				<BoxTitleWithTabs
					title={t('cartaPorte.titulo')}
					tabs={tabs}
					value={value}
					handleChange={handleChange}
				/>
				{/* Tab 1:Pendientes */}
				<Box hidden={value !== 0}>
					{value === 0 && (
						<CommonBooking
							resultComponent='PendienteCartaPorte'
							endpoint='bookings/terrestre/nacional/cartaPorte/bookings-sin-cartaPorte'
						/>
					)}
				</Box>
				{/* Tab 2: Enviados */}
				<Box hidden={value !== 1}>
					{value === 1 && (
						<CommonBooking
							resultComponent='EnviadoCartaPorte'
							endpoint='bookings/terrestre/nacional/cartaPorte/bookings-con-cartaPorte'
						/>
					)}
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
