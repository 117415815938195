import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos de Booking
Componente padre: TimelineSelector
Componente hijo: TimelineItem
props: props.data = informacion a desplegar //
	props.dataContenedor = informacion a desplegar sobre el contenedor //
*/

export default function TimelineEventosBooking(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			{props.dataContenedor['fecha-contenedor-a-bordo'] === undefined ? (
				<TimelineItem data='' title={t('eventos.contenedorBordo')} />
			) : (
				<TimelineItem
					data={props.dataContenedor['fecha-contenedor-a-bordo']}
					title={t('eventos.contenedorBordo')}
				/>
			)}
			{props.data['fecha-zarpe'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-zarpe']}
					title={t('eventos.zarpe')}
				/>
			)}
			{props.data['fecha-arribo-puerto-descarga'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-arribo-puerto-descarga']}
					title={t('eventos.arriboPuertoDescarga')}
				/>
			)}
			{props.data['fecha-liberacion-documentos'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-liberacion-documentos']}
					title={t('eventos.liberacionDocumentos')}
				/>
			)}
			{props.data['fecha-arribo-destino-final'] === undefined ||
			props.data['fecha-arribo-destino-final'] === '' ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-arribo-destino-final']}
					title={t('eventos.fechaArriboDestinoFinal')}
				/>
			)}
			{props.data['fecha-liberacion-destino'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-liberacion-destino']}
					title={t('eventos.liberacionDestino')}
				/>
			)}
		</Timeline>
	);
}
