import React, { useState, useEffect } from 'react';
import {
	Grid,
	Button,
	Typography,
	Box,
	Tooltip,
	IconButton,
} from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import {
	ArrowBackIos,
	ArrowForwardIos,
	Close,
	Download,
} from '@mui/icons-material';
import MySkeletonList from '../Skeletons/SkeletonList';
import InvalidPDF from '../cards/InvalidPDF';
import LoadingButton from '@mui/lab/LoadingButton';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PdfViewerPreBL({
	pdfBlob,
	handleCloseModal,
	selectedBooking,
}) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [isCorrect, setIsCorrect] = useState(true);
	const [loading, setLoading] = useState(false);
	// Constante para el numero de paginas horizontal
	const [currentPage, setCurrentPage] = useState(1);
	// Constante para el numero de paginas vertical
	const [numPages, setNumPages] = useState(null);

	// Función para manejar el renderizado de páginas
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	// Funcion para descargar el pdf
	const downloadBlobAsFile = (blob, fileName) => {
		setLoading(true);
		const blobUrl = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = blobUrl;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(blobUrl);
		setLoading(false);
	};

	useEffect(() => {
		// Resetea el número de páginas cuando cambia el PDF
		setNumPages(null);
	}, [pdfBlob]);

	const setError = () => {
		setIsCorrect(false);
	};

	const handleError = () => {
		if (isCorrect) {
			return (
				<>
					<Grid item container lg={12} md={12} sm={12} xs={12}>
						<Grid
							item
							lg={2}
							md={2}
							sm={2}
							xs={2}
							sx={{ textAlign: 'left' }}
						>
							<Tooltip title={t('modales.anterior')}>
								<Button
									onClick={() =>
										setCurrentPage((prevPage) =>
											Math.max(prevPage - 1, 1)
										)
									}
									variant='outlined'
									color='primary'
									disabled={currentPage === 1}
								>
									<ArrowBackIos fontSize='small' />
								</Button>
							</Tooltip>
						</Grid>
						<Grid
							item
							lg={2}
							md={2}
							sm={2}
							xs={2}
							sx={{ textAlign: 'center' }}
						>
							<Typography variant='h6' component='h6'>
								{currentPage}/{numPages}
							</Typography>
						</Grid>
						<Grid
							item
							lg={2}
							md={2}
							sm={2}
							xs={2}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('modales.siguiente')}>
								<Button
									onClick={() =>
										setCurrentPage((prevPage) =>
											Math.min(
												prevPage + 1,
												numPages || 1
											)
										)
									}
									variant='outlined'
									color='primary'
									disabled={currentPage === (numPages || 1)}
								>
									<ArrowForwardIos fontSize='small' />
								</Button>
							</Tooltip>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{
								textAlign: 'right',
							}}
						>
							<Tooltip title={t('modales.descargar')}>
								<LoadingButton
									onClick={() =>
										downloadBlobAsFile(
											pdfBlob,
											'Draft_' + selectedBooking + '.pdf'
										)
									}
									loading={loading}
									variant='outlined'
									color='primary'
									id='ModalDescargarButton'
								>
									<Download fontSize='small' />
								</LoadingButton>
							</Tooltip>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={handleCloseModal}
									id='ModalCerrarButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					<Document
						file={pdfBlob}
						loading={<MySkeletonList />}
						onLoadSuccess={onDocumentLoadSuccess}
						onLoadError={setError}
					>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Page
								pageNumber={currentPage}
								renderTextLayer={false}
								renderAnnotationLayer={false}
								loading={<MySkeletonList />}
								onLoadError={setError}
							/>
						</Grid>
					</Document>
				</>
			);
		} else {
			return (
				<>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{ textAlign: 'right' }}
					>
						<Tooltip title={t('modales.cerrar')}>
							<Button
								onClick={handleCloseModal}
								id='ModalCerrarButton'
								variant='outlined'
								color='secondary'
								type='submit'
							>
								<Close />
							</Button>
						</Tooltip>
					</Grid>
					<InvalidPDF />
				</>
			);
		}
	};

	useEffect(() => {
		handleError();
	}, [isCorrect]);

	return <Box minWidth={500}>{handleError()}</Box>;
}
