import {
	Grid,
	Typography,
	LinearProgress,
	Card,
	CardContent,
	Stack,
	IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EmisorCard from './Cards/EmisorCard';
import TotalesCard from './Cards/TotalesCard';
import ComprobanteCard from './Cards/ComprobanteCard';
import FiscalesCard from './Cards/FiscalesCard';
import PagosDataGrid from './PagosDataGrid';
import GetDataFetchComplementos from '../get-data/GetDataFetchComplementos';
import SinFacturaAzure from '../cards/SinFacturaAzure';
import PreviewComplementoCard from './Cards/PreviewComplementoCard';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '@mui/icons-material';

/*Descripción: componente que muestra la información de una factura xml
 */

export default function DetallesComplemento(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	let url;
	const navigate = useNavigate();
	// Busca la info de la factura por el num de factura y la fecha de fact
	// Obtener los parámetros recibidos en la url
	const complemento = searchParams.get('folio'); // ruta xml o num factura
	const anio = searchParams.get('year');
	const mes = searchParams.get('month');
	const empresa = searchParams.get('empresa');
	const myURI =
		'complemento=' +
		complemento +
		'&anio=' +
		anio +
		'&mes=' +
		mes +
		'&empresa=' +
		empresa;
	url = 'complementos-pago/complemento/detalle?' + myURI;

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		// Se hace el Get Fetch para la informacion
		await GetDataFetchComplementos(
			url,
			setLoading,
			setDataDetalles,
			navigate
		);
	};

	const handleCloseFolio = () => {
		navigate('/estado-de-cuenta?activo=complementos');
	};

	useEffect(() => {
		dataFetch();
	}, []);

	// Funcion para mostrar el menu de Estado de cuentas
	const resultToRender = () => {
		if (props.rutaActiva) {
			<></>;
		} else {
			// Obtener los parámetros recibidos en la url
			const complemento = searchParams.get('folio'); // ruta xml o num factura

			return (
				<Card
					elevation={3}
					sx={{ paddingTop: '0px' }}
					className='fac-card-top paper-hover'
				>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'row',
								xl: 'row',
							}}
							sx={{ justifyContent: 'space-between' }}
							alignItems='center'
						>
							<Stack
								direction='row'
								spacing={1}
								alignItems='center'
							>
								<Grid item sx={{ textAlign: 'left' }}>
									<IconButton
										size='small'
										id='cerrarButton'
										variant='outlined'
										className='cerrarIconButton'
										onClick={handleCloseFolio}
									>
										<ArrowBack />
									</IconButton>
								</Grid>
								<Grid item>
									<Typography variant='h6'>
										{complemento}
									</Typography>
								</Grid>
							</Stack>
							<Grid item>
								<PreviewComplementoCard
									rutaActiva={false}
									indexFactura={complemento}
								/>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
			);
		}
	};

	const render = () => {
		if (dataDetalles === 'Not found') {
			return (
				<>
					<SinFacturaAzure
						texto={t('cards.subiendoComplementoAzure')}
						navigateRoute={'/estado-de-cuenta?activo=complementos'}
					/>
				</>
			);
		} else {
			return (
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'column',
						lg: 'column',
						xl: 'column',
					}}
					spacing={2}
				>
					{resultToRender()}

					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'row',
							lg: 'row',
							xl: 'row',
						}}
						spacing={1}
					>
						{/* EMISOR / RECEPTOR */}
						<EmisorCard dataDetalles={dataDetalles} />

						{/* TOTALES */}
						<TotalesCard dataDetalles={dataDetalles} />
					</Stack>
					{/* PAGOS */}
					<Card elevation={3} className='fac-card-top paper-hover'>
						<CardContent>
							<PagosDataGrid
								dataDetalles={
									dataDetalles.complemento.pagos.pago
								}
								headCells={headCells}
							/>
						</CardContent>
					</Card>
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'row',
							lg: 'row',
							xl: 'row',
						}}
						spacing={1}
					>
						{/* COMPROBANTE */}
						<ComprobanteCard dataDetalles={dataDetalles} />
						{/* FISCALES */}
						<FiscalesCard dataDetalles={dataDetalles} />
					</Stack>
				</Stack>
			);
		}
	};

	const headCells = [
		{
			id: 'serie',
			field: 'serie',
			width: 100,
			headerName: t('booking.facturas.serie') + '/' + t('dataGrid.folio'),
			editable: false,
		},
		{
			id: 'idDocumento',
			field: 'idDocumento',
			width: 260,
			headerName: t('dataGrid.folioFiscal'),
			editable: false,
		},
		{
			id: 'monedaDR',
			field: 'monedaDR',
			width: 70,
			headerName: t('dataGrid.monedaDR'),
			editable: false,
		},
		{
			id: 'impPagado',
			field: 'impPagado',
			width: 120,
			headerName: t('dataGrid.impPagado'),
			editable: false,
		},
	];

	return (
		// Carga el contenido de la página
		<>{loading ? <LinearProgress /> : <>{render()}</>}</>
	);
}
