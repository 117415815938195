import { Box } from '@mui/material';
import Navbar from './navbar/NavBar';
import Cookies from 'universal-cookie';
import { Navigate } from 'react-router-dom';
import React from 'react';
import { Grid, CssBaseline } from '@mui/material';
import MiniDrawer from './sidebar/MiniDrawer';
import Inicio from '../views/Inicio';
import IndexTN from '../views/Terrestre/Nacional/IndexTN';
import IndexAI from '../views/Aereo/Importacion/IndexAI';
import IndexAE from '../views/Aereo/Exportacion/IndexAE';
import IndexDA from '../views/Aduanas/Aereo/IndexDA';
import IndexDM from '../views/Aduanas/Maritimo/IndexDM';
import IndexDT from '../views/Aduanas/Terrestre/IndexDT';
import IndexMI from '../views/Maritimo/Importacion/IndexMI';
import IndexME from '../views/Maritimo/Exportacion/IndexME';
import IndexEC from '../views/EstadoDeCuenta/IndexEC';
import UserProfile from '../views/Usuario/UserProfile';
import ErrorBoundary from './ErrorBoundary';
import { useState } from 'react';
import IndexFileUpload from '../views/FileUpload/IndexFileUpload';
import IndexTCAE from '../views/Terrestre/Centroamerica/Expo/IndexTCAE';
import IndexUSCAMEXExpo from '../views/USCAMEX/Exportacion/IndexUSCAMEXExpo';
import IndexUSCAMEXImpo from '../views/USCAMEX/Importacion/IndexUSCAMEXImpo';
import ListadoUsuarios from '../views/Usuario/ListadoUsuarios';
import IndexDomestico from '../views/USCAMEX/Domestico/IndexDomestico';
import IndexFronteraMexico from '../views/USCAMEX/FronteraMexico/IndexFronteraMexico';
import IndexMexicoFrontera from '../views/USCAMEX/MexicoFrontera/IndexMexicoFrontera';
import IndexIntermodal from '../views/USCAMEX/Intermodal/IndexIntermodal';
import IndexCatalogos from '../views/Catalogos/IndexCatalogos';
import CentroAyudaTableTable from './centro-de-ayuda/CentroAyudaTable';
import ProductsLists from '../views/Catalogos/Productos/ProductsList';
import ConsignatariosLists from '../views/Catalogos/Consignatarios/ConsignatariosList';
import DetallesFactura from './Facturas/DetallesFactura';
import MisDireccionesList from '../views/Catalogos/MisDirecciones/MisDireccionesList';
import IndexBLWithCatalog from '../views/Maritimo/Exportacion/Bl/IndexBLWithCatalog';
import MySkeletonPage from './Skeletons/SkeletonPage';
import MisEmbalajesList from '../views/Catalogos/MisEmbalajes/MisEmbalajesList';
import IndexCartaPorte from '../views/Terrestre/Nacional/CartaPorte/IndexCartaPorte';
import ProductosCartaPorteList from '../views/Catalogos/ProductosCartaPorte/ProductosCartaPorteList';
import DetallesComplemento from './complemento-de-pago/DetallesComplemento';

/*Descripción:Este componente se encarga  de la renderización de los componentes en comun de las modalidades
como lo son el siderbar, navbar y configurar la zona de los componentes hijos
Componente padre: App.js
Componente hijo: Inicio //
	IndexBL //
	Index AI //
	Index AE //
	IndexDI //
	IndexDE //
	IndexDT //
	IndexTN //
	IndexMI //
	IndexME //
	IndexEC //
	IndexFileUpload //
	UserProfile
props: Sin uso de props
*/

export default function CommonLayout({ view, loading }) {
	// Obtiene el token de la cookie
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');

	// Funcion para asignar el componente a mostrar
	const viewToRender = () => {
		switch (view) {
			case 'Inicio':
				return <Inicio />;
			case 'IndexBLWithCatalog':
				return <IndexBLWithCatalog />;
			case 'IndexAI':
				return <IndexAI />;
			case 'IndexAE':
				return <IndexAE />;
			case 'IndexDA':
				return <IndexDA />;
			case 'IndexDM':
				return <IndexDM />;
			case 'IndexDT':
				return <IndexDT />;
			case 'IndexTN':
				return <IndexTN />;
			case 'IndexCartaPorte':
				return <IndexCartaPorte />;
			case 'IndexMI':
				return <IndexMI />;
			case 'IndexME':
				return <IndexME />;
			case 'IndexEC':
				return <IndexEC />;
			case 'IndexTCAE':
				return <IndexTCAE />;
			case 'UserProfile':
				return <UserProfile />;
			case 'file-upload':
				return <IndexFileUpload />;
			case 'IndexUSCAMEXExpo':
				return <IndexUSCAMEXExpo />;
			case 'IndexUSCAMEXImpo':
				return <IndexUSCAMEXImpo />;
			case 'IndexDomestico':
				return <IndexDomestico />;
			case 'IndexFronteraMexico':
				return <IndexFronteraMexico />;
			case 'IndexMexicoFrontera':
				return <IndexMexicoFrontera />;
			case 'IndexIntermodal':
				return <IndexIntermodal />;
			case 'ListadoUsuarios':
				return <ListadoUsuarios endpoint='user-admin/user-list' />;
			case 'CentroAyuda':
				return <CentroAyudaTableTable />;
			case 'IndexCatalogos':
				return <IndexCatalogos />;
			case 'ProductsList':
				return <ProductsLists />;
			case 'ConsignatariosLists':
				return <ConsignatariosLists />;
			case 'MisDireccionesList':
				return <MisDireccionesList />;
			case 'MisEmbalajesList':
				return <MisEmbalajesList />;
			case 'ProductosCartaPorteList':
				return <ProductosCartaPorteList />;
			case 'DetallesFactura':
				return <DetallesFactura />;
			case 'DetallesComplemento':
				return <DetallesComplemento />;
			default:
				return <h1>No component found</h1>;
		}
	};

	// Funcion para abrir y cerrar el sidebar
	const [openSideBar, setOpenSideBar] = useState(false);
	const handleDrawerOpen = () => {
		if (openSideBar === true) {
			setOpenSideBar(false);
		} else {
			setOpenSideBar(true);
		}
	};

	// Si no hay token redirecciona al login
	if (token === undefined) {
		return <Navigate replace to='/' />;
	} else {
		// Se muestra el componente con el renderizado del tamaño de MiniDrawer
		return (
			<Box>
				<Navbar
					onClick={() => handleDrawerOpen()}
					openSideBar={openSideBar}
				/>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Box id='Box-Minidrawer'>
							<CssBaseline />
							<MiniDrawer
								openSideBar={openSideBar}
								onClick={() => handleDrawerOpen()}
							/>
							<Box component='main' id='boxMainContent'>
								{loading ? (
									<MySkeletonPage />
								) : (
									<ErrorBoundary>
										{viewToRender()}
									</ErrorBoundary>
								)}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		);
	}
}
