import { Box, Modal } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PdfViewer from './PDFViewer';
import MySkeletonList from '../../Skeletons/SkeletonList';

// Se crean los estilos del Modal
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '100%',
	height: 600,
	overflowY: 'auto',
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: 4,
	borderRadius: '10px',
};

export default function PDFFacturaModal(props) {
	// Funcion para traducir la pagina en español o ingles
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [url, setURL] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const pdfFetch = async () => {
		setLoading(true);
		// Se hace el Get Fetch de la información
		// Configuracion del header
		var myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/png');
		myHeaders.append('Authorization', 'Bearer ' + token);
		try {
			const response = await fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					props.endpoint +
					props.factura +
					'&anio=' +
					props.anio +
					'&mes=' +
					props.mes,
				{
					method: 'GET',
					headers: myHeaders,
					redirect: 'follow',
				}
			);
			if (response.status === 200 || response.status === 500) {
				// Obtener el Blob desde la respuesta
				const blob = await response.blob();
				// Crear la URL a partir del Blob
				const pdfBlob = blob;
				setURL(pdfBlob);
				setLoading(false);
			} else if (response.status === 401) {
				// Si el token caduca se redirige al login
				navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
			} else if (response.status === 504) {
				setLoading(false);
			}
		} catch (error) {
			console.error('Error mostrando el PDF: ', error);
		}
	};

	// Hacer la petición al endpoint indicado según la url
	useEffect(() => {
		if (props.openModal) pdfFetch();
	}, [props.openModal]);

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				{loading ? (
					<MySkeletonList />
				) : (
					<Box minWidth={500}>
						{url && (
							<PdfViewer
								pdfBlob={url}
								handleCloseModal={props.handleCloseModal}
							/>
						)}
					</Box>
				)}
			</Box>
		</Modal>
	);
}
