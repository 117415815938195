import GetDocumentosFetch from './get-data/GetDocumentosFetch';

export default async function SelectorBookingFunction(
	booking,
	setLoadingDocumentos,
	setDataDetallesDocumentos,
	navigate
) {
	let letras = [];
	let letrasArray = [];

	// Dependiendo del tamaño de la palabra se recolectan los caracteres relevantes del booking
	if (booking.length === 18) {
		// Si es un RO tomamos los primeros 4 elementos del array y el ultimo
		letras = [booking.slice(0, 4), booking.slice(-1)];
	} else if (booking.length === 17) {
		// Si es un booking "Normal" tomamos los primeros 3 elementos del array y el ultimo
		letras = [booking.slice(0, 3), booking.slice(-1)];
	} else if (booking.length === 15) {
		// Si es un despacho tomamos los primeros 3 elementos del array y el ultimo
		letras = [booking.slice(0, 3), booking.slice(-1)];
	}
	// Se convierte el array de letras en un string y luego se convierte en un array de caracteres
	letrasArray = letras.join('').split('');

	await GetDocumentosFetch(
		letrasArray,
		booking,
		setLoadingDocumentos,
		setDataDetallesDocumentos,
		navigate
	);
}
