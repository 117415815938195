import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, Button, Grid, useMediaQuery } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

/*Descripción: Este componente se encarga de renderizar el contenido del modal para eliminar las direcciones.
Componente padre: DeleteIconDataGrid.jsx
Componente hijo: Sin componentes hijos
props: dataFetch: Función para obtener los datos de la API // id: id del consignatario //
idDireccion: id de la dirección // handleCloseModal: Función para cerrar el modal //
openModal: Variable para abrir el modal // setOpenModal: Función para abrir el modal
*/

export default function EliminarDireccionesModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '250';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 450,
		minWidth: minWidth,
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: 4,
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const navigate = useNavigate();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Se crean los parametros del metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	var raw = JSON.stringify({
		cod_direccion: props.idDireccion,
		cod_persona: props.id,
	});
	var requestOptions = {
		method: 'PATCH',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	// Funciones para el modal
	const handleSaveModal = () => {
		// Se hace el llamado al metodo Patch
		fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'consignatario/direcciones/delete',
			requestOptions
		)
			.then((response) => {
				if (response.status === 200) {
					// Si es correcto se actualiza el contenido y se muestra el mensaje de exito
					props.setOpenModal(false);
					props.dataFetch();
					props.setAlertMessagePage({
						message: t('alertas.direccionElimiada'),
						severity: 'success',
					});
					props.handleClickPage();
				} else if (response.status === 401) {
					// Si el token caduca se redirige al login
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					// Si ocurre un error se muestra el Alert correspondiente
					props.setAlertMessagePage({
						message: t('alertas.direccionElimiadaError'),
						severity: 'error',
					});
					props.handleClickPage();
				}
			})
			.catch((error) => console.log('error', error));
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Grid item container rowSpacing={2}>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							<Typography variant='h5'>
								{t('modales.eliminarDireccion')}
							</Typography>
						</Grid>
						<Grid item>
							<Delete
								aria-label='add an alarm'
								color='error'
								fontSize='large'
							/>
						</Grid>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Typography variant='subtitle2'>
							{t('modales.eliminarDireccionDescripcion')}
						</Typography>
					</Grid>
					<Grid item lg={6} md={6} sm={6} xs={6}>
						<Button
							id='cargaModalCerrarButton'
							variant='outlined'
							color='primary'
							onClick={props.handleCloseModal}
						>
							{t('botones.cancelar')}
						</Button>
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						sm={6}
						xs={6}
						sx={{
							textAlign: 'right',
						}}
					>
						<Button
							id='cargaModalEliminarButton'
							variant='outlined'
							color='secondary'
							onClick={handleSaveModal}
						>
							{t('botones.eliminar')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
