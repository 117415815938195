import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography, Button, Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';

/*Descripción:Este componente se encarga desplegar un modal con el aviso de confirmacion de envio
Componente padre: SnackBarBL
Componente hijo:
props: props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.handelCloseModal = funcion para cuando cierre el modal //
	props.confirmacionDatosFormulario = funcion para los datos confirmados
*/

export default function ConfirmacionEnvio(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '250';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 450,
		minWidth: minWidth,
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: 4,
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Modal
			open={props.openModal}
			onClose={props.handleClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<Box sx={style}>
				<Typography id='modal-modal-title' variant='h5' component='h2'>
					<WarningAmberIcon sx={{ marginRight: '10px' }} />
					{t('modales.confirmacionEnvio')}
				</Typography>
				<Typography
					id='modal-modal-description'
					sx={{ mt: 2 }}
					variant='subtitle1'
				>
					{t('modales.confirmacionEnvioDescripcion')}
				</Typography>
				<Grid container>
					<Grid item lg={6} md={6} sm={6} xs={6}>
						<Button
							variant='outlined'
							color='error'
							onClick={props.hanldeCloseModal}
						>
							{t('botones.cancelar')}
						</Button>
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						sm={6}
						xs={6}
						sx={{ textAlign: 'right' }}
					>
						<LoadingButton
							variant='outlined'
							disableElevation
							onClick={props.confirmacionDatosFormulario}
						>
							{t('botones.enviar')}
						</LoadingButton>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
