import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar los eventos del Booking relacionados al TN
Componente padre: TimelineSelectorTCAExpoFCL
Componente hijo: TimelineItem
props: props.data = informacion a desplegar //
	props.dataContenedor = informacion a desplegar sobre el contenedor
*/

export default function TimelineEventosTCAExpoFCL(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			{props.dataEmpaque.fechaArriboAlmacen === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaArriboAlmacen}
					title={t('eventos.arriboAlmacen')}
				/>
			)}
			{props.dataEmpaque.fechaConsolidacion === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaConsolidacion}
					title={t('eventos.consolidacion')}
				/>
			)}
			{props.dataEmpaque.fechaSalidaAlmacen === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaSalidaAlmacen}
					title={t('eventos.salidaAlmacen')}
				/>
			)}
			{props.dataEmpaque.fechaModulacionNacional === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaModulacionNacional}
					title={t('eventos.modulacionNacional')}
				/>
			)}
			{props.dataEmpaque.fechaArriboPuntoCruce === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaArriboPuntoCruce}
					title={t('eventos.arriboPuntoCruce')}
				/>
			)}
			{props.dataEmpaque.fechaModulacionExtranjero === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaModulacionExtranjero}
					title={t('eventos.modulacionExtranjero')}
				/>
			)}
			{props.dataEmpaque.fechaSalidaPuntoCruce === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaSalidaPuntoCruce}
					title={t('eventos.salidaPuntoCruce')}
				/>
			)}
			{props.dataEmpaque.fechaArriboDestinoFinal === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaArriboDestinoFinal}
					title={t('eventos.arriboDestinoFinal')}
				/>
			)}
			{props.dataEmpaque.fechaEntregaDomicilio === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataEmpaque.fechaEntregaDomicilio}
					title={t('eventos.entregaDomicilio')}
				/>
			)}
		</Timeline>
	);
}
