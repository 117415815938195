import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	IconButton,
	Grid,
	useMediaQuery,
	Tooltip,
	Stack,
} from '@mui/material';
import InformacionCard from '../InformacionCard';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: DeleteIconDataGrid
Componente hijo: Carga
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.actualizarDato = funcion para actualizar los datos del formulario //
	props.dataDetalles = informacion acerca de los detalles del booking //
	props.dataModalidadPago = informacion sobre la modalidad de pago //
	props.dataBulto = informacion sobre el tipo de bulto //
	props.handleNext = funcion para realizar el nextStepp //
	props.emptiesInputs = funcion para validar los campos vacios //
*/

export default function InformacionModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '60%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		minHeight: '20vh',
		maxHeight: '95%',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '2vw',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<Stack direction='column' spacing={2}>
					<Grid item sx={{ textAlign: 'right' }}>
						<Tooltip title={t('botones.cerrar')}>
							<IconButton
								onClick={props.handleCloseModal}
								id='cargaModalCerrarButton'
								variant='outlined'
								color='inherit'
								className='cerrarIconButton'
							>
								<Close />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<InformacionCard
							selectedBooking={props.selectedBooking}
						/>
					</Grid>
				</Stack>
			</Box>
		</Modal>
	);
}
