import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Grid, InputAdornment } from '@mui/material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useState } from 'react';
import SwitchTrackingComponent from './SwitchTrackingComponent';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

/*Descripción:Este componente se encarga de mostrar el card del Track&Trace
Componente padre: Inicio.jsx
Componente hijo: SwitchTrackingComponent
props: Sin uso de props
*/

export default function TrackingCard() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran las const para su uso en TrackingCard
	const [openModal, setOpenModal] = useState(false);
	const [palabra, setPalabra] = useState('');
	const [letrasArray, setLetrasArray] = useState([]);

	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});

	//Se hace el arreglo de los caracteres relevantes del Booking
	const handlePalabraChange = (event) => {
		const inputPalabra = event.target.value.replace(/\s/g, '');
		let letras = [];
		// Se  valida el tamaño del arreglo del booking ingresado
		if (inputPalabra.length === 18) {
			// Si es un RO se obtienen los primeros 4 caracteres y el ultimo
			letras = [inputPalabra.slice(0, 4), inputPalabra.slice(-1)];
		} else if (inputPalabra.length === 17) {
			// Si es un booking "Normal" se obtienen los primeros 3 caracteres y el ultimo
			letras = [inputPalabra.slice(0, 3), inputPalabra.slice(-1)];
		} else if (inputPalabra.length === 15) {
			// Si es un despacho se obtienen los primeros 3 caracteres y el ultimo
			letras = [inputPalabra.slice(0, 3), inputPalabra.slice(-1)];
		}
		// Se hace el split de las letras
		const letrasArray = letras.join('').split('');
		// Se hace el set de las letras
		setLetrasArray(letrasArray);
		// Se hace el set de la palabra
		setPalabra(inputPalabra);
	};

	// Funciones para abrir y cerrar el modal
	const handleOpenModal = () => {
		// Se valida el tamaño del arreglo de letras
		if (palabra.length >= 15) {
			// Si el tamaño cumple el minimo de 15 caracteres abre el modal
			setOpenModal(true);
		}
	};

	const keyDown = (e) => {
		if (e.key === 'Enter') {
			handleOpenModal();
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	return (
		<Card className='paper-hover' sx={{ width: '100%' }}>
			<CardContent>
				<Grid item container spacing={1} paddingBottom={1.5}>
					<Grid item>
						<TravelExploreIcon fontSize='large' color='primary' />
					</Grid>
					<Grid item>
						<Typography gutterBottom variant='h5' component='div'>
							{t('tablero.trackTrace.titulo')}
						</Typography>
					</Grid>
				</Grid>
				<Stack
					direction={{
						xs: 'row',
						sm: 'row',
						md: 'row',
						lg: 'row',
						xl: 'row',
					}}
				>
					<Grid item sx={{ width: '100%' }}>
						<TextField
							id='BookingTrackAndTrace'
							label={t('tablero.bookingsActivos.booking')}
							autoComplete='off'
							variant='outlined'
							size='small'
							fullWidth
							onChange={handlePalabraChange}
							onKeyDown={keyDown}
							onInput={(e) => {
								e.target.value = e.target.value
									.toUpperCase()
									.replace(/\s/g, '');
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Tooltip
											title={t(
												'tablero.trackTrace.buscar'
											)}
											arrow
											placement='right'
										>
											<IconButton
												id='buscarButton'
												variant='outlined'
												color='primary'
												onClick={handleOpenModal}
												disabled={palabra.length < 15}
												startIcon={<Search />}
											>
												<Search />
											</IconButton>
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Stack>
				<SwitchTrackingComponent
					palabra={palabra}
					openModal={openModal}
					handleCloseModal={handleCloseModal}
					letrasArray={letrasArray}
					state={state}
				/>
			</CardContent>
		</Card>
	);
}
