import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import BookingInfo from './Info/InfoBooking';
import EventosBooking from './EventosBooking';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../../get-data/GetDataFetch';
import jwt_decode from 'jwt-decode';
import FileUploadModal from '../../../file-upload/FileUploadModal';
import GetDocumentosFetch from '../../../get-data/GetDocumentosFetch';
import BookingWithTabs from '../../../titles/BookingWithTabs';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Terrestre Nacional
Componente padre: CommonBooking
Componente hijo: BookingInfo //
	EventosBooking //
	IndexFileUpload
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición
*/

export default function BookingTerrestreNacioSearchResult(props) {
	// Se crean las const para el uso del SR
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	// Se crean los const para la validacion de los roles
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [userRole, setUserRole] = useState('');
	const [showContent2, setShowContent] = useState(false);
	const [dataDetallesDocumentos, setDataDetallesDocumentos] = useState();
	const [infoDocumento, setInfoDocumento] = useState({
		uiCodTipoDocumento: '',
		vDescripcionEspaniol: '',
		vDescripcionIngles: '',
	});
	const [documento, setDocumento] = useState('');
	const [indexDocumento, setIndexDocumento] = useState('0');
	const [loadingDocumentos, setLoadingDocumentos] = useState(true);
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});

	// Funcion para actualizar el contenido del Documento
	function actualizarDocumento(documento) {
		setDocumento(documento.value + '');
		setIndexDocumento(documento.label + '');
	}

	//Funcion para obtener las letras clave de la palabra
	async function caracteresClave() {
		let letras = [];
		// Dependiendo del tamaño de la palabra se recolectan los caracteres relevantes del booking
		if (props.selectedBooking.length === 18) {
			// Si es un RO tomamos los primeros 4 elementos del array y el ultimo
			letras = [
				props.selectedBooking.slice(0, 4),
				props.selectedBooking.slice(-1),
			];
		} else if (props.selectedBooking.length === 17) {
			// Si es un booking "Normal" tomamos los primeros 3 elementos del array y el ultimo
			letras = [
				props.selectedBooking.slice(0, 3),
				props.selectedBooking.slice(-1),
			];
		} else if (props.selectedBooking.length === 15) {
			// Si es un despacho tomamos los primeros 3 elementos del array y el ultimo
			letras = [
				props.selectedBooking.slice(0, 3),
				props.selectedBooking.slice(-1),
			];
		}
		// Se convierte el array de letras en un string y luego se convierte en un array de caracteres
		const letrasArray = letras.join('').split('');

		setLoadingDocumentos(true);
		await GetDocumentosFetch(
			letrasArray,
			props.selectedBooking,
			setLoadingDocumentos,
			setDataDetallesDocumentos,
			navigate
		);
	}

	// useEffect para validar el rol de el usuario
	useEffect(() => {
		if (token !== undefined) {
			const claims = jwt_decode(token);
			const rolesClaims = claims?.roles;
			const rol = rolesClaims.substring(1, rolesClaims.length - 1);
			setUserRole(rol);
			//======================================================================
			if (rol === 'ROLE_CLIENTE_DIRECTO_ADMIN') {
				setShowContent(true);
			}
		}
	}, []);

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		const url =
			props.endpoint + '/detalles?booking=' + props.selectedBooking;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};
	//useEffect para la seleccion del booking
	useEffect(() => {
		if (props.selectedBooking !== '') {
			dataFetch();
			caracteresClave();
		}
	}, [props.selectedBooking]);

	return (
		<>
			<BookingWithTabs
				selectedBooking={props.selectedBooking}
				TabPanelDetalles={
					<BookingInfo
						data={dataDetalles}
						selectedBooking={props.selectedBooking}
						loading={loading}
					/>
				}
				TabPanelEstatus={
					<EventosBooking
						data={dataDetalles}
						selectedBooking={props.selectedBooking}
						loading={loading}
						className='paper-hover'
					/>
				}
				TabPanelServiciosAdicionales={'NA'}
				TabPanelDocumentos={
					<FileUploadModal
						modal={false}
						selectedBooking={props.selectedBooking}
						dataDetallesDocumentos={dataDetallesDocumentos}
						infoDocumento={infoDocumento}
						actualizarDocumento={actualizarDocumento}
						documento={documento}
						indexDocumento={indexDocumento}
						setDocumento={setDocumento}
						setIndexDocumento={setIndexDocumento}
						setInfoDocumento={setInfoDocumento}
						state={state}
					/>
				}
				loading={loading}
				loadingDocumentos={loadingDocumentos}
			/>
		</>
	);
}
