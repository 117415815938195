import { Edit } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../../get-data/GetDataFetch';
import { useTranslation } from 'react-i18next';
import EditarProductoCP from '../Modales/EditarProductoCP';

/*Descripción:Este componente se encarga de renderizar el icono de eliminar en el data grid de las direcciones.
Componente padre: ListDirecciones.jsx
Componente hijo: EditarDireccionesModal.jsx
props: id: id del consignatario // idDireccion: id de la dirección //
dataCiudades: data de las ciudades // dataFetch: función para actualizar el data grid
*/

export default function EditProductoCPIcon(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las constantes para el funcionamiendo del modal
	const [dataDetalles, setDataDetalles] = useState({});
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [infoProductoCP, setInfoProductoCP] = useState({
		vAlias: '',
		vDescripcion: '',
		vClaveSat: '',
		bPeligroso: false,
		vClaveSatPeligroso: '',
		uiCodCarga: props.id,
		vFraccionArancelaria: '',
		vClaveUN: '',
	});

	// Funcion para cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Se hace el fetch para obtener los datos ya existentes en la DB
	const dataFetch = async () => {
		const url = 'catalogos/cargas-carta-porte/carga?id=' + props.id;
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	useEffect(() => {
		if (dataDetalles.carga !== undefined) {
			setInfoProductoCP({
				vAlias: dataDetalles.carga.vAlias,
				vDescripcion: dataDetalles.carga.vDescripcion,
				vClaveSat: dataDetalles.carga.vClaveSat,
				bPeligroso: dataDetalles.carga.bPeligroso,
				vClaveSatPeligroso: dataDetalles.carga.vClaveSatPeligroso,
				uiCodCarga: dataDetalles.carga.uiCodCarga,
				vFraccionArancelaria: dataDetalles.carga.vFraccionArancelaria,
				vClaveUN: dataDetalles.carga.vClaveUN,
			});
		}
	}, [dataDetalles]);

	useEffect(() => {
		dataFetch();
	}, [props.id]);

	// Función llamada al dar click al icono de editar
	const handleEditClick = async () => {
		await dataFetch();
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.editar')}>
						<IconButton
							id='editIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleEditClick}
						>
							<Edit color='primary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<EditarProductoCP
				loading={loading}
				dataFetch={props.dataFetch}
				handleTextFieldChange={props.handleTextFieldChange}
				actualizarDato={props.actualizarDato}
				dataDetalles={dataDetalles}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
				setAlertMessagePage={props.setAlertMessagePage}
				handleClickPage={props.handleClickPage}
				infoProductoCP={infoProductoCP}
				setInfoProductoCP={setInfoProductoCP}
			/>
		</>
	);
}
