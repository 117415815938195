import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ListMenu from '../../components/sidebar/ListMenu';
import { useState, useRef } from 'react';

/*Descripción:Este componente se encarga de desplegar el boton de hamburguesa
Componente padre: Global
Componente hijo: Global
props: Sin uso de props
*/

// Se configura el ancho del MiniDrawer
const drawerWidth = 220;
// Se configuran las transiciones al abrir el MiniDrawer
const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});
// Se configuran las transiciones al cerrar el MiniDrawer
const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	width: `calc(${theme.spacing(6)} + 1px)`,
	[theme.breakpoints.up('lg')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
	[theme.breakpoints.up('md')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
	[theme.breakpoints.down('lg')]: {
		width: 0,
		display: 'none',
		zIndex: theme.zIndex.drawer + 1,
	},
	overflowX: 'hidden',
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));
// Se crea el estilo del MiniDrawer
const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	BoxSizing: 'border-Box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default function MiniDrawer({ openSideBar, onClick }) {
	// Se declaran los const para su uso en el MiniDrawer
	const [open, setOpen] = useState(openSideBar);
	const drawerRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				drawerRef.current &&
				!drawerRef.current.contains(event.target)
			) {
				setOpen(false);
				if (openSideBar) {
					onClick();
				}
			}
		};

		// Add event listener on xs and sm screens
		if (window.innerWidth <= 1200) {
			document.addEventListener('mousedown', handleClickOutside);
		}

		return () => {
			// Remove event listener on cleanup
			if (window.innerWidth <= 1200) {
				document.removeEventListener('mousedown', handleClickOutside);
			}
		};
	}, [open, window.innerWidth]);

	// useEffect para abrir y cerrar el MiniDrawer
	useEffect(() => {
		setOpen(openSideBar);
	}, [openSideBar]);

	return (
		<Drawer
			ref={drawerRef}
			variant='permanent'
			open={open}
			anchor='left'
			id='Menu'
		>
			<DrawerHeader />
			<ListMenu openSideBar={openSideBar} />
		</Drawer>
	);
}
