// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import en from './locale/en.json';
import es from './locale/es.json';

i18n.use(Backend)
	.use(initReactI18next)
	.init({
		fallbackLng: 'es',
		debug: true, // Set to false in production
		interpolation: {
			escapeValue: false, // React already escapes data
		},
		resources: {
			en: {
				translation: en,
			},
			es: {
				translation: es,
			},
		},
	});

export default i18n;
