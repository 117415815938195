import React from 'react';
import { Grid, InputAdornment, Tooltip } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { Info, Subtitles } from '@mui/icons-material';

ValidatorForm.addValidationRule('isDecimal', (value) => {
	// Regular expression pattern to match a decimal number
	const decimalRegex = /^-?\d*\.?\d+$/;
	if (decimalRegex.test(value)) {
		return true; // Validation passed
	}
	return false; // Validation failed
});

/*Descripción:Este componente se encarga de desplegar la información del step y formulario Carga
Componente padre: EditarCargaBLModal // AgregarCargaBLModal
Componente hijo:
props: props.handleTextFieldChange = propiedad para actualizar el texto ingresado //
	props.handleNext = funcion para ir al siguiente step //
	props.data = información guardada a desplegar en el formulario //
	props.emptiesInputs = funcion para seleccionar el estilo //
	props.ref = informacion de referencia de los validadores //
	props.actualizarDato = funcion para actualizar el dato seleccionado //
	props.dataBulto = informacion del selectSearch de los bultos
*/

export default function Cargas(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid item id='Box-InfoStepper-Step-Carga'>
			<Grid container rowSpacing={2} columnSpacing={{ md: 2, lg: 2 }}>
				{/*Informacion de la carga*/}
				<Grid
					item
					container
					columnSpacing={2}
					rowSpacing={2}
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					{/*Embalaje*/}
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<TextValidator
							disabled
							ref={props.vEmbalaje}
							validators={['required']}
							errorMessages={['El campo es requerido']}
							onChange={props.handleTextFieldChange('vEmbalaje')}
							required
							id='vEmbalaje'
							name='vEmbalaje'
							label={t('cartaPorte.cargas.vEmbalaje')}
							fullWidth
							value={props.infoCarga.vEmbalaje || ''}
						/>
					</Grid>
					{/*Clave del embalaje en el catalogo SAT y la cantidad de bultos*/}
					<Grid
						item
						container
						columnSpacing={2}
						rowSpacing={2}
						xs={12}
						sm={12}
						md={12}
						lg={12}
					>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextValidator
								disabled
								ref={props.vUnidadDeMedida}
								validators={['required']}
								errorMessages={['El campo es requerido']}
								onChange={props.handleTextFieldChange(
									'vUnidadDeMedida'
								)}
								required
								id='vUnidadDeMedida'
								name='vUnidadDeMedida'
								label={t('cartaPorte.cargas.vUnidadDeMedida')}
								fullWidth
								value={props.infoCarga.vUnidadDeMedida || ''}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextValidator
								disabled
								ref={props.nCantidad}
								validators={[
									'required',
									'isNumber',
									'minNumber:1',
								]}
								errorMessages={['El campo es requerido']}
								onChange={props.handleTextFieldChange(
									'nCantidad'
								)}
								required
								id='nCantidad'
								name='nCantidad'
								type='number'
								label={t('cartaPorte.cargas.nCantidad')}
								fullWidth
								value={props.infoCarga.nCantidad || 0}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<TextValidator
							disabled
							ref={props.vDescripcionMercancia}
							validators={['required']}
							errorMessages={['El campo es requerido']}
							onChange={props.handleTextFieldChange(
								'vDescripcionMercancia'
							)}
							required
							id='vDescripcionMercancia'
							name='vDescripcionMercancia'
							label={t('cartaPorte.cargas.vDescripcionMercancia')}
							multiline
							rows={4}
							fullWidth
							value={props.infoCarga.vDescripcionMercancia || ''}
						/>
					</Grid>
					{/*Clave del producto en el catalogo SAT y el peso de la mercancia*/}
					<Grid
						item
						container
						columnSpacing={2}
						rowSpacing={2}
						xs={12}
						sm={12}
						md={12}
						lg={12}
					>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextValidator
								disabled
								ref={props.vClaveProductoSat}
								validators={['required']}
								errorMessages={['El campo es requerido']}
								onChange={props.handleTextFieldChange(
									'vClaveProductoSat'
								)}
								required
								id='vClaveProductoSat'
								name='vClaveProductoSat'
								label={t('cartaPorte.cargas.vClaveProductoSat')}
								fullWidth
								value={props.infoCarga.vClaveProductoSat || ''}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextValidator
								disabled
								ref={props.nPeso}
								validators={[
									'required',
									'isNumber',
									'minNumber:1',
								]}
								errorMessages={['El campo es requerido']}
								onChange={props.handleTextFieldChange('nPeso')}
								required
								id='nPeso'
								name='nPeso'
								type='number'
								label={t('cartaPorte.cargas.nPeso')}
								fullWidth
								value={props.infoCarga.nPeso || 0}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											KG
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
					{/*Fraccion arancelaria y valor unitario*/}
					<Grid
						item
						container
						columnSpacing={2}
						rowSpacing={2}
						xs={12}
						sm={12}
						md={12}
						lg={12}
					>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextValidator
								disabled
								ref={props.vFraccionArancelaria}
								onChange={props.handleTextFieldChange(
									'vFraccionArancelaria'
								)}
								id='vFraccionArancelaria'
								name='vFraccionArancelaria'
								label={t(
									'cartaPorte.cargas.vFraccionArancelaria'
								)}
								fullWidth
								value={
									props.infoCarga.vFraccionArancelaria || ''
								}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<Subtitles />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position='end'>
											<Tooltip
												title={t(
													'cartaPorte.vFraccionArancelariaIngHelper'
												)}
												arrow
												placement='right'
											>
												<Info
													className='primaryIcon'
													fontSize='medium'
												/>
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						{props.disabled ? (
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<TextValidator
									disabled
									ref={props.vUUID}
									onChange={props.handleTextFieldChange(
										'vUUID'
									)}
									id='vUUID'
									name='vUUID'
									label={t('cartaPorte.cargas.vUUID')}
									fullWidth
									value={props.infoCarga.vUUID || ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Tooltip
													title={t(
														'cartaPorte.vUUIDIngHelper'
													)}
													arrow
													placement='right'
												>
													<Info
														className='primaryIcon'
														fontSize='medium'
													/>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						) : (
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<TextValidator
									ref={props.vUUID}
									validators={['required']}
									errorMessages={['El campo es requerido']}
									onChange={props.handleTextFieldChange(
										'vUUID'
									)}
									required
									id='vUUID'
									name='vUUID'
									label={t('cartaPorte.cargas.vUUID')}
									fullWidth
									value={props.infoCarga.vUUID || ''}
								/>
							</Grid>
						)}
					</Grid>
					{/*Datos de mercancia peligrosa*/}
					{props.infoCarga.bPeligroso && (
						<Grid
							item
							container
							columnSpacing={2}
							rowSpacing={2}
							xs={12}
							sm={12}
							md={12}
							lg={12}
						>
							<Grid item xs={12} sm={12} md={4} lg={4}>
								<TextValidator
									disabled
									ref={props.vClaveMaterialPeligroso}
									validators={
										props.infoCarga.bPeligroso
											? ['required']
											: []
									}
									errorMessages={['El campo es requerido']}
									onChange={props.handleTextFieldChange(
										'vClaveMaterialPeligroso'
									)}
									required={props.infoCarga.bPeligroso}
									id='vClaveMaterialPeligroso'
									name='vClaveMaterialPeligroso'
									label={t(
										'cartaPorte.cargas.vClaveMaterialPeligroso'
									)}
									fullWidth
									value={
										props.infoCarga
											.vClaveMaterialPeligroso || ''
									}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Tooltip
													title={t(
														'cartaPorte.vClaveMaterialPeligrosoIngHelper'
													)}
													arrow
													placement='right'
												>
													<Info
														className='primaryIcon'
														fontSize='medium'
													/>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4}>
								<TextValidator
									disabled
									ref={props.vClaveEmbalajePeligroso}
									validators={
										props.infoCarga.bPeligroso
											? ['required']
											: []
									}
									errorMessages={['El campo es requerido']}
									onChange={props.handleTextFieldChange(
										'vClaveEmbalajePeligroso'
									)}
									required={props.infoCarga.bPeligroso}
									id='vClaveEmbalajePeligroso'
									name='vClaveEmbalajePeligroso'
									label={t(
										'cartaPorte.cargas.vClaveEmbalajePeligroso'
									)}
									fullWidth
									value={
										props.infoCarga
											.vClaveEmbalajePeligroso || ''
									}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Tooltip
													title={t(
														'cartaPorte.vClaveEmbalajePeligrosoIngHelper'
													)}
													arrow
													placement='right'
												>
													<Info
														className='primaryIcon'
														fontSize='medium'
													/>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4}>
								<TextValidator
									disabled
									ref={props.vUNPeligroso}
									validators={
										props.infoCarga.bPeligroso
											? ['required']
											: []
									}
									errorMessages={['El campo es requerido']}
									onChange={props.handleTextFieldChange(
										'vUNPeligroso'
									)}
									required={props.infoCarga.bPeligroso}
									id='vUNPeligroso'
									name='vUNPeligroso'
									label={t('cartaPorte.cargas.vUNPeligroso')}
									fullWidth
									value={props.infoCarga.vUNPeligroso || ''}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}
