import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import InputAdornment from '@mui/material/InputAdornment';
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

/*Descripción:Este componente se encarga de renderizar el contenido del formulario de los contactos.
Componente padre: EditarContactosModal.jsx
Componente hijo: Sin hijos
props: handleTextFieldChange = funcion para actualizar el estado del componente //
	infoContacto = Información del contacto //
*/

export default function ContactoForm(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid
			container
			rowSpacing={1.5}
			columnSpacing={{ md: 1, lg: 1 }}
			mb={3}
		>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('forms.contacto.nombre')}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['required', 'minStringLength:3']}
					errorMessages={[
						t('errorMessages.campoRequerido'),
						t('errorMessages.nombreInvalido'),
					]}
					onChange={props.handleTextFieldChange('contacto')}
					required
					id='contacto'
					name='contacto'
					rows={3}
					fullWidth
					value={props.infoContacto.contacto || ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PermContactCalendarIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('forms.contacto.telefono')}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['required']}
					errorMessages={[t('errorMessages.campoRequerido')]}
					onChange={props.handleTextFieldChange('telefono')}
					required
					id='phone'
					name='phone'
					variant='outlined'
					fullWidth
					value={props.infoContacto.telefono || ''}
					type='tel'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<CallIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Typography variant='subtitle2'>
					{t('forms.contacto.correo')}
				</Typography>
				<TextValidator
					onKeyDown={props.handleKeyPress}
					validators={['isEmail']}
					errorMessages={[t('errorMessages.correoInvalido')]}
					onChange={props.handleTextFieldChange('email')}
					id='email'
					name='email'
					variant='outlined'
					value={props.infoContacto.email || ''}
					fullWidth
					type='email'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<AlternateEmailIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
		</Grid>
	);
}
