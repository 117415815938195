import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	useMediaQuery,
	Tooltip,
	IconButton,
} from '@mui/material';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import DatosProductoForm from '../Forms/DatosProductoForm';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente muestra el modal para agregar un producto al catálogo de productos
Componente padre: ProductsList.jsx
Componente hijo: DatosProductoForm.jsx
props: props.openModal = funcion para abrir el modal //
	props.handelCloseModal = funcion para cuando cierre el modal //
	props.handleClick1 = props para desplegar el Alert //
	props.setAlertMessage = props para declarar el mensaje del Alert
*/

export default function AgregarProducto(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '65%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: '65%',
		maxHeight: '95%',
		minHeight: '45vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const [isInvalid, setIsInvalid] = useState(true);
	const token = cookies.get('jwt_authorization');
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [loadingButton, setLoadingButton] = useState(false);
	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});
	const { vertical, horizontal } = state;
	const navigate = useNavigate();
	var dataClasePeligroso = [
		{ value: 1, label: '1' },
		{ value: 2, label: '2' },
		{ value: 3, label: '3' },
		{ value: 4, label: '4' },
		{ value: 5, label: '5' },
		{ value: 6, label: '6' },
		{ value: 7, label: '7' },
		{ value: 8, label: '8' },
		{ value: 9, label: '9' },
	];
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const [infoProducto, setInfoProducto] = useState({
		nombreCorto: '',
		productoEsp: '',
		productoIng: '',
		fraccionArancelaria: '',
	});
	// Funcion para actualizar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		actualizarDato(name, event.target.value.toUpperCase());
	};
	// Funcion para actualizar el estado de los datos del producto
	function actualizarDato(name, value) {
		setInfoProducto({
			...infoProducto,
			[name]: value,
		});
	}

	// Funcion para validar que todos los campos esten llenos
	function validaCarga() {
		var valida = true;
		Object.keys(infoProducto).forEach(function (key) {
			if (
				infoProducto['nombreCorto'] === '' ||
				infoProducto['productoEsp'] === '' ||
				infoProducto['productoIng'] === '' ||
				infoProducto['fraccionArancelaria'] === '' ||
				isInvalid === 'true'
			) {
				valida = false;
			}
		});
		return valida;
	}
	// Funcion para abrir y cerrar el Snackbar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	// Se obtiene la lista de los números UN
	const [options, setOptions] = useState([
		{
			value: '',
			label: '',
		},
	]);

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	// Funcion para guardar el producto
	const handleSaveModal = (e) => {
		setLoadingButton(true);
		e.preventDefault();
		e.stopPropagation();
		// Hace la validacion de los datos ingresados en el formulario
		if (validaCarga() === true) {
			// Hace el fetch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'catalogos/productos-por-cliente/add',
				requestOptions
			)
				.then((response) => {
					if (response.status === 200) {
						// Se hace la actualizacion de los datos mostrados
						props.dataFetch();
						// Cierra el modal
						props.setOpenModal(false);
						// Muestra el mensaje de exito
						props.setAlertMessage({
							severity: 'success',
							message: t('catalogos.productos.productoAgregado'),
						});
						// Abre el Alert
						props.handleClick1();
						props.actualizaListaProd();
						setLoadingButton(false);
					} else if (response.status === 401) {
						// Si el caduca el token se redirige al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					}
					// Si no se pudo guardar el producto
					else {
						// Cierra el modal
						props.setOpenModal(false);
						// Muestra el mensaje de error
						props.setAlertMessage({
							severity: 'error',
							message: t(
								'catalogos.productos.productoAgregadoError'
							),
						});
						// Abre el Alert
						props.handleClick1();
						setLoadingButton(false);
					}
				})
				.catch((error) => 'error: ' + error);
		}
		// Si no se llenaron todos los campos
		else {
			// Muestra el mensaje de error
			setAlertMessage({
				severity: 'error',
				message: t('catalogos.productos.validaDatosProducto'),
			});
			// Abre el Alert
			handleClick1();
			setLoadingButton(false);
		}
	};

	// Funcion para hacer la validacion de los campos del formulario
	const handleSubmit = (event) => {
		if (event.nativeEvent.target.ariaInvalid === 'true') {
			setIsInvalid(event.nativeEvent.target.ariaInvalid);
		}
	};

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(infoProducto),
		redirect: 'follow',
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={true}
				>
					<Grid item container rowSpacing={2}>
						<Grid item lg={9} md={9} sm={9} xs={9}>
							<Typography variant='h5'>
								{t('catalogos.productos.agregarProducto')}
							</Typography>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={props.handleCloseModal}
									id='cargaModalCerrarIconButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							{/*Aqui va el codigo de formulario */}
							<DatosProductoForm
								agregarProducto={true}
								isChecked={props.isChecked}
								handleTextFieldChange={handleTextFieldChange}
								actualizarDato={actualizarDato}
								handleNext={handleSubmit}
								infoProducto={infoProducto}
								listaNumerosUN={options}
								defaultValue={options[0]}
								optionsClase={dataClasePeligroso}
								defaultClase={dataClasePeligroso[0]}
								handleKeyPress={handleKeyPress}
							/>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Button
								id='templateModalCerrarButton'
								variant='outlined'
								color='error'
								onClick={props.handleCloseModal}
							>
								{t('botones.cancelar')}
							</Button>
						</Grid>
						<Grid
							item
							lg={6}
							md={6}
							sm={6}
							xs={6}
							sx={{ textAlign: 'right' }}
						>
							<LoadingButton
								id='templateModalAgregarButton'
								variant='outlined'
								color='primary'
								loading={loadingButton}
								type='submit'
							>
								{t('botones.agregar')}
							</LoadingButton>
						</Grid>
						<Snackbar
							open={snackbar1.open}
							autoHideDuration={10000}
							onClose={handleClose1}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert
								onClose={handleClose1}
								severity={alertMessage.severity}
							>
								{alertMessage.message}
							</Alert>
						</Snackbar>
					</Grid>
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
