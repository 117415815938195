import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de mostrar los saldos del EstadoDeCuentaCard del inicio
Componente padre: EstadoDeCuentaCard
Componente hijo:
props: props.dataDetallesIntercom = datos de la compañia Intercom //
	props.dataDetallesSAT = datos de la compañia SAT //
	props.dataDetallesFAST = datos de la compañia FastForward //
	props.moneda = Divisa de la deuda
*/

export default function EstadoDeCuentaItem(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en EstadoDeCuentaItem
	const [total, setTotal] = useState(0);
	const [saldo, setSaldo] = useState(0);
	const saldoIntercom = Number(props.dataDetallesIntercom.saldo) || 0;
	const saldoSAT = Number(props.dataDetallesSAT.saldo) || 0;
	const saldoFAST = Number(props.dataDetallesFAST.saldo) || 0;
	const sumaSaldos = saldoIntercom + saldoSAT + saldoFAST;
	const facturasIntercom =
		Number(props.dataDetallesIntercom['total-facturas']) || 0;
	const facturasSat = Number(props.dataDetallesSAT['total-facturas']) || 0;
	const facturasFast = Number(props.dataDetallesFAST['total-facturas']) || 0;
	const sumaFacturas = facturasIntercom + facturasSat + facturasFast;
	// Se declaran los props de iconos como valores locales
	const { icon: Icon } = props;
	// Se declara el flotante con el saldo
	var flotante = parseFloat(saldo, 10);

	// useEffect para actualizar los valores de total y saldo
	useEffect(() => {
		// Se declara el total con los datos
		setTotal(sumaFacturas);
		// Se declara el saldo con los datos
		setSaldo(sumaSaldos.toFixed(3));
	}, [props]);

	// Función para darle formato a la puntuacion de los valores
	const defaultOptions = {
		significantDigits: 2,
		thousandsSeparator: ',',
		decimalSeparator: '.',
		symbol: '',
	};

	// Funcion para darle formato a los valores obtenidos
	const currencyFormatter = (value, options) => {
		options = { ...defaultOptions, ...options };
		value = value.toFixed(options.significantDigits);
		const [currency, decimal] = value.split('.');
		return `${options.symbol} ${currency.replace(
			/\B(?=(\d{3})+(?!\d))/g,
			options.thousandsSeparator
		)}${options.decimalSeparator}${decimal}`;
	};

	return (
		<Grid container item lg={4} md={4} sm={4} xs={12} spacing={1}>
			<Grid item lg={12} md={12} sm={12} xs={12}>
				<Grid container spacing={0}>
					<Typography variant='body1'>
						{t('tablero.estadoresumen.facturas', {
							moneda: props.moneda,
						})}
					</Typography>
				</Grid>
				<Grid container spacing={0}>
					<Typography variant='body2'>{total}</Typography>
				</Grid>
			</Grid>
			<Grid item lg={12}>
				<Grid container spacing={0}>
					<Typography variant='body1'>
						{t('tablero.estadoresumen.saldo', {
							moneda: props.moneda,
						})}
					</Typography>
				</Grid>
				<Grid container spacing={0}>
					{Icon && <Icon fontSize='small' />}
					<Typography variant='body2'>
						{currencyFormatter(flotante)}
						{props.moneda}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}
