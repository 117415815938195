import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/*Descripción: Este componente se encarga de desplegar la informacion de los pedimentos del Despacho Expo
Componente padre: EventosBookingDespachoExpo
Componente hijo:
props: props.data = informacion a desplegar //
	props.dataPedimentos = informacion a desplegar sobre los pedimentos
*/

export default function InfoPedimentoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	let cargaPeligrosa = null;
	// Se valida el valor de carga peligrosa
	if (props.dataPedimento['carga-peligrosa'] === 'S') {
		// Si es peligrosa selecciona el icono en color rojo
		cargaPeligrosa = <Warning sx={{ color: 'red' }} />;
	}
	return (
		<Box sx={{ overflowY: 'auto', maxHeight: '380px', width: '100%' }}>
			<Grid item container rowSpacing={3} lg={12} md={12} sm={12} xs={12}>
				<Grid item container spacing={1} lg={12} md={12} sm={12}>
					<Grid item container spacing={2} lg={12} md={12} sm={12}>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Grid item container>
								<Typography variant='subtitle2' color='initial'>
									{t('booking.estatus.exportador')}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.nombre')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.dataPedimento.exportador ===
									undefined ? (
										<></>
									) : (
										<>
											{
												props.dataPedimento.exportador
													.nombre
											}
										</>
									)}
								</Typography>
							</Grid>
							<Grid item container>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.direccion')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.dataPedimento.exportador ===
									undefined ? (
										<></>
									) : (
										<>
											{
												props.dataPedimento.exportador
													.direccion
											}
										</>
									)}{' '}
									{props.dataPedimento.exportador ===
									undefined ? (
										<></>
									) : (
										<>
											{
												props.dataPedimento.exportador
													.taxid
											}
										</>
									)}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							container
							spacing={1}
							lg={12}
							md={12}
							sm={12}
						>
							<Grid item container>
								<Typography variant='subtitle2' color='initial'>
									{t('booking.detalles.informacionCarga')}
								</Typography>
							</Grid>
							<Grid item lg={6} md={6} sm={6}>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.trafico')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.trafico === undefined ? (
										<></>
									) : (
										<>{props.data.trafico}</>
									)}
								</Typography>
							</Grid>
							<Grid item lg={6} md={6} sm={6}>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.estatus.incoterm')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.incoterm === undefined ? (
										<></>
									) : (
										<>{props.data.incoterm}</>
									)}
								</Typography>
							</Grid>
							<Grid item lg={6} md={6} sm={6}>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.aduana')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.aduana === undefined ? (
										<></>
									) : (
										<>{props.data.aduana}</>
									)}
								</Typography>
							</Grid>
							<Grid item lg={6} md={6} sm={6}>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.estatus.numeroPedimento')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.dataPedimento['numero-pedimento'] ===
									undefined ? (
										<></>
									) : (
										<>
											{
												props.dataPedimento[
													'numero-pedimento'
												]
											}
										</>
									)}
								</Typography>
							</Grid>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.estatus.modulacion')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.dataPedimento['resultado-modulacion'] !==
								undefined ? (
									<>
										{props.dataPedimento[
											'resultado-modulacion'
										] === 'V' ? (
											<CircleIcon color='success' />
										) : (
											<>
												{props.dataPedimento[
													'resultado-modulacion'
												] === 'R' && (
													<CircleIcon
														sx={{
															color: 'red',
														}}
													/>
												)}{' '}
											</>
										)}
									</>
								) : (
									<>
										<CircleIcon color='disabled' />
									</>
								)}
							</Typography>
						</Grid>

						{props.dataPedimento['resultado-modulacion'] !== 'R' ? (
							<></>
						) : (
							<Grid item lg={6} md={6} sm={6} xs={6}>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t(
										'booking.estatus.resultadoReconocimiento'
									)}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.dataPedimento[
										'resultado-reconocimiento'
									] !== undefined ? (
										<>
											{props.dataPedimento[
												'resultado-reconocimiento'
											] === 'V' ? (
												<CircleIcon color='success' />
											) : (
												<>
													{props.dataPedimento[
														'resultado-reconocimiento'
													] === 'R' && (
														<CircleIcon
															sx={{
																color: 'red',
															}}
														/>
													)}{' '}
												</>
											)}
										</>
									) : (
										<>
											<CircleIcon color='disabled' />
										</>
									)}
								</Typography>
							</Grid>
						)}

						{cargaPeligrosa === null ? (
							<></>
						) : (
							<Grid item lg={6} md={6} sm={6} xs={6}>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.estatus.cargaPeligrosa')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{cargaPeligrosa}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
