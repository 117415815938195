import {
	Box,
	Button,
	Grid,
	Modal,
	Typography,
	Tooltip,
	IconButton,
} from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBackIos, ArrowForwardIos, Close } from '@mui/icons-material';
import MySkeletonList from '../../Skeletons/SkeletonList';
import InvalidPDF from '../../cards/InvalidPDF';

export default function QRFacturaModal(props) {
	// Se crean los estilos del Modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 'auto',
		maxWidth: '100%',
		height: 500,
		overflowY: 'auto',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: 4,
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [imagePreviewUrl, setImagePreviewUrl] = useState('');
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [isCorrect, setIsCorrect] = useState(true);
	// Constante para el numero de paginas horizontal
	const [currentPage, setCurrentPage] = useState(1);
	// Constante para el numero de paginas vertical
	const [numPages, setNumPages] = useState(null);

	const qrFetch = async () => {
		setLoading(true);
		// Se hace el Get Fetch de la información
		// Configuracion del header
		var myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/png');
		myHeaders.append('Authorization', 'Bearer ' + token);
		try {
			const response = await fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					props.endpoint +
					props.factura +
					'&anio=' +
					props.anio +
					'&mes=' +
					props.mes,
				{
					method: 'GET',
					headers: myHeaders,
					redirect: 'follow',
				}
			);
			if (response.status === 200 || response.status === 500) {
				// Obtener el Blob desde la respuesta
				const blob = await response.blob();
				// Crear la URL a partir del Blob
				const url = URL.createObjectURL(blob);
				setImagePreviewUrl(url);
				setIsCorrect(true);
				setLoading(false);
			} else if (response.status === 401) {
				// Si el token caduca se redirige al login
				navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
			} else if (response.status === 504) {
				setIsCorrect(false);
				setLoading(false);
			}
		} catch (error) {
			console.error('Error mostrando el QR: ', error);
		}
	};

	const resultToRender = () => {
		if (isCorrect) {
			return (
				<Grid item container rowSpacing={2}>
					<Grid item container lg={12} md={12} sm={12} xs={12}>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'left' }}
						>
							<Tooltip title={t('modales.anterior')}>
								<Button
									onClick={() =>
										setCurrentPage((prevPage) =>
											Math.max(prevPage - 1, 1)
										)
									}
									variant='outlined'
									color='primary'
									disabled={currentPage === 1}
								>
									<ArrowBackIos fontSize='small' />
								</Button>
							</Tooltip>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'center' }}
						>
							<Typography variant='h6' component='h6'>
								{currentPage}/{currentPage}
							</Typography>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('modales.siguiente')}>
								<Button
									onClick={() =>
										setCurrentPage((prevPage) =>
											Math.min(
												prevPage + 1,
												numPages || 1
											)
										)
									}
									variant='outlined'
									color='primary'
									disabled={currentPage === (numPages || 1)}
								>
									<ArrowForwardIos fontSize='small' />
								</Button>
							</Tooltip>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={props.handleCloseModal}
									id='ModalCerrarButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
									type='submit'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{
							alignContent: 'center',
							textAlign: 'center',
						}}
					>
						<img src={imagePreviewUrl} alt='QR' />
					</Grid>
				</Grid>
			);
		} else {
			return (
				<>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{ textAlign: 'right' }}
					>
						<Tooltip title={t('modales.cerrar')}>
							<Button
								onClick={props.handleCloseModal}
								id='ModalCerrarButton'
								variant='outlined'
								color='secondary'
								type='submit'
							>
								<Close />
							</Button>
						</Tooltip>
					</Grid>
					<InvalidPDF />
				</>
			);
		}
	};

	// Hacer la petición al endpoint indicado según la url
	useEffect(() => {
		if (props.openModal) {
			qrFetch();
			resultToRender();
		}
	}, [props.openModal]);

	useEffect(() => {
		// Resetea el número de páginas cuando cambia el PDF
		setNumPages(null);
	}, [imagePreviewUrl]);

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				{loading ? <MySkeletonList /> : resultToRender()}
			</Box>
		</Modal>
	);
}
