import { Grid, Card, CardContent } from '@mui/material';
import React from 'react';
import DataGridCargasCartaPorte from '../Cargas/DataGridCargasCartaPorte';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: CommonLayout.jsx //
Componente hijo: FileUploadModal
props: props.selectedBooking = booking seleccionado
*/

export default function CargasCartaPorte(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Card elevation={0} width={'100%'}>
			<CardContent>
				<Grid item xs={12} sm={12} md={12} lg={12} paddingBottom={2}>
					<Typography variant='subtitle1'>
						{t('cartaPorte.carga')}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<DataGridCargasCartaPorte
						dataDetalles={props.dataDetalles}
						selectedBooking={props.selectedBooking}
						dataCargas={props.dataDetalles.cargas}
						alertMessage={props.alertMessage}
						setAlertMessage={props.setAlertMessage}
						handleOpenSnackBar={props.handleOpenSnackBar}
						handleCloseSnackbar={props.handleCloseSnackbar}
						dataFetch={props.dataFetch}
						hideEdit={props.hideEdit}
					/>
				</Grid>
			</CardContent>
		</Card>
	);
}
