import GetDataFetchAlt from './GetDataFetchAlt';

export default async function GetDocumentosFetch(
	letrasArray,
	palabra,
	setLoadingDocumentos,
	setDataDetallesDocumentos,
	navigate
) {
	let tempArray = [];
	let codTabla = '';
	let tipocodTabla = '';

	if (palabra.length >= 15) {
		// Se valida el tipo de booking
		if (palabra.length === 18) {
			// Si es un RO se obtienen el primer caracter, el tercero y el cuarto
			tempArray = letrasArray[0] + letrasArray[2] + letrasArray[3];
		} else if (palabra.length === 17) {
			// Si es un Booking "Normal" se obtienen el segundo y el tercer caracter
			tempArray = letrasArray[1] + letrasArray[2];
		} else if (palabra.length === 15) {
			// Si es un despacho se obtienen el segundo y el tercer caracter
			tempArray = letrasArray[0] + letrasArray[1] + letrasArray[2];
		}
		// Se valida el tipo de booking
		if (
			(letrasArray[3] === 'I' && palabra.length === 17) ||
			(letrasArray[0] === 'R' &&
				letrasArray[4] === 'I' &&
				palabra.length === 18) ||
			(letrasArray[3] === 'I' && palabra.length === 15)
		) {
			// Si es un booking de importación se valida el tipo de servicio
			switch (tempArray) {
				case 'NL':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('NL Impo');
					break;
				case 'NF':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('NF Impo');
					break;
				case 'RMC':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('RMC Impo');
					break;
				case 'RMA':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('RMA Impo');
					break;
				case 'MC':
					codTabla = '36';
					tipocodTabla = 'I';
					break;
				case 'MA':
					codTabla = '36';
					tipocodTabla = 'I';
					break;
				case 'AE':
					codTabla = '65';
					tipocodTabla = 'I';
					break;
				case 'FT':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('FT Impo');
					break;
				case 'LT':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('LT Impo');
					break;
				case 'PMO':
				case 'ALT':
				case 'ENS':
				case 'VER':
				case 'PRO':
				case 'LZC':
				case 'MAZ':
				case 'MZO':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('Aduana Maritimo Impo');
					break;
				case 'RSA':
				case 'TJA':
				case 'NVL':
				case 'CHS':
				case 'PMX':
				case 'NGL':
				case 'LAR':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('Aduana Terrestre Impo');
					break;
				case 'QRO':
				case 'NLU':
				case 'AMY':
				case 'AGL':
				case 'AMX':
					codTabla = '';
					tipocodTabla = 'I';
					console.log('Aduana Aereo Impo');
					break;
				default:
					console.log('Unknown Impo');
					break;
			}
		} else if (letrasArray[3] === 'E' && palabra.length !== 18) {
			// Si es un booking de exportación se valida el tipo de servicio
			switch (tempArray) {
				case 'NL':
					codTabla = '';
					tipocodTabla = 'E';
					console.log('NL Expo');
					break;
				case 'NF':
					codTabla = '';
					tipocodTabla = 'E';
					console.log('NF Expo');
					break;
				case 'MC':
					codTabla = '59';
					tipocodTabla = 'E';
					break;
				case 'MA':
					codTabla = '58';
					tipocodTabla = 'E';
					break;
				case 'AE':
					codTabla = '23';
					tipocodTabla = 'E';
					console.log('AE Expo');
					break;
				case 'FT':
					codTabla = '';
					tipocodTabla = 'E';
					break;
				case 'LT':
					codTabla = '';
					tipocodTabla = 'E';
					console.log('LT Expo');
					break;
				case 'PMO':
				case 'ALT':
				case 'ENS':
				case 'VER':
				case 'PRO':
				case 'LZC':
				case 'MAZ':
				case 'MZO':
					codTabla = '';
					tipocodTabla = 'E';
					console.log('Aduana Maritimo Expo');
					break;
				case 'RSA':
				case 'TJA':
				case 'NVL':
				case 'CHS':
				case 'PMX':
				case 'NGL':
				case 'LAR':
					codTabla = '';
					tipocodTabla = 'E';
					console.log('Aduana Terrestre Expo');
					break;
				case 'QRO':
				case 'NLU':
				case 'AMY':
				case 'AGL':
				case 'AMX':
					codTabla = '';
					tipocodTabla = 'E';
					console.log('Aduana Aereo Expo');
					break;
				default:
					console.log('Unknown  Expo');
					break;
			}
		} else if (letrasArray[3] === 'N' && palabra.length === 17) {
			// Si es un booking de nacional se valida el tipo de servicio
			switch (tempArray) {
				case 'TN':
					codTabla = '60';
					tipocodTabla = 'N';
					break;
				default:
					console.log('Unknown');
					break;
			}
		} else if (palabra.length === 17 && letrasArray[3] === 'D') {
			// Si es un booking de Domestico se valida el tipo de servicio
			switch (tempArray) {
				case 'NL':
					codTabla = '';
					tipocodTabla = 'D';
					console.log('NL Domestico');
					break;
				case 'NF':
					codTabla = '';
					tipocodTabla = 'D';
					console.log('NF Domestico');
					break;
				default:
					console.log('Unknown');
					break;
			}
		} else if (palabra.length === 17 && letrasArray[3] === 'F') {
			// Si es un Frontera-Mexico se valida el tipo de servicio
			switch (tempArray) {
				case 'NL':
					codTabla = '';
					tipocodTabla = 'F';
					console.log('NL Frontera');
					break;
				case 'NF':
					codTabla = '';
					tipocodTabla = 'F';
					console.log('NF Frontera');
					break;
				default:
					console.log('Unknown');
					break;
			}
		} else if (palabra.length === 17 && letrasArray[3] === 'M') {
			// Si es un Mexico-Frontera se valida el tipo de servicio
			switch (tempArray) {
				case 'NL':
					codTabla = '';
					tipocodTabla = 'M';
					console.log('NL Mexico-Frontera');
					break;
				case 'NF':
					codTabla = '';
					tipocodTabla = 'M';
					console.log('NF Mexico-Frontera');
					break;
				default:
					console.log('Unknown');
					break;
			}
		} else if (palabra.length === 17) {
			// Si es un booking de Intermodal se valida el tipo de servicio
			switch (tempArray) {
				case 'NI':
					codTabla = '';
					tipocodTabla = 'NI';
					console.log('NI Intermodal');
					break;
				default:
					console.log('Unknown');
					break;
			}
		}
	}

	let url =
		'catalogos/documentos/All?' +
		'tabla=' +
		codTabla +
		'&tipoModalidad=' +
		tipocodTabla;

	await GetDataFetchAlt(
		url,
		setLoadingDocumentos,
		setDataDetallesDocumentos,
		navigate
	);
}
