import {
	Card,
	CardContent,
	Grid,
	Typography,
	CardActions,
	Stack,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function EstadisticaCard(props) {
	if (!props.roles.includes(props.role)) {
		// User role doesn't have permission to access this route, redirect to appropriate page
		return <></>;
	}

	return (
		<Card
			className='paper-hover'
			sx={{
				width: '50%',
				maxHeight: '170px',
			}}
		>
			<CardContent
				sx={{
					minHeight: '110px',
					maxHeight: '110px',
				}}
			>
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'column',
						lg: 'column',
						xl: 'column',
					}}
					spacing={0}
					sx={{ height: '10vh' }}
				>
					<Grid
						item
						container
						sx={{
							justifyContent: 'space-between',
							paddingBottom: '0px',
							height: '55px',
						}}
					>
						{props.loading ? (
							<CircularProgress />
						) : (
							<Grid item>
								<Typography gutterBottom variant='h3'>
									{props.numero}
								</Typography>
							</Grid>
						)}
						<Grid item sx={{ paddingTop: '9px' }}>
							{props.icono}
						</Grid>
					</Grid>
					<Grid item spacing={0}>
						<Typography variant='body1' color='initial'>
							{props.texto}
						</Typography>
					</Grid>
				</Stack>
			</CardContent>
			<CardActions
				sx={{
					justifyContent: 'flex-end',
					minHeight: '55px',
					maxHeight: '55px',
					paddingBottom: '1px',
					paddingTop: '20px',
				}}
			>
				{props.button}
			</CardActions>
		</Card>
	);
}
