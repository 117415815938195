import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

/*Descripción:Este componente se encarga de desplegar un selectSearch de los contenedores
Componente padre: EventosBookingMaritimoExpo //
	EventosBookingMaritimoImpo
Componente hijo:
props: props: props.dataOption = son las opciones a desplegar //
	props.actualizarContenedor = funcion para actualizar el contenedor seleccionado //
	props.customLabel = titulo del selectSearch //
*/

export default function SelectedSearchBL(props) {
	// Se crean los const para el uso del SelectSearch
	const [options, setOptions] = useState([
		{ value: 0, label: props.dataOption[0].bl },
	]);

	// Funcion para actualizar el contenedor
	const handleChange = (event) => {
		props.actualizarBL(event.value);
	};

	// useEffect para la seleccion del contenedor
	useEffect(() => {
		const arr = [];
		props.dataOption.map((bl, index) => {
			return arr.push({ value: index, label: bl.bl });
		});
		setOptions(arr);
	}, [props.dataOption]);

	return (
		<Box>
			<Typography variant='subtitle2' paddingBottom={'2%'}>
				{props.customLabel}
			</Typography>
			<Select
				onChange={handleChange}
				className='basic-single'
				classNamePrefix='select'
				placeholder='Seleccionar'
				noOptionsMessage={({ inputValue }) =>
					!inputValue ? 'value' : 'Valor no encontrado'
				}
				isSearchable={true}
				id={props.id}
				name={props.id}
				options={options}
				defaultValue={options[0]}
			/>
		</Box>
	);
}
