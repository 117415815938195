import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	useMediaQuery,
	IconButton,
	Tooltip,
} from '@mui/material';
import Carga from '../Forms/Carga';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: ListadoCargas
Componente hijo: Carga
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.actualizarDato = funcion para actualizar los datos del formulario //
	props.dataDetalles = informacion acerca de los detalles del booking //
	props.dataModalidadPago = informacion sobre la modalidad de pago //
	props.dataBulto = informacion sobre el tipo de bulto //
	props.handleNext = funcion para realizar el nextStepp //
	props.emptiesInputs = funcion para validar los campos vacios //
*/

export default function AgregarCargaBLModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '75%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxHeight: '95%',
		minHeight: '50vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Se crean las variables para el uso de Alert
	const [isInvalid, setIsInvalid] = useState(true);
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});
	const { vertical, horizontal } = state;
	// Se crea el modelo de los datos de la carga
	const [infoCarga, setInfoCarga] = useState({
		booking: props.selectedBooking,
		marcas_numero: '',
		descripcion_esp: '',
		descripcion_ing: '',
		cantidad_bultos: 0,
		tipo_bultos: '',
		peso: 0.0,
		volumen: 0.0,
		fraccion_arancelaria: '',
	});
	const navigate = useNavigate();
	//Se crean los parametros para el metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(infoCarga),
		redirect: 'follow',
	};

	// Se crea la funcion para editar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		const valorEnMayusculas = event.target.value.toUpperCase();
		actualizarDato(name, valorEnMayusculas);
	};

	// Se crea la funcion para actualizar el valor de las cargas
	function actualizarDato(name, value) {
		setInfoCarga({
			...infoCarga,
			[name]: value,
		});
	}

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	// funcion para validar que el formulario este completo
	function validaCarga() {
		var valida = true;
		Object.keys(infoCarga).forEach(function (key) {
			if (infoCarga[key] === '' || isInvalid === 'true') {
				if (
					infoCarga['fraccion_arancelaria'] === '' ||
					isInvalid === 'true'
				) {
					valida = true;
				} else {
					valida = false;
				}
			}
		});
		return valida;
	}

	// Evento click botón Guardar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};

	// funcion para cerrar el Alert
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	// funciones para el modal
	const handleSaveModal = () => {
		// Se valida que el formulario de la carga se completo
		if (validaCarga() === true) {
			// Se hace el llamado al metodo Patch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'bill-of-lading/carga/save-carga',
				requestOptions
			)
				.then((response) => {
					props.setOpenModal(false);
					if (response.status === 200) {
						// Si es correcto se actualiza el contenido y se manda el mensaje de exito
						props.setAlertMessage({
							severity: 'success',
							message: t('alertas.cargaGuardada'),
						});
						props.enviarDatosFormulario();
					} else if (response.status === 401) {
						// Si el token expiro se redirecciona al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						// Si hay un error sobre la carga, se muestra el Alert correspondiente
						setAlertMessage({
							severity: 'error',
							message: t('alertas.cargaGuardadaError'),
						});
						handleClose1();
					}
				})
				.catch((error) => console.log('error', error));
			props.handleClick1();
			setInfoCarga({
				booking: props.selectedBooking,
				marcas_numero: '',
				descripcion_esp: '',
				descripcion_ing: '',
				cantidad_bultos: 0,
				tipo_bultos: '',
				peso: 0.0,
				volumen: 0.0,
				fraccion_arancelaria: '',
			});
		} else {
			// Si no se llena el formulario, se muestra el Alert correspondiente
			setAlertMessage({
				severity: 'error',
				message: t('alertas.errorDatosCarga'),
			});
			handleClick1();
		}
	};

	// Se crea la función para validar que el formulario este completo
	const handleSubmit = (event) => {
		if (event.nativeEvent.target.ariaInvalid === 'true') {
			setIsInvalid(event.nativeEvent.target.ariaInvalid);
		}
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={false}
				>
					<Grid item container rowSpacing={2}>
						<Grid item lg={9} md={9} sm={9} xs={9}>
							<Typography variant='h5'>
								{t('modales.agregarCarga')}
							</Typography>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={props.handleCloseModal}
									id='cargaModalCerrarIconButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Carga
								handleTextFieldChange={handleTextFieldChange}
								actualizarDato={actualizarDato}
								infoCarga={infoCarga}
								data={props.data}
								dataModalidadPago={props.dataModalidadPago}
								dataBulto={props.dataBulto}
								handleNext={handleSubmit}
								emptiesInputs={props.emptiesInputs}
								handleKeyPress={handleKeyPress}
							/>
						</Grid>
						<Grid
							container
							spacing={1}
							direction='row'
							justifyContent='space-between'
							alignItems='flex-end'
							sx={{ paddingTop: '2vw' }}
						>
							<Grid
								item
								sx={{
									textAlign: 'right',
									bottom: '1.5vw',
									left: '1.5vw',
								}}
							>
								<Button
									id='cargaModalCerrarButton'
									variant='outlined'
									color='error'
									onClick={props.handleCloseModal}
								>
									{t('botones.cancelar')}
								</Button>
							</Grid>
							<Grid
								item
								sx={{
									textAlign: 'right',
									bottom: '1.5vw',
									right: '1.5vw',
								}}
							>
								<Button
									id='cargaModalAgregarButton'
									variant='outlined'
									color='primary'
									type='submit'
								>
									{t('botones.agregar')}
								</Button>
							</Grid>
						</Grid>

						<Snackbar
							open={snackbar1.open}
							autoHideDuration={10000}
							onClose={handleClose1}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert
								onClose={handleClose1}
								severity={alertMessage.severity}
							>
								{alertMessage.message}
							</Alert>
						</Snackbar>
					</Grid>
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
