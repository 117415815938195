import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography, Tooltip, IconButton } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import MySkeletonList from '../../Skeletons/SkeletonList';
import InvalidPDF from '../../cards/InvalidPDF';
import { ArrowBackIos, ArrowForwardIos, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PdfViewer({ pdfBlob, handleCloseModal }) {
	const { t } = useTranslation();
	const [isCorrect, setIsCorrect] = useState(true);
	const setError = () => {
		setIsCorrect(false);
	};
	// Constante para el numero de paginas horizontal
	const [currentPage, setCurrentPage] = useState(1);
	// Constante para el numero de paginas vertical
	const [numPages, setNumPages] = useState(null);

	useEffect(() => {
		// Resetea el número de páginas cuando cambia el PDF
		setNumPages(null);
	}, [pdfBlob]);

	const handleError = () => {
		if (isCorrect) {
			return (
				<>
					<Grid item container lg={12} md={12} sm={12} xs={12}>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'left' }}
						>
							<Tooltip title={t('modales.anterior')}>
								<Button
									onClick={() =>
										setCurrentPage((prevPage) =>
											Math.max(prevPage - 1, 1)
										)
									}
									variant='outlined'
									color='primary'
									disabled={currentPage === 1}
								>
									<ArrowBackIos fontSize='small' />
								</Button>
							</Tooltip>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'center' }}
						>
							<Typography variant='h6' component='h6'>
								{currentPage}/{currentPage}
							</Typography>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('modales.siguiente')}>
								<Button
									onClick={() =>
										setCurrentPage((prevPage) =>
											Math.min(
												prevPage + 1,
												numPages || 1
											)
										)
									}
									variant='outlined'
									color='primary'
									disabled={currentPage === (numPages || 1)}
								>
									<ArrowForwardIos fontSize='small' />
								</Button>
							</Tooltip>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={handleCloseModal}
									id='ModalCerrarButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
									type='submit'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					<Document
						file={pdfBlob}
						loading={<MySkeletonList />}
						onLoadError={setError}
					>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Page
								key={`page_1`}
								pageNumber={1}
								renderTextLayer={false}
								renderAnnotationLayer={false}
								onLoadError={setError}
							/>
						</Grid>
					</Document>
				</>
			);
		} else {
			return (
				<>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{ textAlign: 'right' }}
					>
						<Tooltip title={t('botones.cerrar')}>
							<IconButton
								onClick={handleCloseModal}
								id='ModalCerrarButton'
								variant='outlined'
								color='inherit'
								className='cerrarIconButton'
								type='submit'
							>
								<Close />
							</IconButton>
						</Tooltip>
					</Grid>
					<InvalidPDF />
				</>
			);
		}
	};

	useEffect(() => {
		handleError();
	}, [isCorrect]);

	return <>{handleError()}</>;
}
