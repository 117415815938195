import { Container, LinearProgress, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventosBookingUSCAMEX from '../booking/terrestre/USCAMEX/EventosBookingUSCAMEX';
import InvalidBooking from '../cards/InvalidBooking';
import GetDataFetchTT from '../get-data/GetDataFetchTT';
import { UploadFile } from '@mui/icons-material';
import FileUploadModal from '../file-upload/FileUploadModal';
import SelectorBookingFunction from '../SelectorBookingFunction';
import { useTranslation } from 'react-i18next';
import GetRoleFetch from '../get-data/GetRoleFetch';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Terrestre Centroamerica
Componente padre: CommonBooking
Componente hijo: BookingInfo //
	EventosBooking //
	IndexFileUpload
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición
*/

export default function BookingUSCAMEXTrackAndTrace(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en BookingUSCAMEXT&T
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	const [bookingInvalid, setBookingInvalid] = useState(false);
	// const para abrir el modal de subir archivo
	const [loadingDocumentos, setLoadingDocumentos] = useState(false);
	const [dataDetallesDocumentos, setDataDetallesDocumentos] = useState();
	const [openModalFiles, setOpenModalFiles] = useState(false);
	const [infoDocumento, setInfoDocumento] = useState({
		uiCodTipoDocumento: '',
		vDescripcionEspaniol: '',
		vDescripcionIngles: '',
	});
	const [documento, setDocumento] = useState('');
	const [indexDocumento, setIndexDocumento] = useState('0');
	const [role, setRole] = useState('');

	const getInfoRole = async () => {
		await GetRoleFetch(setRole);
	};

	useEffect(() => {
		getInfoRole();
	}, []);

	// Funcion para actualizar el contenido del Documento
	function actualizarDocumento(documento) {
		setDocumento(documento.value + '');
		setIndexDocumento(documento.label + '');
	}

	// Funciones para abrir y cerrar el modal
	const handleOpenModalFiles = () => {
		setOpenModalFiles(true);
	};
	const handleCloseModalFiles = () => {
		setInfoDocumento({
			uiCodTipoDocumento: '',
			vDescripcionEspaniol: '',
			vDescripcionIngles: '',
		});
		setOpenModalFiles(false);
	};

	// Funcion para abrir el modal de subir archivo
	const handleFileClick = async () => {
		await SelectorBookingFunction(
			props.selectedBooking,
			setLoadingDocumentos,
			setDataDetallesDocumentos,
			navigate
		);
		handleOpenModalFiles();
	};

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		let url = props.endpoint + '/detalles?booking=' + props.selectedBooking;
		await GetDataFetchTT(
			url,
			setLoading,
			setDataDetalles,
			navigate,
			setBookingInvalid
		);
	};
	// useEffect Data Fetch
	useEffect(() => {
		if (props.selectedBooking !== '') {
			dataFetch();
		}
	}, [props.selectedBooking]);

	return (
		<>
			{loading ? (
				<Container maxWidth={false} disableGutters>
					<LinearProgress />
				</Container>
			) : (
				<>
					{bookingInvalid ? (
						<InvalidBooking />
					) : (
						<>
							<Grid item container spacing={2}>
								{role.includes('Cliente') && (
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<Button
											id='uploadFile'
											variant='outlined'
											color='primary'
											onClick={() => {
												handleFileClick();
											}}
											startIcon={<UploadFile />}
										>
											{t('fileUpload.subirDocumento')}
										</Button>
									</Grid>
								)}
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<EventosBookingUSCAMEX
										data={dataDetalles}
										selectedBooking={props.selectedBooking}
										loading={loading}
										elevation={0}
									/>
								</Grid>
							</Grid>
							<>
								{loadingDocumentos ? (
									<></>
								) : (
									<>
										{dataDetallesDocumentos ===
										undefined ? (
											<></>
										) : (
											<FileUploadModal
												modal={true}
												openModal={openModalFiles}
												handleCloseModal={
													handleCloseModalFiles
												}
												selectedBooking={
													props.selectedBooking
												}
												dataDetallesDocumentos={
													dataDetallesDocumentos
												}
												infoDocumento={infoDocumento}
												actualizarDocumento={
													actualizarDocumento
												}
												documento={documento}
												indexDocumento={indexDocumento}
												setDocumento={setDocumento}
												setIndexDocumento={
													setIndexDocumento
												}
												setInfoDocumento={
													setInfoDocumento
												}
												state={props.state}
											/>
										)}
									</>
								)}
							</>
						</>
					)}
				</>
			)}
		</>
	);
}
