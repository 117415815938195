import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información del step y formulario Carga
Componente padre: EditarCargaBLModal // AgregarCargaBLModal
Componente hijo:
props: props.handleTextFieldChange = propiedad para actualizar el texto ingresado //
	props.handleNext = funcion para ir al siguiente step //
	props.data = información guardada a desplegar en el formulario //
	props.emptiesInputs = funcion para seleccionar el estilo //
	props.ref = informacion de referencia de los validadores //
	props.actualizarDato = funcion para actualizar el dato seleccionado //
	props.dataBulto = informacion del selectSearch de los bultos
*/

export default function VisualizarCargasCartaPorte(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Stack
			direction='column'
			spacing={2}
			sx={{ justifyContent: 'space-between' }}
		>
			{/*Informacion de la carga*/}
			{/*Embalaje*/}
			<Grid item width={'100%'}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('cartaPorte.cargas.vEmbalaje')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.infoCarga.vEmbalaje}
				</Typography>
			</Grid>
			{/*Clave del embalaje en el catalogo SAT y la cantidad de bultos*/}
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'column',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Grid item width={'100%'}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('cartaPorte.cargas.vUnidadDeMedida')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.infoCarga.vUnidadDeMedida}
					</Typography>
				</Grid>
				<Grid item width={'100%'}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('cartaPorte.cargas.nCantidad')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.infoCarga.nCantidad}
					</Typography>
				</Grid>
			</Stack>
			<Grid item width={'100%'}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('cartaPorte.cargas.vDescripcionMercancia')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.infoCarga.vDescripcionMercancia}
				</Typography>
			</Grid>
			{/*Clave del producto en el catalogo SAT y el peso de la mercancia*/}
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'column',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Grid item width={'100%'}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('cartaPorte.cargas.vClaveProductoSat')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.infoCarga.vClaveProductoSat}
					</Typography>
				</Grid>
				<Grid item width={'100%'}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('cartaPorte.cargas.nPeso')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.infoCarga.nPeso}
					</Typography>
				</Grid>
			</Stack>
			{/*Fraccion arancelaria y valor unitario*/}
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'column',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Grid item width={'100%'}>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('cartaPorte.cargas.vFraccionArancelaria')}
					</Typography>
					<Typography color='initial' class='Datos-bookings'>
						{props.infoCarga.vFraccionArancelaria}
					</Typography>
				</Grid>
				{props.disabled ? (
					<Grid item width={'100%'}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('cartaPorte.cargas.vUUID')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.infoCarga.vUUID}
						</Typography>
					</Grid>
				) : (
					<Grid item width={'100%'}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('cartaPorte.cargas.vUUID')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.infoCarga.vUUID}
						</Typography>
					</Grid>
				)}
			</Stack>
			{/*Datos de mercancia peligrosa*/}
			{props.infoCarga.bPeligroso && (
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'column',
						lg: 'row',
						xl: 'row',
					}}
					spacing={1}
				>
					<Grid item width={'100%'}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('cartaPorte.cargas.vClaveMaterialPeligroso')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.infoCarga.vClaveMaterialPeligroso}
						</Typography>
					</Grid>
					<Grid item width={'100%'}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('cartaPorte.cargas.vClaveEmbalajePeligroso')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.infoCarga.vClaveEmbalajePeligroso}
						</Typography>
					</Grid>
					<Grid item width={'100%'}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{t('cartaPorte.cargas.vUNPeligroso')}
						</Typography>
						<Typography color='initial' class='Datos-bookings'>
							{props.infoCarga.vUNPeligroso}
						</Typography>
					</Grid>
				</Stack>
			)}
		</Stack>
	);
}
