import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información respectiva del Booking en el BL
Componente padre: InformacionCard
Componente hijo:
props: props.data = informacion a desplegar
*/

export default function BookingInfo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid item container spacing={0.5} lg={12}>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('cartaPorte.serviciosRelacionados')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.bookingRelacion
						? props.data.bookingRelacion
						: t('cartaPorte.sinServiciosRelacionados')}
				</Typography>
			</Grid>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('cartaPorte.fechaEstimadaPosicionamiento')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.empaques.length !== 0 &&
						props.data.empaques[0]['fecha-est-posicionamiento']}
				</Typography>
			</Grid>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.puntoCarga')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.empaques.length !== 0 &&
						props.data.empaques[0]['movimiento_carga']}
				</Typography>
			</Grid>
			<Grid item container lg={12}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('tablero.bookingsActivos.destino')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.empaques.length !== 0 &&
						props.data.empaques[0]['movimiento_destino']}
				</Typography>
			</Grid>
		</Grid>
	);
}
