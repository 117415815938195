import React from 'react';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el Card de mantenimiento
Componente padre: Global
Componente hijo:
props: Sin uso de props
*/

export default function MantenimientoCard() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Card className='paper-hover' justifyContent={'center'}>
			<Grid container spacing={0} paddingTop={2} paddingBottom={2}>
				<Grid
					item
					container
					justifyContent={'center'}
					paddingBottom={3}
				>
					<ConstructionIcon fontSize='large' color='primary' />
				</Grid>
				<Grid item container spacing={1} justifyContent={'center'}>
					<Grid container spacing={0} justifyContent={'center'}>
						<Typography
							variant='h6'
							color='initial'
							paddingBottom={2}
						>
							{t('cards.trabajando')}
						</Typography>
					</Grid>
					<Grid container spacing={0} justifyContent={'center'}>
						<Typography variant='body1' color='initial'>
							{t('cards.pronto')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
}
