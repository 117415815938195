import { DataGrid, esES } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

/*Descripción:Este componente se encarga de deplegar la informacion de USCAMEX del Booking Seleccionado
Componente padre: InfoBookingUSCAMEX
Componente hijo:
props: props.dataOption = informacion sobre las opciones del DataGrid
*/

export default function DataGridCargasUSCAMEX(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran las columnas del DataGrid
	const columns = [
		{
			id: 'descripcion',
			field: 'descripcion',
			headerName: t('dataGrid.descripcionES'),
			width: 190,
			editable: false,
		},
		{
			id: 'descripcionIngles',
			field: 'descripcionIngles',
			headerName: t('dataGrid.descripcionEN'),
			width: 190,
			editable: false,
		},
		{
			id: 'pesoBrutoSistemaMetrico',
			field: 'pesoBrutoSistemaMetrico',
			headerName:
				t('booking.detalles.peso') + ' ' + t('booking.detalles.kg'),
			width: 100,
			editable: false,
		},
		{
			id: 'pesoBrutoSistemaIngles',
			field: 'pesoBrutoSistemaIngles',
			headerName:
				t('booking.detalles.peso') + ' ' + t('booking.detalles.lb'),
			width: 100,
			editable: false,
		},
		{
			id: 'cantidad',
			field: 'cantidad',
			headerName: t('booking.detalles.cantidad'),
			width: 80,
			editable: false,
		},
		{
			id: 'embalajeES',
			field: 'embalajeES',
			headerName: t('dataGrid.embalajeES'),
			width: 100,
			editable: false,
		},
		{
			id: 'embalajeEN',
			field: 'embalajeEN',
			headerName: t('dataGrid.embalajeEN'),
			width: 100,
			editable: false,
		},
	];
	// Se inicializa la informacion de las filas del DataGrid
	const tableData = [];
	// Se recorre la informacion de las opciones y se agrega a la informacion de las filas
	for (let i = 0; i < props.dataOption.length; i++) {
		const carga = props.dataOption[i];
		// Se valida si existe las cargas
		if (carga) {
			// Si existen se asignan los valores correspondientes de las columnas a los datos de las filas
			const rowData = {
				id: carga.codCarga,
				descripcion: carga.descripcion,
				descripcionIngles: carga.descripcionIngles,
				pesoBrutoSistemaMetrico: carga.pesoBrutoSistemaMetrico,
				pesoBrutoSistemaIngles: carga.pesoBrutoSistemaIngles,
				cantidad: carga.cantidad,
				embalajeES: carga.embalaje.ES,
				embalajeEN: carga.embalaje.EN,
			};
			// Se agrega la informacion de la fila a la informacion de las filas
			tableData.push(rowData);
		}
	}
	// Funcion para traducir el DataGrid
	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<Box style={{ height: 299, width: '100%', cursor: 'pointer' }}>
			<DataGrid
				rows={tableData}
				columns={columns}
				localeText={localeText()}
				initialState={{
					pagination: {
						paginationModel: {
							page: 0,
							pageSize: 5,
						},
					},
				}}
				pageSizeOptions={[5, 10, 15]}
				sx={{
					'& .MuiDataGrid-cell': {
						fontSize: '12px',
						fontFamily: 'Source Sans Pro',
					},
					'& .MuiDataGrid-columnHeaderTitle': {
						fontSize: '13px',
						fontFamily: 'Open Sans',
					},
				}}
			/>
		</Box>
	);
}
