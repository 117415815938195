import { Modal, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

// Se crea el estilo del modal
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: '80%',
	maxHeight: '95%',
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: '2vw',
	borderRadius: '10px',
};

export default function VideoModal(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Modal open={props.open}>
			<Box sx={style}>
				<Box
					sx={{ width: 'auto', height: 'auto', paddingBottom: '2%' }}
				>
					<Typography variant='h5'>{props.titulo}</Typography>
				</Box>
				<ReactPlayer
					playing={true}
					url={props.videoUrl}
					controls
					width='100%'
				/>
				<Box sx={{ textAlign: 'center', paddingTop: '2%' }}>
					<Button
						id='cargaModalCerrarButton'
						variant='outlined'
						color='error'
						onClick={props.handleClose}
					>
						{t('botones.cerrar')}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}
