import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Select from 'react-select';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información del SelectSearch dependiendo los datos recopilados
Componente padre: ConsignatarioForm.jsx
Componente hijo:
props: actualizarPais: Función para actualizar el pais // dataOption: Información de los paises //
customLabel: Nombre del label // id: id del selectSearch
*/

export default function SelectSearchDocumentos(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se daclaran los const para su uso en el SelectSearchContenedor
	const [defaultValue, setDefaultValue] = useState(
		props.dataOptions[
			props.dataOptions.findIndex(
				(obj) => obj.value === props.defaultValue
			)
		]
	);

	// Funcion para actualizar el contenedor seleccionado
	const handleChange = (event) => {
		props.setInfoDocumento({
			...props.infoDocumento,
			uiCodTipoDocumento: event.value,
			vDescripcionEspaniol: event.label,
		});
		props.actualizarDocumento(event);
	};

	useEffect(() => {
		setDefaultValue(
			props.dataOptions[
				props.dataOptions.findIndex(
					(obj) => obj.value === props.defaultValue
				)
			]
		);
	}, [props.dataOptions]);

	return (
		<Box>
			<Typography variant='subtitle2'>{props.customLabel}</Typography>
			<Select
				onChange={handleChange}
				className='basic-single'
				classNamePrefix='select'
				placeholder={t(
					'catalogos.consignatario.selectSearch.seleccionar'
				)}
				noOptionsMessage={({ inputValue }) =>
					!inputValue ? 'value' : 'Valor no encontrado'
				}
				isSearchable={true}
				id={props.id}
				name={props.id}
				value={defaultValue}
				options={props.dataOptions}
			/>
		</Box>
	);
}
