import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../../get-data/GetDataFetch';
import MySkeletonPage from '../../Skeletons/SkeletonPage';
import ConsignatariosInfoCard from './ConsignatarioInfoCard';
import { Box, Button, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import ListContactos from './ListContactos';
import ListDirecciones from './ListDirecciones';
import { ArrowBack } from '@mui/icons-material';
import DataGridConsignatarios from './DataGridConsignatarios';

/*Descripción:Este componente muestra la información del consignatario elegido y sus contactos y direcciones.
Componente padre: DataGridConsignatarios.jsx
Componente hijo: ListContactos.jsx // ListDirecciones.jsx // ConsignatariosInfoCard.jsx
props: data: Información del consignatario //
id: id del consignatario //
dataDetallesPaises: Manda la información de los paises //
loadingPaises: Variable para el tiempo de carga //
dataGrid: Información de los consignatarios //
dataFetch: Función para obtener los datos de la API
*/

export default function ConsignatariosInfo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loadingContactos, setLoadingContactos] = useState(true);
	const [loadingDirecciones, setLoadingDirecciones] = useState(true);
	const [loading, setLoading] = useState(true);
	const [dataDetallesContactos, setDataDetallesContactos] = useState('');
	const [dataDetallesDirecciones, setDataDetallesDirecciones] = useState('');
	const [dataDetalles, setDataDetalles] = useState('');
	const [consignatarioEdit, setConsignatarioEdit] = useState({
		nombre_razon: props.data.nombre_razon,
		pais: props.data.cod_pais,
		tax_id: props.data.tax_id,
		cod_persona: props.data.idToSend,
	});
	const navigate = useNavigate();
	//======================================================
	const [showConsignatariosList, setShowConsignatariosList] = useState(false);

	const handleBackButtonClick = () => {
		setShowConsignatariosList(true);
	};
	//======================================================
	const [openAgregarConsignatarioModal, setOpenAgregarConsignatarioModal] =
		useState(false);
	const handleAgregarConsignatarioModalOpen = () => {
		setOpenAgregarConsignatarioModal(true);
	};
	const handleAgregarConsignatarioModalClose = () => {
		setOpenAgregarConsignatarioModal(false);
	};

	// Funcion para obtener los datos de la API
	const dataFetchContactos = async () => {
		setLoadingContactos(true);
		let url = 'consignatario/contactos/all?id=' + props.id;
		await GetDataFetch(
			url,
			setLoadingContactos,
			setDataDetallesContactos,
			navigate
		);
	};

	// Funcion para obtener los datos de la API
	const dataFetchDirecciones = async () => {
		setLoadingDirecciones(true);
		let url = 'consignatario/direcciones/all?id=' + props.id;
		await GetDataFetch(
			url,
			setLoadingDirecciones,
			setDataDetallesDirecciones,
			navigate
		);
	};

	// Funcion para obtener los datos de la API
	const dataFetch = async () => {
		setLoading(true);
		let url = 'consignatarios/all';
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	// useEffect del Data Fetch
	useEffect(() => {
		dataFetch();
	}, []);

	// useEffect del Data Fetch
	useEffect(() => {
		dataFetchContactos();
		dataFetchDirecciones();
	}, []);

	// Tabs
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		// Carga el contenido de la página
		<>
			{showConsignatariosList && !props.loadingPaises ? (
				<DataGridConsignatarios
					dataGrid={dataDetalles}
					dataDetallesPaises={props.dataDetallesPaises}
					dataFetch={dataFetch}
				/>
			) : loadingContactos || loadingDirecciones || loading ? (
				<MySkeletonPage />
			) : (
				<Grid
					item
					container
					lg={12}
					md={12}
					sm={12}
					xs={12}
					columnSpacing={1}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						sx={{
							textAlign: 'left',
							paddingBottom: '0.5%',
						}}
					>
						<Tooltip title={t('botones.atras')}>
							<Button onClick={handleBackButtonClick}>
								<ArrowBack />
							</Button>
						</Tooltip>
					</Grid>
					<Grid item lg={2} md={3} sm={12} xs={12}>
						<ConsignatariosInfoCard
							data={props.data}
							id={props.id}
							openAgregarConsignatarioModal={
								openAgregarConsignatarioModal
							}
							setOpenAgregarConsignatarioModal={
								setOpenAgregarConsignatarioModal
							}
							handleAgregarConsignatarioModalOpen={
								handleAgregarConsignatarioModalOpen
							}
							handleAgregarConsignatarioModalClose={
								handleAgregarConsignatarioModalClose
							}
							dataGrid={dataDetalles}
							dataDetallesPaises={props.dataDetallesPaises}
							dataFetch={dataFetch}
							consignatarioEdit={consignatarioEdit}
							setConsignatarioEdit={setConsignatarioEdit}
							handleBackButtonClick={handleBackButtonClick}
							paisToSend={props.paisToSend}
							setAlertMessagePage={props.setAlertMessagePage}
							handleClickPage={props.handleClickPage}
						/>
					</Grid>
					<Grid item lg={10} md={9} sm={12} xs={12}>
						<Box
							sx={{
								width: '100%',
								paddingLeft: '1%',
							}}
						>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: 'background.default',
								}}
							>
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label='basic tabs example'
								>
									<Tab
										label={t(
											'catalogos.consignatario.contactos'
										)}
										sx={{ color: 'grey' }}
									/>
									<Tab
										label={t(
											'catalogos.consignatario.direcciones'
										)}
										sx={{ color: 'grey' }}
									/>
								</Tabs>
							</Box>
							{/* Tab 1:Pendientes */}
							<Box hidden={value !== 0}>
								{value === 0 && (
									<ListContactos
										data={dataDetallesContactos}
										dataFetch={dataFetchContactos}
										id={props.id}
										setAlertMessagePage={
											props.setAlertMessagePage
										}
										handleClickPage={props.handleClickPage}
									/>
								)}
							</Box>
							{/* Tab 2: Enviados */}
							<Box hidden={value !== 1}>
								{value === 1 && (
									<ListDirecciones
										infoConsignatario={
											props.infoConsignatario
										}
										setInfoConsignatario={
											props.setInfoConsignatario
										}
										paisToSend={props.paisToSend}
										data={dataDetallesDirecciones}
										dataFetch={dataFetchDirecciones}
										id={props.id}
										setAlertMessagePage={
											props.setAlertMessagePage
										}
										handleClickPage={props.handleClickPage}
									/>
								)}
							</Box>
						</Box>
					</Grid>
				</Grid>
			)}
		</>
	);
}
