import {
	Grid,
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MySkeletonList from '../Skeletons/SkeletonList';
import SearchBar from './SearchBar';

/*Descripción:Este componente se encarga de renderizar un SearchBar de los bookings desplegados
Componente padre: CommonBooking
Componente hijo:
props: props.bookingList = Lista de los bookings a desplegar //
	props.actualizatBooking = datos para validar que booking fue seleccionado //
	props.selectedIndex = Index seleccionado
*/

export default function BookingSearchBar(props) {
	// Funcion para traducir la pagina en español o ingles
	// Se declaran los const para su uso en BookingSearchBar
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	// Funcion para obtener el booking seleccionado
	const handleListItemClick = (event, index, numBooking) => {
		// Se valida que el booking exista
		if (numBooking !== 'Sin resultados') {
			// Si existe se asigna al booking seleccionado
			props.setSelectedIndex(index);
			props.actualizarBooking(numBooking, index);
		}
	};

	// useEffect para seleccionar el booking clickeado
	useEffect(() => {
		props.setSelectedIndex(0);
		props.setBookings(props.bookingsList);
		setLoading(false);
	}, [props.bookingsList]);

	// useEffect para obtener el index del booking seleccionado
	useEffect(() => {
		if (props.selectedIndex === undefined) {
			props.setSelectedIndex(-1);
		} else {
			props.setSelectedIndex(props.selectedIndex);
		}
	}, [props.selectedIndex]);
	const handleSearch = (term) => {
		setSearchTerm(term);
	};
	const filteredItems = props.bookingsList.filter((item) =>
		item.toUpperCase().includes(searchTerm.toUpperCase())
	);

	return (
		<Grid
			item
			xs={12}
			sm={12}
			md={12}
			lg={12}
			sx={{
				display: 'sticky',
				alignItems: 'sticky-start',
				paddingTop: '18px',
				marginRight: '10px',
				height: '50vh',
				width: '100%',
			}}
		>
			<Grid container paddingLeft={'4%'}>
				<SearchBar onSearch={handleSearch} />
			</Grid>
			<Grid container>
				<Box
					style={{
						height: '60vh',
						overflow: 'auto',
						width: 300,
						marginTop: '6px',
					}}
				>
					{loading ? (
						<MySkeletonList />
					) : (
						<List>
							{filteredItems.map((item, key) => (
								<ListItem disablePadding key={'booking' + key}>
									<ListItemButton
										selected={props.selectedIndex === key}
										onClick={(event) =>
											handleListItemClick(
												event,
												key,
												item
											)
										}
									>
										<ListItemText
											primary={
												<Typography
													sx={{ fontSize: 14.38 }}
												>
													{item}
												</Typography>
											}
										/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					)}
				</Box>
			</Grid>
		</Grid>
	);
}
