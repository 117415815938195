import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Stack, Card, CardContent } from '@mui/material';
import InfoContenedor from './Info/InfoContenedor';
import TimelineSelector from './Timeline/TimelineSelector';
import SelectedSearchContenedor from '../../SelectedSearchContenedor';
import { useTranslation } from 'react-i18next';
import SinInformacion from '../../../cards/SinInformacion';

/*Descripción:Este componente se encarga de desplegar la informacion de el estatus de el booking seleccionado y su Timeline
Componente padre: BookingTerrestreNacioSR
Componente hijo:SelectedSearchContenedor //
	InfoContenedor //
	TimelineSelector //
props: props.data = informacion a desplegar
*/

export default function EventosBooking(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean los const para el uso de los eventos del Booking
	const [dataEmpaque, setEmpaque] = useState({});
	const [indexEmpaque, setIndexEmpaque] = useState(0);
	const [dataOptions, setDataOptions] = useState([]);
	const [defaultValue, setDefaultValue] = useState();

	useEffect(() => {
		if (props.data.empaques === undefined) {
			return;
		} else {
			var options = [];
			props.data.empaques.map((empaque) => {
				options.push({
					value: empaque.secuencia,
					label: empaque.secuencia,
				});
			});
			setDataOptions(options);
			setEmpaque(props.data.empaques[0]);
			setIndexEmpaque(0);
			setDefaultValue(options[0]);
		}
	}, []);

	// Funcion para renderizar la informacion de los bookings si es que existen los empaques
	function ContentToRender() {
		if (props.data.empaques === undefined) {
			return (
				<Box>
					<SinInformacion />
				</Box>
			);
		} else {
			return (
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'row',
						lg: 'row',
						xl: 'row',
					}}
					spacing={1}
				>
					<Card
						className={props.className}
						elevation={props.elevation}
						sx={{ width: '100%' }}
					>
						<CardContent>
							{props.data.empaques === undefined ? (
								<Box>
									{t('booking.estatus.sinEmpaqueAsignado')}
								</Box>
							) : (
								<Box></Box>
							)}
							{props.data.empaques.length === 0 ? (
								<Typography variant='body1' color='initial'>
									{t('booking.estatus.sinEmpaqueAsignado')}
								</Typography>
							) : props.data.empaques === undefined ? (
								<Box></Box>
							) : (
								<Grid item width={'50%'}>
									<SelectedSearchContenedor
										actualizarContenedor={
											actualizarContenedor
										}
										customLabel={t(
											'booking.estatus.contenedor'
										)}
										id='contenedores'
										name='contenedores'
										dataOption={dataOptions}
										defaultValue={defaultValue}
										indexEmpaque={indexEmpaque}
									/>
								</Grid>
							)}
							{props.data.empaques === undefined ? (
								<Box></Box>
							) : (
								<InfoContenedor
									dataEmpaque={dataEmpaque}
									data={props.data}
									indexEmpaque={indexEmpaque}
								/>
							)}
						</CardContent>
					</Card>
					<Card
						className={props.className}
						elevation={props.elevation}
						sx={{ width: '100%' }}
					>
						<CardContent>
							<TimelineSelector
								dataDespacho={props.data['despacho-aduanal']}
								dataEmpaque={dataEmpaque}
								data={props.data}
							/>
						</CardContent>
					</Card>
				</Stack>
			);
		}
	}
	// Funcion para actualizar la informacion del contenedor
	function actualizarContenedor(index) {
		setEmpaque(props.data.empaques[index - 1]);
		setIndexEmpaque(index - 1);
		setDefaultValue(dataOptions[index - 1]);
	}

	return (
		<Box>
			<ContentToRender />
		</Box>
	);
}
