import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	LinearProgress,
	Grid,
	useMediaQuery,
	Tooltip,
	IconButton,
} from '@mui/material';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import DireccionForm from '../../Forms/DireccionForm';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

/*Descripción:Este componente se encarga de renderizar el contenido del modal para agregar las direcciones.
Componente padre: ListDirecciones.jsx
Componente hijo: DireccionForm.jsx
props: props.dataFetch: Función para obtener los datos de la API // props.id: id del consignatario //
props.codPais: Código del país del consignatario // props.dataFetch: Función para obtener los datos de la API
props.dataCiudades: Información de las ciudades // props.loadingCiudades: Variable para el tiempo de carga
props.setIndexCiudad: Función para actualizar el index de la ciudad // props.indexCiudad: Index de la ciudad
props.ciudad: Nombre de la ciudad // props.setCiudad: Función para actualizar el nombre de la ciudad
props.setInfoDireccion: Función para actualizar la información de la dirección
*/

export default function AgregarDireccionesModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '60%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: '60%',
		maxHeight: '95%',
		minHeight: '40vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const navigate = useNavigate();
	// Se crean las variables para el uso de Alert
	const [isInvalid, setIsInvalid] = useState(true);
	//Se crean los parametros para el metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(props.infoDireccion),
		redirect: 'follow',
	};

	// Se crea la funcion para editar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		actualizarDato(name, event.target.value.toUpperCase());
	};

	// Se crea la funcion para actualizar el valor de las cargas
	function actualizarDato(name, value) {
		props.setInfoDireccion({
			...props.infoDireccion,
			[name]: value,
		});
	}

	// funcion para validar que el formulario este completo
	function validaCarga() {
		var valida = true;
		Object.keys(props.infoDireccion).forEach(function (key) {
			if (props.infoDireccion[key] === '' || isInvalid === 'true') {
				if (isInvalid === 'true') {
					valida = true;
				} else {
					valida = false;
				}
			}
		});
		return valida;
	}

	// funciones para el modal
	const handleSaveModal = () => {
		// Se valida que el formulario de la carga se completo
		if (validaCarga() === true) {
			// Se hace el llamado al metodo Patch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'consignatario/direcciones/create',
				requestOptions
			)
				.then((response) => {
					if (response.status === 200) {
						// Si es correcto se actualiza el contenido y se manda el mensaje de exito
						props.setOpenModal(false);
						props.dataFetch();
						props.setAlertMessagePage({
							message: t('alertas.direccionGuardada'),
							severity: 'success',
						});
						props.handleClickPage();
						props.setLoadingButtonDireccion(false);
					} else if (response.status === 401) {
						// Si el token expiro se redirecciona al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						// Si hay un error sobre la carga, se muestra el Alert correspondiente
						props.setAlertMessagePage({
							message: t('alertas.direccionGuardadaError'),
							severity: 'error',
						});
						props.handleClickPage();
						props.setLoadingButtonDireccion(false);
					}
				})
				.catch((error) => console.log('error', error));
			props.setInfoDireccion({
				cod_persona: props.id,
				calle: '',
				cod_ciudad: props.ciudad,
			});
		} else {
			// Si no se llena el formulario, se muestra el Alert correspondiente
			props.setAlertMessagePage({
				message: t('alertas.direccionSinCampos'),
				severity: 'error',
			});
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={false}
				>
					{props.loading ? (
						<LinearProgress />
					) : (
						<Grid item container rowSpacing={2}>
							<Grid item lg={9} md={9} sm={9} xs={9}>
								<Typography variant='h5'>
									{t('modales.agregarDireccion')}
								</Typography>
							</Grid>
							<Grid
								item
								lg={3}
								md={3}
								sm={3}
								xs={3}
								sx={{ textAlign: 'right' }}
							>
								<Tooltip title={t('botones.cerrar')}>
									<IconButton
										onClick={props.handleCloseModal}
										id='cargaModalCerrarIconButton'
										variant='outlined'
										color='inherit'
										className='cerrarIconButton'
									>
										<Close />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<DireccionForm
									handleTextFieldChange={
										handleTextFieldChange
									}
									actualizarDato={actualizarDato}
									infoDireccion={props.infoDireccion}
									handleCloseModal={props.handleCloseModal}
									openModal={props.openModal}
									setOpenModal={props.setOpenModal}
									dataCiudades={props.dataCiudades}
									dataFetch={props.dataFetch}
									setIndexCiudad={props.setIndexCiudad}
									indexCiudad={props.indexCiudad}
									ciudad={props.ciudad}
									setCiudad={props.setCiudad}
									setInfoDireccion={props.setInfoDireccion}
									actualizarCiudad={props.actualizarCiudad}
								/>
							</Grid>
							<Grid item lg={6} md={6} sm={6} xs={6}>
								<Button
									id='cargaModalCerrarButton'
									variant='outlined'
									color='error'
									onClick={props.handleCloseModal}
								>
									{t('botones.cancelar')}
								</Button>
							</Grid>
							<Grid
								item
								lg={6}
								md={6}
								sm={6}
								xs={6}
								sx={{
									textAlign: 'right',
								}}
							>
								<Button
									id='cargaModalAgregarButton'
									variant='outlined'
									color='primary'
									type='submit'
								>
									{t('botones.agregar')}
								</Button>
							</Grid>
						</Grid>
					)}
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
