import React, { useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import Select from 'react-select';
import { Typography } from '@mui/material';

/*Descripción:Este componente se encarga de desplegar la información del SelectSearch dependiendo los datos recopilados
Componente padre: NotifyParty //
	Carga //
Consignatario
Componente hijo:
	props: props.dataOption = son las opciones a desplegar //
	props.emptiesInputs = funcion para seleccionar el estilo //
	props.actualizarDato = funcion para actualizar el dato seleccionado //
	props.customLabel = titulo del selectSearch
*/

export default function SelectSearchConsignatario(props) {
	// Se define el valor inicial de el SelectSearch
	const [defaultValue, setDefaultValue] = useState(
		props.infoConsignatario !== undefined
			? props.dataOptionsConsignatarios[
					props.dataOptionsConsignatarios.findIndex(
						(obj) =>
							obj.value ===
							props.infoConsignatario.cod_consignatario
					)
			  ]
			: null
	);
	// Se define los estilos de validación de datos ingresado
	const [emptyStyles, setEmptyStyles] = useState({});

	// useEffect para la implementación de los estilos para validar si se selecciono algun valor
	useEffect(() => {
		const updatedEmptyStyles = {};
		const emptyClassMap = {
			consignatario: 'emptyStyles',
		};

		Object.keys(emptyClassMap).forEach((id) => {
			updatedEmptyStyles[id] = props.emptiesInputs.includes(id)
				? `${emptyClassMap[id]} my-select-width`
				: 'my-select-width';
		});
		setEmptyStyles(updatedEmptyStyles);
	}, [props.emptiesInputs]);

	// Función para actualizar el dato seleccionado
	const handleChange = (event) => {
		props.actualizarDato(props.id, event.value);
		props.setIndexConsignatario(
			props.dataOptionsConsignatarios.findIndex(
				(obj) => obj.value === event.value
			)
		);
		var nuevoInfoConsignatario =
			props.dataDetallesConsignatarios.consignatarios[
				props.dataOptionsConsignatarios.findIndex(
					(obj) => obj.value === event.value
				)
			];
		props.setInfoConsignatario(nuevoInfoConsignatario);

		setDefaultValue(event);
	};

	useEffect(() => {
		if (props.infoConsignatario === undefined) {
			setDefaultValue(null);
		} else {
			const newDefaultValue = props.dataOptionsConsignatarios.find(
				(obj) => obj.value === props.infoConsignatario.cod_consignatario
			);
			setDefaultValue(newDefaultValue);
		}
	}, [props.infoConsignatario?.cod_consignatario]);

	// useEffect para actualizar el valor predeterminado del SelectSearch
	useEffect(() => {
		if (props.infoConsignatario !== undefined) {
			setDefaultValue(
				props.dataOptionsConsignatarios[
					props.dataOptionsConsignatarios.findIndex(
						(obj) =>
							obj.value ===
							props.infoConsignatario.cod_consignatario
					)
				]
			);
		}
	}, [props.setInfoConsignatario]);

	useEffect(() => {
		if (
			props.dataOptionsConsignatarios !== undefined &&
			props.infoConsignatario !== undefined
		) {
			props.setIndexConsignatario(
				props.dataOptionsConsignatarios.findIndex(
					(obj) =>
						obj.value === props.infoConsignatario.cod_consignatario
				)
			);
			var nuevoInfoConsignatario =
				props.dataDetallesConsignatarios.consignatarios[
					props.dataOptionsConsignatarios.findIndex(
						(obj) =>
							obj.value ===
							props.infoConsignatario.cod_consignatario
					)
				];
			props.setInfoConsignatario(nuevoInfoConsignatario);
		}
	}, [props.dataOptionsConsignatarios]);

	// Se crean los estilos que tendrá el SelectSearch
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			height: '45px',
			border: state.isFocused ? '2px solid #002D58' : '1px solid #cccccc',
			boxShadow: state.isFocused ? 'none' : 'none',
			'&:hover': {
				border: '1px solid black',
			},
			background: props.disabled && 'white',
			fontSize: '13px',
			fontFamily: 'Source Sans Pro',
		}),
		placeholder: (provided, state) => ({
			...provided,
			position: 'absolute',
			top: state.hasValue || state.selectProps.inputValue ? -20 : '20%',
			transition: 'top 0.1s, font-size 0.1s',
			fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
			background: 'white',
			paddingLeft: '5px',
			paddingRight: '5px',
			fontFamily: 'Source Sans Pro',
		}),
		valueContainer: (provided, state) => ({
			...provided,
			overflow: 'visible',
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
		}),
		menu: (provided, state) => ({
			...provided,
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
			maxHeight: '150px',
		}),
		menuList: (provided, state) => ({
			...provided,
			fontFamily: 'Source Sans Pro',
			fontSize: '13px',
			maxHeight: '150px',
		}),
	};

	return (
		<>
			<FormControl fullWidth>
				<Typography variant='subtitle2' paddingBottom={'1%'}>
					{props.customLabel}
				</Typography>
				<Select
					className='basic-single'
					classNamePrefix='select'
					onChange={handleChange}
					placeholder={props.customLabel + ' *'}
					noOptionsMessage={({ inputValue }) =>
						!inputValue ? 'value' : 'Valor no encontrado'
					}
					autoWidth
					isSearchable={true}
					isDisabled={props.disabled}
					id={props.id}
					name={props.id}
					options={props.dataOptionsConsignatarios}
					value={defaultValue}
					styles={customStyles}
					required={true}
				/>
			</FormControl>
		</>
	);
}
