import React from 'react';
import ErrorBoundary from '../../../components/cards/ErrorBoundary';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import CommonBooking from '../../../components/CommonBooking';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla Maritimo Intermodal
y mandar el endpoint a usar
Componente padre: IndexME
Componente hijo: CommonBooking
props: props.openSideBar = Uso del Sidebar
*/

export default function IndexFronteraMexico() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [value, setValue] = useState(0);
	// Funcion para cambiar de tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant='h4'>
						{t('sidebar.uscamex.uscamexFronteraMexico')}
					</Typography>
				</Box>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'Boxider' }}>
						<Tabs value={value} onChange={handleChange}>
							<Tab
								id='TabFullContainer'
								label={t('booking.fullContainer')}
								sx={{ color: 'grey' }}
							/>
							<Tab
								id='TabConsolidado'
								label={t('booking.consolidado')}
								sx={{ color: 'grey' }}
							/>
						</Tabs>
					</Box>
					{/* Tab 1: Full Container */}
					<Box hidden={value !== 0}>
						{value === 0 && (
							<CommonBooking
								resultComponent='BookingUSCAMEXExpoFTLSearchResult'
								endpoint='bookings/terrestre/uscamex/frontera-mexico/ltl'
							/>
						)}
					</Box>
					{/* Tab 2: Consolidado */}
					<Box hidden={value !== 1}>
						{value === 1 && (
							<CommonBooking
								resultComponent='BookingUSCAMEXExpoFTLSearchResult'
								endpoint='bookings/terrestre/uscamex/expo/ltl'
							/>
						)}
					</Box>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
