import * as React from 'react';
import { Close } from '@mui/icons-material';
import {
	Box,
	Collapse,
	CssBaseline,
	Grid,
	IconButton,
	Link,
	Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import MyRandomLogin from '../../components/RandomImageLogin';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoCard from '../../components/cards/LogoCard';
import PasswordForm from '../../components/user/PasswordForm';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de desplegar la pantalla de recuperacion de contraseña
Componente padre:
Componente hijo: Login.jsx //
	MyRandomLogin
props: Sin uso de props
*/

export default function CreatePassword() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const navigate = useNavigate();
	// Access query parameters
	const token = searchParams.get('token');
	const [message, setMessage] = useState('');
	const [severity, setSeverity] = useState('');
	const [open, setOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const { vertical, horizontal } = state;
	const [loading, setLoading] = useState(false);

	// Funcion para abrir el SnackBar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};
	// Funcion para cerrar el Snackbar
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	async function sendData(password) {
		setLoading(true);
		const requestOptionsGuardar = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify({
				password: password,
			}),
			redirect: 'follow',
		};
		// Se hace la peticion al endpoint
		await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'user/create-password',
			requestOptionsGuardar
		)
			.then((response) => {
				// Se valida el estatus de la respesta
				if (response.status === 200) {
					// Se muestra el mensaje de exito
					setAlertMessage({
						severity: 'success',
						message: t('alertas.contrasenaCreada'),
					});
					// Se abre el Alert
					handleClick1();
					// Se redirecciona al login
					setTimeout(() => {
						navigate('/');
					}, 5000);
				} else {
					// Se muestra el mensaje de error
					setAlertMessage({
						severity: 'error',
						message: t('alertas.contrasenaCreadaError'),
					});
					// Se abre el Alert
					handleClick1();
				}
			})
			.catch((error) => console.log('error ', error));
		setLoading(false);
	}

	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<CssBaseline />
			<MyRandomLogin />
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				lg={5}
				xl={4}
				component={Paper}
				elevation={6}
				square
			>
				<Box
					className='text-center'
					sx={{
						my: 4,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						overflowY: 'hidden',
					}}
					paddingTop={9}
				>
					<LogoCard />
					<Typography variant='h5' paddingTop={2}>
						{t('centroAyuda.login.crearContrasena')}
					</Typography>
					<Box
						className='text-center'
						noValidate
						sx={{ paddingTop: '4%' }}
					>
						<Grid item container rowSpacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<PasswordForm
									sendData={sendData}
									myHeaders={myHeaders}
									loading={loading}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Collapse in={open}>
									<Alert
										action={
											<IconButton
												aria-label='close'
												color='inherit'
												size='small'
												onClick={() => {
													setOpen(false);
												}}
											>
												<Close fontSize='inherit' />
											</IconButton>
										}
										sx={{ mb: 2, width: '304px' }}
										severity={severity}
									>
										{message}
									</Alert>
								</Collapse>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								container
								sx={{
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}
							>
								<Link href='/contactanos' variant='body2'>
									<Typography variant='body2'>
										{t('inicio.apoyo')}
									</Typography>
								</Link>
							</Grid>
						</Grid>
					</Box>
					<Snackbar
						open={snackbar1.open}
						autoHideDuration={10000}
						onClose={handleClose1}
						anchorOrigin={{ vertical, horizontal }}
					>
						<Alert
							onClose={handleClose1}
							severity={alertMessage.severity}
						>
							{alertMessage.message}
						</Alert>
					</Snackbar>
				</Box>
			</Grid>
		</Grid>
	);
}
