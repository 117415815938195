import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar los eventos de Despacho relacionados a Aéreo Impo
Componente padre: TimelineSelectorAereoImpo
Componente hijo: TimelineItem
props: props.dataDespacho = informacion a desplegar sobre el despacho
*/

export default function TimelineEventosDespachoAduanalImpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Timeline position='alternate'>
			{props.dataDespacho['fecha-validacion'] === 'N.D.' ||
			props.dataDespacho['fecha-validacion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataDespacho['fecha-validacion']}
					title={t('eventos.validacion')}
				/>
			)}
			{props.dataDespacho['fecha-modulacion'] === 'N.D.' ||
			props.dataDespacho['fecha-modulacion'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.dataDespacho['fecha-modulacion']}
					title={t('eventos.modulacion')}
				/>
			)}
		</Timeline>
	);
}
