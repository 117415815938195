import { Grid, Box } from '@mui/material';
import React from 'react';
import Cookies from 'universal-cookie';
import { useState, useEffect } from 'react';
import CardUser from '../../components/user/Cards/CardUser';
import CardClient from '../../components/user/Cards/CardClient';
import CardModificaDatos from '../../components/user/Cards/CardModificaDatos';
import { useNavigate } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla UserProfile
Componente padre: CommonLayout.jsx
Componente hijo: CardUser //
	CardCliente //
	CardModificaDatos
props: Sin uso de props
*/

export default function UserProfile() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para el uso en el componente
	const [checked] = useState(true);
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [users, setUsers] = useState([]);
	const [newName, setName] = useState(users.name);
	const [newLastName, setLastName] = useState(users.lastname);
	const [newPhone, setPhone] = useState(users.phone);
	const [loading, setLoading] = useState(true);
	const [client, setClient] = useState([]);
	const navigate = useNavigate();
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	// Se hace el Data Fetch
	const getUserInfo = async () => {
		// Se hace la peticion en el endpoint
		const responseUserInfo = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'user/info',
			requestOptions
		);
		// Se asigna la información del usuario
		const dataUserInfo = await responseUserInfo.json();
		// Se valida si el token es valido
		if (dataUserInfo.status === 401) {
			// Si el token caduca se redirige al login
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		}
		// Se asigna la información del usuario
		setUsers(dataUserInfo);
		setLoading(false);
	};
	// useEffect del Data Fetch
	useEffect(() => {
		getUserInfo();
	}, []);
	useEffect(() => {
		getClientInfo();
	}, []);

	// Se hace Data Fetch para obtener la información del usuario
	const getClientInfo = async () => {
		// Se hace la peticion en el endpoint
		const responseClientInfo = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'cliente',
			requestOptions
		);
		// Se asigna la información del usuario
		const dataClientInfo = await responseClientInfo.json();
		if (dataClientInfo.status === 401) {
			// Si el token caduca se redirige al login
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		}
		// Se asigna la información del usuario
		setClient(dataClientInfo.data);
	};

	// Se hace el Data Fetch para actualizar el nombre del usuario
	async function actualizarNombres(setUsers) {
		const requestOptionsGuardar = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify({
				newLastName: setUsers.newLastName,
				newName: setUsers.newName,
			}),
			redirect: 'follow',
		};
		// Se hace la peticion al endpoint
		await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'user/update-name',
			requestOptionsGuardar
		)
			.then((response) => {
				// Se valida el estatus de la peticion
				if (response.status === 200) {
					// Se muestra el mensaje de exito
					setAlertMessage({
						severity: 'success',
						message: t('alertas.nombreGuardado'),
					});
					// Se actualiza la información mostrada del usuario
					getUserInfo();
				} else if (response.status === 401) {
					// Si el token caduca se redirige al login
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					// Se muestra el mensaje de error
					setAlertMessage({
						severity: 'error',
						message: t('alertas.nombreGuardadoError'),
					});
				}
			})
			.catch((error) => console.log('error', error));
	}
	// Se hace el Data Fetch para actualizar el numero del usuario
	async function actualizarNumero(setUsers) {
		const requestOptionsGuardar = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify({
				newPhone: setUsers.newPhone,
			}),
			redirect: 'follow',
		};
		// Se hace la peticion al endpoint
		await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL + 'user/update-phone',
			requestOptionsGuardar
		)
			.then((response) => {
				// Se valida el estatus de la peticion
				if (response.status === 200) {
					// Se muestra el mensaje de exito
					setAlertMessage({
						severity: 'success',
						message: t('alertas.telefonoGuardado'),
					});
					// Se actualiza la información mostrada del usuario
					getUserInfo();
				} else if (response.status === 401) {
					// Si el token caduca se redirige al login
					navigate('/', {
						state: process.env.REACT_APP_ONE_VALID_TOKEN,
					});
				} else {
					// Se muestra el mensaje de error
					setAlertMessage({
						severity: 'error',
						message: t('alertas.telefonoGuardadoError'),
					});
				}
			})
			.catch((error) => console.log('error', error));
	}

	// Funciones para editar el texto de los Textfields
	const handlePhoneChange = (event) => {
		setPhone(event.target.value);
	};
	const handleNombreChange = (event) => {
		setName(event.target.value);
	};
	const handleApellidoChange = (event) => {
		setLastName(event.target.value);
	};

	// Funcion para guardar los datos modificados
	const handleSaveClick = async () => {
		const setUsers = { newName, newLastName, newPhone };
		// Valida que los datos no se manden vacios
		if (setUsers.newName !== '' && setUsers.newLastName !== '') {
			if (
				(setUsers.newPhone !== '' &&
					setUsers.newPhone > 999999999 &&
					setUsers.newPhone < 10000000000) ||
				setUsers.newPhone === '0000000000'
			) {
				await actualizarNumero(setUsers);
				await actualizarNombres(setUsers);
			}
		}
	};

	return (
		// Carga el contenido de la página======================
		<Box>
			<Slide in={checked} direction='up' timeout={900}>
				<Grid item container rowSpacing={1} columnSpacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<CardUser users={users} loading={loading} />
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<CardClient client={client} loading={loading} />
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<CardModificaDatos
							handlePhoneChange={handlePhoneChange}
							handleSaveClick={handleSaveClick}
							alertMessage={alertMessage}
							handleNombreChange={handleNombreChange}
							newName={newName}
							handleApellidoChange={handleApellidoChange}
							newLastName={newLastName}
							newPhone={newPhone}
						/>
					</Grid>
				</Grid>
			</Slide>
		</Box>
	);
}
