import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Fade } from '@mui/material';
import Grow from '@mui/material/Grow';

/*Descripción:Este componente se encarga de desplegar las opciones de el sidebar
Componente padre: ListMenu
Componente hijo:
props: props para desplegar la informacion
*/

export default function SideBarItem(props) {
	// Se crean variables locales con base a las props
	const { icon: Icon } = props;
	const { primaryText } = props;
	const { openValue } = props;

	// Valida si el rol del usuario tiene permisos para acceder a la ruta
	if (!props.roles.includes(props.userRole)) {
		// User role doesn't have permission to access this route, redirect to appropriate page
		return <></>;
	}

	return (
		<Grow in={true} timeout={props.timeout}>
			<ListItemButton
				selected={props.selected}
				component={NavLink}
				to={props.navlink}
				state={{ title: { primaryText }, openValue: { openValue } }}
				id={props.id}
			>
				<ListItemIcon id='Menu-icon'>
					<Tooltip
						title={props.primaryText}
						placement='right'
						disableHoverListener={props.openSideBar}
						arrow
					>
						{Icon && <Icon fontSize='medium' color='primary' />}
					</Tooltip>
				</ListItemIcon>
				<Fade in={props.openSideBar}>
					<ListItemText
						id='Menu-text'
						primary={
							<Typography variant='subtitle2'>
								{props.primaryText}
							</Typography>
						}
					/>
				</Fade>
			</ListItemButton>
		</Grow>
	);
}
