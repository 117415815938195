import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar los eventos del Timeline del Despacho
Componente padre: TimelineSelector
Componente hijo: TImelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosDespacho(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			<TimelineItem
				data={
					props.data['fecha-alta'] !== undefined
						? props.data['fecha-alta']
						: ''
				}
				title={t('eventos.altaDespachoAduanal')}
			/>
			<TimelineItem
				data={
					props.data['fecha-validacion'] !== undefined
						? props.data['fecha-validacion']
						: ''
				}
				title={t('eventos.validacion')}
			/>
			<TimelineItem
				data={
					props.data['fecha-prog-previo'] !== undefined
						? props.data['fecha-prog-previo']
						: ''
				}
				title={t('eventos.programacionPrevio')}
			/>
			<TimelineItem
				data={
					props.data['fecha-previo'] !== undefined
						? props.data['fecha-previo']
						: ''
				}
				title={t('eventos.previo')}
			/>
			<TimelineItem
				data={
					props.data['fecha-modulacion'] !== undefined
						? props.data['fecha-modulacion']
						: ''
				}
				title={t('eventos.modulacion')}
			/>
		</Timeline>
	);
}
