import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	useMediaQuery,
	IconButton,
	Tooltip,
} from '@mui/material';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import ConsignatarioForm from '../../Forms/ConsignatarioForm';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de mostrar el modal de la busqueda de Bookings
Componente padre: DataGridConsignatarios
Componente hijo: ConsignatarioForm
props: props.dataFetch: Función para obtener los datos de la API // props.id: id del consignatario //
	props.codPais: Código del país del consignatario // props.dataFetch: Función para obtener los datos de la API
	props.dataDetallesPaises: Datos de los paises // props.infoConsignatario: Información del consignatario
	props.setInfoConsignatario: Función para actualizar la información del consignatario
	props.handleCloseModal: Función para cerrar el modal // props.openModal: Estado del modal
	props.setOpenModal: Función para actualizar el estado del modal // props.actualizarPais: Función para actualizar el país
	props.dataDetallesPaises: Información de los paises // props.edit: Variable para editar el consignatario
	props.dataFetch: Función para obtener los datos de la API
*/

export default function AgregarConsignatarioModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '60%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: '60%',
		maxHeight: '95%',
		minHeight: '40vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const navigate = useNavigate();
	const [isInvalid, setIsInvalid] = useState(true);
	// Se crean las variables para el uso de Alert
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});
	const { vertical, horizontal } = state;

	// Funciones para abrir y cerrar el Snackbar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	//Se crean los parametros para el metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(props.infoConsignatario),
		redirect: 'follow',
	};

	// Se crea la funcion para editar el texto de los Textfields
	const handleTextFieldChange = (name) => (event) => {
		actualizarDato(name, event.target.value.toUpperCase());
	};

	// Se crea la funcion para actualizar el valor de las cargas
	function actualizarDato(name, value) {
		props.setInfoConsignatario({
			...props.infoConsignatario,
			[name]: value,
		});
	}

	// funcion para validar que el formulario este completo
	function validaCarga() {
		var valida = true;
		Object.keys(props.infoConsignatario).forEach(function (key) {
			if (props.infoConsignatario[key] === '' || isInvalid === 'true') {
				if (isInvalid === 'true') {
					valida = true;
				} else {
					valida = false;
				}
			}
		});
		return valida;
	}

	// funciones para el modal
	const handleSaveModal = () => {
		// Se valida que el formulario de la carga se completo
		if (validaCarga() === true) {
			setLoading(true);
			// Se hace el llamado al metodo Patch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'consignatarios/create',
				requestOptions
			)
				.then((response) => {
					if (response.status === 200) {
						// Si es correcto se actualiza el contenido y se manda el mensaje de exito
						props.setAlertMessagePage({
							severity: 'success',
							message: t('alertas.consignatarioGuardado'),
						});
						props.handleClickPage();
						setLoading(false);
						props.setOpenModal(false);
						props.setInfoConsignatario({
							nombre_razon: '',
							tax_id: '',
							pais: '',
						});
						setTimeout(() => {
							props.dataFetch();
							props.setLoadingButtonConsignatario(false);
						}, 3000);
					} else if (response.status === 401) {
						// Si el token expiro se redirecciona al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						// Si hay un error sobre la carga, se muestra el Alert correspondiente
						props.setAlertMessagePage({
							severity: 'error',
							message: t('alertas.consignatarioGuardadoError'),
						});
						setLoading(false);
						props.handleClickPage();
						props.setLoadingButtonConsignatario(false);
					}
				})
				.catch((error) => console.log('error', error));
		} else {
			// Si no se llena el formulario, se muestra el Alert correspondiente
			setAlertMessage({
				severity: 'error',
				message: t('alertas.consignatarioSinDatos'),
			});
			handleClick1();
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				{loading ? (
					<CircularProgress />
				) : (
					<ValidatorForm
						onSubmit={handleSaveModal}
						instantValidate={false}
					>
						<Grid item container rowSpacing={2}>
							<Grid item container lg={9} md={9} sm={9} xs={9}>
								<Typography variant='h5'>
									{t('modales.agregarConsignatario')}
								</Typography>
							</Grid>
							<Grid
								item
								lg={3}
								md={3}
								sm={3}
								xs={3}
								sx={{ textAlign: 'right' }}
							>
								<Tooltip title={t('botones.cerrar')}>
									<IconButton
										onClick={props.handleCloseModal}
										id='cargaModalCerrarIconButton'
										variant='outlined'
										color='inherit'
										className='cerrarIconButton'
									>
										<Close />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid
								item
								container
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<ConsignatarioForm
									actualizarPais={props.actualizarPais}
									handleTextFieldChange={
										handleTextFieldChange
									}
									actualizarDato={actualizarDato}
									infoConsignatario={props.infoConsignatario}
									handleCloseModal={props.handleCloseModal}
									openModal={props.openModal}
									setOpenModal={props.setOpenModal}
									dataDetallesPaises={
										props.dataDetallesPaises
									}
									edit={false}
									handleKeyPress={handleKeyPress}
								/>
							</Grid>
							<Grid item lg={6} md={6} sm={6} xs={6}>
								<Button
									id='cargaModalCerrarButton'
									variant='outlined'
									color='error'
									onClick={props.handleCloseModal}
								>
									{t('botones.cancelar')}
								</Button>
							</Grid>
							<Grid
								item
								lg={6}
								md={6}
								sm={6}
								xs={6}
								sx={{
									textAlign: 'right',
								}}
							>
								<Button
									id='cargaModalAgregarButton'
									variant='outlined'
									color='primary'
									type='submit'
								>
									{t('botones.agregar')}
								</Button>
							</Grid>
							<Snackbar
								open={snackbar1.open}
								autoHideDuration={10000}
								onClose={handleClose1}
								anchorOrigin={{ vertical, horizontal }}
							>
								<Alert
									onClose={handleClose1}
									severity={alertMessage.severity}
								>
									{alertMessage.message}
								</Alert>
							</Snackbar>
						</Grid>
					</ValidatorForm>
				)}
			</Box>
		</Modal>
	);
}
