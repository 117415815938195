import React from 'react';
import ErrorBoundary from '../../../components/cards/ErrorBoundary';
import { Box, Typography } from '@mui/material';
import CommonBooking from '../../../components/CommonBooking';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla Maritimo Intermodal
y mandar el endpoint a usar
Componente padre: IndexME
Componente hijo: CommonBooking
props: props.openSideBar = Uso del Sidebar
*/

export default function IndexIntermodal() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						borderBottom: 1,
						borderColor: 'Boxider',
					}}
				>
					<Typography variant='h4'>
						{t('sidebar.uscamex.uscamexIntermodal')}
					</Typography>
				</Box>
				<Box sx={{ width: '100%' }}>
					<CommonBooking
						resultComponent='BookingUSCAMEXExpoFTLSearchResult'
						endpoint='bookings/terrestre/uscamex/impo/intermodal'
					/>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
