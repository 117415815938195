import React from 'react';
import { Grid, Typography, Card, CardContent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de Despacho Expo del booking seleccionado
Componente padre: BookingDespachoExpoSR
Componente hijo:
props: props.data = informacion a desplegar
*/

export default function BookingInfoExpo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'row',
				lg: 'row',
				xl: 'row',
			}}
			spacing={1}
		>
			<Card className='paper-hover' sx={{ width: '100%' }}>
				<CardContent>
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'column',
							lg: 'column',
							xl: 'column',
						}}
						spacing={1}
					>
						<Grid item container>
							<Typography variant='subtitle2' color='initial'>
								{t('booking.detalles.embarcador')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.nombre')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.embarcador.nombre}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.direccion')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.embarcador.direccion}{' '}
								{props.data.embarcador.contacto}{' '}
								{props.data.embarcador.taxid}{' '}
								{props.data.embarcador.email}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography variant='subtitle2' color='initial'>
								{t('booking.detalles.informacionCarga')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.trafico')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.trafico}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.estatus.incoterm')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.incoterm}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.aduana')}
							</Typography>
						</Grid>
						<Grid item container>
							<Typography color='initial' class='Datos-bookings'>
								{props.data.aduana}
							</Typography>
						</Grid>
					</Stack>
				</CardContent>
			</Card>
		</Stack>
	);
}
