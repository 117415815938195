import { Typography } from '@mui/material';
import TableAlmacen from '../Tables/TableAlmacen';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la información de el almacen contratado
Componente padre: ServiciosAdicionalesTable
Componente hijo:
props: props.data = informacion a desplegar //
	props.almacen = booleano que indica si se tiene almacen contratado
*/

export default function AcordionAlmacen(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const resultToRender = () => {
		if (props.almacen) {
			return <TableAlmacen data={props.data} />;
		} else {
			return (
				<Typography>{t('eventos.sinServicioContratado')}</Typography>
			);
		}
	};
	return resultToRender();
}
