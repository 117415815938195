import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar los eventos del Timeline del Contenedor
Componente padre: TimelineSelector
Componente hijo: TimelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosContenedor(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las const par el uso del TimelineEventos del contenedor
	const [isFCL, setIsFCL] = useState(false);
	// useEffect para la asignacion de tioi de contenedor
	useEffect(() => {
		// Realiza la validacion de la fecha de entrega de vacio
		if (props.data['fecha-entrega-vacio']) {
			// Si tiene fecha de entrega de vacio, asigna el valor de FCL
			setIsFCL(true);
		} else {
			// Si no tiene fecha de entrega de vacio, no asigna el valor de FCL
			setIsFCL(false);
		}
	}, []);

	// funcion para validar que el dato es valido
	const isInvalidEndpointField = (field) => {
		return (
			field === 'N.D.' ||
			field === undefined ||
			field === null ||
			field === ''
		);
	};

	// funcion para renderizar las tabs del timeline
	const renderTimeLine = (field1, field2, title1, title2) => {
		return isInvalidEndpointField(field1) ? (
			isFCL ? (
				<TimelineItem data={field2} title={title2} />
			) : isInvalidEndpointField(field1) ? (
				<></>
			) : (
				<TimelineItem data={field1} title={title1} />
			)
		) : isFCL ? (
			<TimelineItem data={field1} title={title1} />
		) : (
			<></>
		);
	};

	return (
		<Timeline position='alternate'>
			{renderTimeLine(
				props.data['fecha-llegada-puerto'],
				props.data['fecha-entrega-planta'],
				t('eventos.llegadaPuerto'),
				t('eventos.entregaPlanta')
			)}
			{props.data['fecha-entrega-vacio'] === 'N.D.' ||
			props.data['fecha-entrega-vacio'] === undefined ? (
				<></>
			) : (
				<TimelineItem
					data={props.data['fecha-entrega-vacio']}
					title={t('eventos.entregaVacio')}
				/>
			)}
		</Timeline>
	);
}
