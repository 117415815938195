import { Box, Button, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { PlaylistAdd } from '@mui/icons-material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useTranslation } from 'react-i18next';
import AgregarCargaCartaPorteModal from '../Cargas/Modales/AgregarCargaCartaPorteModal';
import DataGridCargasCartaPorte from '../Cargas/DataGridCargasCartaPorte';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AgregarCargaTemplateCartaPorte from '../Cargas/Modales/AgregarCargaTemplateCartaPorte';
import AgregarCargaExcelTemplate from '../Cargas/Modales/AgregarCargaExcelTemplate';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: CommonLayout.jsx //
Componente hijo: FileUploadModal
props: props.selectedBooking = booking seleccionado
*/

export default function ListadoCargas(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean las funciones del modal
	const [openModalCargaCartaPorte, setOpenModalCargaCartaPorte] =
		useState(false);
	const handleOpenModalCargaCartaPorte = () => {
		setOpenModalCargaCartaPorte(true);
	};
	const handleCloseModalCargaCartaPorte = () => {
		setOpenModalCargaCartaPorte(false);
	};

	// Se crean las funciones del modal con catalogo
	const [openModalCatalogo, setOpenModalCatalogo] = useState(false);
	const handleOpenModalCatalogo = () => {
		setOpenModalCatalogo(true);
	};
	const handleCloseModalCatalogo = () => {
		setOpenModalCatalogo(false);
	};

	//Se crea funcion para abrir modal de carga con template
	const [openModalCargaTemplate, setOpenModalCargaTemplate] = useState(false);
	const handleOpenModalCargaTemplate = () => {
		setOpenModalCargaTemplate(true);
	};
	const handleCloseModalCargaTemplate = () => {
		setOpenModalCargaTemplate(false);
	};

	return (
		<Box>
			<Grid item container marginTop={1}>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ textAlign: 'right' }}
				>
					<Grid
						item
						container
						spacing={2}
						justifyContent={'flex-end'}
					>
						<Grid item>
							<Tooltip title={t('botones.agregarConTemplate')}>
								<Button
									variant='outlined'
									color='primary'
									id='BtnAgregarCargaTemplateCP'
									sx={{
										fontSize: '12px',

										marginBottom: 1,
									}}
									onClick={handleOpenModalCatalogo}
								>
									<NoteAddIcon />
								</Button>
							</Tooltip>
							<AgregarCargaTemplateCartaPorte
								updateCartaPorte={props.updateCartaPorte}
								dataFetch={props.dataFetch}
								dataDetalles={props.dataDetalles}
								selectedBooking={props.selectedBooking}
								openModal={openModalCatalogo}
								handleCloseModalCargaCartaPorte={
									handleCloseModalCatalogo
								}
								alertMessage={props.alertMessage}
								setAlertMessage={props.setAlertMessage}
								handleOpenSnackBar={props.handleOpenSnackBar}
								handleCloseSnackbar={props.handleCloseSnackbar}
							/>
						</Grid>
						<Grid item>
							<Tooltip title={t('botones.agregar')}>
								<Button
									variant='outlined'
									color='primary'
									id='BtnAgregarCargaCP'
									sx={{
										fontSize: '12px',
										marginBottom: 1,
									}}
									onClick={handleOpenModalCargaCartaPorte}
								>
									<PlaylistAdd />
								</Button>
							</Tooltip>
							<AgregarCargaCartaPorteModal
								updateCartaPorte={props.updateCartaPorte}
								dataFetch={props.dataFetch}
								dataDetalles={props.dataDetalles}
								selectedBooking={props.selectedBooking}
								openModal={openModalCargaCartaPorte}
								handleCloseModalCargaCartaPorte={
									handleCloseModalCargaCartaPorte
								}
								alertMessage={props.alertMessage}
								setAlertMessage={props.setAlertMessage}
								handleOpenSnackBar={props.handleOpenSnackBar}
								handleCloseSnackbar={props.handleCloseSnackbar}
							/>
						</Grid>
						<Grid item>
							<Tooltip title={t('botones.subirPlantilla')}>
								<Button
									variant='outlined'
									color='primary'
									id='BtnAgregarCargaExcelCP'
									sx={{
										fontSize: '12px',
										marginBottom: 1,
									}}
									onClick={handleOpenModalCargaTemplate}
								>
									<FileUploadIcon />
								</Button>
							</Tooltip>
							<AgregarCargaExcelTemplate
								updateCartaPorte={props.updateCartaPorte}
								dataFetch={props.dataFetch}
								dataDetalles={props.dataDetalles}
								selectedBooking={props.selectedBooking}
								openModal={openModalCargaTemplate}
								handleCloseModalCargaCartaPorte={
									handleCloseModalCargaTemplate
								}
								alertMessage={props.alertMessage}
								setAlertMessage={props.setAlertMessage}
								handleOpenSnackBar={props.handleOpenSnackBar}
								handleCloseSnackbar={props.handleCloseSnackbar}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<DataGridCargasCartaPorte
						dataFetch={props.dataFetch}
						dataDetalles={props.dataDetalles}
						selectedBooking={props.selectedBooking}
						dataCargas={props.dataDetalles.cargas}
						alertMessage={props.alertMessage}
						setAlertMessage={props.setAlertMessage}
						handleOpenSnackBar={props.handleOpenSnackBar}
						handleCloseSnackbar={props.handleCloseSnackbar}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
