import React from 'react';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material/';

/*Descripción:Este componente se encarga de hacer una pantalla de carga de una lista
Componente padre: Global
Componente hijo: Global
props: Sin uso de props
*/

export default function MySkeletonList() {
	return (
		<Box sx={{ justifyContent: 'center', paddingLeft: '2%' }}>
			{[...Array(4)].map((_) => (
				<Skeleton animation='wave' height='50px' width='27vh' />
			))}
		</Box>
	);
}
