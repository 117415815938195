import { Edit } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import EditarUsuarioModal from '../Modales/EditarUsuarioModal';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el boton de editar
Componente padre: DataGridUsuarios
Componente hijo: EditarUsuarioModal
props: props.id = Información del ID de la fila //
	props.handleCheck = Funcion para validar el checkbox
*/

export default function EditIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [infoUsuario, setInfoUsuario] = useState({
		name: '',
		lastname: '',
		username: '',
		phone: '',
		role: '',
	});
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};
	// Se hace el Data Fetch
	const dataFetch = async () => {
		// Se hace la peticion el endpoint
		const tempDataDetalles = await fetch(
			process.env.REACT_APP_API_ACCUTRACK_URL +
				'user-admin/user-info?email=' +
				props.id,
			requestOptions
		);
		// Se valida el status de la peticion
		if (tempDataDetalles.status === 200) {
			let data = await tempDataDetalles.json();
			// Se valida el rol del usuario
			if (
				data.data.user.role === 'ROLE_CLIENTE_DIRECTO_ADMIN' ||
				data.data.user.role === 'ROLE_AGENTE_EXTRANJERO_ADMIN'
			) {
				props.handleCheck2(true);
			} else {
				props.handleCheck2(false);
			}
			// Se actualiza el estado del componente
			setInfoUsuario({
				name: data.data.user.name,
				lastname: data.data.user.lastname,
				username: data.data.user.username,
				phone: data.data.user.phone,
				role: data.data.user.role,
			});
		} else if (tempDataDetalles.status === 401) {
			// Si el token caduca, se redirige al login
			navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
		} else if (tempDataDetalles.status === 504) {
			console.log('Error 504');
		}
	};

	// Funcion para cerrar el modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// Funcion para actualizar el texto del Textfield
	const handleTextFieldChange = (name) => (event) => {
		if (name === 'phone') {
			const value = event.target.value
				.replace(/\s/g, '')
				.replace(/[^\d]/g, '');
			actualizarDato(name, value);
		} else {
			actualizarDato(name, event.target.value);
		}
	};
	// Funcion para actualizar el estado del componente
	function actualizarDato(name, value) {
		setInfoUsuario({
			...infoUsuario,
			[name]: value,
		});
	}
	// Funcion para manejar el click del boton de editar
	const handleEditClick = async () => {
		// Fetch Data
		await dataFetch();
		// Abre el modal
		setOpenModal(true);
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.editar')}>
						<IconButton
							id='editIconButtonDataGrid'
							className='primary'
							aria-label='add an alarm'
							onClick={handleEditClick}
						>
							<Edit color='primary' />
						</IconButton>
					</Tooltip>
				}
			/>
			<EditarUsuarioModal
				role={props.role}
				handleCheck={props.handleCheck}
				handleCheck2={props.handleCheck2}
				isChecked={props.isChecked}
				dataFetch={props.dataFetch}
				handleClick1={props.handleClick1}
				setAlertMessage={props.setAlertMessage}
				handleTextFieldChange={handleTextFieldChange}
				actualizarDato={actualizarDato}
				infoUsuario={infoUsuario}
				handleCloseModal={handleCloseModal}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
		</>
	);
}
