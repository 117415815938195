import { Box } from '@mui/material';
import React, { useState } from 'react';
import ErrorBoundary from '../../../components/cards/ErrorBoundary';
import CommonBooking from '../../../components/CommonBooking';
import { useTranslation } from 'react-i18next';
import BoxTitleWithTabs from '../../../components/titles/BoxTitleWithTabs';

/*Descripción:Este componente se encarga de renderizar el contenido de las opciones de la pantalla Maritimo Expo
y mandar el endpoint a usar
Componente padre: CommonLayout.jsx
Componente hijo: FullContainer //
	Consolidado
props: Sin uso de props
*/

export default function IndexME() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [value, setValue] = useState(0);
	// Funcion para cambiar de tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const tabs = [
		{ id: 'TabFullContainer', label: t('booking.fullContainer') },
		{ id: 'TabConsolidado', label: t('booking.consolidado') },
	];

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box>
				<BoxTitleWithTabs
					title={t('paginas.maritimoExpo')}
					tabs={tabs}
					value={value}
					handleChange={handleChange}
				/>
				{/* Tab 1: Full Container */}
				<Box hidden={value !== 0}>
					{value === 0 && (
						<CommonBooking
							resultComponent='BookingMaritimoExpoSearchResult'
							endpoint='bookings/maritimo/exportacion/fcl'
						/>
					)}
				</Box>
				{/* Tab 2: Consolidado */}
				<Box hidden={value !== 1}>
					{value === 1 && (
						<CommonBooking
							resultComponent='BookingMaritimoExpoSearchResult'
							endpoint='bookings/maritimo/exportacion/lcl'
						/>
					)}
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
