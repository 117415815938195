import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion del contenedor relaciobada a TN
Componente padre: EventosBookingTerrestreCentroame
Componente hijo:
props: props.dataEmpaque = informacion a desplegar sobre el empaque
*/

export default function InfoContenedorTCAExpoFCL(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Grid item container spacing={1} lg={12} md={12} sm={12} xs={12}>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.peso')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque.peso}
				</Typography>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.cantidad')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque.cantidad}
				</Typography>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.tipoEmpaque')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque.empaque}
				</Typography>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.producto')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque.producto}
				</Typography>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.volumen')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.dataEmpaque.volumen}
				</Typography>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.incoterm')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.incoterm}
				</Typography>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('tablero.bookingsActivos.origen')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.origenDestino.origen}
				</Typography>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.estatus.puntoCruce')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.origenDestino.puntoCruce}
				</Typography>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={6}>
				<Typography
					variant='body2'
					class='Datos-bookings-titulos'
					color='initial'
				>
					{t('booking.detalles.destinoFinal')}
				</Typography>
				<Typography color='initial' class='Datos-bookings'>
					{props.data.origenDestino.destinoFinal}
				</Typography>
			</Grid>

			{props.dataEmpaque.cargaPeligrosa === undefined &&
			props.dataEmpaque.cargaPeligrosa === '' ? (
				<>
					{props.dataEmpaque.cargaPeligrosa === 'N' ? (
						<></>
					) : (
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.estatus.cargaPeligrosa')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.dataEmpaque.cargaPeligrosa === 'S' && (
									<Warning sx={{ color: 'red' }} />
								)}{' '}
							</Typography>
						</Grid>
					)}
				</>
			) : (
				<></>
			)}
		</Grid>
	);
}
