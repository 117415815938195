import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import ErrorBoundary from '../../../components/cards/ErrorBoundary';
import CommonBooking from '../../../components/CommonBooking';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el contenido de las opciones de la pantalla Maritimo Expo
y mandar el endpoint a usar
Componente padre: CommonLayout.jsx
Componente hijo: FullContainer //
	Consolidado
props: Sin uso de props
*/

export default function IndexUSCAMEXExpo() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const [value, setValue] = useState(0);
	// Funcion para cambiar de tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant='h4'>
						{t('sidebar.uscamex.uscamexExportacion')}
					</Typography>
				</Box>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'Boxider' }}>
						<Tabs value={value} onChange={handleChange}>
							<Tab
								id='TabFullContainer'
								label={t('booking.fullContainer')}
								sx={{ color: 'grey' }}
							/>
							<Tab
								id='TabConsolidado'
								label={t('booking.consolidado')}
								sx={{ color: 'grey' }}
							/>
						</Tabs>
					</Box>
					{/* Tab 1: Full Container */}
					<Box hidden={value !== 0}>
						{value === 0 && (
							<CommonBooking
								resultComponent='BookingUSCAMEXExpoFTLSearchResult'
								endpoint='bookings/terrestre/uscamex/expo/ftl'
							/>
						)}
					</Box>
					{/* Tab 2: Consolidado */}
					<Box hidden={value !== 1}>
						{value === 1 && (
							<CommonBooking
								resultComponent='BookingUSCAMEXExpoFTLSearchResult'
								endpoint='bookings/terrestre/uscamex/expo/ltl'
							/>
						)}
					</Box>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
