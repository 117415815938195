import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TimelineEventosBooking from './TimelineEventosBooking';
import TimelineEventosContenedor from './TimelineEventosContenedor';
import TimelineEventosDespacho from './TimelineEventosDespacho';
import TimelineEventosTerminal from './TimelineEventosTerminal';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useEffect, useState } from 'react';

/*Descripción:Este componente se encarga de desplegar el Timeline de los eventos
Componente padre: EventosBookingMaritimoExpo
Componente hijo: TimelineEventosContenedor//
	TimelineEventosTerminal //
	TimelineEventosDespacho //
	TimelineEventosBooking
props: props.data = informacion a desplegar //
	props.dataDespacho = informacion a desplegar sobre el despacho //
	props.dataContenedor = informacion a desplegar sobre el contenedor
*/

export default function TimelineSelectorMaritimoExpo(props) {
	// Se crean las const para el uso del TimelineSelector
	const [value, setValue] = useState('1');
	//Se configura el tamaño del arreglo del despacho
	const [lenghtDespacho, setLengthDespacho] = useState(
		Object.keys(props.dataDespacho).length
	);
	// funcion para seleccionar la tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// useEffect para seleccionar el valor del tab
	useEffect(() => {
		// Se valida si tiene terrestre nacional
		if (props.data.bookingTN !== 'Sin Terrestre Nacional') {
			// Si tiene terrestre nacional selecciona la primer tab
			setValue('1');
		} else {
			// Si no tiene terrestre nacional selecciona la segunda tab
			setValue('2');
		}
		// Asigna el tamaño del arreglo del desacho
		setLengthDespacho(Object.keys(props.dataDespacho).length);
	}, []);

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			{props.role.includes('Cliente') ? (
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} centered>
							{props.data.bookingTN !==
								'Sin Terrestre Nacional' && (
								<Tab
									icon={<LocalShippingIcon />}
									value='1'
									sx={{ color: 'grey' }}
								/>
							)}
							<Tab
								icon={<WarehouseIcon />}
								value='2'
								sx={{ color: 'grey' }}
							/>
							{lenghtDespacho !== 0 && (
								<Tab
									icon={<AssuredWorkloadIcon />}
									value='3'
									sx={{ color: 'grey' }}
								/>
							)}
							<Tab
								icon={<DirectionsBoatIcon />}
								value='4'
								sx={{ color: 'grey' }}
							/>
						</TabList>
					</Box>
					<TabPanel
						value='1'
						sx={{ maxHeight: '45vh', overflowY: 'auto' }}
					>
						<TimelineEventosContenedor
							data={props.dataContenedor}
							dataBooking={props.data}
						/>
					</TabPanel>
					<TabPanel
						value='2'
						sx={{ maxHeight: '45vh', overflowY: 'auto' }}
					>
						<TimelineEventosTerminal
							data={props.dataContenedor}
							dataBooking={props.data}
						/>
					</TabPanel>
					{lenghtDespacho !== 0 && (
						<TabPanel
							value='3'
							sx={{ maxHeight: '45vh', overflowY: 'auto' }}
						>
							<TimelineEventosDespacho
								data={props.dataDespacho}
							/>
						</TabPanel>
					)}
					<TabPanel
						value='4'
						sx={{ maxHeight: '45vh', overflowY: 'auto' }}
					>
						<TimelineEventosBooking
							data={props.data}
							dataContenedor={props.dataContenedor}
						/>
					</TabPanel>
				</TabContext>
			) : (
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} centered>
							<Tab
								icon={<WarehouseIcon />}
								value='1'
								sx={{ color: 'grey' }}
							/>
							<Tab
								icon={<DirectionsBoatIcon />}
								value='2'
								sx={{ color: 'grey' }}
							/>
						</TabList>
					</Box>
					<TabPanel
						value='1'
						sx={{ maxHeight: '45vh', overflowY: 'auto' }}
					>
						<TimelineEventosTerminal
							data={props.dataContenedor}
							dataBooking={props.data}
						/>
					</TabPanel>
					<TabPanel
						value='2'
						sx={{ maxHeight: '45vh', overflowY: 'auto' }}
					>
						<TimelineEventosBooking
							data={props.data}
							dataContenedor={props.dataContenedor}
						/>
					</TabPanel>
				</TabContext>
			)}
		</Box>
	);
}
