import React, { useEffect } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el DataGrid de las cargas del InfoStepper
Componente padre: ListadoCargas
Componente hijo: EditIconDataGrid // DeleteIconDataGrid
props: props.dataGrid = información a desplegar del DataGrid
*/

export default function InfoBlDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crea un arreglo con la información de las cargas
	const tableData = [];
	for (let i = 0; i < props.dataGrid.length; i++) {
		const carga = props.dataGrid[i];
		if (carga) {
			const rowData = {
				id: carga.idCarga,
				descripcion_esp: carga.descripcion_esp,
				cantidad: carga.cantidad_bultos,
				peso: carga.peso + ' KG',
				volumen: carga.volumen + ' CBM',
			};
			tableData.push(rowData);
		}
	}

	useEffect(() => {}, [props.dataGrid]);

	// Se cran las columnas del DataGrid
	const headCells = [
		{
			id: 'descripcion_esp',
			field: 'descripcion_esp',
			width: 650,
			headerName: t('booking.detalles.producto'),
			editable: false,
		},
		{
			id: 'cantidad',
			field: 'cantidad',
			width: 100,
			headerName: t('booking.detalles.cantidad'),
			editable: false,
		},
		{
			id: 'peso',
			field: 'peso',
			width: 100,
			headerName: t('booking.detalles.peso'),
			editable: false,
		},
		{
			id: 'volumen',
			field: 'volumen',
			width: 100,
			headerName: t('booking.detalles.volumen'),
			editable: false,
		},
	];

	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<Box className='ListaCargas'>
			<DataGrid
				localeText={localeText()}
				rows={tableData}
				columns={headCells}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 5 },
					},
				}}
				pageSizeOptions={[5, 10, 20]}
				sx={{
					'& .MuiDataGrid-cell': {
						fontSize: '12px',
						fontFamily: 'Source Sans Pro',
					},
					'& .MuiDataGrid-columnHeaderTitle': {
						fontSize: '13px',
						fontFamily: 'Open Sans',
					},
				}}
			/>
		</Box>
	);
}
