import React from 'react';
import Box from '@mui/material/Box';
import {
	Modal,
	Typography,
	Button,
	Grid,
	useMediaQuery,
	IconButton,
	Tooltip,
} from '@mui/material';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DatosProductoForm from '../Forms/DatosProductoForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de mostrar el modal de editar producto
Componente padre: EditiconDataGrid
Componente hijo:
props:props.openModal = funcion para abrir el modal //
	props.handleClose = funcion para cerrar el modal //
	props.handelCloseModal = funcion para cuando cierre el modal
*/

export default function EditarProductoModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '65%';
	// Se crea el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: '65%',
		maxHeight: '95%',
		minHeight: '45vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
	const [snackbar1, setSnackbar1] = useState({ open: false, message: ' ' });
	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});
	const { vertical, horizontal } = state;
	const navigate = useNavigate();
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', 'Bearer ' + token);
	// Se crea la funcion para guardar el producto
	const handleSaveModal = () => {
		//props.infoUsuario.role = props.role;
		const requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
			body: JSON.stringify(props.dataDetalles),
			redirect: 'follow',
		};
		// Se hace la validación de los campos del formulario
		if (validaProducto() === true) {
			// Se realiza el Data Fetch
			fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'catalogos/productos-por-cliente/update',
				requestOptions
			)
				.then((response) => {
					// Se valida el estatus de la peticion
					if (response.status === 200) {
						// Se actualiza la informacion mostrada
						//props.dataFetch(); //DESCOMENTAR CUANDO YA GUARDE BIEN TODOS LOS CAMPOS
						// Se crea el mensaje de exito
						props.setAlertMessage({
							severity: 'success',
							message: t('catalogos.productos.productoEdito'),
						});
						// Se muestra el mensaje de exito
						props.handleClick1();
						props.dataFetch();
					} else if (response.status === 401) {
						// Si el token caduca se redirige al login
						navigate('/', {
							state: process.env.REACT_APP_ONE_VALID_TOKEN,
						});
					} else {
						props.setAlertMessage({
							severity: 'error',
							message: t('catalogos.productos.error'),
						});
						// Se muestra el mensaje de exito
						props.handleClick1();
					}
				})
				.catch((error) => console.log('error', error));
			// Se cierra el modal
			props.setOpenModal(false);
		} else {
			// Se crea el mensaje de error al no llenar el formulario
			setAlertMessage({
				severity: 'error',
				message: t('alertas.datosIncompletos'),
			});
			// Se muestra el mensaje de error
			handleClick1();
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	// Se crea la funcion para validar que todos los campos esten llenos
	function validaProducto() {
		var valida = true;
		Object.keys(props.dataDetalles).forEach(function (key) {
			if (key === 'esPeligroso' && props.dataDetalles[key] === true) {
				if (
					props.dataDetalles['clasePeligroso'] === null ||
					props.dataDetalles['codUsoPeligroso'] === null
				) {
					valida = false;
				}
			}
		});
		return valida;
	}

	// Funciones para abrir y cerrar el Snackbar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	return (
		<Modal open={props.openModal} onClose={props.handleClose}>
			<Box sx={style}>
				<ValidatorForm
					onSubmit={handleSaveModal}
					instantValidate={true}
				>
					<Grid item container rowSpacing={2}>
						<Grid item lg={9} md={9} sm={9} xs={9}>
							<Typography variant='h5'>
								{t('catalogos.productos.editarProducto')}
							</Typography>
						</Grid>
						<Grid
							item
							lg={3}
							md={3}
							sm={3}
							xs={3}
							sx={{ textAlign: 'right' }}
						>
							<Tooltip title={t('botones.cerrar')}>
								<IconButton
									onClick={props.handleCloseModal}
									id='cargaModalCerrarIconButton'
									variant='outlined'
									color='inherit'
									className='cerrarIconButton'
								>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							{props.loading ? (
								<></>
							) : (
								<DatosProductoForm
									agregarUsuario={false}
									handleCheck={props.handleCheck}
									isChecked={props.isChecked}
									dataFetch={props.dataFetch}
									handleClick1={props.handleClick1}
									setAlertMessage={props.setAlertMessage}
									handleTextFieldChange={
										props.handleTextFieldChange
									}
									actualizarDato={props.actualizarDato}
									infoProducto={props.dataDetalles}
									handleCloseModal={props.handleCloseModal}
									openModal={props.openModal}
									setOpenModal={props.setOpenModal}
									handleKeyPress={handleKeyPress}
								/>
							)}
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Button
								id='cargaModalCerrarButton'
								variant='outlined'
								color='error'
								onClick={props.handleCloseModal}
							>
								{t('botones.cancelar')}
							</Button>
						</Grid>
						<Grid
							item
							lg={6}
							md={6}
							sm={6}
							xs={6}
							sx={{
								textAlign: 'right',
							}}
						>
							<Button
								id='cargaModalActualizarButton'
								variant='outlined'
								color='primary'
								type='submit'
							>
								{t('botones.actualizar')}
							</Button>
						</Grid>
						<Snackbar
							open={snackbar1.open}
							autoHideDuration={10000}
							onClose={handleClose1}
							anchorOrigin={{ vertical, horizontal }}
						>
							<Alert
								onClose={handleClose1}
								severity={alertMessage.severity}
							>
								{alertMessage.message}
							</Alert>
						</Snackbar>
					</Grid>
				</ValidatorForm>
			</Box>
		</Modal>
	);
}
