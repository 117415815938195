import {
	Grid,
	Typography,
	LinearProgress,
	Card,
	CardContent,
	Stack,
	IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PreviewFacturaCard from './Cards/PreviewFacturaCard';
import ConceptosDataGrid from './ConceptosDataGrid';
import EmisorCard from './Cards/EmisorCard';
import TotalesCard from './Cards/TotalesCard';
import ComprobanteCard from './Cards/ComprobanteCard';
import FiscalesCard from './Cards/FiscalesCard';
import GetDataFetchFacturas from '../get-data/GetDataFetchFacturas';
import SinFacturaAzure from '../cards/SinFacturaAzure';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '@mui/icons-material';

/*Descripción: componente que muestra la información de una factura xml
 */

export default function DetallesFactura(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	let url;
	const navigate = useNavigate();
	// Busca la info de la factura por el num de factura y la fecha de fact
	// Obtener los parámetros recibidos en la url
	const factura = searchParams.get('folio'); // ruta xml o num factura
	const anio = searchParams.get('year');
	const mes = searchParams.get('month');
	const myURI = 'factura=' + factura + '&anio=' + anio + '&mes=' + mes;
	url = 'facturacion/facturas-por-booking/detalles-por-numfac?' + myURI;

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		// Se hace el Get Fetch para la informacion
		await GetDataFetchFacturas(url, setLoading, setDataDetalles, navigate);
	};

	const handleCloseFolio = () => {
		navigate('/estado-de-cuenta?activo=facturas');
	};

	useEffect(() => {
		dataFetch();
	}, [props.factura]);

	// Funcion para mostrar el menu de Estado de cuentas
	const resultToRender = () => {
		if (props.rutaActiva) {
			<></>;
		} else {
			// Obtener los parámetros recibidos en la url
			const factura = searchParams.get('folio');

			return (
				<Card
					elevation={3}
					sx={{ paddingTop: '0px' }}
					className='fac-card-top paper-hover'
				>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'row',
								xl: 'row',
							}}
							sx={{ justifyContent: 'space-between' }}
							alignItems='center'
						>
							<Stack
								direction='row'
								spacing={1}
								alignItems='center'
							>
								<Grid item sx={{ textAlign: 'left' }}>
									<IconButton
										size='small'
										id='cerrarButton'
										variant='outlined'
										className='cerrarIconButton'
										onClick={handleCloseFolio}
									>
										<ArrowBack />
									</IconButton>
								</Grid>
								<Grid item>
									<Typography variant='h6'>
										{factura}
									</Typography>
								</Grid>
							</Stack>
							<Grid item>
								<PreviewFacturaCard
									rutaActiva={false}
									indexFactura={factura}
								/>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
			);
		}
	};

	const renderFactura = () => {
		if (dataDetalles === 'Not found') {
			return (
				<SinFacturaAzure
					texto={t('cards.subiendoFacturaAzure')}
					navigateRoute='/estado-de-cuenta?activo=facturas'
				/>
			);
		} else {
			return (
				<Stack
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'column',
						lg: 'column',
						xl: 'column',
					}}
					spacing={2}
				>
					{resultToRender()}
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'row',
							lg: 'row',
							xl: 'row',
						}}
						spacing={1}
					>
						{/* EMISOR / RECEPTOR */}
						<EmisorCard dataDetalles={dataDetalles} />
						{/* TOTALES */}
						<TotalesCard dataDetalles={dataDetalles} />
					</Stack>
					{/* CONCEPTOS */}
					<Card elevation={3} className='fac-card-top paper-hover'>
						<CardContent>
							<ConceptosDataGrid
								dataDetalles={dataDetalles}
								headCells={headCells}
							/>
						</CardContent>
					</Card>
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'row',
							lg: 'row',
							xl: 'row',
						}}
						spacing={1}
					>
						{/* COMPROBANTE */}
						<ComprobanteCard dataDetalles={dataDetalles} />
						{/* FISCALES */}
						<FiscalesCard dataDetalles={dataDetalles} />
					</Stack>
				</Stack>
			);
		}
	};

	const headCells = [
		{
			id: 'claveProdServ',
			field: 'claveProdServ',
			width: 150,
			headerName: t('dataGrid.claveProducto'),
			editable: false,
		},
		{
			id: 'cantidad',
			field: 'cantidad',
			width: 100,
			headerName: t('dataGrid.cantidad'),
			editable: false,
		},
		{
			id: 'claveUnidad',
			field: 'claveUnidad',
			width: 100,
			headerName: t('dataGrid.unidad'),
			editable: false,
		},
		{
			id: 'descripcion',
			field: 'descripcion',
			width: 320,
			headerName: t('dataGrid.descripcion'),
			editable: false,
		},
		{
			id: 'valorUnitario',
			field: 'valorUnitario',
			width: 120,
			headerName: t('dataGrid.precioUnitario'),
			editable: false,
		},
		{
			id: 'importe',
			field: 'importe',
			width: 100,
			headerName: t('dataGrid.monto'),
			editable: false,
		},
	];

	// =============================================================================
	return (
		// Carga el contenido de la página
		<>{loading ? <LinearProgress /> : <>{renderFactura()}</>}</>
	);
}
