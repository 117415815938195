import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingInfoExpo from './Info/InfoBookingExpo';
import EventosBookingAereoExpo from './EventosBookingAereoExpo';
import GetDataFetch from '../../../get-data/GetDataFetch';
import GetDocumentosFetch from '../../../get-data/GetDocumentosFetch';
import FileUploadModal from '../../../file-upload/FileUploadModal';
import BookingWithTabs from '../../../titles/BookingWithTabs';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Aereo Expo
Componente padre: CommonBooking
Componente hijo: BookingInfo //
	EventosBookingAereoExpo //
	IndexFileUpload
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición
*/

export default function BookingAereoExpoSearchResult(props) {
	// Se crean las constantes para el uso del SR
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	const [dataDetallesDocumentos, setDataDetallesDocumentos] = useState();
	const [infoDocumento, setInfoDocumento] = useState({
		uiCodTipoDocumento: '',
		vDescripcionEspaniol: '',
		vDescripcionIngles: '',
	});
	const [documento, setDocumento] = useState('');
	const [indexDocumento, setIndexDocumento] = useState('0');
	const [loadingDocumentos, setLoadingDocumentos] = useState(true);
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});

	// Funcion para actualizar el contenido del Documento
	function actualizarDocumento(documento) {
		setDocumento(documento.value + '');
		setIndexDocumento(documento.label + '');
	}

	//Funcion para obtener las letras clave de la palabra
	async function caracteresClave() {
		let letras = [];
		// Dependiendo del tamaño de la palabra se recolectan los caracteres relevantes del booking
		if (props.selectedBooking.length === 18) {
			// Si es un RO tomamos los primeros 4 elementos del array y el ultimo
			letras = [
				props.selectedBooking.slice(0, 4),
				props.selectedBooking.slice(-1),
			];
		} else if (props.selectedBooking.length === 17) {
			// Si es un booking "Normal" tomamos los primeros 3 elementos del array y el ultimo
			letras = [
				props.selectedBooking.slice(0, 3),
				props.selectedBooking.slice(-1),
			];
		} else if (props.selectedBooking.length === 15) {
			// Si es un despacho tomamos los primeros 3 elementos del array y el ultimo
			letras = [
				props.selectedBooking.slice(0, 3),
				props.selectedBooking.slice(-1),
			];
		}
		// Se convierte el array de letras en un string y luego se convierte en un array de caracteres
		const letrasArray = letras.join('').split('');

		setLoadingDocumentos(true);
		await GetDocumentosFetch(
			letrasArray,
			props.selectedBooking,
			setLoadingDocumentos,
			setDataDetallesDocumentos,
			navigate
		);
	}

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		// Se crea el endpoint
		var url = props.endpoint + '/detalles?booking=' + props.selectedBooking;
		// Se hace el Get Fetch de la información
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	// useEffect para la selección del booking predeterminado
	useEffect(() => {
		if (props.selectedBooking !== '') {
			dataFetch();
			caracteresClave();
		}
	}, [props.selectedBooking]);

	return (
		<>
			<BookingWithTabs
				selectedBooking={props.selectedBooking}
				TabPanelDetalles={
					<BookingInfoExpo
						data={dataDetalles}
						selectedBooking={props.selectedBooking}
						loading={loading}
					/>
				}
				TabPanelEstatus={
					<EventosBookingAereoExpo
						data={dataDetalles}
						selectedBooking={props.selectedBooking}
						loading={loading}
						className='paper-hover'
					/>
				}
				TabPanelServiciosAdicionales={'NA'}
				TabPanelDocumentos={
					<FileUploadModal
						modal={false}
						selectedBooking={props.selectedBooking}
						dataDetallesDocumentos={dataDetallesDocumentos}
						infoDocumento={infoDocumento}
						actualizarDocumento={actualizarDocumento}
						documento={documento}
						indexDocumento={indexDocumento}
						setDocumento={setDocumento}
						setIndexDocumento={setIndexDocumento}
						setInfoDocumento={setInfoDocumento}
						state={state}
					/>
				}
				loading={loading}
				loadingDocumentos={loadingDocumentos}
			/>
		</>
	);
}
