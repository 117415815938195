import * as React from 'react';
import { TextField, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation, withTranslation } from 'react-i18next';
import { useState } from 'react';

const PasswordForm = ({ sendData }) => {
	// Funcion para traducir el texto
	const { t } = useTranslation();
	const [state, setState] = useState({
		password: '',
		confirmPassword: '',
		error: '',
		loading: false,
	});

	const handleChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value,
			error: '',
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const { password, confirmPassword } = state;

		if (password.length < 8) {
			setState({ ...state, error: t('alertas.contrasenaCorta') });
			return;
		} else if (password !== confirmPassword) {
			setState({ ...state, error: t('alertas.contrasenaNoCoincide') });
			return;
		} else {
			sendData(password);
			setState({ ...state, loading: true });
			setTimeout(() => {
				setState({ ...state, loading: false });
			}, 5000);
		}
	};

	const { password, confirmPassword, error, loading } = state;

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={0} justifyContent={'center'}>
				<TextField
					fullWidth
					label={t('inicio.contrasena')}
					type='password'
					name='password'
					value={password}
					onChange={handleChange}
					required
				/>
			</Grid>
			<Grid
				container
				spacing={0}
				justifyContent={'center'}
				paddingTop={2}
			>
				<TextField
					fullWidth
					label={t('inicio.confirmaContrasena')}
					type='password'
					name='confirmPassword'
					value={confirmPassword}
					onChange={handleChange}
					required
					error={error.length > 0}
					helperText={error}
				/>
			</Grid>
			<LoadingButton
				loading={loading}
				id='login-button'
				type='submit'
				fullWidth
				variant='contained'
				sx={{
					mt: 3,
					mb: 2,
					backgroundColor: '#002D58',
				}}
			>
				{t('inicio.crearContrasena')}
			</LoadingButton>
		</form>
	);
};

export default withTranslation()(PasswordForm);
